import { types, applySnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { TableSorter } from "modules/common/models/table-sorter";
import { TablePager } from "modules/common/models/table-pager";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { DEFAULT_SORTING_ASCENDING_VALUE, DATE_TIME_FORMAT, EMPTY_OBJECT_ID } from "modules/common/constants";
import { capitalize, trimStart } from "modules/common/services/strings";
import {
    SaveOrderMailValue,
    OrderMail,
    OrderMailSnapshotType,
    initialState as emptyMail,
    fields as mailFields,
} from "modules/orders-manage/models/order-mails";
import { SortChangedEvent } from "@ag-grid-community/core";
import { formatDate } from "modules/common/services/formatting/date";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { OrderDictionary, initialState as emptyOrders } from "modules/orders-manage/models/order-dictionary";
import { isNewlyCreated } from "modules/common/models/entity";
import { texts } from "modules/common/texts";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { Queryable } from "modules/common/models/queryable";
import { saveAs } from "file-saver";
import {
    EmployerDictionary,
    initialState as empltyEmployee,
} from "modules/spending/employee/models/employee-dictionary";
import { RenderCache } from "modules/orders-manage/models/render-cache";
import { parse } from "query-string";

const DEFAULT_COLUMN = "Date";

export const OrderMailsStore = types
    .compose(
        Notificator,
        Transport,
        TablePager,
        Queryable,
        types.model({
            mails: types.array(OrderMail),
            orders: OrderDictionary,
            employee: EmployerDictionary,
            sorter: TableSorter,
            renderer: RenderCache,

            // filters part
            filtersType: types.string,
            filtersStatus: types.string,
            filtersOrderId: types.string,
        })
    )
    .views((self) => ({
        get queryParams() {
            const filters: TStringMap<any> = {
                page: self.page,
                column: capitalize(self.sorter.column),
                asc: self.sorter.asc,
                itemsPerPage: self.pageSize,
            };

            if (self.filtersOrderId) {
                filters.orderId = self.filtersOrderId;
            }

            if (self.filtersType) {
                filters.type = self.filtersType;
            }

            if (self.filtersStatus) {
                filters.status = self.filtersStatus;
            }

            if (self.pureQuery) {
                filters.complexSearch = self.pureQuery;
            }

            return filters;
        },

        get data() {
            return self.mails.map((m) => {
                return toJsonHard(m);
            });
        },
    }))
    .actions((self) => {
        function loadData() {
            return self.transport.post<PagedList<OrderMailSnapshotType>>(apiUrls.orders.mails.list, self.queryParams);
        }

        return {
            load: flow(function* (sliding = false) {
                try {
                    if (!self.filtersOrderId) {
                        self.orders.isEmpty && self.orders.load();
                    }

                    self.employee.isEmpty && self.employee.load();

                    if (self.filtersOrderId === EMPTY_OBJECT_ID) {
                        applySnapshot(self.mails, []);
                        self.totalCount = 0;
                    } else {
                        let mails: PagedList<OrderMailSnapshotType> = yield loadData();

                        if (sliding && mails.items.length === 0 && self.page > 1) {
                            self.page = self.page - 1;
                            mails = yield loadData();
                        }

                        applySnapshot(self.mails, mails.items);
                        self.totalCount = mails.totalCount;
                    }
                    return true;
                } catch (e) {
                    self.notify.error(e);
                    return false;
                }
            }),

            uploadFile: flow(function* (file: File) {
                try {
                    const model = new FormData();

                    model.append("file", file);
                    model.append("accept", "*");

                    const result: UploadFileResult = yield self.transport.post<any>(
                        apiUrls.application.files.upload,
                        model
                    );
                    const { id, mimeType, previewMimeType } = result;

                    const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                    return fileBase;
                } catch (er) {
                    self.notify.error(er);
                    return null;
                }
            }),
        };
    })
    .actions((self) => ({
        resorted(e: SortChangedEvent) {
            self.sorter.resorted(e, false);
            self.page = 1;
            self.load();
        },

        setPage(page: number) {
            self.page = page;
            return self.load();
        },

        save: flow(function* (message: SaveOrderMailValue) {
            try {
                const body: TStringMap<any> = { ...message };

                if (body[mailFields.date] instanceof Date) {
                    body[mailFields.date] = formatDate(body[mailFields.date], DATE_TIME_FORMAT);
                }

                if (body[mailFields.deadline] instanceof Date) {
                    body[mailFields.deadline] = formatDate(body[mailFields.deadline], DATE_TIME_FORMAT);
                }

                if (Array.isArray(body[mailFields.documents])) {
                    body[mailFields.documents] = body[mailFields.documents].map((doc: FileBase) => doc.fileId);
                }

                isNewlyCreated(message.id)
                    ? yield self.transport.put<any>(apiUrls.orders.mails.create, body)
                    : yield self.transport.post<any>(apiUrls.orders.mails.update(message.id), body);

                yield self.load();

                self.notify.success(texts.messages.saved);
                return true;
            } catch (e) {
                self.notify.error(e);
                return false;
            }
        }),

        assign: flow(function* (mails: Identified[], orderId: string) {
            try {
                const messageIds = mails.map((m) => m.id);

                yield self.transport.post<any>(apiUrls.orders.mails.assign(orderId), { messageIds });

                yield self.load();

                self.notify.success(texts.messages.saved);
                return true;
            } catch (e) {
                self.notify.error(e);
                return false;
            }
        }),

        remove: flow(function* (mails: Identified[]) {
            try {
                const ids = mails.map((m) => m.id);

                yield self.transport.delete<any>(apiUrls.orders.mails.remove, { data: { ids } });

                self.load(true);

                self.notify.success(texts.messages.removed);
                return true;
            } catch (e) {
                self.notify.error(e);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        setFiltersType(type: string) {
            if (self.filtersType !== type) {
                self.filtersType = type;
                self.setPage(1);
            }
        },

        setOrderId(id: string): Promise<boolean> {
            const query = ExternalMailNumber();

            self.filtersOrderId = id;
            self.pureQuery = query.toLowerCase();
            self.query = query;
            self.filtersStatus = "";
            self.filtersType = "";
            return self.setPage(1);
        },

        toggleFiltersStatus(status: string) {
            if (!status) {
                self.filtersStatus = "";
            } else {
                self.filtersStatus = self.filtersStatus === status ? "" : status;
            }

            self.setPage(1);
        },

        onQueryChanged() {
            self.setPage(1);
        },
    }))
    .actions((self) => ({
        moveSelection: flow(function* (from: OrderMailSnapshotType, offcet: number) {
            const index = self.mails.findIndex((mail) => mail.id === from.id);
            let newIndex = index + offcet;

            // go back
            if (newIndex < 0) {
                if (self.page > 1) {
                    self.page = self.page - 1;
                    if (yield self.load()) {
                        newIndex = self.mails.length + newIndex;
                        return self.mails[newIndex];
                    }
                }
            } else if (newIndex >= self.mails.length) {
                newIndex = (self.page + 1) * self.pageSize;
                if (newIndex < self.totalCount) {
                    self.page = self.page + 1;

                    if (yield self.load()) {
                        newIndex = 0;
                        return self.mails[newIndex];
                    }
                }
            } else {
                return self.mails[newIndex];
            }
        }),

        printFile: flow(function* (fileId: string) {
            const mail = self.mails.find((m) => {
                return !!m.documentsAsMap[fileId];
            });

            if (mail) {
                const document = mail.documentsAsMap[fileId];
                yield document.print();
            }
        }),

        printMails: flow(function* (mails: string[]) {
            const source = self.mails.filter((m) => mails.includes(m.id));
            if (source.length) {
                const files: string[] = source.reduce((acc, m) => {
                    return [...acc, ...m.documents.map((d) => d.id)];
                }, [] as string[]);

                if (files.length) {
                    try {
                        const content: DownloadFileResult = yield self.transport.post<any>(
                            apiUrls.application.files.print,
                            {
                                ids: files,
                            }
                        );

                        if (content) {
                            const blob: any = yield base64ToBlob(content.content, content.mimeType);
                            const fileURL = URL.createObjectURL(blob);
                            const printer = printPdf(fileURL, true);
                            if (printer) {
                                printer.onclose = () => URL.revokeObjectURL(fileURL);
                            }

                            return true;
                        }
                    } catch (er) {
                        self.notify.error(er);
                        return false;
                    }
                }
            }

            return false;
        }),

        downloadFile: flow(function* (fileId: string) {
            const mail = self.mails.find((m) => {
                return !!m.documentsAsMap[fileId];
            });

            if (mail) {
                const document = mail.documentsAsMap[fileId];
                yield document.save();
            }
        }),

        downloadMails: flow(function* (mails: string[]) {
            const source = self.mails.filter((m) => mails.includes(m.id));
            if (source.length) {
                const files: string[] = source.reduce((acc, m) => {
                    return [...acc, ...m.documents.map((d) => d.id)];
                }, [] as string[]);

                if (files.length) {
                    try {
                        const content: DownloadFileResult = yield self.transport.post<any>(
                            apiUrls.application.files.download,
                            {
                                ids: files,
                            }
                        );

                        if (content) {
                            const blob: any = yield base64ToBlob(content.content, content.mimeType);
                            saveAs(blob, content.name);
                            return true;
                        }
                    } catch (er) {
                        self.notify.error(er);
                        return false;
                    }
                }
            }

            return false;
        }),

        emptyMail(): OrderMailSnapshotType {
            return {
                ...emptyMail(),
                order: self.filtersOrderId
                    ? {
                          id: self.filtersOrderId,
                          inventoryNumber: "",
                          name: "",
                      }
                    : null,
            };
        },
    }))
    .named("OrderMailsStore");

export type OrderMailsStoreType = typeof OrderMailsStore.Type;
export type OrderMailsStoreSnapshotType = typeof OrderMailsStore.SnapshotType;

const sortStorage = getSortOption(OrderMailsStore.name);

export const initialState = (searchQuery = ""): OrderMailsStoreSnapshotType => {
    const options = sortStorage({ column: DEFAULT_COLUMN, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        mails: [],
        orders: emptyOrders(),
        employee: empltyEmployee(),
        page: 1,
        pageSize: 40,
        totalCount: -1,
        sorter: {
            id: OrderMailsStore.name,
            tableName: OrderMailsStore.name,
            column: options.column,
            asc: options.asc,
        },
        renderer: {
            cache: {},
            id: "",
        },
        filtersType: "",
        filtersStatus: "",
        filtersOrderId: "",
        query: searchQuery,
        pureQuery: searchQuery.toLowerCase(),
    };
};

export const ExternalMailNumber = () => {
    const hash = trimStart("#", window.location.hash);
    const params = parse(hash);

    return (params["number"] as string) ?? "";
};
