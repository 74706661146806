import saveAs from "file-saver";
import { applySnapshot, types } from "mobx-state-tree";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { getSiteAddress } from "modules/common/services/communication/http";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, convertTextToImage, printImage } from "modules/common/services/files";
import { texts } from "modules/common/texts";
import {
    WorkTypeDictionary,
    initialState as emptyWorkTypes,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import { buildAccessTree, makeColumns } from "modules/session/access/models/access-store";
import { OutsourcerSnapshotType, OutsourcerType, initialState as emptyOutsourcer } from "./outsourcer";
import { Outsourcer, OutsourcerList, initialState as emptyList } from "./outsourcer-list";

import {
    AgentsCategoriesDictionary,
    initialState as categoriesInite,
} from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";

export const LegalTableName = "OutsourcersListLegal";
export const PhysicalTableName = "OutsourcersListPhysical";
export const OutsourcerTableName = OutsourcerList.name;

export const OutsourcersStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            workTypes: WorkTypeDictionary,
            categories: AgentsCategoriesDictionary,
            legalEntities: OutsourcerList.named(LegalTableName),
            physicalEntities: OutsourcerList.named(PhysicalTableName),
            list: OutsourcerList,
            details: Outsourcer,
            authorization: types.map(types.string),
            loading: types.boolean,
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
                    apiUrls.outsourcers.list()
                );

                applySnapshot(self.list.outsourcers, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        loadNoRules: flow(function* () {
            try {
                const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
                    apiUrls.suppliers.listNoRules()
                );
                applySnapshot(self.list.outsourcers, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        // loadLists: flow(function* () {
        //     try {

        //         const data: OutsourcerSnapshotType[] = yield self.transport.get<OutsourcerSnapshotType[]>(
        //             apiUrls.outsourcers.list()
        //         );

        //         const legal = data.filter((o) => isLegalEntity(o));
        //         const physical = data.filter((o) => !isLegalEntity(o));

        //         applySnapshot(self.legalEntities.outsourcers, legal);
        //         applySnapshot(self.physicalEntities.outsourcers, physical);

        //         return true;
        //     } catch (er) {
        //         self.notify.error(er);
        //         return false;
        //     }
        // }),

        init: flow(function* (outsourcerId: string) {
            yield self.details.load(outsourcerId);
            self.loading = true;
            yield self.workTypes.load();
            yield self.categories.load();
            yield self.load();
            self.loading = false;
        }),
        printCredentials: flow(function* (user: OutsourcerType) {
            const credentials = yield user.getCredentials();

            if (credentials) {
                const dataURL = convertTextToImage([
                    `Логин : ${credentials.login}`,
                    `Пароль: ${credentials.password}`,
                    `Ссылка: ${getSiteAddress()}`,
                ]);

                printImage(dataURL);
            }
        }),
        printDocCredentials: flow(function* (user: OutsourcerType, order: SelectItem | null) {
            if (self.details.isNewlyCreated) {
                return;
            }
            try {
                if (user) {
                    if (order) {
                        const file: FileDescription = yield self.transport.post<any>(apiUrls.clients.print(user.id), {
                            orderId: order.id,
                        });

                        const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                        saveAs(blob, file.name);
                        return true;
                    } else {
                        self.notify.error("Необходимо указать договор с заказчиком!");
                        return false;
                    }
                } else {
                    self.notify.error("Необходимо указать раздел!");
                    return false;
                }
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
        confirmRequest: flow(function* (id: string) {
            try {
                const snapshot = yield self.transport.post<any>(apiUrls.outsourcers.confirmRequest(id), {});
                if (snapshot) {
                    self.notify.success(texts.messages.saved);
                }

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),
    }))
    .views((self) => ({
        get accessTree() {
            return buildAccessTree(self.authorization.entries());
        },
    }))
    .views((self) => ({
        get accessColumns() {
            return makeColumns(self.accessTree, 4);
        },
    }))
    .named("OutsourcersStore");

export const initialState = (): typeof OutsourcersStore.SnapshotType => ({
    legalEntities: emptyList(LegalTableName),
    physicalEntities: emptyList(PhysicalTableName),
    list: emptyList(OutsourcerTableName),
    details: emptyOutsourcer(true),
    workTypes: emptyWorkTypes(),
    authorization: {},
    loading: false,
    categories: categoriesInite(),
});

export type OutsourcersStoreType = typeof OutsourcersStore.Type;
