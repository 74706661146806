import { Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { OutsourcerDetails } from "modules/agents/outsourcers/components/details/OutsourcerDetails";
import { Page } from "modules/common/components/page/Page";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanrLink } from "modules/common/components/planr/link/Link";
import { routes } from "modules/common/routes";
import { eat } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { OwnFinanceRequests } from "modules/expenses/finance-requests/components/OwnFinanceRequests";
import orderFunc, { canReadOrder } from "modules/orders-manage/functionalities";
import { ApplicationStoreType } from "modules/root/models/store";
import { can } from "modules/session/auth/access";
import React from "react";
import { RouteComponentProps } from "react-router";
import func from "../functionalities";
import styles from "./MainAppPage.module.scss";
import { Calendar } from "./calendar/Calendar";
import { Dashboard } from "./dashboard/Dashboard";
import { MessagesWidget } from "./messages/MessagesWidget";
import { NewsWidget } from "./news/NewsWidget";
import { OwnMaterialsWidget } from "./own-materials/OwnMaterialsWidget";
import { OwnPremiumsWidget } from "./own-premiums/OwnPremiumsWidget";
import { OwnSpendingsWidget } from "./own-spendings/OwnSpendingsWidget";
import { SurveyWidget } from "./surveys/SurveyWidget";
import { tabs } from "./tabsMainpage";

class MainAppPage extends React.PureComponent<MainAppPageProps & RouteComponentProps, MainAppPageState> {
    constructor(props: MainAppPageProps & RouteComponentProps) {
        super(props);

        this.state = {
            employeeIsOpen: false,
            ownSpendingsIsOpen: false,
            materialsIsOpen: false,
            newsIsOpen: false,
            messagesIsOpen: false,
            premiumsIsOpen: false,
            personalDataIsOpen: false,
            taskIsOpen: false,
        };
    }
    updateFile = async () => {
        await this.props.root.session.updateFile();
    };

    componentDidMount() {
        document.title = "Главная";

        const { session, employeeWidget, ownSpendingsWidget } = this.props.root;
        const tabsVisible = can(func.INTERNAL_USER_ACCESS, session.access);

        if (tabsVisible) {
            employeeWidget.list.load();
            employeeWidget.list.loadProjects(session.userId);
            ownSpendingsWidget.load(session.userId, false);
            session.loadPremiums();
        }

        if (session.outsourcer !== null) {
            session.outsourcer.load();
        }

        this.updateFile();
    }
    Refresh = () => {
        const { dashboard } = this.props.root;
        dashboard.loadcharts();
    };
    employeeHandleOpen = () => this.setState({ employeeIsOpen: true });
    employeeHandleClose = () => this.setState({ employeeIsOpen: false });

    materialsHandleOpen = () => this.setState({ materialsIsOpen: true });
    materialsHandleClose = () => this.setState({ materialsIsOpen: false });

    messagesHandleOpen = () => this.setState({ messagesIsOpen: true });
    messagesHandleClose = () => this.setState({ messagesIsOpen: false });

    taskHandleOpen = () => this.setState({ taskIsOpen: true });
    taskHandleClose = () => this.setState({ taskIsOpen: false });

    newsHandleOpen = () => {
        const { newsWidget } = this.props.root;
        newsWidget.loadPage();

        return this.setState({ newsIsOpen: true });
    };
    newsHandleClose = () => this.setState({ newsIsOpen: false });

    ownSpendingsHandleOpen = () => this.setState({ ownSpendingsIsOpen: true });
    ownSpendingsHandleClose = () => this.setState({ ownSpendingsIsOpen: false });

    showPremiums = () => this.setState({ premiumsIsOpen: true });
    hidePremiums = () => this.setState({ premiumsIsOpen: false });

    handleTabChange = (newTabId: string) => {
        const route = routes.main.path + newTabId;
        this.props.history.push(route);
    };

    showPersonalData = () => this.setState({ personalDataIsOpen: true });
    hidePersonalData = () => this.setState({ personalDataIsOpen: false });

    render() {
        const { session } = this.props.root;
        const tabsVisible = can(func.INTERNAL_USER_ACCESS, session.access);

        return <Page>{tabsVisible ? this.renderInternalUserTasbs() : this.renderExternalUserTabs()}</Page>;
    }

    renderExternalUserTabs = () => {
        const { location, root, baseUrl } = this.props;
        const { session, detailedOrders, dashboard, moneyAccounts } = root;
        const { access, outsourcer } = session;
        const { personalDataIsOpen } = this.state;

        const tabId = location.hash.includes(tabs.charts) ? tabs.charts : tabs.main;
        const chartVisible = can(orderFunc.FLOW_ORDERS_CHART, access);
        const canRefresh = can(orderFunc.FLOW_ORDERS_CHART_REFRESH, access);

        const tabContent =
            personalDataIsOpen && outsourcer ? (
                <OutsourcerDetails
                    access={access}
                    canChange={true}
                    canRemove={false}
                    canConfirm={false}
                    canSupplierOrder={false}
                    workTypes={outsourcer.workTypes}
                    outsourcer={outsourcer.self}
                    categories={outsourcer.categories}
                    onSaved={eat}
                    onRemoved={eat}
                    onPrintCredentials={eat}
                    showSensitiveData={false}
                    baseUrl={baseUrl}
                >
                    <PlanrButton
                        type="primary"
                        onClick={this.hidePersonalData}
                        style={{ marginRight: "auto", marginTop: "1rem" }}
                        size="small"
                    >
                        {texts.close}
                    </PlanrButton>
                </OutsourcerDetails>
            ) : (
                <PlanrButton
                    type="secondary"
                    onClick={this.showPersonalData}
                    style={{ marginTop: "30px" }}
                    icon="general-users"
                >
                    Изменить персональные данные
                </PlanrButton>
            );

        return outsourcer ? (
            <Tabs
                className={`${styles.tabs} planr-tabs-navigation`}
                id="ExternalTabs"
                selectedTabId={tabId}
                onChange={this.handleTabChange}
                renderActiveTabPanelOnly={true}
            >
                <Tab
                    id={tabs.main}
                    title="Главная"
                    panel={<div className={`${styles.page} ${styles.compact}`}>{tabContent}</div>}
                />
                {chartVisible && (
                    <Tab
                        id={tabs.charts}
                        title={"Дашборд"}
                        panel={
                            <>
                                <Dashboard
                                    table={detailedOrders}
                                    dashboard={dashboard}
                                    accounts={moneyAccounts}
                                    onRefresh={canRefresh ? this.Refresh : undefined}
                                />
                            </>
                        }
                    />
                )}
            </Tabs>
        ) : (
            this.renderEmptyTabs()
        );
    };

    renderEmptyTabs = () => {
        return (
            <Tabs
                className={`${styles.tabs} planr-tabs-navigation`}
                id="HelloTabs"
                selectedTabId="1"
                renderActiveTabPanelOnly={true}
            >
                <Tab
                    id="1"
                    title="Главная"
                    panel={
                        <div className={styles.page}>
                            <h2>Добро пожаловать!</h2>
                        </div>
                    }
                />
            </Tabs>
        );
    };

    renderInternalUserTasbs = () => {
        const { location, history, match } = this.props;
        const {
            newsWidget,
            detailedOrders,
            dashboard,
            moneyAccounts,
            session,
            surveysWidget,
            eventsWidget,
            messagesWidget,
            ownSpendingsWidget,
            inventoryItems,
            employeeWidget,
            ownFinanceRequests,
            orderContentTaskList,
            suppliers,
            notifications,
            baseUrl,
        } = this.props.root;

        const orderAccess = canReadOrder(session.access);
        const chartVisible = can(orderFunc.FLOW_ORDERS_CHART, session.access);
        const tabId = location.hash.includes(tabs.charts) ? tabs.charts : tabs.main;
        const canComment = can(orderFunc.TIMESHEET_COMMENTS, session.access);

        return (
            <Tabs
                className={`${styles.tabs} planr-tabs-navigation`}
                id="MainAppPageTabs"
                selectedTabId={tabId}
                onChange={this.handleTabChange}
                renderActiveTabPanelOnly={true}
            >
                <Tab
                    id={tabs.main}
                    title="Главная"
                    panel={
                        <div className={styles.page}>
                            <div className={styles.left}>
                                <div className="left-topbtns">
                                    {session.manualFile && (
                                        <PlanrLink
                                            type="secondary"
                                            size="small"
                                            target="_blank"
                                            href={session.fileLink}
                                        >
                                            Долж. инструкция
                                        </PlanrLink>
                                    )}
                                    {!session.manualFile && (
                                        <PlanrButton type="secondary" size="small">
                                            Долж. инструкция
                                        </PlanrButton>
                                    )}
                                    <PlanrButton type="secondary" size="small" onClick={this.materialsHandleOpen}>
                                        Инвентарь
                                    </PlanrButton>
                                    <PlanrButton type="secondary" size="small" onClick={this.ownSpendingsHandleOpen}>
                                        Участие в проектах
                                    </PlanrButton>
                                    <PlanrButton type="secondary" size="small" onClick={this.showPremiums}>
                                        Назначенные премии
                                    </PlanrButton>
                                    <OwnFinanceRequests store={ownFinanceRequests} suppliers={suppliers} />
                                </div>

                                <Calendar
                                    calendar={eventsWidget}
                                    session={session}
                                    canComment={canComment}
                                    orderContentTaskList={orderContentTaskList}
                                    notification={notifications}
                                    baseUrl={baseUrl}
                                />

                                <Dialog
                                    title="Участие в проектах"
                                    canEscapeKeyClose={false}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.ownSpendingsIsOpen}
                                    onClose={this.ownSpendingsHandleClose}
                                    style={{
                                        width: `580px`,
                                        height: "460px",
                                    }}
                                    backdropClassName="standard"
                                    className={`figma-dialog`}
                                >
                                    <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                        <OwnSpendingsWidget
                                            store={ownSpendingsWidget}
                                            withSpendings={false}
                                            employeeList={employeeWidget.list}
                                            employerId={session.userId}
                                        />
                                    </div>
                                </Dialog>

                                <Dialog
                                    icon="info-sign"
                                    title="Закрепленный инвентарь"
                                    canEscapeKeyClose={true}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.materialsIsOpen}
                                    onClose={this.materialsHandleClose}
                                    style={{ width: `600px` }}
                                    backdropClassName="standard"
                                >
                                    <div className={Classes.DIALOG_BODY}>
                                        <OwnMaterialsWidget store={inventoryItems.list} employerId={session.userId} />
                                    </div>
                                </Dialog>

                                <Dialog
                                    icon="info-sign"
                                    title="Назначенные премии"
                                    canEscapeKeyClose={true}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.premiumsIsOpen}
                                    onClose={this.hidePremiums}
                                    style={{ width: `600px` }}
                                    backdropClassName="standard"
                                >
                                    <div className={Classes.DIALOG_BODY}>
                                        <OwnPremiumsWidget store={session.premiums} orderAccess={orderAccess} />
                                    </div>
                                </Dialog>
                            </div>
                            <div className={styles.right}>
                                <Dialog
                                    title=""
                                    canEscapeKeyClose={false}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.newsIsOpen}
                                    onClose={this.newsHandleClose}
                                    style={{
                                        width: `1200px`,
                                        height: "900px",
                                    }}
                                    backdropClassName="standard"
                                    className={`figma-dialog`}
                                >
                                    <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                        <NewsWidget
                                            store={newsWidget}
                                            session={session}
                                            isDialog={true}
                                            history={history}
                                            location={location}
                                            match={match}
                                        />
                                    </div>
                                </Dialog>
                                <Dialog
                                    title=""
                                    canEscapeKeyClose={false}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.messagesIsOpen}
                                    onClose={this.messagesHandleClose}
                                    style={{
                                        width: `1200px`,
                                        height: "900px",
                                    }}
                                    backdropClassName="standard"
                                    className={`figma-dialog`}
                                >
                                    <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                        <MessagesWidget store={messagesWidget} isDialog={true} />
                                    </div>
                                </Dialog>
                                <SurveyWidget store={surveysWidget} session={session} />
                                <NewsWidget
                                    store={newsWidget}
                                    session={session}
                                    onOpen={this.newsHandleOpen}
                                    history={history}
                                    location={location}
                                    match={match}
                                />
                                <MessagesWidget store={messagesWidget} onOpen={this.messagesHandleOpen} />
                            </div>
                        </div>
                    }
                />
                {chartVisible && (
                    <Tab
                        id={tabs.charts}
                        title={"Дашборд"}
                        panel={
                            <>
                                <Dashboard
                                    table={detailedOrders}
                                    dashboard={dashboard}
                                    accounts={moneyAccounts}
                                    onRefresh={this.Refresh}
                                />
                            </>
                        }
                    />
                )}
            </Tabs>
        );
    };
}
export default observer(MainAppPage);

interface MainAppPageProps {
    root: ApplicationStoreType;
    baseUrl: string;
}

interface MainAppPageState {
    personalDataIsOpen: boolean;
    taskIsOpen: boolean;
    employeeIsOpen: boolean;
    ownSpendingsIsOpen: boolean;
    materialsIsOpen: boolean;
    newsIsOpen: boolean;
    messagesIsOpen: boolean;
    premiumsIsOpen: boolean;
}
