import { groupBy, sortBy, sumBy } from "lodash";
import { types } from "mobx-state-tree";
import { ActualPayment } from "modules/agents/outsourcers/models/outsourcer-payment";
import { DATE_TIME_FORMAT } from "modules/common/constants";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { IdEntity } from "modules/common/models/entity";
import { Ordered } from "modules/common/models/ordered";
import { nameof } from "modules/common/services/typescript";
import moment from "moment";
import { OrderBase } from "./order-base";

export const OrderTimesheetSpendingRow = types
    .model({
        comment: types.string,
        month: types.number,
        year: types.number,
        monthName: types.string,
        employer: DictionaryLink,
        hours: types.number,
        hoursForExpertise: types.number,
        hoursForTrip: types.number,
        spending: types.number,
        spendingExpertise: types.number,
        spendingTrip: types.number,
        sum: types.number,
    })
    .named("OrderTimesheetSpendingRow");

const SpendingKvp = types.model({
    key: types.string,
    value: types.array(OrderTimesheetSpendingRow),
});

export const OrderTimesheetSpending = types
    .model({
        rows: types.array(OrderTimesheetSpendingRow),
        departmentMap: types.array(SpendingKvp),
        workTypeMap: types.array(SpendingKvp),
        showMinutes: types.boolean,
    })
    .views((self) => ({
        get spendingsMap() {
            const byWorkType = makeGrouping(self.workTypeMap);
            const byDepartment = makeGrouping(self.departmentMap);

            return [...byWorkType, ...byDepartment];
        },
    }))
    .views((self) => ({
        get spendingsTotal() {
            let factMoney = self.spendingsMap.reduce((acc, r) => acc + r.money, 0);
            let factHours = self.spendingsMap.reduce((acc, r) => acc + r.hours, 0);

            return { factMoney, factHours };
        },
        get spendingsTripTotal() {
            let factMoney = self.rows.reduce((acc, r) => acc + r.spendingTrip, 0);
            let factHours = self.rows.reduce((acc, r) => acc + r.hoursForTrip, 0);

            return { factMoney, factHours };
        },
    }))
    .named("OrderTimesheetSpending");

export const BaseOrderSpending = types
    .compose(
        IdEntity,
        Ordered,
        types.model({
            type: types.string,
            contentGuid: types.string,
            generatedKey: types.string,
        })
    )
    .named("BaseOrderSpending");

export const OwnOrderSpending = types
    .compose(
        BaseOrderSpending,
        types.model({
            hours: types.number,
            spending: types.number,
            employer: DictionaryLink,
        })
    )
    .views((self) => ({
        get employerId() {
            return self.employer.id;
        },
    }))
    .named("OwnOrderSpending");

export const OutsourcedOrderSpending = types
    .compose(
        OrderBase,
        BaseOrderSpending,
        types.model({
            // сумма по договору
            actualSum: types.number,
            planPayments: types.array(ActualPayment),
            actualPayments: types.array(ActualPayment),
            taskIssued: types.boolean,
            taskCompleted: types.boolean,
            taskAccepted: types.boolean,
            outsourcer: types.maybeNull(DictionaryLink),
            label: types.optional(types.string, ""),
            days: types.maybeNull(types.number),
        })
    )
    .views((self) => ({
        get outsourcerId() {
            return self.outsourcer ? self.outsourcer.id : "";
        },
        get startDateAsDate() {
            return self.startDate ? moment(self.startDate, DATE_TIME_FORMAT).toDate() : null;
        },
    }))
    .named("OutsourcedOrderSpending");

export const TripOrderSpending = types
    .compose(
        OutsourcedOrderSpending,
        types.model({
            employerId: types.string,
        })
    )

    .named("TripOrderSpending");

export const OtherOrderSpending = types
    .compose(
        OutsourcedOrderSpending,
        types.model({
            comment: types.string,
        })
    )
    .named("OtherOrderSpending");

export const getDebit = (spenging: OutsourcedOrderSpendingSnapshotType, type: "plan" | "actual") => {
    const sum = type === "actual" ? spenging.actualSum : spenging.planPayments.reduce((acc, p) => acc + p.sum, 0);
    const payments = type === "actual" ? spenging.actualPayments : spenging.planPayments;
    const money = payments.reduce((acc, p) => acc + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0), 0);

    return sum - money;
};

export type OwnOrderSpendingType = typeof OwnOrderSpending.Type;
export type OwnOrderSpendingSnapshotType = typeof OwnOrderSpending.SnapshotType;
export type OutsourcedOrderSpendingType = typeof OutsourcedOrderSpending.Type;
export type OutsourcedOrderSpendingSnapshotType = typeof OutsourcedOrderSpending.SnapshotType;
export type OtherOrderSpendingType = typeof OtherOrderSpending.Type;
export type OtherOrderSpendingSnapshotType = typeof OtherOrderSpending.SnapshotType;
export type TripOrderSpendingType = typeof TripOrderSpending.Type;
export type TripOrderSpendingSnapshotType = typeof TripOrderSpending.SnapshotType;
export type OrderTimesheetSpendingType = typeof OrderTimesheetSpending.Type;
export type OrderTimesheetSpendingSnapshotType = typeof OrderTimesheetSpending.SnapshotType;
export type OrderTimesheetSpendingRowSnapshotType = typeof OrderTimesheetSpendingRow.SnapshotType;
export type OrderTimesheetSpendingRowType = typeof OrderTimesheetSpendingRow.Type;

export const ownSpendingFields = {
    id: nameof((a: OwnOrderSpendingType) => a.id) as string,
    hours: nameof((a: OwnOrderSpendingType) => a.hours) as string,
    employerId: nameof((a: OwnOrderSpendingType) => a.employerId) as string,
    spending: nameof((a: OwnOrderSpendingType) => a.spending) as string,
};

export const outsourcedSpendingFields = {
    id: nameof((a: OtherOrderSpendingType) => a.id) as string,
    planPayments: nameof((a: OtherOrderSpendingType) => a.planPayments) as string,
    actualPayments: nameof((a: OtherOrderSpendingType) => a.actualPayments) as string,
    actualSum: nameof((a: OtherOrderSpendingType) => a.actualSum) as string,
    taskIssued: nameof((a: OtherOrderSpendingType) => a.taskIssued) as string,
    taskAccepted: nameof((a: OtherOrderSpendingType) => a.taskAccepted) as string,
    taskCompleted: nameof((a: OtherOrderSpendingType) => a.taskCompleted) as string,
    outsourcerId: nameof((a: OtherOrderSpendingType) => a.outsourcerId) as string,
    contentGuid: nameof((a: OtherOrderSpendingType) => a.contentGuid) as string,
    days: nameof((a: OtherOrderSpendingType) => a.days) as string,
    comment: nameof((a: OtherOrderSpendingType) => a.comment) as string,
    orders: nameof((a: OtherOrderSpendingType) => a.additions) as string,
    orderNumber: nameof((a: OtherOrderSpendingType) => a.orderNumber) as string,
    startDate: nameof((a: OtherOrderSpendingType) => a.startDate) as string,
};

export const tripSpendingFields = {
    id: nameof((a: TripOrderSpendingType) => a.id) as string,
    planPayments: nameof((a: TripOrderSpendingType) => a.planPayments) as string,
    actualPayments: nameof((a: TripOrderSpendingType) => a.actualPayments) as string,
    employerId: nameof((a: OwnOrderSpendingType) => a.employerId) as string,
    type: nameof((a: TripOrderSpendingType) => a.type) as string,
    contentGuid: nameof((a: TripOrderSpendingType) => a.contentGuid) as string,
};

type SpendingKvpType = typeof SpendingKvp.Type;

export interface SpendingsGropiung {
    label: string;
    comment: string;
    hours: number;
    money: number;
    employee: OrderTimesheetSpendingRowSnapshotType[];
}

function makeGrouping(spendings: SpendingKvpType[]): SpendingsGropiung[] {
    const result: SpendingsGropiung[] = [];

    spendings.forEach(({ key, value }) => {
        const users = groupBy(value, (row) => row.employer.id);
        let money = 0;
        let hours = 0;

        const employee = Object.values(users)
            .map((employerSpendings) => {
                const summary: OrderTimesheetSpendingRowSnapshotType = {
                    employer: employerSpendings[0].employer,
                    hours: sumBy(employerSpendings, (s) => s.hours),
                    hoursForExpertise: sumBy(employerSpendings, (s) => s.hoursForExpertise),
                    hoursForTrip: sumBy(employerSpendings, (s) => s.hoursForTrip),
                    month: 0,
                    monthName: "",
                    comment: "",
                    spending: sumBy(employerSpendings, (s) => s.spending),
                    spendingExpertise: sumBy(employerSpendings, (s) => s.spendingExpertise),
                    spendingTrip: sumBy(employerSpendings, (s) => s.spendingTrip),
                    sum: sumBy(employerSpendings, (s) => s.sum),
                    year: 0,
                };

                money += summary.spending;
                hours += summary.hours;

                return summary;
            })
            .filter((e) => e.hours > 0);

        if (employee.length > 0) {
            result.push({
                label: key,
                comment: value[0].comment,
                employee: sortBy(employee, (e) => e.employer.name),
                money,
                hours,
            });
        }
    });

    return sortBy(result, (r) => -r.hours);
}
