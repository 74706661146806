import { sumBy, trim } from "lodash";
import { HTML_COLOR_REGEXP } from "modules/common/constants";
import { texts } from "modules/common/texts";
import { fields, objectFields, OrderSummarySnapshotType } from "modules/orders-manage/models/order";
import { FinanceValuesFields } from "modules/orders-manage/models/order-finance-values";
import { ipdFields } from "modules/orders-manage/models/order-ipd";
import {
    OutsourcedOrderSpendingSnapshotType,
    outsourcedSpendingFields,
    ownSpendingFields,
    tripSpendingFields,
} from "modules/orders-manage/models/order-spending";
import { techIndicatorFields } from "modules/orders-manage/models/order-tech-indicator";
import { paymentFields } from "modules/orders-manage/models/payment";
import { RequiredMessage } from "modules/root/services/locale";
import * as yup from "yup";
import { validateField } from "./access-map";
import { ACTUAL_PAYMENTS_BLOCK_NAME, PLAN_PAYMENTS_BLOCK_NAME } from "./spending/outsourced/print-view-model";
import { agentFields } from "../models/order-agents";
// import { consignmentFileFields } from "../models/order-file";
const { object, string, date, number, array, boolean } = yup;

export const otherSpendingLabels = () => ({
    [outsourcedSpendingFields.actualSum]: "Сумма договора",
    [outsourcedSpendingFields.planPayments]: PLAN_PAYMENTS_BLOCK_NAME,
    [outsourcedSpendingFields.actualPayments]: ACTUAL_PAYMENTS_BLOCK_NAME,
    [outsourcedSpendingFields.taskIssued]: "Выдано задание",
    [outsourcedSpendingFields.taskCompleted]: "Сдано",
    [outsourcedSpendingFields.taskAccepted]: "Принято",
    [outsourcedSpendingFields.outsourcerId]: "Исполнитель",
    [outsourcedSpendingFields.comment]: "Описание",
    [outsourcedSpendingFields.orderNumber]: "Номер договора",
    [outsourcedSpendingFields.startDate]: "Дата договора",
    [outsourcedSpendingFields.days]: "Срок выполнения",
});

export const ownSpendingLabels = () => ({
    [ownSpendingFields.hours]: "Кол-во часов",
    [ownSpendingFields.employerId]: "Сотрудник",
});

export const OwnOrderSpendingSchema = () => {
    const labels = ownSpendingLabels();

    return object().shape({
        [ownSpendingFields.hours]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[ownSpendingFields.hours])
            .min(1),

        [ownSpendingFields.employerId]: string()
            .label(labels[ownSpendingFields.employerId])
            .required()
            .min(0)
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом"),
    });
};

export const OutsourcedOrderSpendingSchema = (outsourcerRequred = false) => {
    const labels = otherSpendingLabels();

    const outsourcer = () => string().label(labels[outsourcedSpendingFields.outsourcerId]);

    return object().shape({
        [outsourcedSpendingFields.days]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[outsourcedSpendingFields.days])
            .min(1),

        [outsourcedSpendingFields.actualSum]: number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .label(labels[outsourcedSpendingFields.actualSum])
            .required(),

        [outsourcedSpendingFields.outsourcerId]: outsourcerRequred
            ? outsourcer().required()
            : outsourcer().notRequired(),

        [outsourcedSpendingFields.taskIssued]: boolean().label(labels[outsourcedSpendingFields.taskIssued]),

        [outsourcedSpendingFields.taskAccepted]: boolean().label(labels[outsourcedSpendingFields.taskAccepted]),

        [outsourcedSpendingFields.taskCompleted]: boolean().label(labels[outsourcedSpendingFields.taskCompleted]),

        [outsourcedSpendingFields.startDate]: date()
            .label(labels[outsourcedSpendingFields.startDate])
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired(),

        [outsourcedSpendingFields.orderNumber]: string()
            .label(labels[outsourcedSpendingFields.orderNumber])
            .notRequired(),

        [outsourcedSpendingFields.planPayments]: array().of(OrderPaymentSchema("")),

        [outsourcedSpendingFields.actualPayments]: array().of(OrderPaymentSchema("")),
    });
};

export const TripOrderSpendingSchema = (outsourcerRequred = false) => {
    return object().shape({
        [tripSpendingFields.planPayments]: array().of(OrderPaymentSchema("")),

        [tripSpendingFields.actualPayments]: array().of(OrderPaymentSchema("")),
    });
};

export const OtherOrderSpendingSchema = () => {
    const labels = otherSpendingLabels();

    return OutsourcedOrderSpendingSchema().shape({
        [outsourcedSpendingFields.comment]: string().label(labels[outsourcedSpendingFields.comment]).notRequired(),
    });
};

export const OrderSummaryLabels = () => ({
    [fields.name]: "Краткое наименование",
    [fields.fullName]: `${texts.name} объекта`,
    [fields.fullInventoryNumber]: "Инв. номер",
    [fields.orderNumber]: "Номер договора",
    [fields.cypher]: "Шифр",
    [fields.color]: "Цветовая метка",
    [fields.portfolioId]: "Портфель проектов",
    [fields.orderStatusId]: "Статус проекта",
    [fields.clientId]: "Заказчик",
    [fields.orderTypeId]: "Тип проекта",
    [fields.productionStageId]: "Вид строительства",
    [fields.engineerId]: "ГИП / Рук. проекта",
    [fields.price]: "Договор на сумму",
    [fields.startDate]: "Дата начала",
    [fields.stopDatePlan]: "Дата окончания (план)",
    [fields.stopDateActual]: "Дата окончания (факт)",
    [fields.municipalContract]: "Муниципальный контракт",
    [fields.batchContract]: "Рамочный договор",
    [fields.additions]: "Доп. соглашения",
    [fields.expertises]: "Заключ. экспертизы",
    [fields.premiumGipPercents]: "ГИП",
    [fields.premiumProductionPercents]: "Производственные",
    [fields.premiumNonProductionPercents]: "Непроизводственные",
    [fields.finValuesActualOverheadPercents]: "Накладные",
    [fields.finValuesPlanOverheadPercents]: "Накладные",
    [fields.isInternal]: "Внутренний проект",
});

// export const orderConsignmentLabels = () => ({
//     [consignmentFileFields.number]: "Номер",
//     [consignmentFileFields.fileId]: "Файл",
//     [consignmentFileFields.signDate]: "Дата",
//     [consignmentFileFields.comment]: "Примечания",
// });

// const ConsignmentSchema = () => {
//     const labels = orderConsignmentLabels();

//     return object().shape({
//         [consignmentFileFields.number]: string().label(labels[consignmentFileFields.number]).required(),
//         [consignmentFileFields.fileId]: string().label(labels[consignmentFileFields.fileId]).required(),
//         [consignmentFileFields.signDate]: date()
//             .label(labels[consignmentFileFields.signDate])
//             .typeError(texts.messages.incorrectDate("накладной"))
//             .required(),
//         [consignmentFileFields.comment]: string().label(labels[consignmentFileFields.comment]).notRequired(),
//     });
// };

const AdditionSchema = () => {
    return object().shape({
        [fields.orderNumber]: string().label("Номер").required(),
        [fields.startDate]: date().label("Дата").typeError(texts.messages.incorrectDate("соглашения")).notRequired(),
    });
};

export const orderIpdsLabels = () => ({
    [ipdFields.name]: "Наименование",
    [ipdFields.number]: "Реквизиты",
    // [ipdFields.requestFileId]: "Файл запроса",
    // [ipdFields.documentFileId]: "Файл ИРД",
    [ipdFields.clientIsMentor]: "От заказчика",
});

export const OrderIpdsSchema = () => {
    const labels = orderIpdsLabels();

    return object().shape({
        [ipdFields.name]: string().label(labels[ipdFields.name]).required(),
        [ipdFields.number]: string().label(labels[ipdFields.number]).notRequired(),
    });
};

export const orderTechIndicatorsLabels = () => ({
    [techIndicatorFields.orderIndicatorId]: "Показатель",
    [techIndicatorFields.number]: "Значение",
});

export const orderAgentsLabels = () => ({
    [agentFields.newReward]: "Вознаграждение",
    [agentFields.deal]: "Сделка",
    [agentFields.description]: "Комментарии",
    [agentFields.price]: "Стоимость",
});

export const OrderTechIndicatorsSchema = () => {
    const labels = orderTechIndicatorsLabels();

    return object().shape({
        [techIndicatorFields.orderIndicatorId]: string().label(labels[techIndicatorFields.orderIndicatorId]),
        [techIndicatorFields.number]: string().label(labels[techIndicatorFields.number]),
    });
};

export const OrdersAgentsSchema = () => {
    const labels = orderAgentsLabels();

    return object().shape({
        [agentFields.newReward]: string().label(labels[agentFields.newReward]),
        [agentFields.deal]: string().label(labels[agentFields.deal]),
        [agentFields.description]: string().label(labels[agentFields.description]),
        [agentFields.price]: string().label(labels[agentFields.price]),
    });
};

export const financeValuesLabels = () => ({
    [FinanceValuesFields.premiumPercents]: "План. премии",
    [FinanceValuesFields.planProductionSpendingsPercents]: "План. произв. затраты",
});

export const orderPaymentsLabels = () => ({
    [paymentFields.name]: "Номер",
    [paymentFields.sum]: "Сумма",
    [paymentFields.date]: "Дата",
});

const OrderPaymentSchema = (whatDate: string) => {
    const labels = orderPaymentsLabels();

    return object().shape({
        [paymentFields.name]: string().label(labels[paymentFields.name]).required(),

        [paymentFields.date]: date()
            .label(labels[paymentFields.date])
            .typeError(texts.messages.incorrectDate(whatDate))
            .required(),

        [paymentFields.sum]: number()
            .label(labels[paymentFields.sum])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(1),
    });
};

const orderObjectLabels = () => ({
    [objectFields.inventoryNumber]: "№ на генплане",
    [objectFields.name]: texts.name,
});

const OrderObjectSchema = () => {
    const labels = orderObjectLabels();

    return object().shape({
        [objectFields.name]: string()
            .label(labels[objectFields.name])
            .when(fields.inventoryNumber, {
                is: (inventoryNumber) => inventoryNumber > 0,
                then: string().required(`Необходимо указать ${labels[objectFields.name]} объекта по договору`),
                otherwise: string().notRequired(),
            }),

        [objectFields.inventoryNumber]: number()
            .label(labels[objectFields.inventoryNumber])
            .required(`Необходимо указать ${labels[objectFields.inventoryNumber]} объекта по договору`)
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должна быть числом"),
    });
};

const OrderSummarySchema = (access: string[]) => {
    const labels = {
        ...OrderSummaryLabels(),
        ...financeValuesLabels(),
    };
    const shape: TStringMap<any> = {};

    shape[fields.name] = string().label(labels[fields.name]);
    if (validateField(access, fields.name)) {
        shape[fields.name] = (shape[fields.name] as yup.StringSchema).required();
    }
    shape[fields.canClientDownloadTasks] = boolean().label(labels[fields.canClientDownloadTasks]);
    shape[fields.fullName] = string().label(labels[fields.fullName]).notRequired();
    shape[fields.cypher] = string().label(labels[fields.cypher]).notRequired();
    shape[fields.portfolioId] = string().label(labels[fields.portfolioId]).notRequired();
    shape[fields.orderStatusId] = string().label(labels[fields.orderStatusId]).notRequired();
    shape[fields.clientId] = string().label(labels[fields.clientId]).notRequired();
    shape[fields.orderTypeId] = string().label(labels[fields.orderTypeId]).notRequired();
    shape[fields.productionStageId] = string().label(labels[fields.productionStageId]).notRequired();
    shape[fields.engineerId] = string().label(labels[fields.engineerId]).required();
    shape[fields.municipalContract] = boolean().label(labels[fields.municipalContract]);
    shape[fields.batchContract] = boolean().label(labels[fields.batchContract]);

    shape[fields.orderNumber] = string().label(labels[fields.orderNumber]);
    if (validateField(access, fields.orderNumber)) {
        shape[fields.orderNumber] = (shape[fields.orderNumber] as yup.StringSchema).required();
    }

    if (validateField(access, fields.premiumPercents)) {
        shape[fields.premiumPercents] = number()
            .label(labels[fields.premiumPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0)
            .max(100);
    }

    if (validateField(access, fields.planProductionSpendingsPercents)) {
        shape[fields.planProductionSpendingsPercents] = number()
            .label(labels[fields.planProductionSpendingsPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .when(fields.premiumPercents, (premiumValue: any, schema: yup.NumberSchema) => {
                const premium = +premiumValue;
                return schema.min(premium);
            });
    }

    if (validateField(access, fields.finValuesActualOverheadPercents)) {
        shape[fields.finValuesActualOverheadPercents] = number()
            .label(labels[fields.finValuesActualOverheadPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом");
    }

    if (validateField(access, fields.finValuesPlanOverheadPercents)) {
        shape[fields.finValuesPlanOverheadPercents] = number()
            .label(labels[fields.finValuesPlanOverheadPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом");
    }

    if (validateField(access, fields.premiumGipPercents)) {
        shape[fields.premiumGipPercents] = number()
            .label(labels[fields.premiumGipPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0)
            .test("max", "Распределение должно составлять 100%", function (value: number) {
                const { premiumProductionPercents, premiumNonProductionPercents } = this
                    .parent as OrderSummarySnapshotType;
                return value + premiumProductionPercents + premiumNonProductionPercents === 100;
            });
    }

    if (validateField(access, fields.premiumProductionPercents)) {
        shape[fields.premiumProductionPercents] = number()
            .label(labels[fields.premiumProductionPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0)
            // eslint-disable-next-line no-template-curly-in-string
            .max(100, "Значение не должно превышать ${max}%");
    }

    if (validateField(access, fields.premiumNonProductionPercents)) {
        shape[fields.premiumNonProductionPercents] = number()
            .label(labels[fields.premiumNonProductionPercents])
            .transform((value) => (isNaN(value) ? undefined : value))
            .required()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError("${path} должен быть числом")
            .min(0)
            // eslint-disable-next-line no-template-curly-in-string
            .max(100, "Значение не должно превышать ${max}%");
    }

    shape[fields.fullInventoryNumber] = string().label(labels[fields.fullInventoryNumber]);
    if (validateField(access, fields.fullInventoryNumber)) {
        shape[fields.fullInventoryNumber] = (shape[fields.fullInventoryNumber] as yup.StringSchema)
            .required()
            .matches(/^\d{4}$/);
    }

    shape[fields.color] = string().label(labels[fields.color]);
    if (validateField(access, fields.color)) {
        shape[fields.color] = (shape[fields.color] as yup.StringSchema)
            .label(labels[fields.color])
            .matches(HTML_COLOR_REGEXP);
    }

    shape[fields.startDate] = date().label(labels[fields.startDate]);
    if (validateField(access, fields.startDate)) {
        shape[fields.startDate] = (shape[fields.startDate] as yup.DateSchema)
            .typeError(texts.messages.incorrectDate())
            .required();
    } else {
        shape[fields.startDate] = (shape[fields.startDate] as yup.DateSchema).typeError("");
    }

    shape[fields.stopDateActual] = date().label(labels[fields.stopDateActual]);
    if (validateField(access, fields.stopDateActual)) {
        shape[fields.stopDateActual] = (shape[fields.stopDateActual] as yup.DateSchema)
            .typeError(texts.messages.incorrectDate())
            .nullable()
            .notRequired();
    } else {
        shape[fields.stopDateActual] = (shape[fields.stopDateActual] as yup.DateSchema).typeError("");
    }

    if (validateField(access, fields.objects)) {
        shape[fields.objects] = array().of(OrderObjectSchema());
    }

    // if (validateField(access, fields.orderIpds)) {
    //     shape[fields.orderIpds] = array().of(OrderIpdsSchema());
    // }

    if (validateField(access, fields.ownOrderSpendings)) {
        shape[fields.ownOrderSpendings] = array().of(OwnOrderSpendingSchema());
    }

    if (validateField(access, fields.outsourcedOrderSpendins)) {
        shape[fields.outsourcedOrderSpendins] = array().of(OutsourcedOrderSpendingSchema());
    }

    if (validateField(access, fields.tripOrderSpendings)) {
        shape[fields.tripOrderSpendings] = array().of(TripOrderSpendingSchema());
    }

    if (validateField(access, fields.otherOrderSpendings)) {
        shape[fields.otherOrderSpendings] = array().of(OtherOrderSpendingSchema());
    }

    // if (validateField(access, fields.consignments)) {
    //     shape[fields.consignments] = array().of(ConsignmentSchema());
    // }

    if (validateField(access, fields.additions)) {
        shape[fields.additions] = array().of(AdditionSchema());
    }

    shape[fields.employeeFeedback] = object();

    return object().shape(shape);
};

export default OrderSummarySchema;
export { OrderObjectSchema, OrderPaymentSchema };

export const OrderIpdSchema = (access: string[]) => {
    const shape: TStringMap<any> = {};

    if (validateField(access, fields.orderIpds)) {
        shape[fields.orderIpds] = array().of(OrderIpdsSchema());
    }

    return object().shape(shape);
};

export const OrderTechIndicatorSchema = (access: string[]) => {
    const shape: TStringMap<any> = {};

    if (validateField(access, fields.orderTechIndicators)) {
        shape[fields.orderTechIndicators] = array().of(OrderTechIndicatorsSchema());
    }

    return object().shape(shape);
};

export const OrderAgentsSchema = (access: string[]) => {
    const shape: TStringMap<any> = {};

    if (validateField(access, fields.orderAgents)) {
        shape[fields.orderAgents] = array().of(OrderTechIndicatorsSchema());
    }

    return object().shape(shape);
};

export function CheckBlockWarning(block: OutsourcedOrderSpendingSnapshotType[]) {
    // № договора, дата договора, сумма, исполнитель - должны быть заполнены
    // плановые оплаты по сумме должны совпадать с суммой договора

    const labels = otherSpendingLabels();

    for (const spending of block) {
        const { orderNumber, startDate, planPayments, actualSum, days } = spending;
        const outsourcerId = (spending as any)[outsourcedSpendingFields.outsourcerId];

        if (!trim(orderNumber)) {
            return RequiredMessage(labels[outsourcedSpendingFields.orderNumber]);
        }

        if (!startDate) {
            return RequiredMessage(labels[outsourcedSpendingFields.startDate]);
        }

        if (!outsourcerId) {
            return RequiredMessage(labels[outsourcedSpendingFields.outsourcerId]);
        }

        if (actualSum !== sumBy(planPayments, (p) => +p.sum)) {
            return "Сумма договора должна совпадать с плановыми оплатами";
        }

        if (days === null) {
            return RequiredMessage(labels[outsourcedSpendingFields.days]);
        }
    }

    return "";
}
