import React, { PropsWithChildren, useCallback } from "react";
import { Tab, Tabs } from "@blueprintjs/core";
import { routes } from "modules/common/routes";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { RouteChildrenProps } from "react-router";
import { Page } from "modules/common/components/page/Page";
import styles from "./DictionariesTabsPage.module.scss";

const menu = menuItems();

export const DictionariesTabsPage = (props: PropsWithChildren<DictionariesTabsPageProps & RouteChildrenProps>) => {
    const { location, history } = props;
    const { workTypesPanel, orderTypesPanel } = props;
    const { productionStagesPanel, ipdTypesPanel, orderIndicatorsPanel, orderStatusesPanel, agentsCategoriesPanel } =
        props;
    const { inventoryPanel, projectPortfoliosPanel, bankDetailsPanel } = props;

    const tabId = getTabId(location.pathname);
    const handleTabChange = useCallback((newTabId: string) => history.push(newTabId), [history]);

    return (
        <Page>
            <Tabs
                className={`${styles.tabs} planr-tabs-navigation`}
                id="DictionariesTabsPage"
                onChange={handleTabChange}
                selectedTabId={tabId}
                renderActiveTabPanelOnly={true}
            >
                {inventoryPanel && (
                    <Tab
                        id={routes.dictionaries.inventory.path}
                        title={menu.dictionaries.nested.inventory.label}
                        panel={inventoryPanel}
                    />
                )}
                {productionStagesPanel && (
                    <Tab
                        id={routes.dictionaries.productionStage.path}
                        title={menu.dictionaries.nested.productionStages.label}
                        panel={productionStagesPanel}
                    />
                )}
                {ipdTypesPanel && (
                    <Tab
                        id={routes.dictionaries.ipdType.path}
                        title={menu.dictionaries.nested.ipdTypes.label}
                        panel={ipdTypesPanel}
                    />
                )}
                {agentsCategoriesPanel && (
                    <Tab
                        id={routes.dictionaries.agentsCategories.path}
                        title={menu.dictionaries.nested.agentsCategories.label}
                        panel={agentsCategoriesPanel}
                    />
                )}
                {orderIndicatorsPanel && (
                    <Tab
                        id={routes.dictionaries.orderIndicator.path}
                        title={menu.dictionaries.nested.orderIndicators.label}
                        panel={orderIndicatorsPanel}
                    />
                )}
                {orderStatusesPanel && (
                    <Tab
                        id={routes.dictionaries.orderStatus.path}
                        title={menu.dictionaries.nested.orderStatuses.label}
                        panel={orderStatusesPanel}
                    />
                )}
                {orderTypesPanel && (
                    <Tab
                        id={routes.dictionaries.orderType.path}
                        title={menu.dictionaries.nested.orderTypes.label}
                        panel={orderTypesPanel}
                    />
                )}
                {projectPortfoliosPanel && (
                    <Tab
                        id={routes.dictionaries.projectPortfolio.path}
                        title={menu.dictionaries.nested.projectPortfolio.label}
                        panel={projectPortfoliosPanel}
                    />
                )}
                {workTypesPanel && (
                    <Tab
                        id={routes.dictionaries.workType.path}
                        title={menu.dictionaries.nested.workTypes.label}
                        panel={workTypesPanel}
                    />
                )}
                {bankDetailsPanel && (
                    <Tab
                        id={routes.dictionaries.bankDetails.path}
                        title={menu.dictionaries.nested.bankDetais.label}
                        panel={bankDetailsPanel}
                    />
                )}
            </Tabs>
        </Page>
    );
};

const getTabId = (pathname: string) => {
    if (pathname.includes(routes.dictionaries.workType.path)) {
        return routes.dictionaries.workType.path;
    }

    if (pathname.includes(routes.dictionaries.orderType.path)) {
        return routes.dictionaries.orderType.path;
    }

    if (pathname.includes(routes.dictionaries.orderIndicator.path)) {
        return routes.dictionaries.orderIndicator.path;
    }

    if (pathname.includes(routes.dictionaries.orderStatus.path)) {
        return routes.dictionaries.orderStatus.path;
    }

    if (pathname.includes(routes.dictionaries.productionStage.path)) {
        return routes.dictionaries.productionStage.path;
    }

    if (pathname.includes(routes.dictionaries.ipdType.path)) {
        return routes.dictionaries.ipdType.path;
    }

    if (pathname.includes(routes.dictionaries.agentsCategories.path)) {
        return routes.dictionaries.agentsCategories.path;
    }

    if (pathname.includes(routes.dictionaries.inventory.path)) {
        return routes.dictionaries.inventory.path;
    }

    if (pathname.includes(routes.dictionaries.inventory.pathGroups)) {
        return routes.dictionaries.inventory.pathGroups;
    }

    if (pathname.includes(routes.dictionaries.projectPortfolio.path)) {
        return routes.dictionaries.projectPortfolio.path;
    }

    if (pathname.includes(routes.dictionaries.bankDetails.path)) {
        return routes.dictionaries.bankDetails.path;
    }

    return "";
};

interface DictionariesTabsPageProps {
    workTypesPanel: JSX.Element | null;
    orderTypesPanel: JSX.Element | null;
    orderStatusesPanel: JSX.Element | null;
    productionStagesPanel: JSX.Element | null;
    ipdTypesPanel: JSX.Element | null;
    agentsCategoriesPanel: JSX.Element | null;
    orderIndicatorsPanel: JSX.Element | null;
    inventoryPanel: JSX.Element | null;
    projectPortfoliosPanel: JSX.Element | null;
    bankDetailsPanel: JSX.Element | null;
}
