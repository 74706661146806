import React, { useEffect } from "react";
import styles from "./Finance.module.scss";
import { Stages } from "./Stages";
import { OrderFinancesStoreType } from "modules/orders-manage/models/order-finances-store";
import { OrderTimeline } from "../common/OrderTimeline";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OrderSummaryType } from "modules/orders-manage/models/order";

export const FinanceTab = ({ store, orderId, readonly, isActiveTab, printStages, storeDetails }: FinanceTabProps) => {
    useEffect(() => {
        if (isActiveTab && store.orderId !== orderId) {
            store.load(orderId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveTab, orderId]);

    if (!isActiveTab) {
        return null;
    }

    return (
        <div className={styles.finance}>
            <div className="planr-tools">
                <PlanrButton
                    type="graybtn"
                    icon="general-print"
                    onClick={async () => {
                        await store.printStagesModel();
                    }}
                    round={true}
                    title="Печать"
                />
                <PlanrButton
                    type="graybtn"
                    icon="general-document"
                    onClick={() => {
                        printStages();
                    }}
                    round={true}
                    title="Печать"
                />
            </div>
            {orderId !== EMPTY_OBJECT_ID ? (
                <>
                    <div className="order-timeline-wrapper">
                        <OrderTimeline events={store.timeline} isCollapsed={true} />
                    </div>
                    <Stages
                        store={store.stages}
                        readonly={readonly}
                        financeStore={store}
                        additions={storeDetails.additions}
                        details={storeDetails}
                    />
                </>
            ) : (
                <h2>Сначала необходимо сохранить основные данные договора</h2>
            )}
        </div>
    );
};

interface FinanceTabProps {
    orderId: string;
    store: OrderFinancesStoreType;
    isActiveTab: boolean;
    printStages: () => void;
    readonly?: boolean;
    storeDetails: OrderSummaryType;
}
