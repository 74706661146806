import React from "react";
import { SurveyStoreType } from "modules/main/models/surveys-store";
import { observer } from "mobx-react";
import { SessionType } from "modules/session/auth/models/session";
import styles from "./SurveyWidget.module.scss";
import { Survey } from "./Survey";

import { can } from "modules/session/auth/access";

import func from "../../functionalities";
import { SurveyEditor } from "./SurveyEditor";
import { texts } from "modules/common/texts";
import { PlanrButton } from "modules/common/components/planr/button/Button";

class Widget extends React.Component<SurveyWidgetProps, SurveyWidgetState> {
    constructor(props: SurveyWidgetProps) {
        super(props);
        this.state = { onEdit: false };
    }

    componentDidMount() {
        this.props.store.load();
    }

    render() {
        const { store, session } = this.props;
        const { onEdit } = this.state;

        const canManage = can(func.SURVEY_EDIT, session.access);
        const hasSecond = store.items.length > 1;

        const navigation = (
            <div className={styles.nav}>
                <PlanrButton
                    type="neutral"
                    icon="general-big-arrow-left"
                    onClick={this.goBack}
                    title="Вперед"
                    disabled={!store.hasPrevPage ? true : false}
                    size="small"
                    style={{ marginRight: "-10px", border: " 0 !important" }}
                />
                <PlanrButton
                    type="neutral"
                    icon="general-big-arrow-right"
                    onClick={this.goForward}
                    title="Назад"
                    disabled={!store.hasNextPage ? true : false}
                    size="small"
                />
                {/* <Icon
                    icon="arrow-left"
                    iconSize={ICON_BUTTON_SIZE}
                    className={`figma-icon ${!store.hasPrevPage ? "disabled" : ""}`}
                    htmlTitle="Назад"
                    onClick={this.goBack}
                />

                <Icon
                    icon="arrow-right"
                    iconSize={ICON_BUTTON_SIZE}
                    className={`figma-icon ${!store.hasNextPage ? "disabled" : ""}`}
                    htmlTitle="Вперед"
                    onClick={this.goForward}
                /> */}
            </div>
        );

        const addButton = canManage && (
            <PlanrButton
                type="neutral"
                round={true}
                icon="general-plus-big"
                onClick={this.addSurvey}
                title="Добавить опрос"
                size="small"
            />
            // <AddIcon
            //     className={`${styles.addButton} white-icon action-icon`}
            //     onClick={this.addSurvey}
            //     title="Добавить опрос"
            // />
        );

        return (
            <div className={`${styles.widget} main-widget`}>
                {onEdit && <SurveyEditor onClose={this.closeSurveyEditor} onSave={store.saveSurvey} />}

                <div className="">
                    {store.isEmpty && (
                        <>
                            <span>Опросы</span>
                            {addButton}
                        </>
                    )}
                </div>

                <div className={styles.container}>
                    {store.items.length > 0 && (
                        <Survey survey={store.items[0]} button={!hasSecond && addButton}>
                            {!hasSecond && navigation}
                        </Survey>
                    )}
                    {hasSecond && (
                        <Survey survey={store.items[1]} button={addButton}>
                            {navigation}
                        </Survey>
                    )}

                    {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
                </div>
            </div>
        );
    }

    goBack = () => {
        const { store } = this.props;
        store.hasPrevPage && store.setPage(store.page - 1);
    };

    goForward = () => {
        const { store } = this.props;
        store.hasNextPage && store.setPage(store.page + 1);
    };

    addSurvey = () => {
        this.setState({ onEdit: true });
    };

    closeSurveyEditor = () => this.setState({ onEdit: false });
}

export const SurveyWidget = observer(Widget);

interface SurveyWidgetProps {
    store: SurveyStoreType;
    session: SessionType;
}

interface SurveyWidgetState {
    onEdit: boolean;
}
