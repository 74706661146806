import { Field, FieldProps } from "formik";
import { observer } from "mobx-react";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { OrderExternalParams, OrderSummaryType, sortOrderObjects } from "modules/orders-manage/models/order";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { Constants } from "modules/root/models/constants";
import React from "react";
import { CategoryBlock } from "./CategoryBlock";
import styles from "./ContentTask.module.scss";

import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OrderContentTasksDictionaryType } from "modules/orders-manage/models/order-content-tasks";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { Schema } from "yup";
import { ScrollToElement } from "../spending/helpers";
import { DownloadButton } from "./RowTask";

class ContentBase extends React.Component<ContentProps & RouteComponentProps, ContentState> {
    mounted = true;
    private me = React.createRef<HTMLDivElement>();
    recordPointer = "";
    constructor(props: ContentProps & RouteComponentProps) {
        super(props);

        const external = OrderExternalParams(this.props.location.hash);
        this.recordPointer = external.rowId;
        this.state = { downloading: false };
    }
    componentDidMount() {
        this.mounted = true;
        ScrollToElement(this.me, this.recordPointer || "", 1000);
        if (this.recordPointer !== "" && this.me) {
            this.collapseAll(false);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.setState({ downloading: false });
    }
    render() {
        const {
            name,
            order,
            readOnly,
            collapser,
            collapserContent,
            store,
            baseUrl,
            user,
            clientCanDownloadField,
            schema,
            employee,
        } = this.props;

        const collapsed = { ...collapser.plain };
        const categories = Array.from(Constants.workTypeCategory.values());
        let arrToDownload: any[] = [];
        for (let item of store.contentTasksList) {
            if (item.lastFilesVersion) {
                arrToDownload.push({ contentGuid: item.guid });
            }
        }

        const canDownloadFiles =
            store.contentTasksList.length > 0 ? store.contentTasksList[0].canDownloadStructure : false;
        const canGenerateSheet = store.contentTasksList.length > 0 ? store.contentTasksList[0].canGenerateSheet : false;
        return (
            <div className={styles.content}>
                <div className="planr-tools" style={{ height: "52px" }}>
                    {!readOnly && (
                        <div style={{ marginRight: "8px" }}>
                            <StandardFormInput
                                name={clientCanDownloadField}
                                schema={schema}
                                className="planr-form-input"
                                inline={true}
                            >
                                {({ field, form }) => (
                                    <PlanrButton
                                        type={field.value === true ? "blueish" : "graybtn"}
                                        icon={"general-check"}
                                        onClick={() => {
                                            form.setFieldValue(field.name, !field.value);
                                            form.setFieldTouched(field.name, true);
                                        }}
                                        round
                                        title="Доступ к скачиванию для заказчика"
                                    />
                                )}
                            </StandardFormInput>
                        </div>
                    )}
                    {!readOnly && canGenerateSheet && (
                        <PlanrButton
                            type="graybtn"
                            icon="general-export"
                            onClick={() => {
                                store.downloadSheets(order.id);
                            }}
                            round={true}
                            title="Сформировать ИУЛ для всех разделов"
                        />
                    )}

                    {canDownloadFiles && (
                        <DownloadButton
                            gray={true}
                            loading={this.state.downloading}
                            onClick={async () => {
                                try {
                                    this.setState({ downloading: true });
                                    await store.downloadFiles(order.id, arrToDownload);
                                } catch {
                                    return;
                                } finally {
                                    if (this.mounted) {
                                        this.setState({ downloading: false });
                                    }
                                }
                            }}
                        />
                    )}
                    <CollapseExpandAll onChange={this.collapseAll} />
                </div>

                <Field name={name}>
                    {(fieldProps: FieldProps) => {
                        const objects = sortOrderObjects(order.objects);

                        return (
                            <div className={styles.contents} ref={this.me}>
                                {categories.map((category) => {
                                    return (
                                        <CategoryBlock
                                            baseUrl={baseUrl}
                                            key={category}
                                            category={category}
                                            collapsed={!collapsed[category]}
                                            onToggleCollapse={() => collapser.toggle(category)}
                                            fieldProps={fieldProps}
                                            collapserContent={collapserContent}
                                            objects={objects}
                                            readOnly={readOnly}
                                            store={store}
                                            orderId={order.id}
                                            user={user}
                                            highlightRow={this.recordPointer}
                                            employee={employee}
                                        />
                                    );
                                })}
                            </div>
                        );
                    }}
                </Field>
            </div>
        );
    }

    collapseAll = (state: boolean) => {
        this.props.collapser.setAll(state);
        this.props.collapserContent.setAll(state);
    };
}

export const ContentTask = withRouter(observer(ContentBase));

interface ContentProps {
    name: string;
    readOnly: boolean;
    collapser: SectionCollapserType;
    collapserContent: SectionCollapserType;
    order: OrderSummaryType;
    store: OrderContentTasksDictionaryType;
    clientCanDownloadField: string;
    baseUrl: string;
    user: string;
    schema: Schema<any>;
    employee: EmployerDictionaryType;
}
interface ContentState {
    downloading: boolean;
}
