import React from "react";
import { TimesheetStoreType } from "modules/spending/timesheet/models/timesheet-store";
import { WeekDays } from "modules/spending/timesheet/components/WeekDays";
import { TimesheetUserRow } from "modules/spending/timesheet/components/TimesheetUserRow";
import { observer } from "mobx-react";
import styles from "./TimeSheet.module.scss";

class Row extends React.Component<TimesheetProps, TimesheetState> {
    constructor(props: TimesheetProps) {
        super(props);
        this.state = { selectedCell: "", monthModeIsEditing: false };
    }

    render() {
        const { userId, store, userLogin, canComment } = this.props;
        const { users, orders, tasks } = store;
        const { selectedCell } = this.state;

        const timesheet = users.find((r) => r.user.id === userId);
        if (!timesheet) {
            return null;
        }

        return (
            <div className={styles.row}>
                <WeekDays days={timesheet.days} />
                <TimesheetUserRow
                    timesheet={timesheet}
                    key={timesheet.user.id}
                    mode="hours"
                    orders={orders}
                    canManage={true}
                    onSelectCell={this.selectCell}
                    selectedCell={selectedCell}
                    userLogin={userLogin}
                    onSave={this.saveDay}
                    tasks={tasks}
                    changeComment={(model: any) => {
                        store.setComment(model);
                    }}
                    canComment={canComment}
                    sessionId={userId}
                    showMinutes={store.showMinutes}
                    multiSelectCells={true}
                    onContext={() => {}}
                />
            </div>
        );
    }

    saveDay = (model: any) => this.props.store.save(model);

    selectCell = (selectedCell: string) => this.setState({ selectedCell });
}

export const TimesheetRow = observer(Row);

interface TimesheetProps {
    store: TimesheetStoreType;
    userId: string;
    userLogin: string;
    canComment: boolean;
}

interface TimesheetState {
    selectedCell: string;
    monthModeIsEditing: boolean;
}
