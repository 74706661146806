import React from "react";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { ColDef, ICellRendererParams, ValueFormatterParams, ColumnGroup, ColGroupDef } from "@ag-grid-community/core";
import { fields, DetailedOrderRowType, DetailedOrderRowSnapshotType } from "../../models/detailed-order";
import { gridMoneyFormatterWithCurrency, Money } from "modules/common/components/money/Money";
import { formatDate } from "modules/common/services/formatting/date";
import { texts } from "modules/common/texts";
import {
    SortIcon,
    TSorting,
    IHeaderComponentFrameworkParams,
    ExpandIcon,
    CustomHeaderCell,
    CustomSortableHeader,
} from "modules/common/services/table/custom-header";
import { OrderStatusDictionaryType } from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { isNotNull } from "modules/common/services/typescript";
import { Classes, Popover } from "@blueprintjs/core";
import { prettyRound } from "modules/common/services/formatting/numbers";
import { colorLuminance } from "modules/common/services/colors";
import { FinanceValuesDetails } from "./FinanceValuesDetails";
import { Constants } from "modules/root/models/constants";
import moment from "moment";
import { DATE_FORMAT } from "modules/common/constants";

const styles = (color: string): React.CSSProperties => ({
    backgroundColor: color,
    minWidth: "4px",
    height: "28px",
});

const percentsStyle: React.CSSProperties = {
    fontSize: "12px",
};

export const columns = (statuses: OrderStatusDictionaryType, tableName: string, moneyVisible: boolean) => {
    const columnSize = getColumnSize(tableName);

    const typeOptions = Constants.orderCheckOverdueStatusTypes.map((type) => ({
        value: type,
    }));

    const result: ColDef[] = [
        {
            headerName: "",
            field: fields.color,
            width: 4,
            minWidth: 4,
            sortable: true,
            resizable: true,
            cellClass: "flat",
            headerClass: "flat ",
            cellRendererFramework: (params: ICellRendererParams) => {
                return <div style={styles(params.value)} className="order-color" />;
            },
        },
        {
            headerName: "",
            field: fields.allWarnings,
            width: 40,
            sortable: true,
            resizable: true,
            cellRendererFramework: (value: any) => {
                let newArr = [];
                if (value.value.length >= 10) {
                    for (let i = 0; i < 10; i++) {
                        newArr[i] = value.value[i];
                    }
                    newArr.push(`... + еще ${value.value.length - 10} замечаний`);
                } else {
                    for (let i = 0; i < value.value.length; i++) {
                        newArr[i] = value.value[i];
                    }
                }
                if ((value.value && value.value.length !== 0) || value.data.startDate === null) {
                    return (
                        <Popover position="auto" isOpen={undefined} interactionKind="hover">
                            <GeneralIcon type="general-exclamation" style={{ color: "#E31818" }} />
                            <div style={{ color: "#00273D", padding: "16px 24px" }}>
                                {value.data.startDate === null ? (
                                    <p>{"Необходимо указать начальную дату договора"}</p>
                                ) : (
                                    newArr.map((item: any, index: any) => {
                                        return <p key={index}>{item}</p>;
                                    })
                                )}
                            </div>
                        </Popover>
                    );
                } else {
                    return null;
                }
            },
        },
        {
            headerName: "Инв",
            field: fields.fullInventoryNumber,
            width: columnSize(fields.fullInventoryNumber, 60),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "№ договора",
            field: fields.orderNumber,
            width: columnSize(fields.orderNumber, 100),
            sortable: true,
            resizable: true,
        } as ColDef,

        {
            headerName: texts.name,
            field: fields.name,
            width: columnSize(fields.name, 167),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "ГИП",
            field: fields.engineer,
            width: columnSize(fields.engineer, 115),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Заказчик",
            field: fields.client,
            width: columnSize(fields.client, 150),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Дата",
            field: fields.startDate,
            width: columnSize(fields.startDate, 95),
            sortable: true,
            resizable: true,
            sort: "desc",

            comparator: (valueA, valueB, nodeA, nodeB) => {
                const dateA = (nodeA.data as DetailedOrderRowType).startDateSortable;
                const dateB = (nodeB.data as DetailedOrderRowType).startDateSortable;
                return dateA.localeCompare(dateB);
            },

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const row: DetailedOrderRowType = data;
                return <span>{formatDate(row.startDate as any)}</span>;
            },
        } as ColDef,
        {
            headerName: "Завершение",
            field: fields.stopDate,
            width: columnSize(fields.stopDate, 95),
            sortable: true,
            sort: "desc",
            resizable: true,

            comparator: (valueA, valueB, nodeA, nodeB) => {
                const dateA = (nodeA.data as DetailedOrderRowType).stopDateSortable;
                const dateB = (nodeB.data as DetailedOrderRowType).stopDateSortable;
                return dateA.localeCompare(dateB);
            },

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const row: DetailedOrderRowType = data;

                if (row.stopDatePlanWarning) {
                    return (
                        <Popover position="auto" isOpen={undefined} interactionKind="hover">
                            <GeneralIcon type="general-exclamation" style={{ color: "#E31818" }} />
                            <div style={{ color: "#00273D", padding: "16px 24px" }}>{row.stopDatePlanWarning}</div>
                        </Popover>
                    );
                }

                let colorStr = "";

                if (formatDate(data.stopDate as any) === data.stopDatePlan) {
                    colorStr = "#369CD7";
                }
                typeOptions.find((item) => {
                    if (row.statusType === item.value) {
                        if (!!row.stopDatePlan) {
                            const expired = moment(row.stopDatePlan, DATE_FORMAT).isBefore(new Date(), "days");
                            if (expired) {
                                colorStr = "#E31818";
                            }
                        }

                        return true;
                    }

                    return false;
                });

                return <span style={{ color: colorStr }}>{formatDate(row.stopDate as any)}</span>;
            },
        } as ColDef,
        {
            headerName: "Статус",
            field: fields.status,
            width: columnSize(fields.status, 110),
            sortable: true,
            resizable: true,

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const row: DetailedOrderRowType = data;
                const status = statuses.asMap[row.statusId];

                return RenderOrderStatus(status ? status.label : "", status ? status.color : "");
            },
        },
        {
            headerName: "Загружен",
            field: fields.hasResultFiles,
            width: columnSize(fields.hasResultFiles, 110),
            sortable: true,
            resizable: true,

            cellRendererFramework: ({ data }: ICellRendererParams) => {
                const row: DetailedOrderRowType = data;

                if (row.hasAllResultFiles) {
                    return <GeneralIcon type="general-doc-check" style={{ color: "#1DD278" }} />;
                }
                if (row.hasAllNotConfirmedResultFiles) {
                    return <GeneralIcon type="general-doc-check" style={{ color: "#36ACD7" }} />;
                }
                if (row.hasResultFiles) {
                    return <GeneralIcon type="general-doc-check" style={{ color: "#EA561E" }} />;
                }
                return null;
            },
        },
        moneyVisible
            ? {
                  headerName: "Сумма дог-ра",
                  field: fields.sum,
                  width: columnSize(fields.sum, 115),
                  resizable: true,

                  valueFormatter: gridMoneyFormatterWithCurrency,
                  sortable: true,
              }
            : null,
        moneyVisible
            ? {
                  headerName: "Акт выполнен",
                  field: fields.completions,
                  width: columnSize(fields.completions, 115),
                  valueFormatter: gridMoneyFormatterWithCurrency,
                  sortable: true,
                  resizable: true,
              }
            : null,
        moneyVisible
            ? {
                  headerName: "Оплаты",
                  field: fields.paymentSum,
                  width: columnSize(fields.paymentSum, 135),
                  resizable: true,
                  valueFormatter: gridMoneyFormatterWithCurrency,
                  sortable: true,
                  cellRendererFramework: (params: ICellRendererParams) => {
                      if (params.data.isNeedToLocateActPayments) {
                          return (
                              <div style={{ display: "flex" }}>
                                  <div style={{ width: "115px" }}>
                                      <Money amount={params.value} />
                                  </div>
                                  <Popover position="auto" isOpen={undefined} interactionKind="hover">
                                      <GeneralIcon type="general-exclamation" style={{ color: "#E31818" }} />
                                      <div style={{ color: "#00273D", padding: "16px 24px" }}>
                                          Нераспределенные оплаты на вкладке [Финансирование]
                                      </div>
                                  </Popover>
                              </div>
                          );
                      }

                      return <Money amount={params.value} />;
                  },
              }
            : null,

        moneyVisible
            ? ({
                  headerName: "По дог-ру",
                  headerClass: "flat",
                  groupId: "ordersSum",
                  children: [
                      {
                          headerName: "По дог-ру",
                          field: fields.planSpendings,
                          width: columnSize(fields.planSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          headerClass: "custom-col-group",
                          sortable: true,
                          resizable: true,
                          headerComponentFramework: groupHeader("ordersSum"),
                      },
                      {
                          headerName: "СС",
                          field: fields.planOwnSpendings,
                          width: columnSize(fields.planOwnSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          resizable: true,
                      },
                      {
                          headerName: "СП",
                          field: fields.planOutsourcedSpendings,
                          width: columnSize(fields.planOutsourcedSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          resizable: true,
                      },
                      {
                          headerName: "Прочее",
                          field: fields.planOtherSpendings,
                          width: columnSize(fields.planOtherSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          cellClass: "last",
                          resizable: true,
                      },
                  ],
              } as ColGroupDef)
            : (null as any),
        moneyVisible
            ? ({
                  headerName: "Всего затрат",
                  headerClass: "flat",
                  groupId: "spendings",
                  children: [
                      {
                          headerName: "Всего затрат",
                          field: fields.actualSpendings,
                          width: columnSize(fields.actualSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          resizable: true,
                          headerClass: "custom-col-group",
                          sortable: true,
                          headerComponentFramework: groupHeader("spendings"),
                      },
                      {
                          headerName: "СС",
                          field: fields.actualOwnSpendings,
                          width: columnSize(fields.actualOwnSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          resizable: true,
                      },
                      {
                          headerName: "СП",
                          field: fields.actualOutsourcedSpendings,
                          width: columnSize(fields.actualOutsourcedSpendings, 115),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          resizable: true,
                      },
                      {
                          headerName: "Прочее",
                          field: fields.actualOtherSpendings,
                          width: columnSize(fields.actualOtherSpendings, 100),
                          valueFormatter: gridMoneyFormatterWithCurrency,
                          columnGroupShow: "open",
                          resizable: true,
                          cellClass: "last",
                      },
                  ],
              } as ColGroupDef)
            : (null as any),
        moneyVisible
            ? {
                  headerName: "Долг",
                  field: fields.credit,
                  width: columnSize(fields.credit, 115),
                  valueFormatter: gridMoneyFormatterWithCurrency,
                  headerClass: " ",
                  sortable: true,
                  resizable: true,
              }
            : null,
        {
            headerName: "Рамочный / Муниципальный",
            field: fields.marks,
            cellClass: "flat",
            width: 110,
            headerClass: " ",
            resizable: true,
            headerComponentFramework: (props: IHeaderComponentFrameworkParams) => {
                return (
                    <CustomSortableHeader
                        {...props}
                        renderLabel={() => <GeneralIcon type="general-board" style={{ marginTop: "8px" }} />}
                    />
                );
            },
        },
        {
            headerName: "Портфель",
            field: fields.portfolio,
            width: columnSize(fields.portfolio, 60),
            sortable: true,
            resizable: true,
            headerClass: " ",
            headerComponentFramework: (props: IHeaderComponentFrameworkParams) => {
                return (
                    <CustomSortableHeader
                        {...props}
                        renderLabel={() => (
                            <GeneralIcon type="general-portfolio" style={{ marginTop: "8px", marginLeft: "10px" }} />
                        )}
                    />
                );
            },
        },

        moneyVisible
            ? {
                  headerName: "Рент, %",
                  field: fields.profitabilityPercents,
                  width: columnSize(fields.profitabilityPercents, 80),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ value, data }: ICellRendererParams) => {
                      const profitabilityPercents = +value;
                      const row: DetailedOrderRowSnapshotType = data;
                      const style: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${
                              profitabilityPercents > 0 ? "#1dd278" : profitabilityPercents < 0 ? "#E31818" : "#00273D"
                          }`,
                      };

                      return FinanceValuesDetails(
                          row.finances,
                          <div style={style} className={Classes.TOOLTIP_INDICATOR}>
                              {prettyRound(value)}%
                          </div>
                      );
                  },
              }
            : null,

        moneyVisible
            ? {
                  headerName: "Тек.рент, %",
                  field: fields.todayProfitabilityPercents,
                  width: columnSize(fields.todayProfitabilityPercents, 110),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ value, data }: ICellRendererParams) => {
                      const row: DetailedOrderRowSnapshotType = data;

                      const style: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${needHighlight(row) ? "#E31818" : "#00273D"}`,
                      };

                      return FinanceValuesDetails(
                          row.todayFinances,
                          <div style={style} className={Classes.TOOLTIP_INDICATOR}>
                              {prettyRound(value)}%
                          </div>
                      );
                  },
              }
            : null,

        moneyVisible
            ? {
                  headerName: "Рент, ₽",
                  field: fields.profitability,
                  width: columnSize(fields.profitability, 115),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ value }: ICellRendererParams) => {
                      const profitability = +value;
                      const style: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${profitability > 0 ? "#1dd278" : profitability < 0 ? "#E31818" : "#00273D"}`,
                      };

                      return <Money style={style} amount={value} />;
                  },
              }
            : null,

        moneyVisible
            ? {
                  headerName: "Тек.рент, ₽",
                  field: fields.todayProfitability,
                  width: columnSize(fields.todayProfitability, 115),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ value, data }: ICellRendererParams) => {
                      const row: DetailedOrderRowSnapshotType = data;
                      const style: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${needHighlight(row) ? "#E31818" : "#00273D"}`,
                      };

                      return <Money style={style} amount={value} />;
                  },
              }
            : null,

        moneyVisible
            ? {
                  headerName: "После заверш",
                  field: fields.overSpending,
                  width: columnSize(fields.overSpending, 115),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ value }: ICellRendererParams) => {
                      const overSpending = +value;
                      const style: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${overSpending > 0 ? "#E31818" : "#00273D"}`,
                      };

                      return <Money style={style} amount={value} />;
                  },
              }
            : null,

        moneyVisible
            ? {
                  headerName: "Затрат, %",
                  field: fields.spendingPercentage,
                  width: columnSize(fields.spendingPercentage, 76),
                  sortable: true,
                  resizable: true,
                  cellRendererFramework: ({ data }: ICellRendererParams) => {
                      const row: DetailedOrderRowType = data;
                      const status = +row.spendingPercentage;
                      const statusStyle: React.CSSProperties = {
                          ...percentsStyle,
                          color: `${
                              status <= 35
                                  ? "#1DD278"
                                  : status > 35 && status <= 45
                                  ? "#FBB916"
                                  : status > 45 && status <= 65
                                  ? "#D76C36"
                                  : status > 65
                                  ? "#E31818"
                                  : "#00273D"
                          }`,
                      };
                      return <span style={statusStyle}>{prettyRound(status)}%</span>;
                  },
              }
            : null,
        moneyVisible
            ? {
                  headerName: "Оплат, %",
                  field: fields.paymentPercentage,
                  resizable: true,
                  width: columnSize(fields.paymentPercentage, 76),
                  sortable: true,
                  valueFormatter: percentage,
              }
            : null,
        {
            headerName: "Выполн, %",
            field: fields.progress,
            resizable: true,
            width: columnSize(fields.progress, 85),
            sortable: true,
            valueFormatter: percentage,
        },
    ].filter(isNotNull);

    return result;
};

function needHighlight(row: DetailedOrderRowSnapshotType) {
    const highlight =
        prettyRound(row.profitabilityPercents) !== prettyRound(row.todayProfitabilityPercents) ||
        prettyRound(row.finances.profitability) !== prettyRound(row.todayFinances.profitability);

    return highlight;
}

function percentage({ value }: ValueFormatterParams) {
    return typeof value === "number" ? `${value.toFixed(2)}%` : "";
}

const groupHeader = (groupId: string) =>
    class RenderGroupHeader extends React.PureComponent<
        IHeaderComponentFrameworkParams,
        { sorting: TSorting; opened: boolean }
    > {
        constructor(props: IHeaderComponentFrameworkParams) {
            super(props);

            props.column.addEventListener("sortChanged", this.onSortChanged);
            props.api.addEventListener("columnGroupOpened", this.onColumnExpanded);

            const grouper = this.props.columnApi.getColumnGroup(groupId);
            this.state = { sorting: this.getSorting(), opened: grouper ? grouper.isExpanded() : false };
        }

        componentWillUnmount() {
            this.props.column.removeEventListener("sortChanged", this.onSortChanged);
            this.props.api.removeEventListener("columnGroupOpened", this.onColumnExpanded);
        }

        render() {
            const sortIcon = this.props.enableSorting ? SortIcon(this.state.sorting) : null;
            const openIcon = <ExpandIcon expanded={!this.state.opened} onClick={this.setExpanded} />;

            return (
                <CustomHeaderCell
                    onClick={this.setSort}
                    label={this.props.displayName}
                    children={
                        <>
                            {sortIcon}
                            {openIcon}
                        </>
                    }
                />
            );
        }

        setSort = () => {
            const toggle: TStringMap<string> = {
                asc: "desc",
                desc: "asc",
                none: "asc",
            };

            const direction = toggle[this.state.sorting];
            const sortModel = [{ colId: this.props.column.getId(), sort: direction }];
            this.props.api.setSortModel(sortModel);
        };

        setExpanded = () => {
            const newValue = !this.state.opened;
            this.props.columnApi.setColumnGroupOpened(groupId, newValue);
        };

        getSorting = () => {
            if (this.props.column.isSortAscending()) {
                return "asc";
            }

            if (this.props.column.isSortDescending()) {
                return "desc";
            }

            return "none";
        };

        onSortChanged = () => {
            this.setState({ sorting: this.getSorting() });
        };

        onColumnExpanded = ({ columnGroup }: { columnGroup: ColumnGroup }) => {
            this.setState({ opened: columnGroup.isExpanded() });
        };
    };

export function RenderOrderStatus(name: string, color: string) {
    const statusStyle: React.CSSProperties = {
        padding: "5px 7px",
        fontSize: "10px",
        borderRadius: "4px",
        backgroundColor: `${name !== "" ? colorLuminance(color, 0.1) : ""}`,
        color: `${name !== "" ? colorLuminance(color, -0.5) : "#00273D"}`,
    };

    return name && color ? <span style={statusStyle}>{name}</span> : name;
}
