import React from "react";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { Field, FieldProps } from "formik";
import { OtherOrderSpendingType, OtherOrderSpendingSnapshotType } from "modules/orders-manage/models/order-spending";
import { Collapse } from "@blueprintjs/core";
import { findIndex, sortBy } from "lodash";
import baseStyles from "../outsourced/OutsourcedSpendings.module.scss";
import { Money } from "modules/common/components/money/Money";
import { texts } from "modules/common/texts";
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided } from "react-beautiful-dnd";
import { move } from "modules/common/services/array";
import { Caret } from "modules/common/components/collapse/Caret";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { observer } from "mobx-react";
import { UploaderFatory, IdFactory } from "modules/orders-manage/types";
import { ScrollToElement } from "../helpers";
import { OutsourcedSpendingRow, OutsourcerPaymentFactory } from "../outsourced/OutsourcedSpendingRow";
import { EMPTY_GROUP, OtherSpendingsObject } from "./OtherSpendingsObject";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { CheckBlockWarning } from "../../validation";
import { OTHER_BLOCK_NAME } from "../outsourced/print-view-model";

const OBJECTS_DRAG = "groups";
type TSpending = OtherOrderSpendingType;

function makeSpendingGroups(value: TSpending[]) {
    // groups spendings according to its order
    const objects: Tuple<string, TSpending[]>[] = [];

    value.forEach((sp) => {
        const index = findIndex(objects, (tuple) => tuple.item1 === sp.comment);

        const target: Tuple<string, TSpending[]> = index >= 0 ? objects[index] : { item1: sp.comment, item2: [] };

        target.item2.push(sp);

        if (index >= 0) {
            objects[index] = target;
        } else {
            objects.push(target);
        }
    });

    return objects;
}

export class OtherSpendings extends React.PureComponent<OtherSpendingsProps> {
    private me = React.createRef<HTMLDivElement>();
    private fieldProps: FieldProps | null = null;

    componentDidMount() {
        ScrollToElement(this.me, this.props.highlightRow || "");
    }

    render() {
        const { agents, name, paymentFactory, collapsed, toggleStatus, readOnly } = this.props;
        const { baseUrl, upload, newId, disabled, printOrderFile } = this.props;

        return (
            <Field name={name}>
                {(fieldProps: FieldProps) => {
                    this.fieldProps = fieldProps;
                    const { field } = fieldProps;
                    const value: TSpending[] = sortBy(field.value, (s) => s.sortOrder);
                    const orderTotal = value.reduce((acc, unit) => +unit.actualSum + acc, 0);
                    const actualTotal = value.reduce((acc, unit) => {
                        return (
                            acc +
                            unit.actualPayments.reduce(
                                (t, p) => t + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0),
                                0
                            )
                        );
                    }, 0);

                    return (
                        <div className={`${baseStyles.spendings} other-spendings`} ref={this.me}>
                            <h1 className="planr-block-header collapser" onClick={this.onToggleCollapse}>
                                {OTHER_BLOCK_NAME}
                                <Caret collapsed={collapsed} />
                            </h1>

                            <div className="order-total" onClick={this.onToggleCollapse}>
                                Всего по договорам:&nbsp;
                                <Money className="spendings-money" amount={orderTotal} />
                            </div>

                            <div
                                className={`actual-total ${actualTotal < orderTotal ? "red-total" : "green-total"}`}
                                onClick={this.onToggleCollapse}
                            >
                                Всего по оплатам:&nbsp;
                                <Money className="spendings-money " amount={actualTotal} />
                            </div>
                            <div
                                className={`spendings-total ${
                                    orderTotal - actualTotal !== 0 ? "red-total" : "green-total"
                                }`}
                                onClick={this.onToggleCollapse}
                            >
                                Остаток по оплатам:&nbsp;
                                <Money className="spendings-money" amount={orderTotal - actualTotal} />
                            </div>
                            {/* <div className="actual-total" onClick={this.onToggleCollapse}>
                                    Остаток по оплатам:&nbsp;
                                    <Money className="spendings-money" amount={orderTotal - actualTotal} />
                                </div> */}
                            {!readOnly && (
                                // <Icon
                                //     icon="plus"
                                //     iconSize={LARGE_ICON_AS_BUTTON_SIZE}
                                //     className={`action-icon ${styles.addButton}`}
                                //     onClick={() => this.addSpending("")}
                                //     htmlTitle={texts.add}
                                // />
                                <div>
                                    <PlanrButton
                                        type="neutral"
                                        icon={"general-plus-big"}
                                        size="small"
                                        onClick={() => this.addSpending("")}
                                        style={{ position: "absolute", top: "10px", left: "1200px" }}
                                        title={texts.add}
                                    />
                                </div>
                            )}

                            <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                                <div className="collapse">
                                    <div className={"collapse-item"}>
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable
                                                droppableId={OBJECTS_DRAG}
                                                type={OBJECTS_DRAG}
                                                isDropDisabled={readOnly}
                                            >
                                                {(provided) => (
                                                    <DraggableInternals
                                                        agents={agents}
                                                        fieldProps={fieldProps}
                                                        paymentFactory={paymentFactory}
                                                        provided={provided}
                                                        toggleStatus={toggleStatus}
                                                        value={value}
                                                        highlightRow={this.props.highlightRow}
                                                        innerCollapser={this.props.innerCollapser}
                                                        readOnly={readOnly}
                                                        name={name}
                                                        onAdd={this.addSpending}
                                                        baseUrl={baseUrl}
                                                        newId={newId}
                                                        upload={upload}
                                                        disabled={disabled}
                                                        printOrderFile={printOrderFile}
                                                    />
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    );
                }}
            </Field>
        );
    }

    onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination || !this.fieldProps) {
            return;
        }

        // initial index
        const from = result.source.index;
        // new index
        const to = result.destination.index;

        let index = 0;
        const newValue: TSpending[] = [];
        const value: TSpending[] = sortBy(this.fieldProps.field.value, (s: TSpending) => s.sortOrder);
        const objects = makeSpendingGroups(value);

        // whole group was moved
        if (result.type === OBJECTS_DRAG) {
            move(objects, from, to).forEach((tuple) =>
                tuple.item2.forEach((sp) => {
                    newValue.push({
                        ...sp,
                        sortOrder: index++,
                    });
                })
            );
        } else {
            // users inside sigle group were moved
            const comment = result.type === EMPTY_GROUP ? "" : result.type;

            objects.forEach((tuple) => {
                const spendings = tuple.item1 === comment ? move(tuple.item2, from, to) : tuple.item2;

                spendings.forEach((sp) => {
                    newValue.push({
                        ...sp,
                        sortOrder: index++,
                    });
                });
            });
        }

        this.fieldProps.form.setFieldValue(this.fieldProps.field.name, newValue);
        this.fieldProps.form.setFieldTouched(this.fieldProps.field.name, true);
    };

    addSpending = async (comment: string) => {
        const { fieldProps } = this;

        if (fieldProps) {
            const value: TSpending[] = fieldProps.field.value;
            const estimate = await this.props.factory.emptyOtherSpending(value.length + 1);
            estimate.comment = comment || "";
            const newValue = [...value, estimate];

            fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
            fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            this.props.onToggleCollapse(true);
        }
    };

    onToggleCollapse = () => this.props.onToggleCollapse();
}

const DraggableInternals = observer(
    class extends React.Component<DraggableInternalsProps> {
        render() {
            const { readOnly, provided, agents, value, paymentFactory, highlightRow, onAdd, printOrderFile } =
                this.props;
            const { name, fieldProps, toggleStatus, innerCollapser, baseUrl, newId, upload, disabled } = this.props;
            const { form } = fieldProps;

            // groups spendings according to its order
            const objects = makeSpendingGroups(value);

            return (
                <div {...provided.droppableProps} ref={provided.innerRef} className={`spendings ${baseStyles.table}`}>
                    {objects.map((tuple, index) => {
                        const spendings = tuple.item2;
                        const ids = spendings.map((s) => s.id);
                        const collapsed = !innerCollapser.plain[tuple.item1];

                        const onDescriptionChange = (descr: string) => {
                            const newValue = value.map((s) => {
                                return ids.includes(s.id) ? { ...s, comment: descr } : s;
                            });

                            innerCollapser.set(descr, !collapsed);
                            form.setFieldValue(name, newValue);
                            form.setFieldTouched(name, true);
                        };

                        const addSpending = () => onAdd(tuple.item1);

                        const warning = CheckBlockWarning(spendings);

                        return (
                            <OtherSpendingsObject
                                spendings={spendings}
                                collapsed={collapsed}
                                index={index}
                                description={tuple.item1}
                                fieldProps={fieldProps}
                                onToggleCollapse={() => innerCollapser.toggle(tuple.item1)}
                                readOnly={disabled || readOnly}
                                addSpending={addSpending}
                                key={tuple.item1}
                                onDescriptionChange={onDescriptionChange}
                                warning={warning}
                            >
                                {spendings.map((spending, i) => (
                                    <Draggable
                                        key={spending.id}
                                        draggableId={spending.id}
                                        index={i}
                                        isDragDisabled={readOnly}
                                    >
                                        {(providedForSpending, snapshot) => (
                                            <OutsourcedSpendingRow
                                                disabled={disabled}
                                                baseUrl={baseUrl}
                                                isDragging={snapshot.isDragging}
                                                draggable={providedForSpending}
                                                agents={agents}
                                                paymentFactory={paymentFactory}
                                                spending={spending}
                                                highlight={highlightRow}
                                                onChange={(field, v) => {
                                                    const spIndex = findIndex(value, (s) => s.id === spending.id);
                                                    const newValue = [
                                                        ...value.slice(0, spIndex),
                                                        { ...spending, [field]: v },
                                                        ...value.slice(spIndex + 1),
                                                    ];

                                                    form?.setFieldValue(name, newValue);
                                                    form?.setFieldTouched(name, true);
                                                }}
                                                onRemove={() => {
                                                    const spIndex = findIndex(value, (s) => s.id === spending.id);
                                                    const newValue = [
                                                        ...value.slice(0, spIndex),
                                                        ...value.slice(spIndex + 1),
                                                    ];

                                                    form?.setFieldValue(name, newValue);
                                                    form?.setFieldTouched(name, true);
                                                }}
                                                printOrderFile={printOrderFile}
                                                formDirty={form.dirty}
                                                toggleStatus={toggleStatus}
                                                readOnly={readOnly}
                                                newId={newId}
                                                upload={upload}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            </OtherSpendingsObject>
                        );
                    })}
                    {provided.placeholder}
                </div>
            );
        }
    }
);

export interface OtherSpendingFactory {
    emptyOtherSpending: (sortOrder: number) => Promise<OtherOrderSpendingSnapshotType>;
}

interface OtherSpendingsProps extends IdFactory, UploaderFatory {
    name: string;
    paymentFactory: OutsourcerPaymentFactory;
    agents: OutsourcerDictionaryType;
    onToggleCollapse: (value?: boolean) => void;
    collapsed: boolean;
    factory: OtherSpendingFactory;
    toggleStatus: (guid: string) => void;
    readOnly: boolean;
    innerCollapser: SectionCollapserType;
    highlightRow?: string;
    baseUrl: string;
    disabled: boolean | undefined;
    printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
}

interface DraggableInternalsProps extends IdFactory, UploaderFatory {
    name: string;
    provided: DroppableProvided;
    innerCollapser: SectionCollapserType;
    readOnly?: boolean;
    fieldProps: FieldProps;
    value: TSpending[];
    paymentFactory: OutsourcerPaymentFactory;
    agents: OutsourcerDictionaryType;
    toggleStatus: (guid: string) => void;
    baseUrl: string;
    onAdd: (comment: string) => void;
    highlightRow?: string;
    disabled?: boolean | undefined;
    printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
}
