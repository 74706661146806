import React from "react";
import { observer } from "mobx-react";
import styles from "./BankDetailsList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import {
    RowClickedEvent,
    GetContextMenuItemsParams,
    GridReadyEvent,
    CellContextMenuEvent,
    GridOptions,
} from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { agGridLocale } from "modules/root/services/locale";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import copyToClipboard from "copy-to-clipboard";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { CompanyBankDetailsSnapshotType, formatCompanyBankDetails } from "../../models/bank-details";
import { CompanyBankDetailsListType, CompanyBankDetailsList } from "../../models/bank-details-list";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { MAX_INT } from "modules/common/constants";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = CompanyBankDetailsList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const config = columns(TABLE_NAME);

class Internal extends React.Component<BankDetailsListProps> {
    private options: GridOptions;

    constructor(props: any) {
        super(props);
        this.options = DefaultAgGridOptions(true);
    }

    render() {
        const { store } = this.props;

        return (
            <RemoveConfirmation<CompanyBankDetailsSnapshotType>
                observation={store.data}
                what={(details) => `реквизиты ${details.name}`}
                onConfirmed={(details) => this.removeBankDetails(details)}
                render={({ confirmRemoving }) => (
                    <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                        <AgGridReact
                            rowStyle={{
                                borderBottom: "1 px solid #EDF0F2",
                                fontFamily: "GothamPro, Arial, sans-serif",
                                color: "#00273D",
                                height: "32px",
                                fontSize: "12px",
                            }}
                            rowSelection="single"
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={config}
                            rowData={store.data}
                            localeText={agGridLocale}
                            onRowDoubleClicked={this.onRowSelected}
                            getRowNodeId={getRowNodeId}
                            getContextMenuItems={(p) => this.getContextMenuItems(p, confirmRemoving)}
                            onGridReady={this.onGridReady}
                            onCellContextMenu={this.onCellContextMenu}
                            onSortChanged={store.sorter.resorted}
                            onColumnResized={sizeSaver}
                            gridOptions={this.options}
                            modules={modules}
                            rowBuffer={MAX_INT}
                        />
                    </GridTheme>
                )}
            />
        );
    }

    onCellContextMenu = ({ node }: CellContextMenuEvent) => {
        node && node.setSelected(true, true);
    };

    getContextMenuItems = (
        params: GetContextMenuItemsParams,
        confirmRemoving: (d: CompanyBankDetailsSnapshotType) => void
    ) => {
        const { onBankDetailsSelected } = this.props;
        return agGridEntityContextMenu({
            onChange: () => onBankDetailsSelected(getRowNodeId(params.node.data)),
            onCopy: () => copyToClipboard(formatCompanyBankDetails(params.node.data), { format: "text/plain" }),
            onRemove: () => confirmRemoving(params.node.data),
        });
    };

    removeBankDetails = async (details: CompanyBankDetailsSnapshotType) => {
        const success = await this.props.store.delete(details);
        success && this.props.onBankDetailsRemoved();
    };

    onRowSelected = ({ data }: RowClickedEvent) => {
        const { onBankDetailsSelected } = this.props;
        onBankDetailsSelected(getRowNodeId(data));
    };

    onGridReady = async ({ api }: GridReadyEvent) => {
        const { sorter } = this.props.store;
        setTimeout(() => api.setSortModel(sorter.agGridSortModel));
    };
}

export const BankDetailsList = observer(Internal);

const getRowNodeId = (e: CompanyBankDetailsSnapshotType) => e.id;

interface BankDetailsListProps {
    onBankDetailsSelected: (id: string) => void;
    onBankDetailsRemoved: () => void;
    store: CompanyBankDetailsListType;
}
