import {
    Button,
    Classes,
    Dialog,
    Divider,
    FormGroup,
    HTMLTable,
    InputGroup,
    MenuDivider,
    MenuItem,
    Switch,
    TextArea,
} from "@blueprintjs/core";
import { Menu } from "@blueprintjs/core/lib/esnext/components";
import { ItemListRenderer, ItemRenderer } from "@blueprintjs/select";
import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import {
    BankDetailsCompanyAddressField,
    BankDetailsFields,
    DirectorField,
} from "modules/agents/components/BankDetailsFields";
import { OutsourcerType, fields } from "modules/agents/outsourcers/models/outsourcer";
import { Buttons, CreditCard } from "modules/common/components/form";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Money } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { Rating } from "modules/common/components/rating/Rating";
import { routes } from "modules/common/routes";
import {
    DefaultSelectedOption,
    SelectFactory,
    SimpleSelect,
    filterItemPredicate,
    renderSingleOption as renderOption,
} from "modules/common/services/form/select";
import { MODAL_AWAIT_DELAY, fixTextAreaHeight } from "modules/common/services/form/textarea";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { texts } from "modules/common/texts";
import { WorkTypeDictionaryItemSnapshotType } from "modules/dictionaries/work-types/models/work-type";
import { WorkTypeDictionaryType } from "modules/dictionaries/work-types/models/work-type-dictionary";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import orderFunc from "modules/orders-manage/functionalities";
import { PARAM_SECTION } from "modules/orders-manage/models/order";
import { Constants, ConstantsType } from "modules/root/models/constants";
import { can } from "modules/session/auth/access";
import React from "react";
import { Prompt } from "react-router";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { SupplierDocuments } from "../../../suppliers/components/details/SupplierDocuments";
import { maxIssueDate, fields as passportFields } from "../../models/passport";
import styles from "./OutsourcerDetails.module.scss";
import { PassportBirthDay, PassportDetailsFields } from "./PassportDetailsFields";
import { SelfEmployedBankDetailsFields } from "./SelfEmployedBankDetailsFields";
import OutsourcerSchema, { MAX_WORK_TYPES } from "./validation";
import { AgentsCategoriesDictionaryType } from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";
const WorkTypeSelect = SelectFactory<WorkTypeDictionaryItemSnapshotType>();

class OutsourcerDetailsBase extends React.PureComponent<OutsourcerDetailsProps, OutsourcerDetailsState> {
    private formProps: FormikProps<any> | null = null;

    constructor(props: OutsourcerDetailsProps) {
        super(props);

        this.state = {
            workTypeId: "",
            renderMenu: renderMenu(Constants),
            legal: false,
            showClientOrders: false,
            selectedOrder: null,
            materialVal: "",
            showMaterials: false,
            showCategories: false,
            materials: [],
            selectedCategories: [],
        };
    }

    private commentArea: HTMLTextAreaElement | null = null;

    private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.commentArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.commentArea);
    };

    public async componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY * 2);
    }

    public componentDidUpdate() {
        this.resize();
    }

    setOutsourcer = (type: string) => {
        if (type === "legalOutsourcer") {
            this.formProps?.setFieldValue(fields.type, "legalOutsourcer");
            this.formProps?.setFieldTouched(fields.type, true);
            this.formProps?.setFieldValue(fields.isAgent, false);
            this.formProps?.setFieldTouched(fields.isAgent, true);
        }
        if (type === "physOutsourcer") {
            this.formProps?.setFieldValue(fields.type, "physOutsourcer");
            this.formProps?.setFieldTouched(fields.type, true);
            this.formProps?.setFieldValue(fields.isAgent, false);
            this.formProps?.setFieldTouched(fields.isAgent, true);
        }
        if (type === "supplier") {
            this.formProps?.setFieldValue(fields.type, "supplier");
            this.formProps?.setFieldTouched(fields.type, true);
            this.formProps?.setFieldValue(fields.isAgent, false);
            this.formProps?.setFieldTouched(fields.isAgent, true);
        }
        if (type === "client") {
            this.formProps?.setFieldValue(fields.type, "client");
            this.formProps?.setFieldTouched(fields.type, true);
            this.formProps?.setFieldValue(fields.isAgent, false);
            this.formProps?.setFieldTouched(fields.isAgent, true);
        }
        if (type === "agent") {
            this.formProps?.setFieldValue(fields.type, "agent");
            this.formProps?.setFieldTouched(fields.type, true);
            this.formProps?.setFieldValue(fields.isAgent, true);
            this.formProps?.setFieldTouched(fields.isAgent, true);
        }
    };

    printCredentials = () => this.props.onPrintCredentials(this.props.outsourcer);

    public render() {
        const {
            outsourcer,
            workTypes,
            canChange,
            canRemove,
            canConfirm,
            canSupplierOrder,
            access,
            showSensitiveData,
            baseUrl,
            categories,
        } = this.props;
        const { onSaved, onRemoved, children } = this.props;
        const { legal, showClientOrders, selectedOrder } = this.state;
        const isLegalEntity = outsourcer.isNewlyCreated ? legal : outsourcer.isLegalEntity;

        let regionsItems = Constants.allCountryRegions.map((reg) => ({ id: reg, label: reg }));
        var categoriesItems = categories.types.map((item) => ({ id: item.id, label: item.label }));
        const schema = OutsourcerSchema(isLegalEntity);
        const types = getSnapshot(workTypes.workTypes);
        const canOrderNavigation = can(orderFunc.ORDERS_SPENDING_READ, access);
        const initial = getFormValues(outsourcer);
        const hasLogin = !!initial.login;
        return (
            <div className={`${styles.details} outsourcer-details`}>
                <Formik
                    initialValues={initial}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await outsourcer.save(values as any, isLegalEntity);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        this.formProps = formProps;
                        const filesVisible: boolean = formProps.values[fields.hasAgentOrder];
                        const rewardVisible: boolean = formProps.values[fields.isAgentReward];
                        const { values, setFieldValue, setFieldTouched, dirty } = formProps;
                        const { materials, selectedCategories } = this.state;
                        const workTypeIds: string[] = values[fields.workTypeIds];
                        const selfEmployed = !!values[fields.selfEmployed];
                        const addWorkType = () => {
                            if (workTypeIds.length < MAX_WORK_TYPES) {
                                setFieldValue(fields.workTypeIds, [...workTypeIds, ""]);
                                setFieldTouched(fields.workTypeIds, true);
                            }
                        };

                        const removeWorkType = (index: number) => {
                            const result = workTypeIds.filter((_, i) => i !== index);
                            setFieldValue(fields.workTypeIds, result);
                            setFieldTouched(fields.workTypeIds, true);
                        };
                        const items =
                            (formProps.values as any)[fields.type] === "client" && outsourcer.orders
                                ? outsourcer.orders.map((order) => ({ id: order.id, label: order.name }))
                                : [];
                        const onRemove = async () => {
                            formProps.setSubmitting(true);
                            const success = await outsourcer.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form className={styles.details} autoComplete="off">
                                <Prompt when={dirty && canChange} message={texts.messages.leaveConfiramtion} />
                                <Dialog
                                    title={`Выберите договор`}
                                    isOpen={showClientOrders}
                                    onClose={this.toggleShowClientOrders}
                                    backdropClassName="standard"
                                    className={`${styles.FilesDialog} figma-dialog`}
                                >
                                    <div className={Classes.DIALOG_BODY}>
                                        <SimpleSelect
                                            className={`full-width-select ${Classes.FILL}`}
                                            activeItem={selectedOrder}
                                            items={items}
                                            itemRenderer={renderOption}
                                            onItemSelect={(i: SelectItem) => {
                                                this.setState({ selectedOrder: i });
                                            }}
                                            popoverProps={{
                                                boundary: "viewport",
                                                popoverClassName: "import-spendings-table__select",
                                            }}
                                            inputProps={{
                                                placeholder: "Расход",
                                            }}
                                            filterable={true}
                                            itemPredicate={filterItemPredicate}
                                        >
                                            <DefaultSelectedOption option={selectedOrder} />
                                        </SimpleSelect>
                                    </div>
                                    <div className={Classes.DIALOG_FOOTER}>
                                        {canChange && (
                                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                <Buttons
                                                    left={
                                                        <div style={{ display: "flex" }}>
                                                            <PlanrButton
                                                                size="small"
                                                                type="greenish"
                                                                onClick={this.printDocumentCredentials}
                                                            >
                                                                Сформировать
                                                            </PlanrButton>
                                                            <PlanrButton
                                                                size="small"
                                                                type="graybtn"
                                                                onClick={this.toggleShowClientOrders}
                                                            >
                                                                Отмена
                                                            </PlanrButton>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Dialog>

                                <div className={Classes.DIALOG_BODY}>
                                    <div className={styles.mainInfo}>
                                        <h3 className={styles.headerLabel}>Основные данные</h3>
                                        {showSensitiveData && (
                                            <div className={styles.filterBtns}>
                                                <PlanrButton
                                                    type={
                                                        (formProps.values as any)[fields.type] === "legalOutsourcer"
                                                            ? "primary"
                                                            : "graybtn"
                                                    }
                                                    size="small"
                                                    style={{
                                                        borderTopRightRadius: "0px",
                                                        borderBottomRightRadius: "0px",
                                                    }}
                                                    onClick={() => {
                                                        this.setOutsourcer("legalOutsourcer");
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ fontSize: "10px" }}>Аутсорсер</div>
                                                        <div>Юр. лицо</div>
                                                    </div>
                                                </PlanrButton>
                                                <PlanrButton
                                                    type={
                                                        (formProps.values as any)[fields.type] === "physOutsourcer"
                                                            ? "primary"
                                                            : "graybtn"
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        this.setOutsourcer("physOutsourcer");
                                                    }}
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                        marginLeft: "0px",
                                                    }}
                                                >
                                                    <div>
                                                        <div style={{ fontSize: "10px" }}>Аутсорсер</div>
                                                        <div>Физ. лицо</div>
                                                    </div>
                                                </PlanrButton>
                                                <PlanrButton
                                                    type={
                                                        (formProps.values as any)[fields.type] === "client"
                                                            ? "primary"
                                                            : "graybtn"
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        this.setOutsourcer("client");
                                                    }}
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                        marginLeft: "0px",
                                                    }}
                                                >
                                                    Заказчик
                                                </PlanrButton>
                                                <PlanrButton
                                                    type={
                                                        (formProps.values as any)[fields.type] === "supplier"
                                                            ? "primary"
                                                            : "graybtn"
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        this.setOutsourcer("supplier");
                                                    }}
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                        marginLeft: "0px",
                                                    }}
                                                >
                                                    Поставщик
                                                </PlanrButton>
                                                <PlanrButton
                                                    type={
                                                        (formProps.values as any)[fields.type] === "agent"
                                                            ? "primary"
                                                            : "graybtn"
                                                    }
                                                    size="small"
                                                    onClick={() => {
                                                        this.setOutsourcer("agent");
                                                    }}
                                                    style={{
                                                        borderTopLeftRadius: "0px",
                                                        borderBottomLeftRadius: "0px",
                                                        marginLeft: "0px",
                                                    }}
                                                >
                                                    Агент
                                                </PlanrButton>
                                            </div>
                                        )}
                                        {showSensitiveData &&
                                            ((formProps.values as any)[fields.type] === "legalOutsourcer" ||
                                                (formProps.values as any)[fields.type] === "supplier" ||
                                                (formProps.values as any)[fields.type] === "client") && (
                                                <StandardFormInput
                                                    name={fields.isAgent}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={true}
                                                >
                                                    {({ field, form }) => (
                                                        <Switch
                                                            checked={field.value}
                                                            {...field}
                                                            large={true}
                                                            disabled={!canChange}
                                                            style={{ marginLeft: "-5px" }}
                                                            onChange={(e) => {
                                                                const checked = e.currentTarget.checked;

                                                                // form.setFieldValue(
                                                                //     fields.bankDetails,
                                                                //     checked ? emptyBankDetails() : null
                                                                // );
                                                                // form.setFieldTouched(fields.bankDetails, true);

                                                                form.setFieldValue(field.name, checked);
                                                                form.setFieldTouched(field.name, true);
                                                            }}
                                                        />
                                                    )}
                                                </StandardFormInput>
                                            )}
                                        {(formProps.values as any)[fields.type] !== "client" &&
                                            (formProps.values as any)[fields.type] !== "supplier" &&
                                            (formProps.values as any)[fields.type] !== "agent" && (
                                                <div>
                                                    <div className={styles.formRow}>
                                                        <StandardFormInput
                                                            name={fields.isBim}
                                                            schema={schema}
                                                            small={true}
                                                            className="planr-form-input"
                                                            inline={false}
                                                        >
                                                            {({ field }) => (
                                                                <Switch
                                                                    checked={field.value}
                                                                    {...field}
                                                                    large={true}
                                                                    disabled={!canChange}
                                                                />
                                                            )}
                                                        </StandardFormInput>

                                                        {(formProps.values as any)[fields.type] ===
                                                            "physOutsourcer" && (
                                                            <StandardFormInput
                                                                name={fields.selfEmployed}
                                                                schema={schema}
                                                                small={true}
                                                                className="planr-form-input"
                                                                inline={false}
                                                            >
                                                                {({ field, form }) => (
                                                                    <Switch
                                                                        checked={field.value}
                                                                        {...field}
                                                                        large={true}
                                                                        disabled={!canChange}
                                                                        onChange={(e) => {
                                                                            const checked = e.currentTarget.checked;

                                                                            // form.setFieldValue(
                                                                            //     fields.bankDetails,
                                                                            //     checked ? emptyBankDetails() : null
                                                                            // );
                                                                            // form.setFieldTouched(fields.bankDetails, true);

                                                                            form.setFieldValue(field.name, checked);
                                                                            form.setFieldTouched(field.name, true);
                                                                        }}
                                                                    />
                                                                )}
                                                            </StandardFormInput>
                                                        )}

                                                        <StandardFormInput
                                                            name={fields.isConfirmed}
                                                            schema={schema}
                                                            small={true}
                                                            className="planr-form-input"
                                                            inline={false}
                                                        >
                                                            {({ field }) => (
                                                                <Switch
                                                                    checked={field.value}
                                                                    {...field}
                                                                    large={true}
                                                                    disabled={!canConfirm || !canChange}
                                                                />
                                                            )}
                                                        </StandardFormInput>
                                                    </div>
                                                    {(!canConfirm || !canChange) &&
                                                        (formProps.values as any)[fields.isConfirmed] !== true && (
                                                            <PlanrButton
                                                                type="secondary"
                                                                onClick={() => {
                                                                    if (typeof this.props.confirmRequest == "function")
                                                                        this.props.confirmRequest(outsourcer.id);
                                                                }}
                                                                size="small"
                                                                style={{ marginBottom: "5px", marginLeft: "30px" }}
                                                            >
                                                                Запрос на одобрение
                                                            </PlanrButton>
                                                        )}
                                                </div>
                                            )}

                                        {(formProps.values as any)[fields.type] === "physOutsourcer" &&
                                            selfEmployed && (
                                                <>
                                                    <StandardFormInput
                                                        name={fields.selfEmployedAccount}
                                                        schema={schema}
                                                        small={true}
                                                        inline={false}
                                                        className="planr-form-input"
                                                    >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                id={field.name}
                                                                {...field}
                                                                className="planr-default-input"
                                                                autoComplete="off"
                                                                data-lpignore="true"
                                                                disabled={!canChange}
                                                            />
                                                        )}
                                                    </StandardFormInput>

                                                    <StandardFormInput
                                                        name={fields.selfEmployedAccountDate}
                                                        schema={schema}
                                                        small={true}
                                                        className="planr-form-input"
                                                        inline={false}
                                                    >
                                                        {({ field, form }) => {
                                                            return (
                                                                <div className={`${Classes.INPUT_GROUP} `}>
                                                                    <DatePicker
                                                                        clasName="planr-default-input"
                                                                        value={field.value}
                                                                        onChange={(date) => {
                                                                            form.setFieldValue(field.name, date);
                                                                            form.setFieldTouched(field.name, true);
                                                                        }}
                                                                        disabled={!canChange}
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                    </StandardFormInput>
                                                </>
                                            )}

                                        <StandardFormInput
                                            name={fields.name}
                                            schema={schema}
                                            label={
                                                (formProps.values as any)[fields.type] !== "physOutsourcer"
                                                    ? texts.name
                                                    : "ФИО"
                                            }
                                            small={true}
                                            inline={false}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>

                                        {(formProps.values as any)[fields.type] === "physOutsourcer" && (
                                            <>
                                                <StandardFormInput
                                                    name={fields.genitiveName}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                    label="ФИО в родительном падеже"
                                                >
                                                    {({ field }) => (
                                                        <InputGroup
                                                            id={field.name}
                                                            {...field}
                                                            className="planr-default-input"
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            disabled={!canChange}
                                                        />
                                                    )}
                                                </StandardFormInput>
                                                <PassportBirthDay
                                                    name={fields.birthday}
                                                    schema={schema}
                                                    inline={false}
                                                    readOnly={!canChange}
                                                />
                                            </>
                                        )}

                                        {(formProps.values as any)[fields.type] !== "physOutsourcer" && (
                                            <StandardFormInput
                                                name={fields.fullName}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                                inline={false}
                                            >
                                                {({ field }) => (
                                                    <InputGroup
                                                        id={field.name}
                                                        {...field}
                                                        className="planr-default-input"
                                                        autoComplete="off"
                                                        disabled={!canChange}
                                                    />
                                                )}
                                            </StandardFormInput>
                                        )}

                                        {(formProps.values as any)[fields.type] !== "physOutsourcer" && (
                                            <>
                                                <DirectorField
                                                    schema={schema}
                                                    fields={fields}
                                                    inline={false}
                                                    readonly={!canChange}
                                                />

                                                <StandardFormInput
                                                    name={fields.genitiveName}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                    label="Директор (Родит. падеж)"
                                                >
                                                    {({ field }) => (
                                                        <InputGroup
                                                            id={field.name}
                                                            {...field}
                                                            className="planr-default-input"
                                                            autoComplete="off"
                                                            data-lpignore="true"
                                                            disabled={!canChange}
                                                        />
                                                    )}
                                                </StandardFormInput>

                                                <BankDetailsCompanyAddressField
                                                    schema={schema}
                                                    fields={fields}
                                                    inline={false}
                                                    readonly={!canChange}
                                                />
                                            </>
                                        )}

                                        <StandardFormInput
                                            name={fields.phone}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            inline={false}
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.phone2}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            inline={false}
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.phone3}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            inline={false}
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.email}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            inline={false}
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>

                                        <StandardFormInput
                                            name={fields.comment}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            inline={false}
                                        >
                                            {({ field }) => (
                                                <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                    <TextArea
                                                        id={field.name}
                                                        {...field}
                                                        growVertically={true}
                                                        className={Classes.FILL}
                                                        inputRef={this.commentAreaRef}
                                                        disabled={!canChange}
                                                        style={{ maxHeight: "220px" }}
                                                    />
                                                </div>
                                            )}
                                        </StandardFormInput>

                                        {(formProps.values as any)[fields.type] === "physOutsourcer" &&
                                            !selfEmployed && (
                                                <StandardFormInput
                                                    name={fields.cardNumber}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                >
                                                    {({ field }) => (
                                                        <CreditCard
                                                            id={field.name}
                                                            {...field}
                                                            className="planr-default-input"
                                                            disabled={!canChange}
                                                        />
                                                    )}
                                                </StandardFormInput>
                                            )}
                                    </div>

                                    {(formProps.values as any)[fields.isAgent] === true && (
                                        <div className={styles.mainInfo}>
                                            <div>
                                                <label className={styles.headerLabel}>&nbsp;</label>
                                            </div>

                                            <div style={{ position: "relative" }}>
                                                <StandardFormInput
                                                    name={fields.сategories}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                >
                                                    {({ field, form }) => (
                                                        <div style={{ display: "flex" }}>
                                                            <div className={styles.materialsList}>
                                                                {field.value &&
                                                                    field.value.map((item: string, index: number) => {
                                                                        const cat = categoriesItems.find(
                                                                            (category) => category.id === item
                                                                        );
                                                                        if (cat) {
                                                                            return <div key={index}>{cat.label};</div>;
                                                                        }
                                                                        return null;
                                                                    })}
                                                            </div>
                                                            <PlanrButton
                                                                type="secondary"
                                                                size="small"
                                                                icon="general-plus-big"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "-12px",
                                                                    right: "0px",
                                                                    height: "30px",
                                                                    width: "54px",
                                                                }}
                                                                onClick={() => {
                                                                    let newResposes: {
                                                                        id: string;
                                                                        label: string;
                                                                    }[] = [];
                                                                    field.value.forEach((c: string) => {
                                                                        newResposes.push({
                                                                            id: c,
                                                                            label: c,
                                                                        });
                                                                    });
                                                                    this.setState({
                                                                        selectedCategories: field.value.map(
                                                                            (val: any) => val
                                                                        ),
                                                                    });
                                                                    this.toggleShowCategories();
                                                                }}
                                                            />

                                                            <Dialog
                                                                title={`Категория`}
                                                                isOpen={this.state.showCategories}
                                                                onClose={this.toggleShowCategories}
                                                                backdropClassName="standard"
                                                                className={`${styles.smallDialog} ${styles.workDialog} figma-dialog`}
                                                            >
                                                                <div className={`${Classes.DIALOG_BODY} `}>
                                                                    {categoriesItems && categoriesItems.length > 0 && (
                                                                        <div className={styles.workItemContainer}>
                                                                            {categoriesItems.map((category) => {
                                                                                const item = selectedCategories.find(
                                                                                    (u) => u === category.id
                                                                                );
                                                                                return (
                                                                                    <div
                                                                                        className={styles.workItem}
                                                                                        key={category.id}
                                                                                    >
                                                                                        <PlanrButton
                                                                                            icon={
                                                                                                item
                                                                                                    ? "general-checkbox"
                                                                                                    : "general-zero-checkbox"
                                                                                            }
                                                                                            type="neutral"
                                                                                            size="small"
                                                                                            onClick={() => {
                                                                                                this.setSelectedCategories(
                                                                                                    category.id
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <div>{category.label}</div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className={Classes.DIALOG_FOOTER}>
                                                                    <Buttons
                                                                        left={
                                                                            <div style={{ display: "flex" }}>
                                                                                <PlanrButton
                                                                                    size="small"
                                                                                    type="greenish"
                                                                                    onClick={() => {
                                                                                        const vals = [
                                                                                            ...this.state
                                                                                                .selectedCategories,
                                                                                        ];
                                                                                        form.setFieldValue(
                                                                                            field.name,
                                                                                            vals
                                                                                        );
                                                                                        form.setFieldTouched(
                                                                                            field.name,
                                                                                            true
                                                                                        );
                                                                                        this.toggleShowCategories();
                                                                                    }}
                                                                                >
                                                                                    Сохранить
                                                                                </PlanrButton>
                                                                                <PlanrButton
                                                                                    size="small"
                                                                                    type="graybtn"
                                                                                    onClick={this.toggleShowCategories}
                                                                                >
                                                                                    Отмена
                                                                                </PlanrButton>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </Dialog>
                                                        </div>
                                                    )}
                                                </StandardFormInput>
                                            </div>

                                            <StandardFormInput
                                                name={fields.region}
                                                schema={schema}
                                                small={true}
                                                inline={false}
                                                className="planr-form-input"
                                            >
                                                {({ field, form }) => {
                                                    const typeOption = regionsItems.find((r) => r.id === field.value);
                                                    return (
                                                        <SimpleSelect
                                                            className={`full-width-select ${Classes.FILL}`}
                                                            activeItem={typeOption}
                                                            items={regionsItems}
                                                            itemRenderer={renderOption}
                                                            onItemSelect={(i) => {
                                                                form.setFieldValue(field.name, i.id);
                                                                form.setFieldTouched(field.name, true);
                                                            }}
                                                            filterable={true}
                                                            itemPredicate={filterItemPredicate}
                                                        >
                                                            <DefaultSelectedOption option={typeOption} />
                                                        </SimpleSelect>
                                                    );
                                                }}
                                            </StandardFormInput>

                                            <StandardFormInput
                                                name={fields.memberName}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                                inline={false}
                                            >
                                                {({ field }) => (
                                                    <InputGroup
                                                        id={field.name}
                                                        {...field}
                                                        className="planr-default-input"
                                                        autoComplete="off"
                                                        disabled={!canChange}
                                                    />
                                                )}
                                            </StandardFormInput>

                                            <div style={{ position: "relative" }}>
                                                <StandardFormInput
                                                    name={fields.materials}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                >
                                                    {({ field, form }) => (
                                                        <div style={{ display: "flex" }}>
                                                            <div className={styles.materialsList}>
                                                                {field.value &&
                                                                    field.value.map((item: string, index: number) => (
                                                                        <div key={`${item} ${index}`}>{`${item}`};</div>
                                                                    ))}
                                                            </div>
                                                            <PlanrButton
                                                                type="secondary"
                                                                size="small"
                                                                icon="general-plus-big"
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "6px",
                                                                    right: "0px",
                                                                    height: "30px",
                                                                    width: "54px",
                                                                }}
                                                                onClick={() => {
                                                                    let newResposes: {
                                                                        value: string;
                                                                        guid: string;
                                                                    }[] = [];
                                                                    field.value.forEach((c: string) => {
                                                                        newResposes.push({
                                                                            guid: v4(),
                                                                            value: c,
                                                                        });
                                                                    });
                                                                    this.setState({ materials: newResposes });
                                                                    this.toggleShowMaterials();
                                                                }}
                                                            />
                                                            <Dialog
                                                                title={`Поставляемое оборудование`}
                                                                isOpen={this.state.showMaterials}
                                                                onClose={this.toggleShowMaterials}
                                                                backdropClassName="standard"
                                                                className={`${styles.FilesDialog} figma-dialog`}
                                                            >
                                                                <div className={`${Classes.DIALOG_BODY} `}>
                                                                    <div className={styles.materials}>
                                                                        {materials &&
                                                                            materials.map((res) => {
                                                                                return (
                                                                                    <div
                                                                                        key={res.guid}
                                                                                        className={styles.responsesRow}
                                                                                    >
                                                                                        <InputGroup
                                                                                            type="text"
                                                                                            autoComplete="off"
                                                                                            className="planr-default-input"
                                                                                            data-lpignore="true"
                                                                                            value={res.value}
                                                                                            style={{
                                                                                                width: "400px",
                                                                                            }}
                                                                                            onChange={(
                                                                                                e: React.FormEvent<HTMLInputElement>
                                                                                            ) => {
                                                                                                this.setMaterials(
                                                                                                    e.currentTarget
                                                                                                        .value,
                                                                                                    res.guid
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                        <PlanrButton
                                                                                            type="neutral"
                                                                                            icon="general-trash"
                                                                                            onClick={() => {
                                                                                                this.removeMaterials(
                                                                                                    res.guid
                                                                                                );
                                                                                            }}
                                                                                            size="small"
                                                                                            title={texts.remove}
                                                                                        />
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        <div className={styles.addRows}>
                                                                            <PlanrButton
                                                                                size="small"
                                                                                type="secondary"
                                                                                onClick={() => {
                                                                                    this.addMaterials("");
                                                                                }}
                                                                            >
                                                                                Добавить
                                                                            </PlanrButton>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={Classes.DIALOG_FOOTER}>
                                                                    <Buttons
                                                                        left={
                                                                            <div style={{ display: "flex" }}>
                                                                                <PlanrButton
                                                                                    size="small"
                                                                                    type="greenish"
                                                                                    onClick={() => {
                                                                                        let val =
                                                                                            this.state.materials.map(
                                                                                                (mat) => mat.value
                                                                                            );
                                                                                        form.setFieldValue(
                                                                                            field.name,
                                                                                            val
                                                                                        );
                                                                                        form.setFieldTouched(
                                                                                            field.name,
                                                                                            true
                                                                                        );
                                                                                        this.toggleShowMaterials();
                                                                                    }}
                                                                                >
                                                                                    Сохранить
                                                                                </PlanrButton>
                                                                                <PlanrButton
                                                                                    size="small"
                                                                                    type="graybtn"
                                                                                    onClick={() => {
                                                                                        this.toggleShowMaterials();
                                                                                    }}
                                                                                >
                                                                                    Отмена
                                                                                </PlanrButton>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </Dialog>
                                                        </div>
                                                    )}
                                                </StandardFormInput>
                                            </div>
                                            <StandardFormInput
                                                name={fields.site}
                                                schema={schema}
                                                small={true}
                                                inline={false}
                                                className="planr-form-input"
                                            >
                                                {({ field }) => (
                                                    <InputGroup
                                                        id={field.name}
                                                        {...field}
                                                        className="planr-default-input"
                                                        autoComplete="off"
                                                    />
                                                )}
                                            </StandardFormInput>
                                            {canSupplierOrder && (
                                                <>
                                                    {" "}
                                                    <StandardFormInput
                                                        name={fields.hasAgentOrder}
                                                        schema={schema}
                                                        small={true}
                                                        className="planr-form-input"
                                                        inline={false}
                                                    >
                                                        {({ field, form }) => (
                                                            <Switch
                                                                checked={field.value}
                                                                {...field}
                                                                large={true}
                                                                disabled={!canChange}
                                                                onChange={(e) => {
                                                                    const checked = e.currentTarget.checked;
                                                                    form.setFieldValue(field.name, checked);
                                                                    form.setFieldTouched(field.name, true);
                                                                }}
                                                            />
                                                        )}
                                                    </StandardFormInput>
                                                    {(formProps.values as any)[fields.hasAgentOrder] === true && (
                                                        <div
                                                            className={`${styles.files} ${
                                                                filesVisible ? "" : styles.hidden
                                                            }`}
                                                        >
                                                            <Field name={fields.documents}>
                                                                {(fieldProps: FieldProps) => {
                                                                    return (
                                                                        <SupplierDocuments
                                                                            canChange={canChange}
                                                                            supplier={outsourcer}
                                                                            fieldProps={fieldProps}
                                                                            baseUrl={baseUrl}
                                                                            label={"Файлы договора"}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </div>
                                                    )}
                                                    <StandardFormInput
                                                        name={fields.isAgentReward}
                                                        schema={schema}
                                                        small={true}
                                                        className="planr-form-input"
                                                        inline={false}
                                                    >
                                                        {({ field, form }) => (
                                                            <Switch
                                                                checked={field.value}
                                                                {...field}
                                                                large={true}
                                                                disabled={!canChange}
                                                                onChange={(e) => {
                                                                    const checked = e.currentTarget.checked;
                                                                    form.setFieldValue(field.name, checked);
                                                                    form.setFieldTouched(field.name, true);
                                                                }}
                                                            />
                                                        )}
                                                    </StandardFormInput>
                                                    {(formProps.values as any)[fields.isAgentReward] === true && (
                                                        <div className={`${rewardVisible ? "" : styles.hidden}`}>
                                                            <StandardFormInput
                                                                name={fields.agentReward}
                                                                schema={schema}
                                                                small={true}
                                                                className="planr-form-input"
                                                            >
                                                                {({ field, form }) => (
                                                                    <InputGroup
                                                                        id={field.name}
                                                                        {...field}
                                                                        className="planr-default-input"
                                                                        autoComplete="off"
                                                                        onChange={(
                                                                            e: React.FormEvent<HTMLInputElement>
                                                                        ) => {
                                                                            const value = e.currentTarget.value;
                                                                            form.setFieldValue(
                                                                                field.name,
                                                                                isNaN(+value) || value === ""
                                                                                    ? 0
                                                                                    : value
                                                                            );
                                                                            form.setFieldTouched(field.name, true);
                                                                        }}
                                                                        data-lpignore="true"
                                                                    />
                                                                )}
                                                            </StandardFormInput>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            <div className={`${styles.files}`}>
                                                <Field name={fields.materialDocuments}>
                                                    {(fieldProps: FieldProps) => {
                                                        return (
                                                            <SupplierDocuments
                                                                canChange={canChange}
                                                                supplier={outsourcer}
                                                                fieldProps={fieldProps}
                                                                baseUrl={baseUrl}
                                                                label={"Демонстрационные материалы"}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </div>
                                        </div>
                                    )}

                                    <div className={styles.mainInfo}>
                                        {showSensitiveData && (
                                            <>
                                                <div>
                                                    <label className={styles.headerLabel}>&nbsp;</label>
                                                </div>

                                                <FormGroup
                                                    inline={true}
                                                    className={`${styles.ratings} planr-form-input`}
                                                >
                                                    <Field name={fields.priceRating}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className={styles.rating}>
                                                                <div className={styles.labelRating}>Цена</div>
                                                                <Rating
                                                                    id={field.name}
                                                                    rating={field.value}
                                                                    onChange={(rating) => {
                                                                        form.setFieldValue(field.name, rating);
                                                                        form.setFieldTouched(field.name, true);
                                                                    }}
                                                                    readOnly={!canChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={fields.qualityRating}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className={styles.rating}>
                                                                <div className={styles.labelRating}>Качество</div>
                                                                <Rating
                                                                    id={field.name}
                                                                    rating={field.value}
                                                                    onChange={(rating) => {
                                                                        form.setFieldValue(field.name, rating);
                                                                        form.setFieldTouched(field.name, true);
                                                                    }}
                                                                    readOnly={!canChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    <Field name={fields.timeRating}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className={styles.rating}>
                                                                <div className={styles.labelRating}>Сроки</div>
                                                                <Rating
                                                                    id={field.name}
                                                                    rating={field.value}
                                                                    onChange={(rating) => {
                                                                        form.setFieldValue(field.name, rating);
                                                                        form.setFieldTouched(field.name, true);
                                                                    }}
                                                                    readOnly={!canChange}
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                            </>
                                        )}

                                        {(formProps.values as any)[fields.type] === "physOutsourcer" && (
                                            <PassportDetailsFields
                                                schema={schema}
                                                fields={fields}
                                                inline={false}
                                                maxIssueDate={maxIssueDate()}
                                                readOnly={!canChange}
                                            />
                                        )}

                                        {(formProps.values as any)[fields.type] === "supplier" && (
                                            <>
                                                <StandardFormInput
                                                    name={fields.hasSupplierOrder}
                                                    schema={schema}
                                                    small={true}
                                                    className="planr-form-input"
                                                    inline={false}
                                                >
                                                    {({ field, form }) => (
                                                        <Switch
                                                            checked={field.value}
                                                            {...field}
                                                            large={true}
                                                            disabled={!canChange}
                                                            onChange={(e) => {
                                                                const checked = e.currentTarget.checked;
                                                                form.setFieldValue(field.name, checked);
                                                                form.setFieldTouched(field.name, true);
                                                            }}
                                                        />
                                                    )}
                                                </StandardFormInput>
                                                {(formProps.values as any)[fields.hasSupplierOrder] === true && (
                                                    <div
                                                        className={`${styles.files} ${
                                                            filesVisible ? "" : styles.hidden
                                                        }`}
                                                    >
                                                        <Field name={fields.supplierDocuments}>
                                                            {(fieldProps: FieldProps) => {
                                                                return (
                                                                    <SupplierDocuments
                                                                        canChange={canChange}
                                                                        supplier={outsourcer}
                                                                        fieldProps={fieldProps}
                                                                        baseUrl={baseUrl}
                                                                        label={"Файлы договора"}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {(formProps.values as any)[fields.type] !== "physOutsourcer" && (
                                            <>
                                                <h3>Реквизиты</h3>
                                                <BankDetailsFields
                                                    schema={schema}
                                                    fields={fields}
                                                    inline={false}
                                                    readonly={!canChange}
                                                />
                                            </>
                                        )}

                                        {selfEmployed &&
                                            (formProps.values as any)[fields.type] === "physOutsourcer" && (
                                                <SelfEmployedBankDetailsFields
                                                    schema={schema}
                                                    fields={fields}
                                                    inline={false}
                                                    readonly={!canChange}
                                                />
                                            )}

                                        {canChange && showSensitiveData && (
                                            <div className={styles.credentials}>
                                                <Divider />
                                                <div>
                                                    <StandardFormInput
                                                        name={fields.login}
                                                        schema={schema}
                                                        small={true}
                                                        className="planr-form-input"
                                                    >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                id={field.name}
                                                                {...field}
                                                                className="planr-default-input"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    </StandardFormInput>

                                                    <StandardFormInput
                                                        name={fields.newPassword}
                                                        schema={schema}
                                                        small={true}
                                                        label={hasLogin ? "Новый пароль" : "Пароль"}
                                                        className="planr-form-input"
                                                    >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                id={field.name}
                                                                {...field}
                                                                className="planr-default-input"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    </StandardFormInput>
                                                </div>
                                                {hasLogin && (
                                                    <div className={styles.printCredentials}>
                                                        <PlanrButton
                                                            type="blueish"
                                                            size="small"
                                                            onClick={
                                                                outsourcer.type === "client"
                                                                    ? this.toggleShowClientOrders
                                                                    : this.printCredentials
                                                            }
                                                        >
                                                            Распечатать учетные данные
                                                        </PlanrButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    {((formProps.values as any)[fields.type] === "physOutsourcer" ||
                                        (formProps.values as any)[fields.type] === "legalOutsourcer") && (
                                        <div className={styles.mainInfo}>
                                            <h3 className={styles.headerLabel} style={{ marginBottom: "30px" }}>
                                                Виды выполняемых работ
                                            </h3>

                                            <StandardFormInput
                                                name={fields.workTypeIds}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                                inline={false}
                                            >
                                                {({ field, form }) => {
                                                    return (
                                                        <>
                                                            {workTypeIds.map((value, index) => {
                                                                const other = workTypeIds.filter((v) => v !== value);
                                                                const option =
                                                                    types.find((i) => i.id === value) || null;

                                                                const onItemSelect = (
                                                                    item: WorkTypeDictionaryItemSnapshotType
                                                                ) => {
                                                                    const newValue = [...workTypeIds];
                                                                    newValue[index] = item.id;
                                                                    form.setFieldValue(field.name, newValue);
                                                                    form.setFieldTouched(field.name, true);
                                                                };

                                                                return (
                                                                    <div key={index} className={styles.selectRow}>
                                                                        <WorkTypeSelect
                                                                            className={`planr-default-select ${Classes.FILL}`}
                                                                            filterable={false}
                                                                            activeItem={option}
                                                                            inputProps={field}
                                                                            items={types.filter(
                                                                                (type) => !other.includes(type.id)
                                                                            )}
                                                                            itemRenderer={renderSingleOption}
                                                                            itemListRenderer={this.state.renderMenu}
                                                                            onItemSelect={onItemSelect}
                                                                            disabled={!canChange}
                                                                        >
                                                                            {renderSelctedOption(option)}
                                                                        </WorkTypeSelect>

                                                                        {canChange && (
                                                                            <PlanrButton
                                                                                type="neutral"
                                                                                icon="general-trash"
                                                                                onClick={() => removeWorkType(index)}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    );
                                                }}
                                            </StandardFormInput>
                                            {canChange && (
                                                <PlanrButton
                                                    type="secondary"
                                                    icon="general-plus-big"
                                                    onClick={addWorkType}
                                                    disabled={workTypeIds.length === MAX_WORK_TYPES}
                                                >
                                                    Добавить
                                                </PlanrButton>
                                            )}
                                        </div>
                                    )}
                                    {(formProps.values as any)[fields.isAgent] === false &&
                                        (formProps.values as any)[fields.type] !== "legalOutsourcer" &&
                                        (formProps.values as any)[fields.type] !== "physOutsourcer" && (
                                            <div className={styles.mainInfo}></div>
                                        )}

                                    <div className={`${styles.projects} outsourcer-details-projects`}>
                                        <h3 className={styles.headerLabel}>Участие в проектах</h3>
                                        <HTMLTable condensed={true}>
                                            <colgroup>
                                                <col />
                                                {this.col()}
                                                {this.col()}
                                                {this.col()}
                                            </colgroup>
                                            <tbody>
                                                {outsourcer.type !== "client" ? (
                                                    <tr className={styles.projectsHeader}>
                                                        <td style={{ verticalAlign: "middle", paddingLeft: "10px" }}>
                                                            Итоги
                                                        </td>
                                                        <td>
                                                            <Money amount={outsourcer.actualSum} noSymbol={true} />
                                                        </td>
                                                        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
                                                            <Money amount={outsourcer.debit} noSymbol={true} />
                                                        </td>
                                                        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
                                                            <Money amount={outsourcer.credit} noSymbol={true} />
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr className={styles.projectsHeader}>
                                                        <td style={{ verticalAlign: "middle", paddingLeft: "10px" }}>
                                                            Итоги
                                                        </td>
                                                        <td>
                                                            <Money
                                                                amount={outsourcer.orders?.reduce(
                                                                    (acc: any, sp: any) => acc + sp.planSum,
                                                                    0
                                                                )}
                                                                noSymbol={true}
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
                                                            <Money
                                                                amount={outsourcer.orders?.reduce(
                                                                    (acc: any, sp: any) => acc + sp.actSum,
                                                                    0
                                                                )}
                                                                noSymbol={true}
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: "middle", textAlign: "right" }}>
                                                            <Money
                                                                amount={outsourcer.orders?.reduce(
                                                                    (acc: any, sp: any) => acc + sp.remainSum,
                                                                    0
                                                                )}
                                                                noSymbol={true}
                                                            />
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </HTMLTable>

                                        <HTMLTable condensed={true}>
                                            {this.colgroup()}
                                            <thead>
                                                <tr>
                                                    <th
                                                        className={styles.tableHeader}
                                                        colSpan={2}
                                                        style={{ width: "500px" }}
                                                    >
                                                        Проект
                                                    </th>
                                                    <th className={styles.tableHeader}>Вид работ</th>
                                                    <th className={styles.tableHeader}>Стоимость</th>
                                                    <th className={styles.tableHeader}>Оплачено</th>
                                                    <th className={styles.tableHeader}>Долг</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {outsourcer.type !== "client" &&
                                                    outsourcer.spendings.map((sp, index) => {
                                                        // link to order
                                                        const section = `&${PARAM_SECTION}=${
                                                            sp.workType ? "outsource" : "other"
                                                        }`;
                                                        const href =
                                                            canOrderNavigation && sp.orderId
                                                                ? routes.orders.details(
                                                                      sp.orderId,
                                                                      OrderTabs.spending
                                                                  ) + section
                                                                : "";

                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {href ? (
                                                                        <Link to={href}>{sp.fullInventoryNumber}</Link>
                                                                    ) : (
                                                                        sp.fullInventoryNumber
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {href ? (
                                                                        <Link to={href}>{sp.orderName}</Link>
                                                                    ) : (
                                                                        sp.orderName
                                                                    )}
                                                                </td>
                                                                <td>{sp.workTypeName}</td>
                                                                <td className="righted">
                                                                    <Money amount={sp.actualSum} noSymbol={true} />
                                                                </td>
                                                                <td className="righted">
                                                                    <Money amount={sp.debit} noSymbol={true} />
                                                                </td>
                                                                <td className="righted">
                                                                    <Money amount={sp.credit} noSymbol={true} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                {outsourcer.type === "client" &&
                                                    outsourcer.orders != null &&
                                                    outsourcer.orders.map((sp, index) => {
                                                        // link to order

                                                        const href =
                                                            canOrderNavigation && sp.id
                                                                ? routes.orders.details(sp.id, OrderTabs.summary)
                                                                : "";

                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {href ? (
                                                                        <Link to={href}>{sp.inventoryNumber}</Link>
                                                                    ) : (
                                                                        sp.inventoryNumber
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {href ? <Link to={href}>{sp.name}</Link> : sp.name}
                                                                </td>
                                                                <td></td>
                                                                <td className="righted">
                                                                    <Money amount={sp.planSum} noSymbol={true} />
                                                                </td>
                                                                <td className="righted">
                                                                    <Money amount={sp.actSum} noSymbol={true} />
                                                                </td>
                                                                <td className="righted">
                                                                    <Money amount={sp.remainSum} noSymbol={true} />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </HTMLTable>
                                    </div>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            {children}
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`аутсорсера ${outsourcer.name}`}
                                                isNewlyCreated={!canRemove || outsourcer.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }

    setMaterials = (value: string, guid: string) => {
        const materials = [...this.state.materials];
        const index = materials.findIndex((e) => e.guid === guid);
        if (index > -1) {
            const item = materials[index];
            materials.splice(index, 1, { ...item, value });
            this.setState({ materials });
        }
    };

    setSelectedCategories = (id: string) => {
        let newUnits = [...this.state.selectedCategories];
        const index = newUnits.findIndex((unit) => unit === id);
        let newArr: string[] = newUnits;
        if (index > -1) {
            newArr = [...newUnits.slice(0, index), ...newUnits.slice(index + 1)];
        } else {
            newArr.push(id);
        }
        this.setState({ selectedCategories: newArr });
    };

    saveSelectedCategories = () => {
        this.toggleShowCategories();
    };

    removeMaterials = (guid: string) => {
        const materials = [...this.state.materials];
        const index = materials.findIndex((e) => e.guid === guid);
        if (index > -1) {
            materials.splice(index, 1);
            this.setState({ materials });
        }
    };

    addMaterials = (value: string) => {
        const materials = [...this.state.materials];
        materials.push({ guid: v4(), value });
        this.setState({ materials });
    };

    toggleShowClientOrders = () => {
        this.setState({ showClientOrders: !this.state.showClientOrders });
    };

    toggleShowMaterials = () => {
        this.setState({ showMaterials: !this.state.showMaterials });
    };

    toggleShowCategories = () => {
        this.setState({ showCategories: !this.state.showCategories });
    };
    //  printCredentials = () => this.props.onPrintCredentials(this.props.client);

    printDocumentCredentials = () =>
        this.props.onPrintDocCredentials !== undefined
            ? this.props.onPrintDocCredentials(this.props.outsourcer, this.state.selectedOrder)
            : () => {};
    private colgroup = () => (
        <colgroup>
            <col width="45" />
            <col />
            <col />
            {this.col()}
            {this.col()}
            {this.col()}
        </colgroup>
    );

    private col = () => <col width="93px" />;
}

export const OutsourcerDetails = observer(OutsourcerDetailsBase);

interface OutsourcerDetailsProps {
    outsourcer: OutsourcerType;
    workTypes: WorkTypeDictionaryType;
    categories: AgentsCategoriesDictionaryType;
    onSaved: () => void;
    onRemoved: () => void;
    onPrintCredentials: (outsourcer: OutsourcerType) => void;
    canChange: boolean;
    canRemove: boolean;
    canConfirm: boolean;
    canSupplierOrder: boolean;
    baseUrl: string;
    access: string[];
    showSensitiveData: boolean;
    onPrintDocCredentials?: (client: OutsourcerType, order: SelectItem | null) => void;
    confirmRequest?: (id: string) => void;
}

interface OutsourcerDetailsState {
    workTypeId: string;
    renderMenu: ItemListRenderer<WorkTypeDictionaryItemSnapshotType>;
    legal: boolean;
    showClientOrders: boolean;
    selectedOrder: null | SelectItem;
    materialVal: string;
    showMaterials: boolean;
    showCategories: boolean;
    materials: { value: string; guid: string }[];
    selectedCategories: string[];
}

function getFormValues(outsourcer: OutsourcerType): TStringMap<any> {
    return {
        ...getSnapshot(outsourcer),
        isLegalEntity: outsourcer.isLegalEntity,
        [fields.phone]: formatPhoneNumber(outsourcer.phone),
        [fields.phone2]: formatPhoneNumber(outsourcer.phone2),
        [fields.phone3]: formatPhoneNumber(outsourcer.phone3),
        [fields.workTypeIds]: [...outsourcer.workTypeIds],
        [fields.selfEmployedAccountDate]: outsourcer.selfEmployedAccountDateAsDate,
        [fields.birthday]: outsourcer.birthdayAsDate,
        [fields.documents]: [...outsourcer.documents.map((doc) => doc.asFileBase)],
        [fields.supplierDocuments]: [...outsourcer.supplierDocuments.map((doc) => doc.asFileBase)],
        [fields.materialDocuments]: [...outsourcer.materialDocuments.map((doc) => doc.asFileBase)],
        [fields.passport]: {
            ...getSnapshot(outsourcer.passport),
            [passportFields().issueDate]: outsourcer.passport.issueDateAsDate,
        },
    };
}

const renderSingleOption: ItemRenderer<WorkTypeDictionaryItemSnapshotType> = (item, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.id}
            onClick={handleClick}
            text={item.label}
        />
    );
};

const renderSelctedOption = (option: WorkTypeDictionaryItemSnapshotType | null | undefined, disabled = false) => (
    <Button
        disabled={disabled}
        fill={true}
        rightIcon={<GeneralIcon type="general-chevron-down" />}
        className="selected-option"
        text={option ? option.label || texts.undefined : texts.undefined}
    />
);

const renderMenu = (constants: ConstantsType) => {
    const values = Array.from(constants.workTypeCategory.values());
    const categories = values.filter((name) => !!name);

    const result: ItemListRenderer<WorkTypeDictionaryItemSnapshotType> = ({ items, itemsParentRef, renderItem }) => {
        const groups = categories.map((category) => ({
            header: category,
            options: items.filter((i) => i.workTypeCategory === category),
        }));

        return (
            <Menu ulRef={() => itemsParentRef}>
                {groups.map((gr) => (
                    <React.Fragment key={gr.header}>
                        <MenuDivider title={gr.header} key={gr.header} />
                        {gr.options.map(renderItem)}
                    </React.Fragment>
                ))}
            </Menu>
        );
    };

    return result;
};
