import {
    OrderIndicatorList,
    initialState as orderIndicatorList,
} from "./../../dictionaries/order-indicators/models/order-indicator-list";

import { OrderTechIndicator } from "./../../orders-manage/models/order-tech-indicator";
import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { nameof } from "modules/common/services/typescript";
import { DATE_FORMAT } from "modules/common/constants";
import moment from "moment";
import { TableSorter } from "modules/common/models/table-sorter";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { getSavedColumns } from "modules/common/services/table/columns-storage";
import { sumBy } from "lodash";
import { IdEntity } from "modules/common/models/entity";
import { Queryable } from "modules/common/models/queryable";
import {
    OrderStatusDictionary,
    initialState as orderStatusDictionary,
} from "modules/dictionaries/order-statuses/models/order-status-dictionary";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { FinanceValues } from "modules/orders-manage/models/order-finance-values";
import { MOVE_STORAGE_PREFIX, SIZE_STORAGE_PREFIX, TableColumner } from "modules/common/models/table-columner";
import { texts } from "modules/common/texts";
import { v4 } from "uuid";

export const DetailedOrderRow = types
    .compose(
        IdEntity,
        types.model({
            name: types.string,
            status: types.string,
            hasResultFiles: types.boolean,
            hasAllNotConfirmedResultFiles: types.boolean,
            hasAllResultFiles: types.boolean,
            statusId: types.string,
            statusColor: types.string,
            startDate: types.maybeNull(types.string),
            stopDate: types.maybeNull(types.string),
            startDateSortable: types.string,
            stopDateSortable: types.string,
            color: types.string,
            isNeedToLocateActPayments: types.boolean,
            portfolio: types.string,
            planOwnSpendings: types.number,
            actualOwnSpendings: types.number,
            planOutsourcedSpendings: types.number,
            actualOutsourcedSpendings: types.number,
            actualOtherSpendings: types.number,
            planOtherSpendings: types.number,
            planSpendings: types.number,
            actualSpendings: types.number,
            inventoryNumber: types.number,
            engineer: types.string,
            client: types.string,
            fullInventoryNumber: types.string,
            sum: types.number,
            completions: types.number,
            paymentSum: types.number,
            credit: types.number,
            municipalContract: types.boolean,
            batchContract: types.boolean,
            marks: types.string,
            progress: types.number,
            profitabilityPercents: types.number,
            todayProfitabilityPercents: types.number,
            profitability: types.number,
            todayProfitability: types.number,
            overSpending: types.number,
            finances: FinanceValues,
            todayFinances: FinanceValues,
            statusType: types.string,
            stopDatePlan: types.maybeNull(types.string),
            stopDateActual: types.maybeNull(types.string),
            orderNumber: types.string,
            warning: types.string,
            stopDatePlanWarning: types.string,
            allWarnings: types.array(types.string),
            techIndicators: types.maybeNull(types.array(OrderTechIndicator)),
        })
    )
    .views((self) => ({
        /** % производственных затрат */
        get spendingPercentage() {
            const result = self.sum === 0 ? 0 : (self.actualSpendings / self.sum) * 100;
            return result;
        },

        /** % оплат */
        get paymentPercentage() {
            const result = self.sum === 0 ? 0 : (self.paymentSum / self.sum) * 100;
            return result;
        },

        get startDateAsDate() {
            return self.startDate ? moment(self.startDate, DATE_FORMAT).toDate() : null;
        },

        get stopDateAsDate() {
            return self.stopDate ? moment(self.stopDate, DATE_FORMAT).toDate() : null;
        },
    }))
    .named("DetailedOrderRow");

const SelectedIndicator = types
    .model({
        id: types.string,
        idIndicator: types.string,
        from: types.string,
        to: types.string,
    })
    .named("SelectedIndicator");
export type SelectedIndicatorType = typeof SelectedIndicator.Type;
export const DetailedOrderList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            statuses: OrderStatusDictionary,
            orderIndicators: OrderIndicatorList,
            selectedIndicators: types.array(SelectedIndicator),
            orders: types.array(DetailedOrderRow),
            sorter: TableSorter,
            columner: TableColumner,
            status: types.string,
            selectedStatuses: types.array(types.string),
            loading: types.boolean,
            row: types.string,
        })
    )
    .views((self) => ({
        get isEmpty() {
            return !self.orders.length;
        },

        get data() {
            const predicate = (o: DetailedOrderRowType) => {
                let result = true;
                if (self.status) {
                    result = result && o.statusId === self.status;
                }

                if (self.selectedIndicators.length > 0) {
                    let isFounded = false;
                    self.selectedIndicators.forEach((i) => {
                        if (i.idIndicator) {
                            o.techIndicators?.forEach((tech) => {
                                if (tech.orderIndicatorId === i.idIndicator) {
                                    if (i.from && !i.to) {
                                        if (+i.from <= +tech.number) {
                                            isFounded = true;
                                        }
                                    }

                                    if (i.to && !i.from) {
                                        if (+i.to >= +tech.number) {
                                            isFounded = true;
                                        }
                                    }

                                    if (i.to && i.from) {
                                        if (+tech.number >= +i.from && +tech.number <= +i.to) {
                                            isFounded = true;
                                        }
                                    }

                                    if (!i.from && !i.to) {
                                        isFounded = true;
                                    }
                                }
                            });
                        }
                    });
                    result = isFounded;
                }

                if (self.selectedStatuses.length > 0) {
                    result = result && self.selectedStatuses.includes(o.statusId);
                }
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.inventoryNumber} ${o.name} ${o.status} ${o.portfolio} ${o.engineer} ${o.client} ${o.marks}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }
                if (result) {
                }

                return result;
            };

            return self.orders.filter(predicate).map((order) => {
                return {
                    ...getSnapshot(order),
                    [fields.paymentPercentage]: order.paymentPercentage,
                    [fields.spendingPercentage]: order.spendingPercentage,
                    [fields.startDate]: order.startDateAsDate,
                    [fields.stopDate]: order.stopDateAsDate,
                };
            });
        },

        get totalCredit() {
            return sumBy(self.orders, (o) => o.credit);
        },
    }))
    .views((self) => ({
        get observation() {
            return { data: self.data, laoding: self.loading };
        },
    }))
    .actions((self) => ({
        loadFromCache() {
            return self.transport.get<any>(apiUrls.orders.detailedList, {
                headers: {
                    "X-Cached": "yes",
                },
            });
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            if (self.loading) {
                return;
            }

            self.loading = true;
            try {
                if (self.statuses.isEmpty) {
                    yield self.statuses.load();
                }

                try {
                    const cached = yield self.loadFromCache();
                    if (cached) {
                        applySnapshot(self.orders, cached);
                    }
                } catch {
                    // nothing
                }

                const data: any[] = yield self.transport.get<any>(apiUrls.orders.detailedList);

                applySnapshot(self.orders, data);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),
        loadIndicators: flow(function* () {
            try {
                yield self.orderIndicators.load();
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
            }
        }),
        // delete: flow(function* (id: string) {
        //     try {
        //         yield self.transport.delete<boolean>(apiUrls.orders.summary.delete(id));
        //         self.notify.success(texts.messages.removed);
        //         const index = self.orders.findIndex((order) => order.id === id);
        //         if (index > -1) {
        //             self.orders.splice(index, 1);
        //         }
        //         return true;
        //     } catch (er) {
        //         self.notify.error(er);
        //         return false;
        //     }
        // }),
        sendToRemove: flow(function* (id: string) {
            try {
                var res = yield self.transport.post<boolean>(apiUrls.orders.summary.remove(id));
                if (res != null) {
                    if (res === true) {
                        self.notify.success(texts.messages.removed);
                        const index = self.orders.findIndex((order) => order.id === id);
                        if (index > -1) {
                            self.orders.splice(index, 1);
                        }
                    } else {
                        self.notify.success("Запрос на удаление проекта успешно отправлен!");
                    }
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        print: flow(function* (noMoney: boolean) {
            try {
                const data: FileDescription = yield self.transport.post<any>(apiUrls.application.print, {
                    variables: JSON.stringify({ rows: self.data }),
                    templateId: noMoney ? "ExportOrderDetailedList_NoMoney" : "ExportOrderDetailedList",
                });
                const blob: any = yield base64ToBlob(data.content || "", data.mimeType);

                const fileURL = URL.createObjectURL(blob);
                const printer = printPdf(fileURL, true);
                if (printer) {
                    printer.onclose = () => URL.revokeObjectURL(fileURL);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        selectRow(row: string) {
            self.row = row;
        },

        setStatus(status: string) {
            self.status = status;
        },
        setStatuses(status: any) {
            if (status.id) {
                if (self.selectedStatuses.findIndex((item) => item === status.id) === -1) {
                    self.selectedStatuses.push(status.id);
                }
            } else {
                self.selectedStatuses.push(status);
            }
        },

        addIndicators() {
            self.selectedIndicators.push({ id: v4(), idIndicator: "", from: "", to: "" });
        },

        changeIndicators(id: string, value: string, field: string) {
            self.selectedIndicators.forEach((item: SelectedIndicatorType) => {
                if (item.id === id) {
                    if (field === "to") {
                        item.to = value;
                    }
                    if (field === "from") {
                        item.from = value;
                    }
                    if (field === "indicatorId") {
                        item.idIndicator = value;
                    }
                }
            });
        },

        deleteIndicators(id: string) {
            const index = self.selectedIndicators.findIndex((i) => i.id === id);
            if (index > -1) {
                self.selectedIndicators.splice(index, 1);
            }
        },

        setIndicators(arr: any) {
            self.selectedIndicators = arr;
        },

        clearStatuses() {
            self.selectedStatuses.clear();
        },
        removeStatuses(item: any) {
            const index = self.selectedStatuses.findIndex((status) => status === item.id);
            if (index > -1) {
                self.selectedStatuses.splice(index, 1);
            }
        },
    }))
    .named("DetailedOrderList");

const sortStorage = getSortOption(DetailedOrderList.name);
const columnsStorage = getSavedColumns(DetailedOrderList.name);
const movedColumnsStorage = getSavedColumns(MOVE_STORAGE_PREFIX + DetailedOrderList.name);
const sizedColumnsStorage = getSavedColumns(SIZE_STORAGE_PREFIX + DetailedOrderList.name);

export const initialState = (): DetailedOrderListSnapshotType => {
    const options = sortStorage({ column: fields.startDate, asc: false });

    return {
        statuses: orderStatusDictionary(),
        orderIndicators: orderIndicatorList(),
        orders: [],
        loading: false,
        row: "",
        status: "",
        selectedStatuses: [],
        selectedIndicators: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: DetailedOrderList.name,
            tableName: DetailedOrderList.name,
            column: options.column,
            asc: options.asc,
        },
        columner: {
            tableName: DetailedOrderList.name,
            hiddenColumns: columnsStorage([]),
            movedColumns: movedColumnsStorage([]),
            sizedColumns: sizedColumnsStorage([]),
        },
    };
};

export type DetailedOrderRowType = typeof DetailedOrderRow.Type;
export type DetailedOrderListType = typeof DetailedOrderList.Type;
export type DetailedOrderListSnapshotType = typeof DetailedOrderList.SnapshotType;
export type DetailedOrderRowSnapshotType = typeof DetailedOrderRow.SnapshotType;

export const fields = {
    color: nameof((a: DetailedOrderRowType) => a.color) as string,
    status: nameof((a: DetailedOrderRowType) => a.status) as string,
    hasResultFiles: nameof((a: DetailedOrderRowType) => a.hasResultFiles) as string,
    name: nameof((a: DetailedOrderRowType) => a.name) as string,
    engineer: nameof((a: DetailedOrderRowType) => a.engineer) as string,
    client: nameof((a: DetailedOrderRowType) => a.client) as string,
    portfolio: nameof((a: DetailedOrderRowType) => a.portfolio) as string,
    inventoryNumber: nameof((a: DetailedOrderRowType) => a.inventoryNumber) as string,
    fullInventoryNumber: nameof((a: DetailedOrderRowType) => a.fullInventoryNumber) as string,
    sum: nameof((a: DetailedOrderRowType) => a.sum) as string,
    completions: nameof((a: DetailedOrderRowType) => a.completions) as string,
    paymentSum: nameof((a: DetailedOrderRowType) => a.paymentSum) as string,
    planOwnSpendings: nameof((a: DetailedOrderRowType) => a.planOwnSpendings) as string,
    actualOwnSpendings: nameof((a: DetailedOrderRowType) => a.actualOwnSpendings) as string,
    planOutsourcedSpendings: nameof((a: DetailedOrderRowType) => a.planOutsourcedSpendings) as string,
    actualOutsourcedSpendings: nameof((a: DetailedOrderRowType) => a.actualOutsourcedSpendings) as string,
    planSpendings: nameof((a: DetailedOrderRowType) => a.planSpendings) as string,
    actualSpendings: nameof((a: DetailedOrderRowType) => a.actualSpendings) as string,
    planOtherSpendings: nameof((a: DetailedOrderRowType) => a.planOtherSpendings) as string,
    actualOtherSpendings: nameof((a: DetailedOrderRowType) => a.actualOtherSpendings) as string,
    credit: nameof((a: DetailedOrderRowType) => a.credit) as string,
    startDate: nameof((a: DetailedOrderRowType) => a.startDate) as string,
    stopDate: nameof((a: DetailedOrderRowType) => a.stopDate) as string,
    municipalContract: nameof((a: DetailedOrderRowType) => a.municipalContract) as string,
    batchContract: nameof((a: DetailedOrderRowType) => a.batchContract) as string,
    spendingPercentage: nameof((a: DetailedOrderRowType) => a.spendingPercentage) as string,
    paymentPercentage: nameof((a: DetailedOrderRowType) => a.paymentPercentage) as string,
    progress: nameof((a: DetailedOrderRowType) => a.progress) as string,
    marks: nameof((a: DetailedOrderRowType) => a.marks) as string,
    profitabilityPercents: nameof((a: DetailedOrderRowType) => a.profitabilityPercents) as string,
    todayProfitabilityPercents: nameof((a: DetailedOrderRowType) => a.todayProfitabilityPercents) as string,
    profitability: nameof((a: DetailedOrderRowType) => a.profitability) as string,
    todayProfitability: nameof((a: DetailedOrderRowType) => a.todayProfitability) as string,
    overSpending: nameof((a: DetailedOrderRowType) => a.overSpending) as string,
    stopDatePlan: nameof((a: DetailedOrderRowType) => a.stopDatePlan) as string,
    stopDateActual: nameof((a: DetailedOrderRowType) => a.stopDateActual) as string,
    statusType: nameof((a: DetailedOrderRowType) => a.statusType) as string,
    warning: nameof((a: DetailedOrderRowType) => a.warning) as string,
    allWarnings: nameof((a: DetailedOrderRowType) => a.allWarnings) as string,
    orderNumber: nameof((a: DetailedOrderRowType) => a.orderNumber) as string,
    isNeedToLocateActPayments: nameof((a: DetailedOrderRowType) => a.isNeedToLocateActPayments) as string,
};
