import React from "react";
import { UserTimesheetType } from "modules/spending/timesheet/models/user-timesheet";
import styles from "./TimesheetUserRow.module.scss";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { observer } from "mobx-react";
import { TimesheetCell, DaySelector, TimesheetCellMode, OvertimeCell } from "./TimesheetCell";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { TimesheetInput, TimesheetSaver } from "./TimesheetInput";
import { getSnapshot } from "mobx-state-tree";
import { makeTimesheetInputStore } from "modules/spending/timesheet/models/timesheet-store";
import {
    TasksDictionaryType,
    WorkloadDayStoreType,
    WorkloadDayType,
} from "modules/spending/workload/models/workload-day";
import { eat } from "modules/common/services/typescript";

class Row extends React.Component<TimesheetUserRowProps, TimesheetUserRowState> {
    private cells: string[] = [];
    constructor(props: TimesheetUserRowProps) {
        super(props);

        this.state = { formStore: null, selectedItems: [], mouseDown: false };
    }

    render() {
        const {
            timesheet,
            onSave,
            selectedCells,
            onSelectCells,
            onSelectCell,
            tasks,
            selectedCell,
            multiSelectCells,
            onContext,
            ...selector
        } = this.props;
        const { formStore } = this.state;
        let timesheetDay: any;

        const selectCel = (str: string) => {
            const index = selectedCells
                ? selectedCells.findIndex((cell) => {
                      return cell === str;
                  })
                : 0;
            if (index === -1) {
                onSelectCells && onSelectCells([]);
                onSelectCell(str);
            } else {
                onSelectCell(str);
            }
        };

        const moveMouse = (event: any) => {
            if (event.target.offsetParent) {
                const item = event.target.offsetParent.id;

                this.cells.push(item);
                this.setState({ selectedItems: this.cells });
            } else {
                this.cells = [];
                this.setState({ selectedItems: this.cells });
            }
        };

        return (
            <div className={`timesheet-user-row ${styles.row}`}>
                <div className="timesheet-user-name">
                    <EmployerName name={timesheet.user.name} position={timesheet.user.position} />
                </div>
                <div
                    className="timesheet-user-days"
                    style={{ display: "flex" }}
                    onMouseDown={(e: any) => {
                        this.cells = [];

                        if (e.target.offsetParent) {
                            const item = e.target.offsetParent.id;
                            if (item !== "") {
                                this.setState({ mouseDown: true });
                                selectCel(item);
                            }
                        }
                    }}
                    onMouseMove={this.state.mouseDown && !multiSelectCells ? moveMouse : () => {}}
                    onMouseUp={
                        multiSelectCells
                            ? eat
                            : (e) => {
                                  this.setState({ mouseDown: false });
                                  //В связи с обнулением главного стейта в mouseDown происходит
                                  //небольшая задержка выбора ячеек и они визуально не успевают выбраться
                                  //было решено формировать главный стейт на основании начального и конечного
                                  //значения выбранных ячеек

                                  if (this.cells.length > 0) {
                                      let arrCells: string[] = [];
                                      const arrStart = selectedCell
                                          ? parseInt(selectedCell.slice(8, 10))
                                          : parseInt(this.cells[0].slice(8, 10));
                                      const arrEnd = this.cells[this.cells.length - 1]
                                          ? parseInt(this.cells[this.cells.length - 1].slice(8, 10))
                                          : parseInt(this.cells[this.cells.length - 2].slice(8, 10));
                                      const date = selectedCell.slice(0, 8);
                                      const user = selectedCell.slice(10, selectedCell.length);
                                      //формируем массив выбора слева направо
                                      if (arrEnd > arrStart) {
                                          for (let i = arrStart; i <= arrEnd; i++) {
                                              if (!timesheet.days[i - 1].isDayOff) {
                                                  if (i < 10) {
                                                      const item = `${date}0${i}${user}`;
                                                      arrCells.push(item);
                                                  } else {
                                                      const item = `${date}${i}${user}`;
                                                      arrCells.push(item);
                                                  }
                                              }
                                          }
                                      } else {
                                          ////формируем массив выбора справа налево
                                          for (let i = arrEnd; i <= arrStart; i++) {
                                              if (!timesheet.days[i - 1].isDayOff) {
                                                  if (i < 10) {
                                                      const item = `${date}0${i}${user}`;
                                                      arrCells.push(item);
                                                  } else {
                                                      const item = `${date}${i}${user}`;
                                                      arrCells.push(item);
                                                  }
                                              }
                                          }
                                      }
                                      this.cells = arrCells;
                                      // if (timesheet.user.nonProduction) {
                                      //     this.cells = [];
                                      // }
                                  }

                                  const arr = this.cells;

                                  this.cells = [];
                                  this.setState({ selectedItems: this.cells });

                                  if (arr.length > 0) {
                                      onSelectCells && onSelectCells(arr);
                                      onSelectCell("");
                                  }
                              }
                    }
                >
                    {timesheet.days.map((day, index) => {
                        const next = index + 1 < timesheet.days.length ? timesheet.days[index + 1] : undefined;
                        const preToday = !!next && next.isToday();
                        timesheetDay = day;
                        return (
                            <TimesheetCell
                                key={day.day}
                                day={day}
                                preToday={preToday}
                                onEdit={this.onDayEditStart}
                                locked={!this.isDayEditable(day)}
                                selectedCells={selectedCells}
                                cells={this.cells}
                                onSelectCel={selectCel}
                                selectedCell={selectedCell}
                                onContext={onContext}
                                {...selector}
                            />
                        );
                    })}
                </div>

                <OvertimeCell state={timesheet.hoursDiff} />

                {formStore && (
                    <TimesheetInput
                        store={formStore}
                        isOpened={true}
                        onClose={this.onDayEditStop}
                        onSave={onSave}
                        day={timesheetDay}
                        readOnly={!this.isDayEditable(timesheetDay)}
                        {...selector}
                    />
                )}
            </div>
        );
    }

    onDayEditStart = (day: WorkloadDayType) => {
        const { orders, tasks } = this.props;

        day.isDayWorkable &&
            this.setState({
                formStore: makeTimesheetInputStore(day, getSnapshot(orders.orders), getSnapshot(tasks.tasks)),
            });
    };

    onDayEditStop = () =>
        this.setState({
            formStore: null,
        });

    isDayEditable = (day: WorkloadDayType) => {
        const { canManage, userLogin } = this.props;

        return day.isDayEditable(canManage, userLogin);
    };
}

export const TimesheetUserRow = observer(Row);

interface TimesheetUserRowProps extends DaySelector, TimesheetSaver {
    timesheet: UserTimesheetType;
    orders: OrderDictionaryType;
    canManage: boolean;
    mode: TimesheetCellMode;
    userLogin: string;
    tasks: TasksDictionaryType;
    showMinutes: boolean;
    selectedCells?: string[];
    onSelectCells?: (cell: string[]) => void;
    onContext: (x: number, y: number, day: WorkloadDayType) => void;
    multiSelectCells?: boolean;
}

interface TimesheetUserRowState {
    formStore: WorkloadDayStoreType | null;
    selectedItems: string[];
    mouseDown: boolean;
}
