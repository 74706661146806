import { Button, Checkbox, Classes, FormGroup, InputGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { DefaultSelectedOption, renderSingleOption, SelectFactory } from "modules/common/services/form/select";
import { EventDateType, fields } from "modules/orders-manage/models/order-events";
import { Constants } from "modules/root/models/constants";
import React, { useCallback } from "react";
import { ChangeStatusBusinessEvent } from "./ChangeStatusBusinessEvent";
import { CommonBusinessEventProps, Error, star } from "./common";
import { ExpertiseBusinessEvent } from "./ExpertiseBusinessEvent";
import { SentExpertiseBusinessEvent } from "./SentExpertiseBusinessEvent";
import styles from "./Event.module.scss";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { toString } from "modules/common/services/strings";
import { ConsignmentBusinessEvent } from "./ConsignmentBusinessEvent";
import { OrderPaymentBusinessEvent } from "./OrderPaymentBusinessEvent";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { texts } from "modules/common/texts";
import { OrderWorkStartedBusinessEvent } from "./OrderWorkStartedBusinessEvent";
import { OrderStageRowItem, PlanOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import { OrderStageStartedBusinessEvent } from "./OrderStageStartedBusinessEvent";
import { OrderStageCompletedBusinessEvent } from "./OrderStageCompletedBusinessEvent";
import { OrderSignedBusinessEvent } from "./OrderSignedBusinessEvent";
import { OrderAdditionBuisnessEvent } from "./OrderAdditionBuisnessEvent";
import { IpdFileBusinessEvent } from "./IpdFileBusinessEvent";
const digits = onlyDigitsInputKeyPress();

const Components = [
    ChangeStatusBusinessEvent,
    ExpertiseBusinessEvent,
    SentExpertiseBusinessEvent,
    IpdFileBusinessEvent,
    ConsignmentBusinessEvent,
    OrderPaymentBusinessEvent,
    OrderWorkStartedBusinessEvent,
    OrderAdditionBuisnessEvent,
    OrderStageStartedBusinessEvent,
    OrderStageCompletedBusinessEvent,
    OrderSignedBusinessEvent,
];

export const SimpleSelect = SelectFactory<SelectItem>(false);

export const BusinessEvent = observer(
    ({ event, payments, stages, onRemove, additions, stageGuid }: BusinessEventProps) => {
        const onTypeChangeHandler = useCallback(
            (item: SelectItem) => {
                event.model.setValue(fields.type, item.id);
            },
            [event]
        );

        const onWorkDaysCheckedHandler = useCallback(
            (e: React.FormEvent<HTMLInputElement>) => {
                event.setWorkDays(e.currentTarget.checked);
            },
            [event]
        );

        const onDaysCountChangeHandler = useCallback(
            (e: React.FormEvent<HTMLInputElement>) => {
                event.setDaysCount(parseInt(e.currentTarget.value || "0", 10));
            },
            [event]
        );

        const eventType = Constants.businessEventTypesOptions.find((o) => o.id === event.model.type);
        const Component = Components.find((c) => c.TYPE === event.model.type) ?? UnknownEventType;
        const readonly = !!event.eventId;
        const daysCountValue = event.daysCount === 0 ? "" : toString(event.daysCount);

        return (
            <div className={styles.event}>
                <div>
                    <RemoveConfirmation<EventDateType>
                        what={() => "Событие"}
                        actionName="отвязать"
                        render={({ confirmRemoving }) => (
                            <PlanrButton
                                type="secondary"
                                icon="general-trash"
                                onClick={() => confirmRemoving(event)}
                                size="small"
                                title={texts.remove}
                                style={{ marginBottom: "5px", width: "100%" }}
                                className={styles.deleteButton}
                            >
                                Отвязать событие
                            </PlanrButton>
                        )}
                        onConfirmed={onRemove}
                    />
                </div>

                <FormGroup
                    labelInfo={star}
                    helperText={event.model.validate["type"]}
                    intent={event.model.validate["type"] ? Error : "none"}
                    className={Classes.SMALL}
                >
                    <div className={`${Classes.INPUT_GROUP}`}>
                        <SimpleSelect
                            className={`full-width-select ${Classes.FILL}`}
                            filterable={false}
                            activeItem={eventType}
                            items={Constants.businessEventTypesOptions}
                            itemRenderer={renderSingleOption}
                            onItemSelect={onTypeChangeHandler}
                            disabled={readonly}
                            popoverProps={{
                                popoverClassName: "business-event__break-dropdown",
                            }}
                        >
                            {readonly && <Button fill={true} className="selected-option" text={eventType?.label} />}
                            {!readonly && (
                                <DefaultSelectedOption
                                    option={eventType}
                                    empty={!Constants.businessEventTypesOptions.length}
                                />
                            )}
                        </SimpleSelect>
                    </div>
                </FormGroup>

                <Component
                    event={event}
                    payments={payments}
                    stages={stages}
                    readonly={readonly}
                    additions={additions}
                    //  stageGuid={stageGuid}
                />

                <div style={{ display: "flex", alignItems: "center" }}>
                    <label className={Classes.LABEL}>Через:&nbsp;</label>
                    <InputGroup
                        id="offcet"
                        value={daysCountValue}
                        type="text"
                        className="planr-default-input"
                        autoComplete="off"
                        data-lpignore="true"
                        onKeyPress={digits}
                        onChange={onDaysCountChangeHandler}
                    />
                    &nbsp;
                    <Checkbox
                        large={false}
                        checked={event.workDays}
                        onChange={onWorkDaysCheckedHandler}
                        label="раб.дней"
                    />
                </div>
            </div>
        );
    }
);

interface BusinessEventProps {
    event: EventDateType;
    onRemove: (event: EventDateType) => void;
    payments: PlanOrderPaymentDetailsType[];
    stages: OrderStageRowItem[];
    additions: any[];
    stageGuid?: string;
}

const UnknownEventType = (props: CommonBusinessEventProps) => <strong>Неподдерживаемый тип события</strong>;
