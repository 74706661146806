import React from "react";
import {
    WorkloadDayStoreType,
    MAX_RECORD_COUNT,
    DayWorkRowSnapshotType,
    DayWorkRowType,
    WorkloadDayType,
} from "modules/spending/workload/models/workload-day";
import { observer } from "mobx-react";
import { Classes, Button, InputGroup, Dialog, Icon, MenuItem } from "@blueprintjs/core";
import { toString } from "modules/common/services/strings";
import styles from "./WorkloadInput.module.scss";
import { OrderDictionaryItemSnapshotType } from "modules/orders-manage/models/order-dictionary";
import { getSnapshot } from "mobx-state-tree";
import { texts } from "modules/common/texts";
import { Constants } from "modules/root/models/constants";
import { MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import {
    ProjectSelect,
    renderProjectOption,
    optionLabel,
    filterProjectItemPredicate,
} from "modules/common/components/form/ProjectSelect";
import { Buttons } from "modules/common/components/form";
import { LARGE_ICON_AS_BUTTON_SIZE } from "modules/common/constants";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import {
    DefaultSelectedOption,
    renderSingleOption,
    SelectFactory,
    SimpleSelect,
    filterItemPredicate,
} from "modules/common/services/form/select";
import { WorkloadUnitTypeType } from "../models/workload-unit";
import { ReactComponent as WithoutPayIcon } from "./without-work.svg";
import { ReactComponent as IllnessIcon } from "modules/common/components/icons/illness.icon.svg";
import { ReactComponent as VacationIcon } from "modules/common/components/icons/vacation.icon.svg";
import { ItemRenderer } from "@blueprintjs/select";
import { groupBy } from "lodash";

const MissingSelect = SelectFactory<WorkloadUnitTypeType>();
type Event = React.ChangeEvent<HTMLInputElement>;

class Input extends React.Component<WorkloadInputProps, WorkloadInputState> {
    constructor(props: WorkloadInputProps) {
        super(props);
        this.state = {
            taskSelected: { id: "", label: "" },
            confirmOpened: false,
            comment: "",
        };
        this.initialValues = getSnapshot(props.store.units);
        this.otherTypes = Constants.otherHoursTypes.map((type) => type.name);
    }

    otherTypes: string[];
    initialValues: DayWorkRowSnapshotType[];

    dialogBody = React.createRef<HTMLDivElement>();
    confirmation = React.createRef<HTMLDivElement>();

    nonTaskedUnits = (unitList: RenderUnit[]) => {
        const { readOnly } = this.props;

        return unitList.map(({ unit, index, label }) => {
            return (
                <div className={styles.projectRows} key={label.id} data-unit-index={index}>
                    <div className={styles.projectRow}>
                        <div className={styles.projectName}>{label.node}</div>
                        <InputGroup
                            className={`${styles.hoursInput} planr-default-input hoursInputer`}
                            type="number"
                            value={hoursToString(unit.hours)}
                            onChange={(e: Event) => {
                                this.setHours(unit, e.target.value);
                            }}
                            disabled={unit.isDisabled}
                            readOnly={readOnly}
                            maxLength={2}
                        />
                        ч.
                        {!readOnly && (
                            <Icon
                                icon="cross"
                                onClick={this.removeUnint}
                                iconSize={LARGE_ICON_AS_BUTTON_SIZE}
                                className="red-icon action-icon"
                                title={texts.remove}
                                data-index={index}
                            />
                        )}
                    </div>
                    <div className={styles.projectRow}>
                        <InputGroup
                            className={`${styles.commentInput} ${Classes.FILL} planr-default-input`}
                            type="string"
                            value={unit.comment}
                            placeholder="Примечания"
                            onChange={(e: Event) => {
                                this.setComment(unit, e.target.value);
                            }}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            );
        });
    };

    taskedUnits = (unitList: RenderUnit[]) => {
        const { store, readOnly } = this.props;
        const groupLabel = unitList[0].label;
        const options = store.projectTasks(groupLabel.project ?? null);

        return (
            <div className={styles.projectRows} key={groupLabel.id}>
                <div className={styles.projectRow}>
                    <div className={styles.projectName}>{groupLabel.node}</div>
                </div>

                {unitList.map(({ unit, index }) => {
                    return (
                        <div key={index}>
                            <div className={styles.projectRow} data-unit-index={index}>
                                <div
                                    style={{
                                        margin: "10px 0px",
                                        position: "relative",
                                        width: "100%",
                                        maxWidth: "852px",
                                    }}
                                >
                                    <TaskSelect
                                        store={store}
                                        currentOptions={options}
                                        value={unit.workId}
                                        onChange={(item) => {
                                            unit.setTask(item.id);
                                        }}
                                    />

                                    {Object.entries(options).length === 0 && (
                                        <span className={styles.noWorkInfo}>Нет незавершенных задач для СС</span>
                                    )}
                                </div>
                                <InputGroup
                                    className={`${styles.hoursInput} planr-default-input hoursInputer`}
                                    type="number"
                                    style={{ marginTop: "-1px" }}
                                    value={hoursToString(unit.hours)}
                                    onChange={(e: Event) => {
                                        this.setHours(unit, e.target.value);
                                    }}
                                    disabled={unit.isDisabled}
                                    readOnly={readOnly}
                                    maxLength={2}
                                />
                                ч.
                                {/* Select minutes */}
                                {!readOnly && (
                                    <Icon
                                        icon="cross"
                                        onClick={this.removeUnint}
                                        iconSize={LARGE_ICON_AS_BUTTON_SIZE}
                                        className="red-icon action-icon"
                                        title={texts.remove}
                                        data-index={index}
                                    />
                                )}
                            </div>
                            <div className={styles.projectRow}>
                                <InputGroup
                                    className={`${styles.commentInput} ${Classes.FILL} planr-default-input`}
                                    type="string"
                                    value={unit.comment}
                                    placeholder="Примечания"
                                    onChange={(e: Event) => {
                                        this.setComment(unit, e.target.value);
                                    }}
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                    );
                })}

                <PlanrButton
                    type="dashed"
                    icon="general-plus-big"
                    size="small"
                    style={{ width: "100%", marginTop: "10px" }}
                    data-project={groupLabel.project}
                    onClick={this.addUnit}
                >
                    Добавить задачу для {groupLabel.node}
                </PlanrButton>
            </div>
        );
    };

    render() {
        const { isOpened, store, onClose, readOnly, removeDay, closeDialog } = this.props;
        const { nonProduction, missingType, day, user, units, usedProjects, dirty, isDayOff } = store;
        const projectOptions = store.orders.filter((o) => !usedProjects.includes(o.inventoryNumber));

        const renderList: RenderUnit[] = units.map((unit, index) => ({
            label: this.getRowLabel(unit),
            unit,
            index,
        }));

        const unitsGroup = groupBy(renderList, (u) => u.label.id);

        return (
            <>
                <Dialog
                    title={`${day.day} (${user.label})`}
                    isCloseButtonShown={true}
                    isOpen={isOpened}
                    onClose={onClose}
                    onOpened={this.focusFirstInput}
                    backdropClassName="standard"
                    className={`${styles.dialog} figma-dialog`}
                >
                    <Dialog
                        title={`Очистка`}
                        isOpen={this.state.confirmOpened}
                        backdropClassName="standard"
                        className={`${styles.smallDialog} figma-dialog`}
                    >
                        <div className={`${Classes.DIALOG_BODY}`}>
                            <p className={styles.clearInfo}>
                                Вы точно хотите очистить выбранные дни для пользователя {user.label}?
                            </p>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <Buttons
                                left={
                                    <div style={{ display: "flex" }}>
                                        <PlanrButton
                                            type="graybtn"
                                            onClick={this.toggleConfirm}
                                            size="small"
                                            style={{ marginRight: "10px" }}
                                        >
                                            Отмена
                                        </PlanrButton>
                                        <PlanrButton
                                            type="danger"
                                            size="small"
                                            onClick={() => {
                                                removeDay();
                                                this.toggleConfirm();
                                                closeDialog();
                                            }}
                                        >
                                            {" "}
                                            {texts.clear}
                                        </PlanrButton>
                                    </div>
                                }
                            />
                        </div>
                    </Dialog>
                    {isOpened && (
                        <form onSubmit={this.onSubmit}>
                            <div className={`${Classes.DIALOG_BODY}`} ref={this.dialogBody}>
                                {!readOnly && (
                                    <div className={styles.dayType}>
                                        {!isDayOff && (
                                            <WithoutPayIcon
                                                className={`figma-icon ${
                                                    missingType === Constants.withoutPayHoursType.name
                                                        ? styles.activeIcon
                                                        : ""
                                                }`}
                                                title={Constants.withoutPayHoursType.name}
                                                onClick={this.selectedByIcon}
                                                data-type={Constants.withoutPayHoursType.name}
                                            />
                                        )}

                                        <IllnessIcon
                                            className={`figma-icon ${
                                                missingType === Constants.illnesHoursType.name ? styles.activeIcon : ""
                                            }`}
                                            title={Constants.illnesHoursType.name}
                                            onClick={this.selectedByIcon}
                                            data-type={Constants.illnesHoursType.name}
                                        />

                                        <VacationIcon
                                            className={`figma-icon ${
                                                missingType === Constants.vacationHoursType.name
                                                    ? styles.activeIcon
                                                    : ""
                                            }`}
                                            title={Constants.vacationHoursType.name}
                                            onClick={this.selectedByIcon}
                                            data-type={Constants.vacationHoursType.name}
                                        />

                                        <MissingSelect
                                            className={"full-width-select"}
                                            filterable={false}
                                            activeItem={null}
                                            items={Constants.otherHoursTypes}
                                            itemRenderer={renderTimesheetTypeOption}
                                            onItemSelect={this.missingTypeSelected}
                                        >
                                            <Button
                                                className="selected-option"
                                                active={this.otherTypes.includes(missingType)}
                                                fill={true}
                                                rightIcon={<GeneralIcon type="general-chevron-down" />}
                                                text="Другое"
                                            />
                                        </MissingSelect>
                                    </div>
                                )}
                                {nonProduction && !readOnly && (
                                    <div className={styles.projectSelection}>
                                        <PlanrButton
                                            type={"graybtn"}
                                            className={styles.justWork}
                                            onClick={this.justWorkSelected}
                                            disabled={store.workButtonActive}
                                            size="small"
                                        >
                                            Работа
                                        </PlanrButton>
                                    </div>
                                )}
                                {!nonProduction && !readOnly && (
                                    <div className={styles.projectSelection}>
                                        <ProjectSelect
                                            className={`full-width-select ${styles.projectSelect}`}
                                            filterable={true}
                                            itemPredicate={filterProjectItemPredicate}
                                            activeItem={null}
                                            items={projectOptions}
                                            itemRenderer={renderProjectOption}
                                            onItemSelect={this.projectSelected}
                                            disabled={units.length >= MAX_RECORD_COUNT}
                                            resetOnClose={true}
                                            inputProps={projectSelectInputProps}
                                        >
                                            <Button
                                                className="selected-option"
                                                active={true}
                                                fill={true}
                                                rightIcon={<GeneralIcon type="general-chevron-down" />}
                                                text="Проект"
                                                disabled={units.length >= MAX_RECORD_COUNT}
                                            />
                                        </ProjectSelect>
                                    </div>
                                )}

                                <div className={styles.divider}></div>

                                <div className={styles.dayResult}>
                                    {Object.values(unitsGroup).map((renderUnits) => {
                                        const label = renderUnits[0].label;
                                        return label.project
                                            ? this.taskedUnits(renderUnits)
                                            : this.nonTaskedUnits(renderUnits);
                                    })}
                                </div>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <Buttons
                                    left={
                                        <div style={{ display: "flex" }}>
                                            <PlanrButton type="danger" size="small" onClick={this.toggleConfirm}>
                                                {texts.clear}
                                            </PlanrButton>
                                            <PlanrButton
                                                type="greenish"
                                                size="small"
                                                nativeType="submit"
                                                style={{ marginLeft: "10px" }}
                                            >
                                                {texts.save}
                                            </PlanrButton>
                                            {dirty && (
                                                <PlanrButton
                                                    type="graybtn"
                                                    onClick={this.revertChanges}
                                                    size="small"
                                                    style={{ marginLeft: "10px" }}
                                                >
                                                    Отмена
                                                </PlanrButton>
                                            )}
                                        </div>
                                    }
                                />
                            </div>
                        </form>
                    )}
                </Dialog>
            </>
        );
    }

    revertChanges = () => {
        const { store } = this.props;
        store.revertChanges(this.initialValues);
    };

    toggleConfirm = () => {
        this.setState({ confirmOpened: !this.state.confirmOpened });
    };

    setCommentDay = (day: WorkloadDayType, str: string) => {
        day.setComment(str);
    };

    removeUnint = (e: React.MouseEvent<HTMLElement>) => {
        const { store } = this.props;
        const index = parseInt(e.currentTarget.dataset.index || "", 10);
        store.removeUnit(index);
    };

    getRowLabel = (unit: DayWorkRowType): UnitLabel => {
        const { store } = this.props;
        const order = unit.project !== null ? store.ordersMap.get(unit.project) : undefined;

        if (order) {
            return { id: optionLabel(order), node: optionLabel(order), project: order.inventoryNumber };
        }

        if (unit.type.name === Constants.workedOutHoursType.name) {
            return { id: "Работа", node: "Работа" };
        }

        return {
            id: unit.type.name,
            node: getRowIcon(unit),
        };
    };

    selectedByIcon = (e: React.MouseEvent<SVGSVGElement>) => {
        const typeName = e.currentTarget.dataset.type;
        const selectedType = Constants.allHoursTypes.find((t) => t.name === typeName);
        selectedType && this.missingTypeSelected(selectedType);
    };

    missingTypeSelected = (type: WorkloadUnitTypeType) => {
        const { store } = this.props;
        store.setMissingType(type);
        this.focusLastInput();
    };

    justWorkSelected = () => this.projectSelected(null);

    projectSelected = (project: OrderDictionaryItemSnapshotType | null = null) => {
        this.addUnitToProject(project ? project.inventoryNumber : null);
    };

    addUnit = (e: React.MouseEvent<HTMLButtonElement>) => {
        const inventory = parseInt(e.currentTarget.dataset["project"] ?? "");
        this.addUnitToProject(isNaN(inventory) ? null : inventory);
    };

    addUnitToProject = (inventory: number | null) => {
        const { store } = this.props;
        store.addProject(inventory);
        this.focusLastInput();
    };

    focusLastInput = () => {
        const { store } = this.props;
        const index = store.units.length - 1;
        this.focusInput(index);
    };

    focusFirstInput = () => this.focusInput(0);

    focusInput = (index = 0) => {
        index >= 0 &&
            setTimeout(() => {
                if (this.dialogBody.current) {
                    const input: HTMLInputElement | null = this.dialogBody.current.querySelector(
                        `[data-unit-index='${index}'] .hoursInputer input`
                    );

                    input && input.focus();
                }
            }, MODAL_AWAIT_DELAY);
    };

    focusConfirmation = () => {
        setTimeout(() => {
            if (this.confirmation.current) {
                const button: HTMLButtonElement | null = this.confirmation.current.querySelector(".bp3-intent-primary");
                button && button.focus();
            }
        }, MODAL_AWAIT_DELAY);
    };

    setHours = (unit: DayWorkRowType, hours: string) => {
        const value = parseInt(hours, 10);

        if (!isNaN(value) && value >= 0) {
            unit.setHours(value);
        } else {
            unit.setHours(0);
        }
    };

    setComment = (unit: DayWorkRowType, str: string) => {
        unit.setComment(str);
    };

    onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        await this.save();
    };

    save = async () => {
        const { onSave, store, onClose } = this.props;
        const model = store.getSubmitModel();

        if (model != null) {
            const success = await onSave(model);
            success && onClose();
        }
    };
}

export const WorkloadInput = observer(Input);

export interface WorkloadSaver {
    onSave: (model: any) => Promise<boolean> | undefined;
    readOnly?: boolean;
}

interface WorkloadInputProps extends WorkloadSaver {
    store: WorkloadDayStoreType;
    isOpened: boolean;
    onClose: () => void;
    readonly?: boolean;
    removeDay: () => void;
    closeDialog: () => void;
}

interface WorkloadInputState {
    taskSelected: OptionItem;
    confirmOpened: boolean;
    comment: string;
}

function hoursToString(hours: number) {
    return hours > 0 ? toString(hours) : "";
}

const renderTimesheetTypeOption: ItemRenderer<WorkloadUnitTypeType> = (item, { handleClick, modifiers }) => {
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.name}
            onClick={handleClick}
            text={item.name}
        />
    );
};

const projectSelectInputProps = {
    placeholder: "Проект",
};

export const TaskSelect = observer(
    class extends React.Component<StatusFilterHeaderProps> {
        render() {
            const { value, currentOptions, showMinutes } = this.props;
            const option = currentOptions[value];

            return (
                <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    filterable={true}
                    itemPredicate={filterItemPredicate}
                    activeItem={option}
                    items={Object.values(currentOptions)}
                    itemRenderer={renderSingleOption}
                    onItemSelect={this.onItemSelect}
                    popoverProps={{
                        popoverClassName: showMinutes
                            ? "timesheet-task-select-dropdown"
                            : "timesheet-task-select-dropdown-long",
                    }}
                    inputProps={{
                        placeholder: texts.search,
                    }}
                >
                    <DefaultSelectedOption
                        option={option}
                        empty={!currentOptions}
                        what={"Вид работ"}
                        style={{ overflow: "hidden", width: showMinutes ? "592px" : "712px" }}
                    />
                </SimpleSelect>
            );
        }

        onItemSelect = (item: SelectItem) => this.props.onChange(item);
    }
);

interface StatusFilterHeaderProps {
    value: string;
    onChange: (value: any) => void;
    currentOptions: { [k: string]: any };
    filter?: string;
    store: any;
    showMinutes?: boolean;
}

export interface UnitLabel {
    id: string;
    node: any;
    project?: number;
}

export interface RenderUnit {
    label: UnitLabel;
    unit: DayWorkRowType;
    index: number;
}

export const getRowIcon = (unit: DayWorkRowType) => {
    if (unit.type.name === Constants.withoutPayHoursType.name) {
        return <WithoutPayIcon title={unit.type.name} className={styles.selectedIcon} />;
    }

    if (unit.type.name === Constants.illnesHoursType.name) {
        return <IllnessIcon title={unit.type.name} className={styles.selectedIcon} />;
    }

    if (unit.type.name === Constants.vacationHoursType.name) {
        return <VacationIcon title={unit.type.name} className={styles.selectedIcon} />;
    }

    return unit.type.name;
};
