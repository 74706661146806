import { Button, Classes, FormGroup } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { EventTypes, fields } from "modules/orders-manage/models/order-events";
import React, { useCallback } from "react";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import { CommonBusinessEventProps, Error, stageLabel, star } from "./common";

const Base = ({ event, readonly, stages }: CommonBusinessEventProps) => {
    const { value, validate } = event.model;
    const stage = stages.find((o) => o.stage.newGuid === value);

    const item: SelectItem | null = stage ? { id: stage.stage.newGuid, label: stageLabel(stage) } : null;
    const options = stages.map((o) => ({ id: o.stage.newGuid, label: stageLabel(o) }));

    const onChangeHandler = useCallback(
        (i: SelectItem) => {
            event.model.setValue(fields.stageGuid, i.id);
        },
        [event]
    );

    return (
        <FormGroup
            labelInfo={star}
            helperText={validate[fields.stageGuid]}
            intent={validate[fields.stageGuid] ? Error : "none"}
            className={Classes.SMALL}
        >
            <div className={`${Classes.INPUT_GROUP}`}>
                <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    filterable={false}
                    activeItem={item}
                    items={options}
                    itemRenderer={renderSingleOption}
                    onItemSelect={onChangeHandler}
                    disabled={readonly}
                    popoverProps={{
                        usePortal: true,
                        boundary: "viewport",
                        popoverClassName: "business-event__typeselect-dropdown",
                    }}
                >
                    {readonly && (
                        <Button fill={true} className="selected-option">
                            {stage && stageLabel(stage)}
                        </Button>
                    )}
                    {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
                </SimpleSelect>
            </div>
        </FormGroup>
    );
};

Base.TYPE = EventTypes.SentExpertiseBusinessEvent;

export const SentExpertiseBusinessEvent = observer(Base);
