import React from "react";
import { CalendarEventDayType, EventDayType } from "modules/main/models/calendar-event-store";
import { observer } from "mobx-react";
import styles from "./DayEvents.module.scss";

import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { eat } from "modules/common/services/typescript";

class Day extends React.Component<DayEventsProps> {
    render() {
        const { events, day, onChange } = this.props;

        return (
            <div className={styles.day}>
                <div className={styles.dayName}>
                    <PlanrButton
                        type="secondary"
                        icon="general-plus-big"
                        onClick={this.onAddClick}
                        size="small"
                        round={true}
                        style={{ marginRight: "20px" }}
                    />
                    {day.isToday ? "Сегодня" : day.day}
                </div>
                {events.map((evt) => (
                    <div key={evt.event.id} className={styles.event}>
                        <GeneralIcon
                            type="general-edit"
                            onClick={evt.event.readonly ? eat : () => onChange(evt)}
                            style={{
                                cursor: evt.event.readonly ? "default" : "pointer",
                                marginRight: "10px",
                                color: "",
                                visibility: evt.event.readonly ? "hidden" : "visible",
                            }}
                        />
                        <span className={styles.time}>{evt.eventTime(day.day)}</span>
                        {evt.event.name}
                    </div>
                ))}
                {!events.length && <div>Ничего не запланировано</div>}
            </div>
        );
    }

    onAddClick = () => this.props.onAdd(this.props.day.day);
}

export const DayEvents = observer(Day);

interface DayEventsProps {
    day: EventDayType;
    events: CalendarEventDayType[];
    onAdd: (day: string) => void;
    onChange: (event: CalendarEventDayType) => void;
}
