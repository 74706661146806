import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback, History, Location } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { EmployeeStoreType } from "../models/employee-store";
import { EmployerDetails } from "./details/EmployerDetails";
import { EmployerList } from "./list/EmployerList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "../../functionalities";
import sessionFunc from "modules/session/functionalities";
import { can } from "modules/session/auth/access";
import { Search } from "modules/common/components/form/Search";
import { RemovedList } from "./list/RemovedList";
import { Dialog, Classes, Spinner } from "@blueprintjs/core";
import styles from "./EmployeePage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { OwnPremiumsWidget } from "modules/main/components/own-premiums/OwnPremiumsWidget";
import { OwnMaterialsWidget } from "modules/main/components/own-materials/OwnMaterialsWidget";
import { canReadOrder } from "modules/orders-manage/functionalities";
import { MaterialValuesStoreType } from "modules/dictionaries/inventory/models/material-value-store";
import { isNewlyCreated } from "modules/common/models/entity";
import { Constants } from "modules/root/models/constants";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import { RoleDetails } from "modules/session/access/components/roles/RoleDetails";
import { SessionType } from "modules/session/auth/models/session";

const menu = menuItems();

class EmployeePage extends React.Component<EmployeePageProps, EmployeePageState> {
    private unregister: UnregisterCallback | null = null;
    private options: { [k: string]: any } = {};
    constructor(props: EmployeePageProps) {
        super(props);

        this.state = {
            employerId: getSelectedEmployerId(props.location.pathname),
            showRemoved: false,
            showPremiums: false,
            showMaterials: false,
            itemPrint: { id: "", label: "" },
            showPrint: false,
            loadingPrint: false,
            showAccess: false,
            loading: false,
        };
    }

    async componentDidMount() {
        document.title = menu.spending.nested.employee.label;

        let i = 0;
        Constants.employerDocumentType.forEach((type) => {
            let res: OptionItem = { id: i.toString(), label: type };

            this.options[i] = res;
            i++;
        });

        const item = this.options[0];
        this.setState({ itemPrint: item });

        const { history, store } = this.props;
        const { employerId } = this.state;
        store.init(employerId);

        this.unregister = history.listen(async (location) => {
            const userId = getSelectedEmployerId(location.pathname);

            if (this.state.employerId !== userId) {
                this.setState({ employerId: userId, loading: true });

                await this.loadDetails(userId);
                this.setState({ loading: false });
            }
        });
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { store, access, baseUrl, materials } = this.props;
        const { showRemoved, showPremiums, showMaterials, showAccess, loading } = this.state;
        const canChange = can(func.EMPLOYER_EDIT, access);
        const orderAccess = canReadOrder(access);

        return (
            <Block columned={true} className={styles.page}>
                <Route path={routes.spending.employee.details()}>
                    {this.state.employerId && !loading && (
                        <DetailsPopup
                            title={this.title(store.details.name)}
                            onCancel={this.onEmployerSelected}
                            width={1310}
                        >
                            <EmployerDetails
                                baseUrl={baseUrl}
                                positions={store.positions}
                                employer={store.details}
                                onSaved={this.onEmployerSaved}
                                onRemoved={this.onEmployerRemoved}
                                canChange={canChange}
                                onPrintCredentials={store.printCredentials}
                                spendings={store.projects}
                                orderAccess={orderAccess}
                                employerList={store.list}
                                employerId={this.state.employerId}
                            />
                        </DetailsPopup>
                    )}

                    <Dialog
                        icon="info-sign"
                        title="Назначенные премии"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={false}
                        isOpen={showPremiums}
                        onClose={this.hidePremiums}
                        style={{ width: `600px` }}
                        backdropClassName="standard"
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <OwnPremiumsWidget store={store.premiums} orderAccess={orderAccess} />
                        </div>
                    </Dialog>

                    <Dialog
                        icon="info-sign"
                        title="Закрепленный инвентарь"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={false}
                        isOpen={showMaterials}
                        onClose={this.hideMaterials}
                        style={{ width: "600px" }}
                        backdropClassName="standard"
                    >
                        <div className={Classes.DIALOG_BODY}>
                            <OwnMaterialsWidget
                                store={materials.list}
                                orderAccess={orderAccess}
                                employerId={this.state.employerId}
                            />
                        </div>
                    </Dialog>

                    <Dialog
                        icon="info-sign"
                        title="Права доступа"
                        canEscapeKeyClose={true}
                        canOutsideClickClose={false}
                        isOpen={showAccess}
                        onClose={this.hideAccess}
                        style={{ width: `1180px` }}
                        backdropClassName="standard"
                    >
                        <div className={`${Classes.DIALOG_BODY} ${styles.userAccess}`}>
                            <RoleDetails
                                role={store.details.role}
                                columns={store.accessColumns}
                                onToggle={this.setFunctionality}
                            />
                        </div>
                    </Dialog>
                </Route>
                <Dialog
                    icon="info-sign"
                    title="Печать документов"
                    canEscapeKeyClose={true}
                    canOutsideClickClose={false}
                    isOpen={this.state.showPrint}
                    onClose={this.printHide}
                    style={{ width: `600px` }}
                    backdropClassName="standard"
                >
                    <div className={Classes.DIALOG_BODY} style={{ margin: "auto", width: "100%" }}>
                        <div className={styles.documentSelector}>
                            <span className={styles.spanDocumentSelector}>Выберите тип документа</span>
                            <StatusFilterHeader
                                width={300}
                                currentOptions={this.options}
                                value={this.state.itemPrint.id}
                                onChange={async (item: any) => {
                                    this.setState({ itemPrint: item });
                                }}
                            />
                        </div>
                        {this.state.loadingPrint && (
                            <div style={{ margin: "0 auto", display: "flex" }}>
                                <span
                                    className={styles.spanDocumentSelector}
                                    style={{ textAlign: "right", marginLeft: "65px" }}
                                >
                                    Загрузка документов...
                                </span>
                                {<Spinner intent="primary" size={25} />}
                            </div>
                        )}
                        {!this.state.loadingPrint && (
                            <PlanrButton
                                type="blueish"
                                style={{ margin: "0 auto" }}
                                onClick={async () => {
                                    this.setState({ loadingPrint: true });
                                    await store.printDocuments(this.state.itemPrint.label);

                                    this.setState({ loadingPrint: false });
                                }}
                            >
                                Распечатать
                            </PlanrButton>
                        )}
                    </div>
                </Dialog>

                <div className={styles.tools}>
                    {canChange && !showRemoved && (
                        <PlanrButton
                            type="secondary"
                            size="small"
                            icon="general-plus-big"
                            onClick={this.onEmployerAdd}
                            style={{
                                width: "230px",
                                marginBottom: "32px",
                                borderRadius: "120px",
                            }}
                        >{`${texts.add} сотрудника`}</PlanrButton>
                    )}

                    <PlanrButton
                        type="graybtn"
                        size="small"
                        icon="general-ppl"
                        onClick={this.toggleVisibility}
                        className={styles.toggler}
                        style={{
                            width: "260px",
                            marginBottom: "32px",
                            borderRadius: "120px",
                        }}
                    >
                        {showRemoved ? "Показать активных" : "Показать удаленных"}
                    </PlanrButton>

                    <div className={styles.right}>
                        <PlanrButton
                            type="graybtn"
                            icon="general-print"
                            round={true}
                            onClick={this.printShow}
                            title="Печать"
                        />
                        <Search query={store.list.query} onSearch={store.list.setQuery} />
                    </div>
                </div>

                <EmployerList
                    store={store.list}
                    onEmployerSelected={this.onEmployerSelected}
                    hidden={showRemoved}
                    onEmployerRemove={this.onEmployerRemove}
                    canChange={canChange}
                />

                <RemovedList
                    store={store.removed}
                    onEmployerSelected={this.onEmployerSelected}
                    hidden={!showRemoved}
                    onEmployerRestore={this.onEmployerRestore}
                    canChange={canChange}
                />
            </Block>
        );
    }

    title = (header: string) => {
        const showButtons = !isNewlyCreated(this.state.employerId);
        const canAccess = showButtons && can(sessionFunc.ACCESS_EDIT, this.props.access);

        return (
            <div className={styles.title}>
                {header}
                {showButtons && (
                    <PlanrButton type="secondary" size="small" onClick={this.showPremiums}>
                        Назначенные премии
                    </PlanrButton>
                )}
                {showButtons && (
                    <PlanrButton type="secondary" size="small" onClick={this.showMaterials}>
                        Закрепленный инвентарь
                    </PlanrButton>
                )}
                {canAccess && (
                    <PlanrButton type="secondary" size="small" onClick={this.showAccess}>
                        Права доступа
                    </PlanrButton>
                )}
            </div>
        );
    };

    printHide = () => this.setState({ showPrint: false });
    printShow = () => this.setState({ showPrint: true });

    showPremiums = () => this.setState({ showPremiums: true });
    hidePremiums = () => this.setState({ showPremiums: false });

    showMaterials = () => this.setState({ showMaterials: true });
    hideMaterials = () => this.setState({ showMaterials: false });

    showAccess = () => this.setState({ showAccess: true });
    hideAccess = () => this.setState({ showAccess: false });

    toggleVisibility = () => this.setState({ showRemoved: !this.state.showRemoved });

    onEmployerAdd = () => {
        this.onEmployerSelected(EMPTY_OBJECT_ID);
    };

    onEmployerSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.spending.employee.details(id) : routes.spending.employee.path;

        history.push(url);
    };

    onEmployerRestore = async (id: string = "") => {
        const user = this.props.store.removed.asMap[id];
        if (user) {
            const ok = await user.restore();
            if (ok) {
                this.loadList();
                this.loadRemovedList();
            }
        }
    };

    onEmployerRemove = async (id: string = "") => {
        const user = this.props.store.list.asMap[id];
        if (user) {
            const ok = await user.delete();
            if (ok) {
                this.onEmployerRemoved();
            }
        }
    };

    onEmployerSaved = async (id: string) => {
        this.onEmployerSelected(id);
        await this.loadList();
    };

    onEmployerRemoved = () => {
        const { history } = this.props;

        this.loadList();
        this.loadRemovedList();

        const url = routes.spending.employee.path;
        history.push(url);
    };

    loadList = () => this.props.store.list.load();

    loadRemovedList = () => this.props.store.removed.load();

    loadDetails = async (id: string) => {
        await this.props.store.details.load(id);
        this.props.store.premiums.load(id);
        this.props.store.loadSpendings(id);
    };

    setFunctionality = (roleId: string, functionality: string, state: boolean) =>
        this.props.store.details.setFunctionality(functionality, state);
}

export default observer(EmployeePage);

function getSelectedEmployerId(pathname: string) {
    const match = matchPath<EmployeeParams>(pathname, routes.spending.employee.details());

    return match && match.params ? match.params.employerId : "";
}

interface EmployeePageProps {
    baseUrl: string;
    store: EmployeeStoreType;
    access: string[];
    materials: MaterialValuesStoreType;
    history: History;
    session: SessionType;
    location: Location;
}

interface EmployeeParams {
    employerId: string;
}

interface EmployeePageState extends EmployeeParams {
    showRemoved: boolean;
    showPremiums: boolean;
    showMaterials: boolean;
    showAccess: boolean;
    itemPrint: OptionItem;
    showPrint: boolean;
    loading: boolean;
    loadingPrint: boolean;
}

const StatusFilterHeader = observer(
    class extends React.Component<StatusFilterHeaderProps> {
        render() {
            const { width, value, currentOptions } = this.props;

            const option = currentOptions[value];
            return (
                <SimpleSelect
                    className={`${Classes.FILL} planr-default-input`}
                    filterable={false}
                    items={Object.values(currentOptions)}
                    itemRenderer={renderSingleOption}
                    onItemSelect={this.onItemSelect}
                >
                    <DefaultSelectedOption
                        option={option}
                        empty={!!Constants.employerDocumentType.values.length}
                        style={{ overflow: "hidden", maxWidth: `${width - 3}px` }}
                    />
                </SimpleSelect>
            );
        }

        onItemSelect = (item: SelectItem) => this.props.onChange(item);
    }
);

interface StatusFilterHeaderProps {
    value: string;
    onChange: (value: any) => void;
    currentOptions: { [k: string]: any };
    width: number;
    filter?: string;
}
