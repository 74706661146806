import React from "react";
import { Formik, Form } from "formik";
import styles from "./OrderTypeDetails.module.scss";
import OrderTypeSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { OrderTypeType, fields } from "../../models/order-type";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";

const schema = OrderTypeSchema();

class OrderTypeDetailsBase extends React.PureComponent<OrderTypeDetailsProps> {
    private descriptionArea: HTMLTextAreaElement | null = null;

    private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.descriptionArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.descriptionArea);
    };

    public componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    render() {
        const { orderType, onSaved, onRemoved, canChange } = this.props;

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(orderType)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await orderType.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            if (!canChange) {
                                return;
                            }

                            formProps.setSubmitting(true);
                            const success = await orderType.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.fullName}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.description}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.descriptionAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`тип договора ${orderType.name}`}
                                                isNewlyCreated={orderType.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const OrderTypeDetails = observer(OrderTypeDetailsBase);

interface OrderTypeDetailsProps {
    orderType: OrderTypeType;
    onSaved: () => void;
    onRemoved: () => void;
    canChange: boolean;
}

function getFormValues(orderType: OrderTypeType) {
    return {
        ...getSnapshot(orderType),
    };
}
