import React from "react";
import { MessageStoreType, MessageType } from "modules/main/models/mesages-store";
import { observer } from "mobx-react";
import styles from "./MessagesWidget.module.scss";
import { texts } from "modules/common/texts";
import InfiniteScroll from "react-infinite-scroller";
import { OrderRelativeMessage } from "./OrderRelativeMessage";
import { KnowledgeBaseFileUploadedMessage } from "./KnowledgeBaseFileUploadedMessage";
import { SimpleMessage } from "./SimpleMessage";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { PlanrButton } from "modules/common/components/planr/button/Button";

class Widget extends React.Component<MessagesWidgetProps, MessagesWidgetState> {
    scroller = React.createRef<HTMLDivElement>();
    timer = 0;

    constructor(props: MessagesWidgetProps) {
        super(props);

        this.state = { collapsed: false, selection: {} };
    }

    componentDidMount() {
        const { store } = this.props;

        if (store.isEmpty) {
            store.loadNext();
        }

        this.timer = setInterval(store.refresh, 60 * 1000) as any;
        store.loadCount();
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    }

    render() {
        const { store, onOpen, isDialog } = this.props;
        const { selection } = this.state;

        const selected = Object.keys(selection).filter((id) => !!selection[id]);

        return (
            <div className={isDialog ? styles.widgetDialog : styles.widget}>
                <div className="planr-block-header flat-mode">
                    Уведомления <span className="unreaded">{store.unreadCount}</span>
                    {isDialog && (
                        <PlanrButton
                            type="secondary"
                            size="small"
                            style={{
                                position: "absolute",
                                right: "95px",
                                height: "25px",
                            }}
                            onClick={() => {
                                let newObj: { [key: string]: any } = {};
                                store.items.forEach((item) => {
                                    newObj[item.id] = true;
                                });

                                if (selected.length === store.items.length) {
                                    this.setState({
                                        selection: {},
                                    });
                                } else {
                                    this.setState({
                                        selection: newObj,
                                    });
                                }
                            }}
                        >
                            Выделить все
                        </PlanrButton>
                    )}
                    {selected.length > 0 && (
                        <>
                            <RemoveConfirmation<string[]>
                                what={confirmationLabel}
                                render={({ confirmRemoving }) => (
                                    <PlanrButton
                                        type="secondary"
                                        icon="general-trash"
                                        size="small"
                                        round={true}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            confirmRemoving(selected);
                                        }}
                                        style={{
                                            position: "absolute",
                                            right: isDialog ? "15px" : "17px",
                                            height: "25px",
                                            width: "25px",
                                        }}
                                    />
                                )}
                                onConfirmed={this.removeSelected}
                            />
                            <PlanrButton
                                type="secondary"
                                icon="general-eye"
                                size="small"
                                round={true}
                                onClick={async () => {
                                    await store.setRead(selected);
                                    this.setState({
                                        selection: {},
                                    });
                                }}
                                style={{
                                    position: "absolute",
                                    right: "55px",
                                    height: "25px",
                                    visibility: isDialog ? "visible" : "hidden",
                                    width: "25px",
                                }}
                            />
                        </>
                    )}
                    {/* {!selected.length && <Caret collapsed={collapsed} />} */}
                    {onOpen && (
                        <PlanrButton
                            size="small"
                            type="lightblueish"
                            onClick={onOpen}
                            style={{
                                position: "absolute",
                                right: "55px",
                                height: "25px",
                                width: "160px",
                            }}
                        >
                            Посмотреть все
                        </PlanrButton>
                    )}
                </div>
                <div className={styles.scroller} ref={this.scroller}>
                    {/* <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                        <div className="collapse"> */}
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={store.loadNext}
                        hasMore={store.hasMore}
                        useWindow={false}
                        initialLoad={false}
                        threshold={50}
                        getScrollParent={this.getScrollParent}
                    >
                        {store.items.map((message) => this.message(message))}
                    </InfiniteScroll>

                    {store.isEmpty && <div className={styles.noData}>{texts.noData}</div>}
                    {/* </div>
                    </Collapse> */}
                </div>
            </div>
        );
    }

    removeSelected = async (selected: string[]) => {
        const ok = await this.props.store.remove(selected);
        if (ok) {
            this.setState({ selection: {} });
        }
    };

    getScrollParent = () => this.scroller.current;

    message = (message: MessageType) => {
        const { selection } = this.state;

        const onSelect = (v: boolean) => this.onSelected(message.id, v);
        const props = {
            message,
            key: message.id,
            markAsRead: this.markAsRead,
            selected: !!selection[message.id],
            onSelect,
        };

        switch (message.type) {
            case "OrderRelativeMessage":
                return <OrderRelativeMessage {...props} />;
            case "KnowledgeBaseFileUploadedMessage":
                return <KnowledgeBaseFileUploadedMessage {...props} />;
            default:
                return <SimpleMessage {...props} />;
        }
    };

    markAsRead = (message: MessageType) => this.props.store.markAsRead(message);

    onToggleCollapse = () => this.setState({ collapsed: !this.state.collapsed });

    onSelected = (id: string, selected: boolean) =>
        this.setState({
            selection: {
                ...this.state.selection,
                [id]: selected,
            },
        });
}

const confirmationLabel = () => "уведомления";

export const MessagesWidget = observer(Widget);

interface MessagesWidgetProps {
    store: MessageStoreType;
    onOpen?: () => void;
    isDialog?: boolean;
}

interface MessagesWidgetState {
    collapsed: boolean;
    selection: TStringMap<boolean>;
}
