import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
import { StagesList } from "./StagesList";
import styles from "./Stages.module.scss";
import { observer } from "mobx-react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { DraggableDialog } from "modules/common/components/dialog/DraggableDialog";
import { Classes } from "@blueprintjs/core";
import { ActualPayment } from "./ActualPayment";
import portal from "../portal";
import { ActualOrderPaymentDetailsType, OrderStagesStoreType } from "modules/orders-manage/models/order-stages-store";
import { formatMoney } from "modules/common/components/money/Money";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { OrderFinancesStoreType } from "modules/orders-manage/models/order-finances-store";
import { OrderFileSnapshotType } from "modules/orders-manage/models/order-file";
import { OrderSummaryType } from "modules/orders-manage/models/order";

export const Stages: React.FC<StagesProps> = observer(({ store, readonly, financeStore, additions, details }) => {
    const { unmatchedActualPayments, linkerIsvisible } = store;
    const buttonRef = React.useRef<HTMLButtonElement>();
    const point = useRef<{ left?: number; top?: number }>({ left: undefined, top: undefined });

    const onDragEnd = useCallback(
        (result: DropResult) => {
            const planPayment = store.planMap[result.destination?.droppableId ?? ""];
            const actualPayment = store.actualMap[result.draggableId];
            if (planPayment && actualPayment && store.linkPayments(actualPayment.guid, planPayment.guid)) {
                store.saveLinks();
            }
        },
        [store]
    );

    const openMatcher = useCallback(() => {
        const rect = buttonRef.current?.getBoundingClientRect();
        point.current = {
            top: rect?.top,
            left: rect?.left,
        };
        store.showLinker();
    }, [store]);

    const unmatcehdSum = unmatchedActualPayments.reduce((sum, payment) => sum + payment.sum, 0);
    const unmatcehdSumLabel = unmatcehdSum > 0 ? <PlanrTag type="neutral">{formatMoney(unmatcehdSum)}</PlanrTag> : null;
    //   const unmatchedPaymentsAble = unmatchedActualPayments.length === 0 && store.planPayments.length > 0;
    return (
        <div className={styles.stages}>
            <DragDropContext onDragEnd={onDragEnd}>
                {!readonly && (
                    <PlanrButton
                        innerRef={buttonRef}
                        type="secondary"
                        icon="general-plus-big"
                        onClick={openMatcher}
                        size="small"
                        disabled={!!store.activeRow || unmatchedActualPayments.length === 0}
                    >
                        Распределить оплаты {unmatcehdSumLabel}
                    </PlanrButton>
                )}
                {linkerIsvisible && (
                    <DraggableDialog
                        isOpen={true}
                        onClose={store.closeLinker}
                        width={500}
                        height={600}
                        initialY={point.current.top}
                        initialX={point.current.left}
                        title="Фактические платежи"
                    >
                        <div className={Classes.DIALOG_BODY} style={{ overflow: "hidden" }}>
                            <UnmatchedActualPayments payments={unmatchedActualPayments} />
                        </div>
                    </DraggableDialog>
                )}
                <StagesList
                    store={store}
                    readonly={readonly}
                    financeStore={financeStore}
                    additions={additions}
                    details={details}
                />{" "}
            </DragDropContext>
        </div>
    );
});

interface StagesProps {
    store: OrderStagesStoreType;
    readonly?: boolean;
    financeStore: OrderFinancesStoreType;
    additions: OrderFileSnapshotType[];
    details: OrderSummaryType;
}

const UnmatchedActualPayments = observer(({ payments }: { payments: ActualOrderPaymentDetailsType[] }) => {
    return (
        <Droppable droppableId="actual">
            {(provided) => {
                return (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ overflowY: "auto" }}>
                        <strong>Перетащите в соответствующий плановый платеж этапа</strong>
                        <br />
                        <br />
                        {payments.map((actualPayment, index) => {
                            return (
                                <Draggable key={actualPayment.guid} draggableId={actualPayment.guid} index={index}>
                                    {(draggable, snapshot) => {
                                        const child = (
                                            <ActualPayment
                                                key={actualPayment.guid}
                                                draggable={draggable}
                                                payment={actualPayment}
                                            />
                                        );
                                        return snapshot.isDragging ? ReactDOM.createPortal(child, portal) : child;
                                    }}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                );
            }}
        </Droppable>
    );
});
