import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { DictionaryLink } from "modules/common/models/dictionary-link";
import { nameof } from "modules/common/services/typescript";
import { TableSorter } from "modules/common/models/table-sorter";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { sumBy, range } from "lodash";
import {
    OverheadType,
    initialState as emptyOverheadType,
} from "modules/dictionaries/overhead-types/models/overhead-type";
import { SpendingsListRow } from "modules/expenses/summary/models/spending-list-row";
import { MoneyInfoForPeriod } from "modules/expenses/model";
import { BaseEntity, IdEntity } from "modules/common/models/entity";
import { toJsonHard } from "modules/common/services/mobx/serialize";

export const OverheadSpending = types
    .compose(
        BaseEntity,
        types.model({
            date: types.string,
            sortableDate: types.string,
            sum: types.number,
            day: types.string,
            month: types.string,
            year: types.number,
            overheadType: DictionaryLink,
        })
    )
    .named("OverheadSpending");

const PlanOverheadSpending = types
    .compose(
        BaseEntity,
        types.model({
            sum: types.number,
            month: types.number,
            year: types.number,
            overheadType: DictionaryLink,
        })
    )
    .named("PlanOverheadSpending");

const OverheadSpendingsByMonth = types
    .compose(
        IdEntity,
        types.model({
            actualSum: types.number,
            planSum: types.number,
            actual: types.array(SpendingsListRow),
            plan: types.array(SpendingsListRow),
        })
    )
    .named("OverheadSpendingsByMonth");

const OverheadSpendingsByYear = types
    .model({
        months: types.array(OverheadSpendingsByMonth),
        overheadType: DictionaryLink,
    })
    .views((self) => ({
        get overheadTypeName() {
            return self.overheadType.name;
        },

        get totalActualSumm() {
            return self.months.length > 0 ? self.months[self.months.length - 1].actualSum : 0;
        },

        get totalPlanSumm() {
            return self.months.length > 0 ? self.months[self.months.length - 1].planSum : 0;
        },
    }))
    .actions((self) => ({
        toData() {
            return {
                ...getSnapshot(self),
                overheadTypeName: self.overheadTypeName,
            };
        },
    }))
    .named("OverheadSpendingsByYear");

export const OverheadSpendings = types
    .compose(
        Notificator,
        Transport,
        types.model({
            year: types.string,
            summary: types.array(MoneyInfoForPeriod),
            detailed: types.array(OverheadSpendingsByYear),
            sorter: TableSorter,
            details: OverheadType,
            loading: types.boolean,
        })
    )
    .actions((self) => ({
        copyFrom: flow(function* (model: CopyPlan) {
            try {
                const data: OverheadSpendingsByYearSnapshotType[] = yield self.transport.post<any>(
                    apiUrls.spendings.overhead.copy,
                    model
                );

                const snapshot = toJsonHard(getSnapshot(self.detailed));

                data.forEach((src) => {
                    const dst = snapshot.find((r) => r.overheadType.id === src.overheadType.id);
                    if (dst) {
                        dst.months = src.months;
                    }
                });

                applySnapshot(self.detailed, snapshot);

                const summary = self.summary.find((s) => s.period === self.year);
                if (summary) {
                    const planSum = sumBy(self.detailed, (r) => r.totalPlanSumm);
                    summary.planSum = planSum;
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        delete: flow(function* (overheadType: OverheadSpendingsByYearSnapshotType) {
            const model = OverheadType.create(
                { comment: "", created: "", ...overheadType.overheadType },
                {
                    http: self.transport,
                    notificator: self.notify,
                }
            );

            const success = yield model.delete();
            if (success) {
                const item = self.detailed.find((i) => i.overheadType.id === overheadType.overheadType.id);
                if (item) {
                    self.detailed.remove(item);
                }
            }

            return success;
        }),
    }))
    .actions((self) => ({
        setSum: flow(function* (id: string, sum: number) {
            try {
                let unit: OverheadSpendingsByMonthSnapshotType | undefined;

                // use snapshot to trigger view reaction
                const detailedDnapshot = toJsonHard(getSnapshot(self.detailed));

                const row = detailedDnapshot.find((s) => {
                    const month = s.months.find((m) => m.id === id);
                    if (month) {
                        unit = month;
                    }
                    return !!month;
                });

                if (row && unit) {
                    const model: SavePlan = {
                        month: row.months.indexOf(unit) + 1,
                        sum: sum,
                        typeId: row.overheadType.id,
                    };

                    const data: PlanOverheadSpendingSnapshotType = yield self.transport.post<any>(
                        apiUrls.spendings.overhead.plan,
                        {
                            ...model,
                            year: parseInt(self.year, 10),
                        }
                    );

                    const diff = data.sum - row.months[model.month - 1].planSum;

                    row.months[row.months.length - 1].planSum += diff;
                    unit.planSum = data.sum;

                    applySnapshot(self.detailed, detailedDnapshot);

                    const summary = diff !== 0 ? self.summary.find((s) => s.period === self.year) : undefined;
                    if (summary) {
                        summary.planSum += diff;
                    }

                    return true;
                }

                return false;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        copyFromMonth(from: number, to: number) {
            const toYear = parseInt(self.year, 10);
            let fromYear = toYear;

            if (from <= 0) {
                from = 12;
                fromYear--;
            }

            return self.copyFrom({
                from: { month: from, year: fromYear },
                to: { month: to, year: toYear },
            });
        },

        copyFromYear(month: number) {
            const toYear = parseInt(self.year, 10);
            const fromYear = toYear - 1;

            return self.copyFrom({
                from: { month, year: fromYear },
                to: { month, year: toYear },
            });
        },
    }))
    .actions((self) => ({
        loadSumary: flow(function* () {
            try {
                const data: OverheadSpendingsSummarySnapshotType[] = yield self.transport.get<any>(
                    apiUrls.spendings.overhead.summary
                );
                applySnapshot(self.summary, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        loadDetailed: flow(function* () {
            try {
                if (!self.detailed.length) {
                    self.loading = true;
                }

                const data: OverheadSpendingsByYearSnapshotType[] = yield self.transport.get<any>(
                    apiUrls.spendings.overhead.detailed(self.year)
                );
                applySnapshot(self.detailed, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            } finally {
                self.loading = false;
            }
        }),
    }))
    .actions((self) => ({
        setYear(year: string, forse?: boolean) {
            if (self.year !== year || forse) {
                self.year = year;
                self.loading = true;
                self.loadDetailed();
            }
        },
    }))
    .views((self) => ({
        get totals() {
            if (self.detailed.length) {
                const iteratee = range(1, self.detailed[0].months.length + 1);

                const total: OverheadSpendingsByYearSnapshotType = {
                    overheadType: { name: "", id: "" },
                    months: iteratee.map(() => ({
                        id: "",
                        actualSum: 0,
                        planSum: 0,
                        actual: [],
                        plan: [],
                    })),
                };

                self.detailed.forEach((row) => {
                    iteratee.forEach((i) => {
                        total.months[i - 1].actualSum += row.months[i - 1].actualSum;
                        total.months[i - 1].planSum += row.months[i - 1].planSum;
                    });
                });

                return total;
            } else {
                return null;
            }
        },
    }))
    .views((self) => ({
        get totalRow() {
            if (self.totals) {
                const total = toJsonHard(self.totals);

                // % column
                total.months.push({
                    id: "",
                    actualSum: undefined,
                    planSum: undefined,
                    rows: [],
                } as any);

                return [total];
            }

            return [];
        },

        get isEmpty() {
            return !self.detailed.length;
        },
    }))
    .views((self) => ({
        get data() {
            const actualYear = self.totals ? self.totals.months[self.totals.months.length - 1].actualSum : 0;
            const planYear = self.totals ? self.totals.months[self.totals.months.length - 1].planSum : 0;

            return self.detailed.map((r) => {
                const row = toJsonHard(r.toData());

                // % column
                row.months.push({
                    actualSum: actualYear > 0 ? (r.totalActualSumm / actualYear) * 100 : 0,
                    planSum: planYear > 0 ? (r.totalPlanSumm / planYear) * 100 : 0,
                    id: "",
                    actual: [],
                    plan: [],
                });

                return row;
            });
        },
    }))
    .named("OverheadSpendings");

export type OverheadSpendingsSummarySnapshotType = typeof MoneyInfoForPeriod.SnapshotType;
export type OverheadSpendingsSummaryType = typeof MoneyInfoForPeriod.Type;
export type OverheadSpendingsType = typeof OverheadSpendings.Type;
export type OverheadSpendingsSnapshotType = typeof OverheadSpendings.SnapshotType;
export type OverheadSpendingsByYearType = typeof OverheadSpendingsByYear.Type;
export type OverheadSpendingsByYearSnapshotType = typeof OverheadSpendingsByYear.SnapshotType;
export type OverheadSpendingsByMonthType = typeof OverheadSpendingsByMonth.Type;
export type OverheadSpendingsByMonthSnapshotType = typeof OverheadSpendingsByMonth.SnapshotType;
export type PlanOverheadSpendingType = typeof PlanOverheadSpending.Type;
export type PlanOverheadSpendingSnapshotType = typeof PlanOverheadSpending.SnapshotType;

export const fields = {
    overheadTypeName: nameof((s: OverheadSpendingsByYearType) => s.overheadTypeName) as string,
    totalSumm: nameof((s: OverheadSpendingsByYearType) => s.totalActualSumm) as string,
};

const sortStorage = getSortOption(OverheadSpendings.name);

export const initialState = (): OverheadSpendingsSnapshotType => {
    const options = sortStorage({ column: "months[13].actualSum", asc: false });
    const stop = new Date().getFullYear();

    return {
        summary: range(stop - 3, stop).map((year) => ({
            actualSum: 0,
            loading: true,
            planSum: 0,
            period: year.toString(),
        })),
        detailed: [],
        details: emptyOverheadType(),
        sorter: {
            id: OverheadSpendings.name,
            tableName: OverheadSpendings.name,
            column: options.column,
            asc: options.asc,
        },
        year: new Date().getFullYear().toString(),
        loading: false,
    };
};

interface SavePlan {
    month: number;
    typeId: string;
    sum: number;
}

interface CopyPlan {
    from: { year: number; month: number };
    to: { year: number; month: number };
}
