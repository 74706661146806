import React from "react";
import { SettingsStoreType, ApplicationSettingsType, fields, ACCEPT } from "../models/settings";
import { observer } from "mobx-react";
import styles from "./SettingsPage.module.scss";
import { Page } from "modules/common/components/page/Page";
import { getSnapshot } from "mobx-state-tree";
import { Tabs, Tab, Collapse } from "@blueprintjs/core";
import { SettingsSchema } from "../validation";
import { Formik, Form, FormikProps } from "formik";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { eat } from "modules/common/services/typescript";
import { KpiSettings } from "./KpiSettings";
import { GeneralSettings } from "./CommonSettings";
import { Caret } from "modules/common/components/collapse/Caret";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { SystemSettings } from "./SystemSettings";
import { SystemTemplate } from "./SystemTemplate";
import { Constants } from "modules/root/models/constants";

const TabList = {
    values: "values",
    techTask: "tech-task",
    infoCertSheet: "iul",
};

const schema = SettingsSchema();

const collaboraPortal: HTMLDivElement = document.getElementById("CollaboraLoader-portal") as any;

class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {
    private formProps: FormikProps<any> | null = null;

    constructor(props: any) {
        super(props);

        this.state = { tab: TabList.values };
    }

    async componentDidMount() {
        await this.props.store.load();
        this.formProps && this.formProps.validateForm();
    }

    render() {
        const { store } = this.props;
        const initial = getFormValues(store.model);
        const kpiCollapsed = !store.collapser.opened.get("kpi");
        const generalCollapsed = !store.collapser.opened.get("general");
        const systemCollapsed = !store.collapser.opened.get("system");
        const buttonsVisible = this.state.tab !== TabList.techTask;

        return (
            <Page className={styles.page}>
                <Formik
                    initialValues={initial}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        const succsessfull = await store.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                        }
                    }}
                >
                    {(formProps) => {
                        this.formProps = formProps;

                        return (
                            <Form autoComplete="off">
                                <Tabs
                                    className={`${styles.tabs} planr-tabs-navigation`}
                                    id="SettingsTabsPage"
                                    selectedTabId={this.state.tab}
                                    onChange={this.tabSelected}
                                    renderActiveTabPanelOnly={false}
                                >
                                    <Tab
                                        id={TabList.values}
                                        title={"Настройки приложения"}
                                        panel={
                                            <div>
                                                <div className="planr-tools">
                                                    <CollapseExpandAll onChange={this.collapseAll} />
                                                </div>
                                                <div className={styles.content}>
                                                    <h1
                                                        className="planr-block-header collapser"
                                                        onClick={this.toggleGeneral}
                                                    >
                                                        Общие данные
                                                        <Caret collapsed={generalCollapsed} />
                                                    </h1>
                                                    <Collapse isOpen={!generalCollapsed} keepChildrenMounted={true}>
                                                        <div className="collapse">
                                                            <GeneralSettings schema={schema} />
                                                        </div>
                                                    </Collapse>

                                                    <h1
                                                        className="planr-block-header collapser"
                                                        onClick={this.toggleSystem}
                                                    >
                                                        Системные настройки
                                                        <Caret collapsed={systemCollapsed} />
                                                    </h1>
                                                    <Collapse isOpen={!systemCollapsed} keepChildrenMounted={true}>
                                                        <div className="collapse">
                                                            <SystemSettings schema={schema} />
                                                        </div>
                                                    </Collapse>

                                                    <h1
                                                        className="planr-block-header collapser"
                                                        onClick={this.toggleKpi}
                                                    >
                                                        Показатели KPI для премий (%)
                                                        <Caret collapsed={kpiCollapsed} />
                                                    </h1>
                                                    <Collapse isOpen={!kpiCollapsed} keepChildrenMounted={true}>
                                                        <div className="collapse">
                                                            <KpiSettings schema={schema} />
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        }
                                    />

                                    <Tab
                                        id={TabList.techTask}
                                        title={"Шаблон ТЗ проекта"}
                                        panel={
                                            store.editorUrl ? (
                                                <SystemTemplate
                                                    store={store}
                                                    anchors={store.techTaskAnchors}
                                                    container={collaboraPortal}
                                                    accept={ACCEPT}
                                                    templateId={Constants.settingsTechnicalTaskTemplateId}
                                                />
                                            ) : undefined
                                        }
                                    />

                                    <Tab
                                        id={TabList.infoCertSheet}
                                        title={"Шаблон ИУЛ проекта"}
                                        panel={
                                            store.editorUrl ? (
                                                <SystemTemplate
                                                    store={store}
                                                    anchors={store.contentTaskAnchors}
                                                    container={collaboraPortal}
                                                    accept={ACCEPT}
                                                    templateId={
                                                        Constants.settingsInformationAndCertificationSheetTemplateId
                                                    }
                                                />
                                            ) : undefined
                                        }
                                    />
                                </Tabs>

                                {buttonsVisible && (
                                    <div className={styles.buttons}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what={""}
                                            isNewlyCreated={true}
                                            submitOnDirty={true}
                                            onRemove={eat}
                                        />
                                    </div>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Page>
        );
    }

    collapseAll = (state: boolean) => this.props.collapser.setAll(state);

    toggleKpi = () => this.props.store.collapser.toggle("kpi");

    toggleGeneral = () => this.props.store.collapser.toggle("general");

    toggleSystem = () => this.props.store.collapser.toggle("system");

    tabSelected = (tab: string) => this.setState({ tab });
}

export default observer(SettingsPage);

interface SettingsPageProps {
    store: SettingsStoreType;
    collapser: SectionCollapserType;
}

interface SettingsPageState {
    tab: string;
}

function getFormValues(settings: ApplicationSettingsType) {
    return {
        ...getSnapshot(settings),
        [fields.phoneNumber]: settings.formattedPhone,
    };
}
