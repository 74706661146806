import { applySnapshot, getParent, getSnapshot, Instance, SnapshotOut, types } from "mobx-state-tree";
import { DATE_TIME_FORMAT, EMPTY_OBJECT_ID, MAX_INT } from "modules/common/constants";
import { Notificator } from "modules/common/models/notificator";
import { Transport } from "modules/common/models/transport";
import { apiUrls } from "modules/common/services/communication/urls";
import moment from "moment";
import { flow } from "modules/common/models/flow";
import { Duration, EventDate, EventDateType } from "./order-events";
import { formatDate } from "modules/common/services/formatting/date";
import { nameof } from "modules/common/services/typescript";
import { texts } from "modules/common/texts";
import { emptyCompletion, OrderCompletion } from "./order-completions";
import { delay } from "modules/common/services/app";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import {
    ActualOrderPayment,
    ActualOrderPaymentSnapshotType,
    PlanOrderPayment,
    PlanOrderPaymentSnapshotType,
} from "./payment";
import { sortBy } from "lodash";
import { emptyOrderExpertise, OrderExpertise } from "./order-expertise";
import { emptyConsignment, OrderConsignment } from "./order-consignments";
import { emptyOrderSentExpertise, OrderSentExpertise } from "./order-sent-expertise";

export const StageItem = types
    .model({
        guid: types.string,
        name: types.string,
    })
    .named("StageItem");
export type StageItemType = Instance<typeof StageItem>;

const OrderConsignmentRowBase = types.compose(
    OrderConsignment,
    types.model({
        newGuid: types.string,
    })
);

const OrderConsignmentRow = types
    .compose(
        OrderConsignmentRowBase,
        types.model({
            removed: types.optional(types.boolean, false),
            active: types.optional(types.boolean, false),
            clone: types.maybeNull(OrderConsignmentRowBase),
        })
    )
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};

            if (!self.number) {
                errors.number = "Не указан номер";
            }

            if (!self.signDate) {
                errors.date = "Не указана дата";
            }
            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        remove() {
            self.removed = true;
        },

        activate() {
            if (!self.clone) {
                self.clone = OrderConsignmentRowBase.create(getSnapshot(self));
            }
            self.active = true;
        },

        deactivate() {
            self.active = false;
            self.clone = null;
        },

        getSaveModel() {
            return {
                ...getSnapshot(self),
                fileId: self.file ? self.file.fileId : null,
            };
        },

        linkToStage(from: string, to: string) {
            if (to !== self.stageGuid) {
                if (to || from === self.stageGuid) {
                    if (!self.clone) {
                        self.clone = OrderConsignmentRowBase.create(getSnapshot(self));
                    }
                    self.stageGuid = to;
                    return true;
                }
            }
            return false;
        },
    }))
    .named("OrderConsignmentRow");

export type OrderConsignmentRowType = Instance<typeof OrderConsignmentRow>;

const OrderCompletionRowBase = types.compose(
    OrderCompletion,
    types.model({
        newGuid: types.string,
    })
);

const OrderCompletionRow = types
    .compose(
        OrderCompletionRowBase,
        types.model({
            removed: types.optional(types.boolean, false),
            active: types.optional(types.boolean, false),
            clone: types.maybeNull(OrderCompletionRowBase),
        })
    )
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};

            if (!self.name) {
                errors.name = "Не указан номер";
            }

            if (!self.sum) {
                errors.sum = "Не указана сумма";
            }

            if (!self.date) {
                errors.date = "Не указана дата";
            }

            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        remove() {
            self.removed = true;
        },

        activate() {
            if (!self.clone) {
                self.clone = OrderCompletionRowBase.create(getSnapshot(self));
            }
            self.active = true;
        },

        deactivate() {
            self.active = false;
            self.clone = null;
        },

        getSaveModel() {
            return {
                ...getSnapshot(self),
                fileId: self.file ? self.file.fileId : null,
            };
        },

        linkToStage(from: string, to: string) {
            if (to !== self.stageGuid) {
                if (to || from === self.stageGuid) {
                    if (!self.clone) {
                        self.clone = OrderCompletionRowBase.create(getSnapshot(self));
                    }
                    self.stageGuid = to;
                    return true;
                }
            }
            return false;
        },
    }))
    .named("OrderCompletionRow");

export type OrderCompletionRowType = Instance<typeof OrderCompletionRow>;

const OrderExpertiseRowBase = types.compose(
    OrderExpertise,
    types.model({
        newGuid: types.string,
    })
);

const OrderExpertiseRow = types
    .compose(
        OrderExpertiseRowBase,
        types.model({
            removed: types.optional(types.boolean, false),
            active: types.optional(types.boolean, false),
            clone: types.maybeNull(OrderExpertiseRowBase),
        })
    )
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};
            if (!self.number) {
                errors.number = "Не указан номер";
            }
            if (!self.date) {
                errors.date = "Не указана дата";
            }

            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        remove() {
            self.removed = true;
        },

        activate() {
            if (!self.clone) {
                self.clone = OrderExpertiseRowBase.create(getSnapshot(self));
            }
            self.active = true;
        },

        deactivate() {
            self.active = false;
            self.clone = null;
        },

        getSaveModel() {
            return {
                ...getSnapshot(self),
                fileId: self.file ? self.file.fileId : null,
            };
        },

        linkToStage(from: string, to: string) {
            if (to !== self.stageGuid) {
                if (to || from === self.stageGuid) {
                    if (!self.clone) {
                        self.clone = OrderExpertiseRowBase.create(getSnapshot(self));
                    }
                    self.stageGuid = to;
                    return true;
                }
            }
            return false;
        },
    }))
    .named("OrderExpertiseRow");

export type OrderExpertiseRowType = Instance<typeof OrderExpertiseRow>;

const OrderSentExpertiseRowBase = types.compose(
    OrderSentExpertise,
    types.model({
        newGuid: types.string,
    })
);

const OrderSentExpertiseRow = types
    .compose(
        OrderSentExpertiseRowBase,
        types.model({
            removed: types.optional(types.boolean, false),
            active: types.optional(types.boolean, false),
            clone: types.maybeNull(OrderSentExpertiseRowBase),
        })
    )
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};

            if (!self.date) {
                errors.date = "Не указана дата";
            }

            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        remove() {
            self.removed = true;
        },

        activate() {
            if (!self.clone) {
                self.clone = OrderSentExpertiseRowBase.create(getSnapshot(self));
            }
            self.active = true;
        },

        deactivate() {
            self.active = false;
            self.clone = null;
        },

        getSaveModel() {
            return {
                ...getSnapshot(self),
                fileId: self.file ? self.file.fileId : null,
            };
        },

        linkToStage(from: string, to: string) {
            if (to !== self.stageGuid) {
                if (to || from === self.stageGuid) {
                    if (!self.clone) {
                        self.clone = OrderSentExpertiseRowBase.create(getSnapshot(self));
                    }
                    self.stageGuid = to;
                    return true;
                }
            }
            return false;
        },
    }))
    .named("OrderSentExpertiseRow");

export type OrderSentExpertiseRowType = Instance<typeof OrderSentExpertiseRow>;

const ActualOrderPaymentDetails = types
    .compose(
        ActualOrderPayment,
        types.model({
            agent: types.string,
        })
    )
    .actions((self) => ({
        resortLinks(indexes: TStringMap<number>) {
            const links = self.planPaymentGuids.map((guid) => ({ guid, index: indexes[guid] ?? MAX_INT }));
            self.planPaymentGuids.replace(sortBy(links, (l) => l.index).map((l) => l.guid));
        },
    }));
export type ActualOrderPaymentDetailsType = Instance<typeof ActualOrderPaymentDetails>;

export const PlanOrderPaymentDetails = types
    .compose(
        PlanOrderPayment,
        types.model({
            newGuid: types.string,
            actualPaymentGuids: types.optional(types.array(types.string), []),
            eventDates: types.array(EventDate),
            dateDescription: types.string,
            isPaid: types.boolean, // backend data
            remainsSum: types.number,
        })
    )
    .views((self) => ({
        get looksToBePaid() {
            return !!self.concreteDate && !!self.type && self.actualPaymentGuids.length > 0;
        },
    }))
    .views((self) => ({
        get canBeLinked() {
            return !self.looksToBePaid;
        },
    }))
    .actions((self) => ({
        resortLinks(indexes: TStringMap<number>) {
            const links = self.actualPaymentGuids.map((guid) => ({ guid, index: indexes[guid] ?? MAX_INT }));
            self.actualPaymentGuids.replace(sortBy(links, (l) => l.index).map((l) => l.guid));
        },
    }))
    .actions((self) => ({
        addActualPayment(guid: string) {
            if (!self.actualPaymentGuids.includes(guid) && self.canBeLinked) {
                self.actualPaymentGuids.push(guid);
                return true;
            }

            return false;
        },

        removeActualPayment(guid: string) {
            return self.actualPaymentGuids.remove(guid);
        },

        setDate(value: EventedDateValue) {
            self.concreteDate = formatDate(value.date);
            applySnapshot(self.eventDates, value.events);
        },
    }));
export type PlanOrderPaymentDetailsType = Instance<typeof PlanOrderPaymentDetails>;

const PlanOrderPaymentDetailsRow = types
    .compose(
        PlanOrderPaymentDetails,
        types.model({
            removed: types.optional(types.boolean, false),
            active: types.optional(types.boolean, false),
            clone: types.maybeNull(PlanOrderPaymentDetails),
        })
    )
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};

            if (!self.name) {
                errors.name = "Не указан номер";
            }

            if (!self.sum) {
                errors.sum = "Не указана сумма";
            }

            if (!self.type && self.stageGuid) {
                errors.type = "Не указан тип";
            }

            const dateIsValid = !!self.concreteDate || self.eventDates.length > 0;
            if (!dateIsValid) {
                errors.date = "Не указана дата платежа";
            }

            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        remove() {
            self.removed = true;
        },

        activate() {
            if (!self.clone) {
                self.clone = PlanOrderPaymentDetails.create(getSnapshot(self));
            }
            self.active = true;
        },

        deactivate() {
            self.active = false;
            self.clone = null;
        },

        getSaveModel() {
            return getSnapshot(self);
        },

        setGuid(value: string) {
            if (!self.guid) {
                self.guid = value;
                self.newGuid = value;
            }
        },

        linkToStage(from: string, to: string, type: string) {
            if (to !== self.stageGuid || type !== self.type) {
                if (to || from === self.stageGuid) {
                    if (!self.clone) {
                        self.clone = PlanOrderPaymentDetails.create(getSnapshot(self));
                    }
                    self.type = type;
                    self.stageGuid = to;
                    return true;
                }
            }
            return false;
        },
    }));

export type PlanOrderPaymentDetailsRowType = Instance<typeof PlanOrderPaymentDetailsRow>;

export const OrderStage = types
    .model({
        orderId: types.string,
        guid: types.string,
        name: types.string,
        stageIsConsigned: types.boolean,
        sum: types.number,
        withoutSum: types.maybeNull(types.boolean),
        startDateText: types.string,
        start: types.maybeNull(types.string),
        stopDateText: types.string,
        stop: types.maybeNull(types.string),
        startPlanDate: types.maybeNull(types.string),
        stopPlanDate: types.maybeNull(types.string),
        duration: types.maybeNull(Duration),
        startEventDates: types.array(EventDate),
        stopEventDates: types.array(EventDate),
    })
    .views((self) => ({
        get startPlanDateAsDate() {
            return self.startPlanDate ? moment(self.startPlanDate, DATE_TIME_FORMAT).toDate() : null;
        },

        get stopPlanDateAsDate() {
            return self.stopPlanDate ? moment(self.stopPlanDate, DATE_TIME_FORMAT).toDate() : null;
        },
    }))
    .named("OrderStage");

const OrderStageRowBase = types.compose(
    OrderStage,
    types.model({
        active: types.optional(types.boolean, false),
        newGuid: types.string,
    })
);

export const OrderStageRow = types
    .compose(Notificator, Transport, OrderStageRowBase)
    .actions((self) => ({
        activate() {
            self.active = true;
        },

        deactivate() {
            self.active = false;
        },
    }))
    .views((self) => ({
        get validation() {
            const errors: TStringMap<string> = {};

            if (!self.name) {
                errors[fields.name] = "Не указано наименование";
            }

            if (!self.sum && !self.withoutSum) {
                errors[fields.sum] = "Не указана сумма";
            }

            const startIsValid = !!self.startPlanDate || self.startEventDates.length > 0;
            if (!startIsValid) {
                errors[fields.startDate] = "Не указан срок начала работ";
            }

            const stopIsValid =
                !!self.stopPlanDate ||
                (self.duration != null && self.duration.daysCount > 0) ||
                self.stopEventDates.length > 0;
            if (!stopIsValid) {
                errors[fields.stopDate] = "Не указан срок окончания работ";
            }

            return errors;
        },
    }))
    .views((self) => ({
        get isValid() {
            return Object.keys(self.validation).length === 0;
        },
    }))
    .actions((self) => ({
        setName(value: string) {
            self.name = value;
        },

        setConsignment(value: boolean) {
            self.stageIsConsigned = value;
        },

        setSum(sum: number) {
            self.sum = sum;
        },
        setWithoutSum(val: boolean) {
            self.withoutSum = val;
            self.sum = 0;
        },

        setStartPlanDate(value: EventedDateValue) {
            self.startPlanDate = formatDate(value.date);
            applySnapshot(self.startEventDates, value.events);
        },

        setStopPlanDate(value: EventedDateValue) {
            self.stopPlanDate = formatDate(value.date);

            if (value.days > 0 || value.workDaysOnly) {
                if (self.duration != null) {
                    applySnapshot(self.duration, {
                        daysCount: value.days,
                        workDays: value.workDaysOnly,
                    });
                } else {
                    self.duration = Duration.create({
                        daysCount: value.days,
                        workDays: value.workDaysOnly,
                    });
                }
            } else {
                self.duration = null;
            }

            applySnapshot(self.stopEventDates, value.events);
        },

        getSaveModel(
            payments: PlanOrderPaymentDetailsRowType[],
            removedPayments: string[],
            completions: OrderCompletionRowType[],
            removedCompletions: string[],
            consignments: OrderConsignmentRowType[],
            removedConsignments: string[],
            expertises: OrderExpertiseRowType[],
            removedExpertises: string[],
            sentExpertises: OrderSentExpertiseRowType[],
            removedSentExpertises: string[]
        ) {
            if (!self.isValid) {
                return null;
            }

            if (payments.some((p) => !p.isValid)) {
                return null;
            }
            if (completions.some((p) => !p.isValid)) {
                return null;
            }

            if (expertises.some((p) => !p.isValid)) {
                return null;
            }

            if (sentExpertises.some((p) => !p.isValid)) {
                return null;
            }

            if (consignments.some((p) => !p.isValid)) {
                return null;
            }

            return {
                stage: getSnapshot(self),
                savePayments: payments.map((p) => p.getSaveModel()),
                removePayments: removedPayments,
                saveCompletions: completions.map((c) => c.getSaveModel()),
                saveConsignments: consignments.map((c) => c.getSaveModel()),
                removedConsignments: removedConsignments,
                removeСompletions: removedCompletions,
                saveExpertises: expertises.map((e) => e.getSaveModel()),
                removeExpertises: removedExpertises,
                saveSentExpertises: sentExpertises.map((e) => e.getSaveModel()),
                removeSentExpertises: removedSentExpertises,
            };
        },
    }))

    .named("OrderStageRow");

export type OrderStageRowItem = {
    stage: OrderStageRowType;
    index: number;
};

export const OrderStagesStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            orderId: types.string,
            stages: types.array(OrderStageRow),
            clone: types.maybeNull(OrderStageRow),
            planPayments: types.array(PlanOrderPaymentDetailsRow),
            actualPayments: types.array(ActualOrderPaymentDetails),
            completions: types.array(OrderCompletionRow),
            expertises: types.array(OrderExpertiseRow),
            sentExpertises: types.array(OrderSentExpertiseRow),
            consignments: types.array(OrderConsignmentRow),
            linkerIsvisible: types.boolean,
        })
    )
    .views((self) => ({
        get incorrectPayments() {
            return checkError(getSnapshot(self.planPayments), getSnapshot(self.actualPayments));
        },

        get activeRow() {
            return self.stages.find((stage) => stage.active);
        },

        get planMap() {
            return self.planPayments.reduce((map, payment) => {
                map[payment.newGuid] = payment;
                return map;
            }, {} as TStringMap<PlanOrderPaymentDetailsRowType>);
        },

        get actualMap() {
            return self.actualPayments.reduce((map, payment) => {
                map[payment.guid] = payment;
                return map;
            }, {} as TStringMap<ActualOrderPaymentDetailsType>);
        },

        /** Порядковые номера фактических платежей */
        get actualIndexes() {
            return sortBy(self.actualPayments, (payment) => payment.sortableDate).reduce((map, payment, index) => {
                map[payment.guid] = index;
                return map;
            }, {} as TStringMap<number>);
        },

        /** Список фактических платежей, требующих распределение */
        get unmatchedActualPayments() {
            return self.actualPayments.filter((p) => !p.hasPlanMatch);
        },

        get completionMap() {
            return self.completions.reduce((map, comlpetion) => {
                map[comlpetion.newGuid] = comlpetion;
                return map;
            }, {} as TStringMap<OrderCompletionRowType>);
        },

        get consignmentMap() {
            return self.consignments.reduce((map, consignment) => {
                map[consignment.newGuid] = consignment;
                return map;
            }, {} as TStringMap<OrderConsignmentRowType>);
        },

        get expertiseMap() {
            return self.expertises.reduce((map, expertise) => {
                map[expertise.newGuid] = expertise;
                return map;
            }, {} as TStringMap<OrderExpertiseRowType>);
        },

        get sentExpertiseMap() {
            return self.sentExpertises.reduce((map, expertise) => {
                map[expertise.newGuid] = expertise;
                return map;
            }, {} as TStringMap<OrderSentExpertiseRowType>);
        },

        get stagePlanMap() {
            const result: any = {};

            self.planPayments.forEach((payment) => {
                if (payment.removed) {
                    return;
                }

                if (payment.stageGuid && payment.type) {
                    result[payment.stageGuid] = result[payment.stageGuid] ?? { Авансовый: [], Расчетный: [] };
                    result[payment.stageGuid][payment.type].push(payment);
                }
            });

            return result;
        },

        get stageCompletionMap() {
            const result: TStringMap<OrderCompletionRowType[]> = {};

            self.completions.forEach((completion) => {
                if (completion.removed) {
                    return;
                }

                if (completion.stageGuid) {
                    result[completion.stageGuid] = result[completion.stageGuid] ?? [];
                    result[completion.stageGuid].push(completion);
                }
            });

            return result;
        },
        get stageConsignmentMap() {
            const result: TStringMap<OrderConsignmentRowType[]> = {};

            self.consignments.forEach((consignment) => {
                if (consignment.removed) {
                    return;
                }

                if (consignment.stageGuid) {
                    result[consignment.stageGuid] = result[consignment.stageGuid] ?? [];
                    result[consignment.stageGuid].push(consignment);
                }
            });

            return result;
        },

        get stageExpertiseMap() {
            const result: TStringMap<OrderExpertiseRowType[]> = {};

            self.expertises.forEach((expertise) => {
                if (expertise.removed) {
                    return;
                }

                if (expertise.stageGuid) {
                    result[expertise.stageGuid] = result[expertise.stageGuid] ?? [];
                    result[expertise.stageGuid].push(expertise);
                }
            });

            return result;
        },

        get stageSentExpertiseMap() {
            const result: TStringMap<OrderSentExpertiseRowType[]> = {};

            self.sentExpertises.forEach((expertise) => {
                if (expertise.removed) {
                    return;
                }

                if (expertise.stageGuid) {
                    result[expertise.stageGuid] = result[expertise.stageGuid] ?? [];
                    result[expertise.stageGuid].push(expertise);
                }
            });

            return result;
        },

        get completionsSum() {
            return self.completions.reduce((sum, completion) => sum + completion.sum, 0);
        },

        get stagesSum() {
            return self.stages.reduce((sum, stage) => sum + stage.sum, 0);
        },

        get stageItems() {
            return self.stages.map((stage, index) => ({ stage, index } as OrderStageRowItem));
        },

        /** Сумма всех фактических выплат */
        get paidSum() {
            return self.actualPayments.reduce((sum, payment) => sum + payment.sum, 0);
        },

        /** Сумма всех плановых выплат */
        get planSum() {
            return self.planPayments.reduce((sum, payment) => sum + payment.sum, 0);
        },

        get canLinkExpertises() {
            return self.expertises.length > 0 && self.expertises.some((e) => !e.stageGuid);
        },

        get canLinkSentExpertises() {
            return self.sentExpertises.length > 0 && self.sentExpertises.some((e) => !e.stageGuid);
        },

        get canLinkPayments() {
            return self.planPayments.length > 0 && self.planPayments.some((e) => !e.stageGuid);
        },

        get canLinkCompletions() {
            return self.completions.length > 0 && self.completions.some((e) => !e.stageGuid);
        },

        get canLinkConsignments() {
            return self.consignments.length > 0 && self.consignments.some((e) => !e.stageGuid);
        },
    }))
    .actions((self) => ({
        showLinker() {
            if (!self.activeRow) {
                self.linkerIsvisible = true;
            }
        },
        paymentsMatcher() {
            let result: any[] = [];
            let actualMapClone = {} as TStringMap<ActualOrderPaymentDetailsType[]>;
            self.actualPayments.forEach((payment) => {
                payment.planPaymentGuids.forEach((guid) => {
                    if (!(guid in actualMapClone)) {
                        actualMapClone[guid] = [];
                    }
                    actualMapClone[guid].push(payment);
                });
            });

            self.planPayments.forEach((planPayment) => {
                let requiredSum = planPayment.sum;

                if (planPayment.guid in actualMapClone) {
                    actualMapClone[planPayment.guid].forEach((actPayment) => {
                        if (requiredSum > 0) {
                            let sum = actPayment.sum;
                            let take = requiredSum >= actPayment.sum ? actPayment.sum : requiredSum;
                            sum = take;

                            requiredSum -= take;
                            result.push({ guid: actPayment.guid, sum });
                        } else {
                            return;
                        }
                    });
                }
            });
            return result;
        },
        closeLinker() {
            self.linkerIsvisible = false;
        },

        buildMatchSaveModel() {
            const map: TStringMap<any> = {};
            self.actualPayments.forEach((payment: any) => {
                map[payment.guid] = { guid: payment.guid, planPaymentGuids: new Set() };
            });

            self.planPayments.forEach((payment: any) => {
                payment.actualPaymentGuids.forEach((guid: string) => {
                    map[guid].planPaymentGuids.add(payment.guid);
                });
            });

            return Object.values(map).map((payment: any) => {
                return {
                    ...payment,
                    planPaymentGuids: Array.from(payment.planPaymentGuids),
                };
            });
        },
    }))
    .actions((self) => ({
        load: flow(function* (orderId: string) {
            if (orderId === EMPTY_OBJECT_ID) {
                return;
            }

            self.orderId = orderId;

            try {
                const completions = yield self.transport.get<any>(apiUrls.orders.stages.comlpetions.list(orderId));
                applySnapshot(self.completions, treatCompletions(completions));

                const expertises = yield self.transport.get<any>(apiUrls.orders.stages.expertises.list(orderId));
                applySnapshot(self.expertises, treatExpertises(expertises));

                const sentExpertises = yield self.transport.get<any>(
                    apiUrls.orders.stages.sentExpertises.list(orderId)
                );
                applySnapshot(self.sentExpertises, treatSentExpertises(sentExpertises));

                const consignments = yield self.transport.get<any>(apiUrls.orders.stages.consignments.list(orderId));
                applySnapshot(self.consignments, treatConsignments(consignments));

                const stages = yield self.transport.get<any>(apiUrls.orders.stages.list(orderId));
                applySnapshot(self.stages, treatStages(stages));

                const payments = treatPayments(
                    yield self.transport.get<any>(apiUrls.orders.stages.payments.list(orderId))
                );
                applySnapshot(self.planPayments, payments.plan);
                applySnapshot(self.actualPayments, payments.actual);
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        saveStage: flow(function* (stageGuid: string) {
            if (self.orderId === EMPTY_OBJECT_ID) {
                return;
            }

            const stage = self.stages.find((s) => s.newGuid === stageGuid);
            if (!stage) {
                return;
            }

            const removedPayments = self.planPayments.filter((p) => p.removed && p.guid).map((p) => p.guid);
            const stagePayments = self.planPayments.filter((p) => p.stageGuid === stageGuid && !p.removed);
            const removedConsignments = self.consignments.filter((p) => p.removed && p.guid).map((p) => p.guid);
            const stageConsignments = self.consignments.filter((p) => p.stageGuid === stageGuid && !p.removed);
            const removedCompletions = self.completions.filter((p) => p.removed && p.guid).map((p) => p.guid);
            const stageCompletions = self.completions.filter((p) => p.stageGuid === stageGuid && !p.removed);
            const removedExpertises = self.expertises.filter((p) => p.removed && p.guid).map((p) => p.guid);
            const stageExpertises = self.expertises.filter((p) => p.stageGuid === stageGuid && !p.removed);
            const removedSentExpertises = self.sentExpertises.filter((p) => p.removed && p.guid).map((p) => p.guid);
            const stageSentExpertises = self.sentExpertises.filter((p) => p.stageGuid === stageGuid && !p.removed);

            try {
                const model = stage.getSaveModel(
                    stagePayments,
                    removedPayments,
                    stageCompletions,
                    removedCompletions,
                    stageConsignments,
                    removedConsignments,
                    stageExpertises,
                    removedExpertises,
                    stageSentExpertises,
                    removedSentExpertises
                );
                if (!model) {
                    return;
                }

                const url = stage.guid
                    ? apiUrls.orders.stages.update(self.orderId, stage.guid)
                    : apiUrls.orders.stages.create(self.orderId);
                const stages = stage.guid
                    ? yield self.transport.post<any>(url, model)
                    : yield self.transport.put<any>(url, model);

                const payments = treatPayments(
                    yield self.transport.get<any>(apiUrls.orders.stages.payments.list(self.orderId))
                );
                const completions = yield self.transport.get<any>(apiUrls.orders.stages.comlpetions.list(self.orderId));
                const expertises = yield self.transport.get<any>(apiUrls.orders.stages.expertises.list(self.orderId));
                const sentExpertises = yield self.transport.get<any>(
                    apiUrls.orders.stages.sentExpertises.list(self.orderId)
                );
                const consignments = yield self.transport.get<any>(
                    apiUrls.orders.stages.consignments.list(self.orderId)
                );

                applySnapshot(self.expertises, treatExpertises(expertises));
                applySnapshot(self.sentExpertises, treatExpertises(sentExpertises));
                applySnapshot(self.consignments, treatConsignments(consignments));
                applySnapshot(self.completions, treatCompletions(completions));
                applySnapshot(self.planPayments, payments.plan);
                applySnapshot(self.actualPayments, payments.actual);
                applySnapshot(self.stages, treatStages(stages));

                const order = getParent(self, 2);
                if (typeof order?.refreshOrderPrice === "function") {
                    order.refreshOrderPrice(stages);
                }

                self.notify.success(texts.messages.saved);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        removeStage: flow(function* (stage: OrderStageRowType) {
            try {
                if (self.orderId === EMPTY_OBJECT_ID) {
                    return;
                }

                if (!stage.guid) {
                    self.stages.remove(stage);
                    self.notify.success(texts.messages.saved);
                    return;
                }

                yield self.transport.delete<any>(apiUrls.orders.stages.delete(self.orderId, stage.guid));
                self.stages.remove(stage);
                self.notify.success(texts.messages.saved);

                // ждем всех триггеров на удаление лишних событий
                yield delay(1000);

                const payments = treatPayments(
                    yield self.transport.get<any>(apiUrls.orders.stages.payments.list(self.orderId))
                );
                const stages = yield self.transport.get<any>(apiUrls.orders.stages.list(self.orderId));
                applySnapshot(self.stages, treatStages(stages));
                applySnapshot(self.planPayments, payments.plan);
                applySnapshot(self.actualPayments, payments.actual);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        addNewStage: flow(function* () {
            if (self.activeRow) {
                return false;
            }

            self.stages.push({
                guid: "",
                newGuid: yield self.generateGuid(),
                name: "",
                stageIsConsigned: false,
                sum: 0,
                startDateText: "",
                stopDateText: "",
                startPlanDate: null,
                stopPlanDate: null,
                duration: null,
                active: true,
                orderId: self.orderId,
                withoutSum: false,
            });

            return true;
        }),

        addNewCompletion: flow(function* () {
            if (!self.activeRow) {
                return false;
            }

            self.completions.push({
                ...emptyCompletion("", 0),
                name: "",
                newGuid: yield self.generateGuid(),
                stageGuid: self.activeRow.guid,
            });

            return true;
        }),

        addNewConsignment: flow(function* () {
            if (!self.activeRow) {
                return false;
            }

            const guid = yield self.generateGuid();
            self.consignments.push({
                ...emptyConsignment(""),
                newGuid: guid,
                stageGuid: self.activeRow.guid,
            });

            return true;
        }),
        addNewExpertise: flow(function* () {
            if (!self.activeRow) {
                return false;
            }

            const guid = yield self.generateGuid();
            self.expertises.push({
                ...emptyOrderExpertise(""),
                newGuid: guid,
                stageGuid: self.activeRow.guid,
            });

            return true;
        }),
        addNewSentExpertise: flow(function* () {
            if (!self.activeRow) {
                return false;
            }

            const guid = yield self.generateGuid();
            self.sentExpertises.push({
                ...emptyOrderSentExpertise(""),
                newGuid: guid,
                stageGuid: self.activeRow.guid,
            });

            return true;
        }),

        addNewPayment: flow(function* (type: string) {
            if (!self.activeRow) {
                return false;
            }

            self.planPayments.push({
                guid: "",
                newGuid: yield self.generateGuid(),
                name: "",
                sum: 0,
                remainsSum: 0,
                stageGuid: self.activeRow.newGuid,
                type,
                concreteDate: null,
                sortableDate: "",
                money: "",
                comment: "",
                day: "",
                date: null,
                created: "",
                dateDescription: "",
                isPaid: false,
                actualPaymentGuids: [],
            });

            return true;
        }),

        editStage(stage: OrderStageRowType) {
            if (self.activeRow || self.linkerIsvisible) {
                return false;
            }

            self.clone = OrderStageRow.create({
                ...getSnapshot(stage),
                active: false,
            });

            self.planPayments.filter((p) => p.stageGuid === stage.guid).forEach((p) => p.activate());

            self.completions.filter((p) => p.stageGuid === stage.guid).forEach((p) => p.activate());

            self.expertises.filter((p) => p.stageGuid === stage.guid).forEach((p) => p.activate());

            self.sentExpertises.filter((p) => p.stageGuid === stage.guid).forEach((p) => p.activate());

            self.consignments.filter((p) => p.stageGuid === stage.guid).forEach((p) => p.activate());

            stage.activate();

            return true;
        },

        cancelEditing(stage: OrderStageRowType) {
            if (!stage.guid) {
                self.stages.remove(stage);
            } else {
                if (self.clone !== null) {
                    applySnapshot(stage, getSnapshot(self.clone));
                    self.clone = null;
                }

                const paymentsCopy = [...self.planPayments];
                for (const payment of paymentsCopy) {
                    if (payment.stageGuid === stage.guid || !!payment.clone) {
                        if (!payment.guid) {
                            // только что добавленный платеж просто удаляем
                            self.planPayments.remove(payment);
                        } else {
                            if (payment.clone !== null) {
                                applySnapshot(payment, getSnapshot(payment.clone));
                            }

                            payment.deactivate();
                        }
                    }
                }

                self.completions
                    .filter((p) => p.stageGuid === stage.guid || !!p.clone)
                    .forEach((c) => {
                        if (c.clone !== null) {
                            applySnapshot(c, getSnapshot(c.clone));
                        }

                        c.deactivate();
                    });

                self.expertises
                    .filter((p) => p.stageGuid === stage.guid || !!p.clone)
                    .forEach((c) => {
                        if (c.clone !== null) {
                            applySnapshot(c, getSnapshot(c.clone));
                        }

                        c.deactivate();
                    });

                self.sentExpertises
                    .filter((p) => p.stageGuid === stage.guid || !!p.clone)
                    .forEach((c) => {
                        if (c.clone !== null) {
                            applySnapshot(c, getSnapshot(c.clone));
                        }

                        c.deactivate();
                    });

                self.consignments
                    .filter((p) => p.stageGuid === stage.guid || !!p.clone)
                    .forEach((c) => {
                        if (c.clone !== null) {
                            applySnapshot(c, getSnapshot(c.clone));
                        }

                        c.deactivate();
                    });

                stage.deactivate();
            }
        },

        saveLinks: flow(function* () {
            if (self.orderId === EMPTY_OBJECT_ID) {
                return;
            }

            try {
                const payments = treatPayments(
                    yield self.transport.post<any>(apiUrls.orders.stages.payments.match(self.orderId), {
                        actualPayments: self.buildMatchSaveModel(),
                    })
                );

                applySnapshot(self.planPayments, payments.plan);
                applySnapshot(self.actualPayments, payments.actual);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .actions((self) => ({
        linkPayments(actualPaymentGuid: string, planPaymentGuid: string) {
            const actual = self.actualMap[actualPaymentGuid];
            const plan = self.planMap[planPaymentGuid];

            if (actual && plan && !actual.hasPlanMatch && plan.addActualPayment(actualPaymentGuid)) {
                plan.resortLinks(self.actualIndexes);
                return true;
            }

            return false;
        },

        unlinkPayments(actualPaymentGuid: string, planPaymentGuid: string) {
            const plan = self.planMap[planPaymentGuid];
            if (plan && plan.removeActualPayment(actualPaymentGuid)) {
                return true;
            }

            return false;
        },
    }))
    .named("OrderStagesStore");

export interface EventedDateValue {
    date: Date | null;
    days: number;
    workDaysOnly: boolean;
    events: EventDateType[];
}

export type OrderStagesStoreType = Instance<typeof OrderStagesStore>;
export type OrderStageRowType = Instance<typeof OrderStageRow>;
export type OrderStageType = Instance<typeof OrderStage>;
type OrderStageRowBaseType = Instance<typeof OrderStageRowBase>;

export const initialState = (orderId = ""): SnapshotOut<typeof OrderStagesStore> => ({
    orderId,
    stages: [],
    clone: null,
    planPayments: [],
    actualPayments: [],
    completions: [],
    expertises: [],
    sentExpertises: [],
    consignments: [],
    linkerIsvisible: false,
});

export const fields = {
    name: nameof((a: OrderStageRowBaseType) => a.name) as string,
    sum: nameof((a: OrderStageRowBaseType) => a.sum) as string,
    startDate: "startDate",
    stopDate: "stopDate",
};

const treatStages = (stages: OrderStageRowType[]) => {
    stages.forEach((s) => {
        s.newGuid = s.guid;
    });

    return stages;
};

/** revert links from actual to plan */
const treatPayments = (response: any) => {
    const map: TStringMap<any> = {};
    response = toJsonHard(response);

    response.plan.forEach((payment: any) => {
        map[payment.guid] = payment;
        payment.newGuid = payment.guid;
        payment.actualPaymentGuids = new Set();
    });

    response.actual.forEach((payment: any) => {
        payment.planPaymentGuids.forEach((guid: string) => {
            map[guid].actualPaymentGuids.add(payment.guid);
        });
    });

    response.plan.forEach((payment: any) => {
        payment.actualPaymentGuids = Array.from(payment.actualPaymentGuids);
    });

    return response;
};

const treatCompletions = (comlpetions: OrderCompletionRowType[]) => {
    comlpetions.forEach((s) => {
        s.newGuid = s.guid;
    });

    return comlpetions;
};

const treatConsignments = (consignment: OrderConsignmentRowType[]) => {
    consignment.forEach((s) => {
        s.newGuid = s.guid;
    });

    return consignment;
};

const treatExpertises = (expertises: OrderExpertiseRowType[]) => {
    expertises.forEach((s) => {
        s.newGuid = s.guid;
    });

    return expertises;
};

const treatSentExpertises = (expertises: OrderSentExpertiseRowType[]) => {
    expertises.forEach((s) => {
        s.newGuid = s.guid;
    });

    return expertises;
};

const checkError = (plan: PlanOrderPaymentSnapshotType[], actual: ActualOrderPaymentSnapshotType[]) => {
    actual = toJsonHard(actual);

    const actualMapClone: TStringMap<ActualOrderPaymentSnapshotType[]> = {};
    const overhead = new Set<string>();

    actual.forEach((actualOrderPayment: ActualOrderPaymentSnapshotType) => {
        actualOrderPayment.planPaymentGuids.forEach((guid: string) => {
            if (!actualMapClone[guid]) {
                actualMapClone[guid] = [];
            }

            actualMapClone[guid].push(actualOrderPayment);
        });
    });

    for (const planOrderPayment of plan) {
        var payments = actualMapClone[planOrderPayment.guid] ?? [];
        let requiredSum = planOrderPayment.sum;

        for (const actualOrderPayment of payments) {
            if (requiredSum > 0) {
                if (actualOrderPayment.sum === 0) {
                    // скорее всего, платеж прицепили еще где-то и его суммы уже не хватает
                    overhead.add(actualOrderPayment.guid);
                } else {
                    const take = requiredSum >= actualOrderPayment.sum ? actualOrderPayment.sum : requiredSum;
                    actualOrderPayment.sum -= take;
                    requiredSum -= take;
                }
            }
        }
    }

    return overhead;
};
