import React from "react";
import { Schema } from "yup";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { InputGroup, Position, Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { fields } from "../models/settings";
import styles from "./SystemSettings.module.scss";
import { getFieldLabel } from "modules/common/services/form/fields";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrButton } from "modules/common/components/planr/button/Button";

class SystemBase extends React.PureComponent<SystemSettingsProps> {
    render() {
        const { schema } = this.props;
        return (
            <div className="columns">
                <div className={styles.columns}>
                    <div className={styles.column}>
                        <StandardFormInput
                            name={fields.administratorPositions}
                            schema={schema}
                            small={true}
                            className="planr-form-input"
                            inline={false}
                            label={
                                <span className="planr-input-label-help">
                                    {getFieldLabel(fields.administratorPositions, schema, null)} (или несколько через
                                    запятую)&nbsp;&nbsp;
                                    <Tooltip
                                        content="Используется для рассылки уведомлений системным администраторам"
                                        position={Position.RIGHT}
                                    >
                                        <GeneralIcon type="general-question" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {({ field }) => (
                                <InputGroup
                                    {...field}
                                    id={field.name}
                                    className="planr-default-input"
                                    type="text"
                                    autoComplete="off"
                                    data-lpignore="true"
                                />
                            )}
                        </StandardFormInput>
                    </div>

                    <div className={styles.column} style={{ marginTop: "5px" }}>
                        <StandardFormInput
                            name={fields.showMinutes}
                            schema={schema}
                            small={true}
                            className="planr-form-input"
                            inline={false}
                        >
                            {({ field, form }) => (
                                <PlanrButton
                                    type="neutral"
                                    icon={field.value ? "general-checkbox" : "general-zero-checkbox"}
                                    onClick={() => {
                                        form.setFieldValue(field.name, !field.value);
                                        form.setFieldTouched(field.name, true);
                                    }}
                                    style={{ marginTop: "-5px" }}
                                />
                            )}
                        </StandardFormInput>
                        <StandardFormInput
                            name={fields.prevDaysCount}
                            schema={schema}
                            small={true}
                            className="planr-form-input"
                            inline={false}
                        >
                            {({ field }) => (
                                <InputGroup
                                    id={field.name}
                                    className="planr-default-input"
                                    {...field}
                                    type="number"
                                    autoComplete="off"
                                    data-lpignore="true"
                                />
                            )}
                        </StandardFormInput>
                        <StandardFormInput
                            name={fields.futureDaysCount}
                            schema={schema}
                            small={true}
                            className="planr-form-input"
                            inline={false}
                        >
                            {({ field }) => (
                                <InputGroup
                                    id={field.name}
                                    className="planr-default-input"
                                    {...field}
                                    type="number"
                                    autoComplete="off"
                                    data-lpignore="true"
                                />
                            )}
                        </StandardFormInput>
                    </div>
                </div>
            </div>
        );
    }
}

export const SystemSettings = observer(SystemBase);

interface SystemSettingsProps {
    schema: Schema<any>;
}
