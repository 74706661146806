import { Classes, Dialog, Tab, Tabs } from "@blueprintjs/core";
import { Form, Formik, FormikErrors } from "formik";
import { Location, UnregisterCallback } from "history";
import { cloneDeep, groupBy } from "lodash";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { Page } from "modules/common/components/page/Page";
import DenyNavigation from "modules/common/components/routing/DenyNavigation";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { colorLuminance } from "modules/common/services/colors";
import { collectMessages } from "modules/common/services/form/fields";
import { buildPatch } from "modules/common/services/form/values";
import { eat } from "modules/common/services/typescript";
import { MailsTab } from "modules/order-mails/components/Tab";
import { menuItems } from "modules/root/components/menu/MainMenu";
import { MenuItems } from "modules/root/components/menu/types";
import { can, canAny } from "modules/session/auth/access";
import { SessionType } from "modules/session/auth/models/session";
import { parse } from "query-string";
import React from "react";
import { RouteComponentProps, matchPath } from "react-router";
import func from "../functionalities";
import { OrderExternalParams, OrderSummaryType, fields, getContentUnits } from "../models/order";
import { outsourcedSpendingFields, ownSpendingFields, tripSpendingFields } from "../models/order-spending";
import { OrdersStoreType } from "../models/orders-store";
import { paymentFields } from "../models/payment";
import styles from "./OrderDetailsTabsPage.module.scss";
import { ContentTask } from "./content-task/ContentTask";
import { Content } from "./content/Content";
import { OrderIpd } from "./ipd/Ipd";
import { Spendings } from "./spending/Spendings";
import { MakeGroupKey } from "./spending/other/OtherSpendingsObject";
import { Summary } from "./summary/Summary";
import { TechTask } from "./tech-task/TechTask";
import OrderSummarySchema, { OrderAgentsSchema, OrderIpdSchema, OrderTechIndicatorSchema } from "./validation";
// import { mapConsignment } from "../models/order-file";
import { ClientFunctionalities } from "modules/agents/clients/models/client";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { SettingsStoreType } from "../../session/settings/models/settings";
import { WorkResultStoreType } from "../models/order-work-result";
import { FinanceTab } from "./finance/FinanceTab";
import { OrderCommunicationPage } from "./orderCommunication/OrderCommunication";
import { OrderTechIndicator } from "./orderTechIndicators/TechIndicator";
import { WorkResultPage } from "./work-result/WorkResult";
import { LogList } from "./LogList";
import { OrderAgentsPage } from "./order-agents/OrderAgents";

const collaboraPortal: HTMLDivElement = document.getElementById("CollaboraLoader-portal") as any;

export const OrderTabs = {
    summary: "summary",
    content: "content",
    project: "project",
    ipd: "ipd",
    orderTechIndicators: "orderTechIndicators",
    orderAgents: "orderAgents",
    orderCommunication: "orderCommunication",
    finance: "finance",
    spending: "spending",
    mails: "mails",
    techTask: "tech-task",
    workPreview: "work-preview",
};

class OrderDetailsTabsPage extends React.Component<
    OrderDetailsTabsPageProps & RouteComponentProps,
    OrderDetailsTabsPageState
> {
    private unregister: UnregisterCallback | null = null;
    private menu = menuItems();
    private premiumsSnapshot: any = null;

    constructor(props: OrderDetailsTabsPageProps & RouteComponentProps) {
        super(props);

        this.state = {
            orderId: getSelectedOrderId(props.location.pathname),
            tabId: getTabId(props.location.hash),
            showLog: false,
        };
    }

    componentDidMount() {
        document.title = this.menu.orders.label;

        const { history, store, clientStore, session } = this.props;
        const canWorkResultRead = canAny([ClientFunctionalities.CLIENTS_USER_ACCESS], session.access);
        this.unregister = history.listen((location) => {
            const orderId = getSelectedOrderId(location.pathname);

            if (this.state.orderId !== orderId && orderId) {
                this.loadDetails(orderId);
                if (canWorkResultRead) {
                    clientStore.load(orderId);
                }
            }

            this.setState({ orderId, tabId: getTabId(location.hash) });
        });

        store.load();
        this.loadDetails(this.state.orderId);
        if (canWorkResultRead) {
            clientStore.load(this.state.orderId);
        }
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { settings, store, location, session, baseUrl } = this.props;

        const checkCanEdit = (accessPage: string) => {
            return store.details.isNewlyCreated
                ? can(accessPage, session.access)
                : can(accessPage, session.access) &&
                      (can(func.ORDERS_ALL_EDIT, session.access) || store.details.employer?.id === session.userId);
        };

        const tabId = getTabId(location.hash);
        const initial = getFormValues(store.details);
        const access = [...session.access, ClientFunctionalities.CLIENTS_USER_ACCESS];
        const schema = OrderSummarySchema(access);
        const schemaIpd = OrderIpdSchema(access);
        const schemaTechIndicator = OrderTechIndicatorSchema(access);
        const schemaOrderAgents = OrderAgentsSchema(access);
        const canSummaryRead = canAny([func.ORDERS_SUMMARY_EDIT, func.ORDERS_SUMMARY_READ], session.access);
        const canFinanceRead = canAny([func.ORDERS_FINANCE_EDIT, func.ORDERS_FINANCE_READ], session.access);
        const canWorkResultRead = canAny([ClientFunctionalities.CLIENTS_USER_ACCESS], session.access);
        const canContentRead = canAny([func.ORDERS_CONTENTS_EDIT, func.ORDERS_CONTENTS_READ], session.access);
        const canOrderIndicatorsRead = canAny(
            [func.ORDERS_TECHINDICATORS_READ, func.ORDERS_TECHINDICATORS_EDIT],
            session.access
        );
        const canOrderAgentsRead = canAny([func.ORDERS_AGENTS_EDIT, func.ORDERS_AGENTS_READ], session.access);
        const canOrderCommunicationRead = canAny(
            [ClientFunctionalities.CLIENTS_USER_ACCESS, func.ORDERS_COMMUNICATION_READ, func.ORDERS_COMMUNICATION_EDIT],
            session.access
        );
        const canContentTaskRead = canAny(
            [func.ORDERS_CONTENT_TASK_EDIT, func.ORDERS_CONTENT_TASK_READ],
            session.access
        );
        const canIpdRead = canAny(
            [ClientFunctionalities.CLIENTS_USER_ACCESS, func.ORDERS_IPD_EDIT, func.ORDERS_IPD_READ],
            session.access
        );
        const canSpendingRead = canAny([func.ORDERS_SPENDING_EDIT, func.ORDERS_SPENDING_READ], session.access);
        const canMailsRead = canAny(
            [ClientFunctionalities.CLIENTS_USER_ACCESS, func.ORDERS_MAILS_EDIT, func.ORDERS_MAILS_READ],
            session.access
        );
        const canTechTaskRead = canAny(
            [func.ORDERS_TECHNICAL_TASK_EDIT, func.ORDERS_TECHNICAL_TASK_READ],
            session.access
        );

        const canSummaryEdit = checkCanEdit(func.ORDERS_SUMMARY_EDIT);

        const canFinanceEdit = checkCanEdit(func.ORDERS_FINANCE_EDIT);

        const canContentEdit = checkCanEdit(func.ORDERS_CONTENTS_EDIT);

        const canContentTaskEdit = checkCanEdit(func.ORDERS_CONTENT_TASK_EDIT);

        const canIpdEdit = checkCanEdit(func.ORDERS_IPD_EDIT);

        const canSpendingEdit = checkCanEdit(func.ORDERS_SPENDING_EDIT);

        const canTechTasksEdit = checkCanEdit(func.ORDERS_TECHNICAL_TASK_EDIT);

        const canMailsEdit = checkCanEdit(func.ORDERS_MAILS_EDIT);

        const canOrderIndicatorsEdit = checkCanEdit(func.ORDERS_TECHINDICATORS_EDIT);

        const canOrderAgentsEdit = checkCanEdit(func.ORDERS_AGENTS_EDIT);

        const canOrderCommunicationEdit = checkCanEdit(func.ORDERS_COMMUNICATION_EDIT);

        const canSensitive = checkCanEdit(func.ORDERS_SENSITIVE_DATA);

        const canClientEdit = can(ClientFunctionalities.CLIENTS_USER_ACCESS, session.access);

        const canSubmitForm =
            canSummaryEdit ||
            canFinanceEdit ||
            canContentEdit ||
            canIpdEdit ||
            canSpendingEdit ||
            canMailsEdit ||
            canContentTaskEdit ||
            canClientEdit;

        const orderId = this.getOrderId();
        const wopi = store.details.wopiToken;

        return (
            <Page className={styles.orderManage}>
                <LocalLoader active={store.loading} />

                <Formik
                    initialValues={initial}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        let patch: any;

                        if (store.details.isNewlyCreated) {
                            patch = cloneDeep(values);
                        } else {
                            patch = buildPatch(initial, values);
                        }

                        const orderSnapshot = await store.saveOrderDetails(patch);

                        setSubmitting(false);

                        if (!!orderSnapshot) {
                            this.premiumsSnapshot = orderSnapshot.premiumDetails;
                            store.details.refresh(orderSnapshot);
                            resetForm();
                            this.onOrderSaved();
                        }

                        if (orderSnapshot === null) {
                            resetForm();
                        }
                    }}
                    onReset={this.onFormReset}
                >
                    {(formProps) => {
                        let color = "#F6F8F9";
                        store.statuses.isAvailable.forEach((item) => {
                            if (item.type === store.details.orderStatusLink?.name) {
                                color = item.color;
                            }
                        });

                        return (
                            <Form autoComplete="off">
                                <DenyNavigation
                                    when={formProps.dirty}
                                    message="На странице остались несохраненные изменения"
                                    skip={this.navigationPromt}
                                />

                                {!store.loading && (
                                    <div className={styles.orderTitle}>
                                        {store.details.orderTitle}
                                        <span
                                            style={{
                                                marginLeft: "30px",
                                                padding: "5px 7px",
                                                fontSize: "12px",
                                                borderRadius: "4px",
                                                backgroundColor: `${colorLuminance(color, 0.1)}`,
                                                color: `${colorLuminance(color, -0.5)}`,
                                                marginRight: "20px",
                                            }}
                                        >
                                            {store.details.orderStatusLink?.name}
                                        </span>
                                        <PlanrButton
                                            type="graybtn"
                                            icon="general-exclamation"
                                            round
                                            onClick={() => {
                                                this.setState({ showLog: true });
                                            }}
                                        />
                                    </div>
                                )}
                                <Dialog
                                    title="История изменений"
                                    canEscapeKeyClose={false}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.showLog}
                                    onClose={() => {
                                        this.setState({ showLog: false });
                                    }}
                                    style={{
                                        width: `1250px`,
                                        height: "860px",
                                    }}
                                    backdropClassName="standard"
                                    className={`figma-dialog`}
                                >
                                    <div className={`${Classes.DIALOG_BODY} ${styles.dialog}`}>
                                        <LogList store={store.logs} orderId={orderId} />
                                    </div>
                                </Dialog>
                                <Tabs
                                    className={`${styles.tabs} planr-tabs-navigation`}
                                    id="OrderDetailsTabsPage"
                                    onChange={this.handleTabChange}
                                    selectedTabId={tabId}
                                    renderActiveTabPanelOnly={false}
                                >
                                    {canSummaryRead && (
                                        <Tab
                                            id={OrderTabs.summary}
                                            title={this.menu.orders.nested.summary.label}
                                            panel={
                                                !store.loading ? (
                                                    <Summary
                                                        formProps={formProps}
                                                        portfolios={store.portfolios}
                                                        statuses={store.statuses}
                                                        clients={store.clients}
                                                        types={store.orderTypes}
                                                        stages={store.productionStages}
                                                        engineers={store.engineers}
                                                        objectFactory={store.details}
                                                        // consignmentFactory={store.details}
                                                        additionFactory={store.details}
                                                        schema={schema}
                                                        readOnly={!canSummaryEdit}
                                                        upload={store.details.uploadFile}
                                                        newId={store.details.generateGuid}
                                                        baseUrl={baseUrl}
                                                        withSensitive={canSensitive}
                                                        isActiveTab={tabId === OrderTabs.summary}
                                                        storeDetails={store.details}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}

                                    {canFinanceRead && (
                                        <Tab
                                            id={OrderTabs.finance}
                                            title={this.menu.orders.nested.finance.label}
                                            panel={
                                                <FinanceTab
                                                    store={store.finances}
                                                    orderId={orderId}
                                                    isActiveTab={tabId === OrderTabs.finance}
                                                    readonly={!canFinanceEdit}
                                                    printStages={store.printStages}
                                                    storeDetails={store.details}
                                                />
                                            }
                                        />
                                    )}

                                    {canContentRead && (
                                        <Tab
                                            id={OrderTabs.content}
                                            title={this.menu.orders.nested.content.label}
                                            panel={
                                                !store.loading ? (
                                                    <Content
                                                        baseUrl={baseUrl}
                                                        order={store.details}
                                                        orderDictionary={store.orderDictionary}
                                                        workTypes={store.workTypes}
                                                        employers={store.employee}
                                                        agents={store.agents}
                                                        contentFactory={store}
                                                        name={fields.objects}
                                                        collapser={store.contentCollapser}
                                                        readOnly={!canContentEdit}
                                                        onPrint={() => {
                                                            store.printContentType();
                                                        }}
                                                        CreateDocument={store.printContentDocument}
                                                        CreateInvoice={store.printContentInvoice}
                                                        isActiveTab={tabId === OrderTabs.content}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}

                                    {canContentTaskRead && (
                                        <Tab
                                            id={OrderTabs.project}
                                            title={this.menu.orders.nested.project.label}
                                            panel={
                                                !store.loading ? (
                                                    <ContentTask
                                                        baseUrl={baseUrl}
                                                        order={store.details}
                                                        schema={schema}
                                                        store={store.contentTasks}
                                                        name={fields.contentTasks}
                                                        clientCanDownloadField={fields.canClientDownloadTasks}
                                                        collapser={store.contentCollapser}
                                                        collapserContent={store.objectsCollapser}
                                                        readOnly={!canContentTaskEdit}
                                                        user={session.userName}
                                                        employee={store.employee}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}
                                    {canWorkResultRead && (
                                        <Tab
                                            id={OrderTabs.workPreview}
                                            title={"Результат работы"}
                                            panel={
                                                <WorkResultPage
                                                    store={this.props.clientStore}
                                                    readOnly={!canWorkResultRead}
                                                    baseUrl={baseUrl}
                                                    orderId={this.state.orderId}
                                                    financeStore={store.finances}
                                                />
                                            }
                                        />
                                    )}
                                    {canIpdRead && (
                                        <Tab
                                            id={OrderTabs.ipd}
                                            title={this.menu.orders.nested.ipd.label}
                                            panel={
                                                !store.loading ? (
                                                    <OrderIpd
                                                        name={fields.orderIpds}
                                                        store={store}
                                                        factory={store.details}
                                                        readOnly={!canIpdEdit}
                                                        baseUrl={baseUrl}
                                                        canClientEdit={canClientEdit}
                                                        ipdTypes={store.ipdTypeDictionary}
                                                        orderDictionary={store.orderDictionary}
                                                        onPrint={this.props.store.printIpdOrder}
                                                        schema={schemaIpd}
                                                        orderId={orderId}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}
                                    {canSpendingRead && (
                                        <Tab
                                            id={OrderTabs.spending}
                                            title={this.menu.orders.nested.spending.label}
                                            panel={
                                                !store.loading ? (
                                                    <Spendings
                                                        formProps={formProps}
                                                        employee={store.employee}
                                                        ownSpendingFactory={store.details}
                                                        otherSpendingFactory={store.details}
                                                        tripSpendingFactory={store.details}
                                                        store={store}
                                                        paymentFactory={store.details}
                                                        outsourcedSpendingFactory={store.details}
                                                        readOnly={!canSpendingEdit}
                                                        schema={schema}
                                                        baseUrl={baseUrl}
                                                        upload={store.details.uploadFile}
                                                        newId={store.details.generateGuid}
                                                        isActiveTab={tabId === OrderTabs.spending}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}
                                    {canOrderAgentsRead && (
                                        <Tab
                                            id={OrderTabs.orderAgents}
                                            title={this.menu.orders.nested.orderAgents.label}
                                            panel={
                                                !store.loading ? (
                                                    <OrderAgentsPage
                                                        name={fields.orderAgents}
                                                        store={store}
                                                        readOnly={!canOrderAgentsEdit}
                                                        baseUrl={baseUrl}
                                                        orderDictionary={store.orderDictionary}
                                                        onPrint={this.props.store.printIpdOrder}
                                                        schema={schemaOrderAgents}
                                                        orderId={orderId}
                                                        agents={store.orderAgents}
                                                        agentsCategories={store.agentsCategories}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}
                                    {canOrderIndicatorsRead && (
                                        <Tab
                                            id={OrderTabs.orderTechIndicators}
                                            title={this.menu.orders.nested.orderTechIndicators.label}
                                            panel={
                                                !store.loading ? (
                                                    <OrderTechIndicator
                                                        name={fields.orderTechIndicators}
                                                        store={store}
                                                        readOnly={!canOrderIndicatorsEdit}
                                                        baseUrl={baseUrl}
                                                        orderDictionary={store.orderDictionary}
                                                        onPrint={this.props.store.printIpdOrder}
                                                        schema={schemaTechIndicator}
                                                        orderId={orderId}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}

                                    {canOrderCommunicationRead && (
                                        <Tab
                                            id={OrderTabs.orderCommunication}
                                            title={this.menu.orders.nested.orderCommunication.label}
                                            panel={
                                                !store.loading ? (
                                                    <OrderCommunicationPage
                                                        employers={store.employee}
                                                        name={fields.orderCommunication}
                                                        settings={settings}
                                                        store={store}
                                                        readOnly={!canOrderCommunicationEdit}
                                                        baseUrl={baseUrl}
                                                        orderDictionary={store.orderDictionary}
                                                        onPrint={this.props.store.printCommunication}
                                                        schema={schemaTechIndicator}
                                                        orderId={orderId}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}

                                    {canMailsRead && orderId !== EMPTY_OBJECT_ID && (
                                        <Tab
                                            id={OrderTabs.mails}
                                            title={this.menu.orders.nested.mails.label}
                                            panel={
                                                !store.loading ? (
                                                    <MailsTab
                                                        canManage={canMailsEdit}
                                                        store={store.mails}
                                                        hideProject={true}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}

                                    {orderId !== EMPTY_OBJECT_ID && canTechTaskRead && (
                                        <Tab
                                            id={OrderTabs.techTask}
                                            title={"ТЗ"}
                                            className="flat"
                                            panel={
                                                !store.loading && wopi ? (
                                                    <TechTask
                                                        readOnly={!canTechTasksEdit}
                                                        fileId={orderId}
                                                        editorUrl={store.details.editorUrl}
                                                        wopiToken={wopi}
                                                        container={collaboraPortal}
                                                        store={store.techTasks}
                                                        anchors={store.taskAnchors}
                                                        orderDictionary={store.orderDictionary}
                                                        order={store.details}
                                                        baseUrl={baseUrl}
                                                        isEngineer={store.details.canCreateTaskVersion}
                                                    />
                                                ) : undefined
                                            }
                                        />
                                    )}
                                </Tabs>

                                {canSubmitForm &&
                                    ![OrderTabs.techTask, OrderTabs.finance, OrderTabs.ipd].includes(tabId) && (
                                        <div className={styles.buttons}>
                                            <div>
                                                <StandardFormButtons
                                                    {...formProps}
                                                    isRemoved={false}
                                                    what={""}
                                                    isNewlyCreated={true}
                                                    submitOnDirty={true}
                                                    onRemove={eat}
                                                />

                                                {tabId === OrderTabs.summary && (
                                                    <div className={styles.reminder}>
                                                        <span>*</span> &mdash; поля отмеченные звездочкой, обязательные
                                                        к заполнению
                                                    </div>
                                                )}
                                            </div>
                                            {proccessErrors(formProps.errors, this.menu)}
                                        </div>
                                    )}
                            </Form>
                        );
                    }}
                </Formik>
            </Page>
        );
    }

    onFormReset = () => {
        this.props.store.resetPremiums(this.premiumsSnapshot);
        this.props.store.contentTasks.resetTasksToDefault();
    };

    loadDetails = (id: string) => {
        const { session, store } = this.props;
        const canMailsRead = canAny(
            [ClientFunctionalities.CLIENTS_USER_ACCESS, func.ORDERS_MAILS_EDIT, func.ORDERS_MAILS_READ],
            session.access
        );

        this.premiumsSnapshot = null;
        store.loadDetails(id, canMailsRead).then(({ snapshot, premiums }) => {
            this.premiumsSnapshot = premiums;

            if (snapshot) {
                // outsourcers and own
                const units = getContentUnits(snapshot);
                units.forEach((unit) => {
                    store.outsourceCollapser.set(unit.guid, false);
                    store.ownPlanCollapser.set(unit.guid, false);
                });

                // other
                Object.keys(groupBy(snapshot.otherOrderSpendings, (s) => MakeGroupKey(s.comment))).forEach((key) => {
                    store.otherCollapser.set(key, false);
                });

                // expand outer blocks
                const external = OrderExternalParams(this.props.location.hash);

                // section
                if (external.expanded) {
                    store.setupCollapseState(external.expanded);
                }

                if (external.rowId) {
                    // outsourcers
                    const outsourced = snapshot.outsourcedOrderSpendins.find(
                        (sp) =>
                            sp.actualPayments.some((p) => p.guid === external.rowId) ||
                            sp.planPayments.some((p) => p.guid === external.rowId)
                    );

                    if (outsourced) {
                        store.outsourceCollapser.set(outsourced.contentGuid, true);
                        return;
                    }

                    // other
                    const other = snapshot.otherOrderSpendings.find(
                        (sp) =>
                            sp.actualPayments.some((p) => p.guid === external.rowId) ||
                            sp.planPayments.some((p) => p.guid === external.rowId)
                    );

                    if (other) {
                        store.otherCollapser.set(MakeGroupKey(other.comment), true);
                        return;
                    }
                }
            }
        });
        store.outsourceCollapser.reset();
        store.otherCollapser.reset();
    };

    onOrderSaved = () => {
        const { store, history } = this.props;
        const { details } = store;

        store.updateOrder(details);

        const url = routes.orders.details(details.id, this.state.tabId);
        history.push(url);
    };

    getOrderId = () => this.props.store.details.id || EMPTY_OBJECT_ID;

    handleTabChange = (tabId: string) => {
        const { history } = this.props;

        const to: any = routes.orders.details(this.getOrderId(), tabId);

        history.push(to);
    };

    navigationPromt = (location: Location) => {
        // just tabs switching
        return location.pathname.startsWith(routes.orders.base(this.getOrderId())) ? true : false;
    };
}

export default observer(OrderDetailsTabsPage);

const proccessErrors = (errors: FormikErrors<any>, menu: MenuItems) => {
    const tabs = {
        [menu.orders.nested.spending.label]: [
            fields.otherOrderSpendings,
            fields.outsourcedOrderSpendins,
            fields.tripOrderSpendings,
            fields.ownOrderSpendings,
            fields.premiumGipPercents,
        ],
        [menu.orders.nested.finance.label]: [],
        [menu.orders.nested.summary.label]: [
            fields.color,
            fields.fullInventoryNumber,
            fields.orderStatusId,
            fields.orderNumber,
            fields.startDate,
            fields.clientId,
            fields.engineerId,
            fields.fullName,
            fields.name,
            fields.stopDateActual,
            fields.portfolioId,
            fields.price,
            fields.orderTypeId,
            fields.productionStageId,
            fields.objects,
            fields.planProductionSpendingsPercents,
            fields.finValuesActualOverheadPercents,
            fields.finValuesPlanOverheadPercents,
            fields.premiumPercents,
            fields.feedbackFileId,
        ],
        [menu.orders.nested.ipd.label]: [fields.orderIpds],
    };

    const rows: Array<{ tab: string; message: string }> = [];
    Object.keys(errors).forEach((field) => {
        const tab = Object.keys(tabs).find((label) => tabs[label].includes(field)) ?? "";
        rows.push({ tab, message: collectMessages(errors[field]) });
    });

    const grouped = groupBy(rows, (g) => g.tab);

    const result = Object.keys(grouped).map((tab) => {
        const parts = grouped[tab].map((e) => e.message).join(". ");
        const tabLabel = tab ? `${tab}:` : "";
        return (
            <div key={tab}>
                <strong>{tabLabel}</strong> {parts}
            </div>
        );
    });

    return <div className={styles.message}>{result}</div>;
};

function getTabId(query: string) {
    let tabId = "";
    const params = parse(query)["tab"];
    if (params) {
        tabId = typeof params === "string" ? params : params[0];
    }

    return tabId || OrderTabs.summary;
}

export function getSelectedOrderId(pathname: string) {
    const match = matchPath<any>(pathname, routes.orders.details(undefined, ""));
    return match && match.params ? match.params.orderId : "";
}

interface OrderDetailsTabsPageProps {
    baseUrl: string;
    location: Location;
    store: OrdersStoreType;
    settings: SettingsStoreType;
    clientStore: WorkResultStoreType;
    session: SessionType;
}

interface OrderDetailsTabsPageState {
    orderId: string;
    tabId: string;
    showLog: boolean;
}

function getFormValues(order: OrderSummaryType) {
    const result = {
        ...getSnapshot(order),
        [fields.portfolioId]: order.projectPortfolioId,
        [fields.orderStatusId]: order.orderStatusId,
        [fields.clientId]: order.clientId,
        [fields.cypher]: order.cypher,
        [fields.orderTypeId]: order.orderTypeId,
        [fields.productionStageId]: order.productionStageId,
        [fields.engineerId]: order.engineerId,
        [fields.startDate]: order.startDateAsDate,
        [fields.stopDateActual]: order.stopDateActualAsDate,
        [fields.ownOrderSpendings]: order.ownOrderSpendings.map((sp) => ({
            ...getSnapshot(sp),
            [ownSpendingFields.employerId]: sp.employerId,
        })),
        [fields.outsourcedOrderSpendins]: order.outsourcedOrderSpendins.map((sp) => ({
            ...getSnapshot(sp),
            [outsourcedSpendingFields.planPayments]: sp.planPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [outsourcedSpendingFields.actualPayments]: sp.actualPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [outsourcedSpendingFields.outsourcerId]: sp.outsourcerId,
            [outsourcedSpendingFields.startDate]: sp.startDateAsDate,
        })),
        [fields.tripOrderSpendings]: order.tripOrderSpendings.map((sp) => ({
            ...getSnapshot(sp),
            [tripSpendingFields.planPayments]: sp.planPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [tripSpendingFields.actualPayments]: sp.actualPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [ownSpendingFields.employerId]: sp.employerId,
        })),
        [fields.otherOrderSpendings]: order.otherOrderSpendings.map((sp) => ({
            ...getSnapshot(sp),
            [outsourcedSpendingFields.planPayments]: sp.planPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [outsourcedSpendingFields.actualPayments]: sp.actualPayments.map((payment) => ({
                ...getSnapshot(payment),
                [paymentFields.date]: payment.dateAsDate,
            })),
            [outsourcedSpendingFields.outsourcerId]: sp.outsourcerId,
            [outsourcedSpendingFields.startDate]: sp.startDateAsDate,
        })),
        [fields.otherOrders]: getSnapshot(order.otherOrders),
        [fields.flags]: order.municipalContract
            ? "municipal"
            : order.batchContract
            ? "batch"
            : order.federalContract
            ? "federal"
            : order.regionContract
            ? "region"
            : "",
        // [fields.orderIpds]: order.ipds.map(mapIpd),
        // [fields.consignments]: order.consignments.map(mapConsignment),
        [fields.additions]: order.additions.map((a) => ({
            ...getSnapshot(a),
            startDate: a.startDateAsDate,
        })),
        [fields.feedbackFileId]: order.feedbackFile,
        [fields.finValuesActualOverheadPercents]: order.financeValues.actual.percents.get("overhead"),
        [fields.finValuesPlanOverheadPercents]: order.financeValues.plan.percents.get("overhead"),
        [fields.premiums]: null,
        [fields.contentTasks]: null,
    };

    return result;
}
