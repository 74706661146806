import React from "react";
import { UserWorkloadType } from "modules/spending/workload/models/user-workload";
import styles from "./WorkloadUserRow.module.scss";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { observer } from "mobx-react";
import { WorkloadCell, DaySelector, WorkloadCellMode } from "./WorkloadCell";
import {
    WorkloadDayStoreType,
    WorkloadDayType,
    TasksDictionaryType,
} from "modules/spending/workload/models/workload-day";
import { OrderDictionaryType } from "modules/orders-manage/models/order-dictionary";
import { WorkloadInput, WorkloadSaver } from "./WorkloadInput";
import { getSnapshot } from "mobx-state-tree";
import { makeWorkloadInputStore } from "modules/spending/workload/models/workload-store";

class Row extends React.Component<WorkloadUserRowProps, WorkloadUserRowState> {
    private cells: string[] = [];
    constructor(props: WorkloadUserRowProps) {
        super(props);

        this.state = { formStore: null, selectedItems: [], mouseDown: false };
    }
    componentDidUpdate() {}

    render() {
        const {
            workload,
            onSave,
            selectedCells,
            onSelectCells,
            onSelectCell,
            tasks,
            removeDay,
            selectedCell,

            ...selector
        } = this.props;
        const { formStore } = this.state;

        const selectCel = (str: string) => {
            const index = selectedCells.findIndex((cell) => {
                return cell === str;
            });
            if (index === -1) {
                onSelectCells([]);
                onSelectCell(str);
            } else {
                onSelectCell(str);
            }
        };

        const moveMouse = (event: any) => {
            if (event.target.offsetParent) {
                const item = event.target.offsetParent.id;

                this.cells.push(item);
                this.setState({ selectedItems: this.cells });
            } else {
                this.cells = [];
                this.setState({ selectedItems: this.cells });
            }
        };

        return (
            <div className={`timesheet-user-row ${styles.row}`}>
                <div
                    className={`timesheet-user-name ${workload.user.nonProduction ? styles.nonProduction : null}`}
                    // style={workload.user.nonProduction ? { background: " #f6f8f9ee" } : {}}
                >
                    <EmployerName name={workload.user.label} position={workload.user.position} />
                </div>

                <div
                    className="timesheet-user-days"
                    style={{ display: "flex" }}
                    onMouseDown={(e: any) => {
                        this.cells = [];

                        if (e.target.offsetParent) {
                            const item = e.target.offsetParent.id;
                            this.setState({ mouseDown: true });
                            selectCel(item);
                        }
                    }}
                    onMouseMove={this.state.mouseDown ? moveMouse : () => {}}
                    onMouseUp={(e) => {
                        this.setState({ mouseDown: false });
                        //В связи с обнулением главного стейта в mouseDown происходит
                        //небольшая задержка выбора ячеек и они визуально не успевают выбраться
                        //было решено формировать главный стейт на основании начального и конечного
                        //значения выбранных ячеек

                        if (this.cells.length > 0) {
                            let arrCells: string[] = [];
                            const arrStart = selectedCell
                                ? parseInt(selectedCell.slice(8, 10))
                                : parseInt(this.cells[0].slice(8, 10));
                            const arrEnd = this.cells[this.cells.length - 1]
                                ? parseInt(this.cells[this.cells.length - 1].slice(8, 10))
                                : parseInt(this.cells[this.cells.length - 2].slice(8, 10));
                            const date = selectedCell.slice(0, 8);
                            const user = selectedCell.slice(10, selectedCell.length);
                            //формируем массив выбора слева направо
                            if (arrEnd > arrStart) {
                                for (let i = arrStart; i <= arrEnd; i++) {
                                    if (!workload.days[i - 1].isDayOff) {
                                        if (i < 10) {
                                            const item = `${date}0${i}${user}`;
                                            arrCells.push(item);
                                        } else {
                                            const item = `${date}${i}${user}`;
                                            arrCells.push(item);
                                        }
                                    }
                                }
                            } else {
                                ////формируем массив выбора справа налево
                                for (let i = arrEnd; i <= arrStart; i++) {
                                    if (!workload.days[i - 1].isDayOff) {
                                        if (i < 10) {
                                            const item = `${date}0${i}${user}`;
                                            arrCells.push(item);
                                        } else {
                                            const item = `${date}${i}${user}`;
                                            arrCells.push(item);
                                        }
                                    }
                                }
                            }
                            this.cells = arrCells;
                            if (workload.user.nonProduction) {
                                this.cells = [];
                            }
                        }

                        const arr = this.cells;

                        this.cells = [];
                        this.setState({ selectedItems: this.cells });

                        if (arr.length > 0) {
                            onSelectCells(arr);
                            onSelectCell("");
                        }
                    }}
                >
                    {workload.days.map((day, index) => {
                        const next = index + 1 < workload.days.length ? workload.days[index + 1] : undefined;
                        const preToday = !!next && next.isToday();

                        return (
                            <WorkloadCell
                                key={day.day}
                                day={day}
                                preToday={preToday}
                                onEdit={this.onDayEditStart}
                                locked={false}
                                selectedCells={selectedCells}
                                cells={this.cells}
                                onSelectCel={selectCel}
                                selectedCell={selectedCell}
                                nonProduction={workload.user.nonProduction}
                                {...selector}
                            />
                        );
                    })}
                </div>

                {formStore && (
                    <WorkloadInput
                        store={formStore}
                        isOpened={true}
                        onClose={this.onDayEditStop}
                        onSave={onSave}
                        readOnly={false}
                        removeDay={removeDay}
                        closeDialog={this.closeDialog}
                    />
                )}
            </div>
        );
    }

    onDayEditStart = (day: WorkloadDayType) => {
        const { orders, tasks } = this.props;

        this.setState({
            formStore: makeWorkloadInputStore(day, getSnapshot(orders.orders), getSnapshot(tasks.tasks)),
        });
    };
    closeDialog = () => {
        this.setState({ formStore: null });
    };
    onDayEditStop = () =>
        this.setState({
            formStore: null,
        });
}

export const WorkloadUserRow = observer(Row);

interface WorkloadUserRowProps extends DaySelector, WorkloadSaver {
    workload: UserWorkloadType;
    orders: OrderDictionaryType;
    tasks: TasksDictionaryType;
    canManage: boolean;
    mode: WorkloadCellMode;
    userLogin: string;
    selectedCells: string[];
    onSelectCells: (cell: string[]) => void;
    removeDay: () => void;
}

interface WorkloadUserRowState {
    formStore: WorkloadDayStoreType | null;
    selectedItems: string[];
    mouseDown: boolean;
}
