import React from "react";
import { ColDef } from "@ag-grid-community/core";
import { nameof, isNotNull } from "modules/common/services/typescript";
import { formatMoney } from "modules/common/components/money/Money";
import { Dialog, Classes, Button } from "@blueprintjs/core";
import { AgGridReact } from "@ag-grid-community/react";
import { ROW_HEIGHT, HEADER_HEIGHT, DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { agGridLocale } from "modules/root/services/locale";
import styles from "./PaymentList.module.scss";
import { texts } from "modules/common/texts";
import { FlowDatasetOrderPaymentSnapshotType, PLAN, ACTUAL } from "modules/main/models/orders-chart";
import { getRowNodeId, DateCellContext, DateCell } from "./DateCell";
import { GridTheme } from "modules/common/components/table/GridTheme";

const columns = (showOutsourcer: boolean, showActuals: boolean, dateEditable: boolean) => {
    const result: (ColDef | null)[] = [
        {
            sortable: true,
            headerName: "Дата",
            field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.date) as string,
            width: 100,
            sort: "asc",
            cellClass: dateEditable ? "flat" : "centered",
            comparator: (valueA, valueB, nodeA, nodeB) => {
                const dateA = (nodeA.data as FlowDatasetOrderPaymentSnapshotType).sortableDate;
                const dateB = (nodeB.data as FlowDatasetOrderPaymentSnapshotType).sortableDate;
                return dateA.localeCompare(dateB);
            },
            cellRendererFramework: dateEditable ? DateCell : undefined,
        },
        {
            sortable: true,
            headerName: "Сумма",
            field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.sum) as string,
            width: 140,
            cellClass: "righted",
            valueFormatter: (params) => formatMoney(params.value, { noSymbol: true }),
        },
        showActuals
            ? {
                  sortable: true,
                  headerName: "О",
                  field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.actualPaymentsSign) as string,
                  width: 50,
                  cellClass: "centered",
              }
            : null,
        showOutsourcer
            ? {
                  sortable: true,
                  headerName: "Контрагент",
                  field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.outsourser) as string,
                  width: 230,
              }
            : null,
        {
            sortable: true,
            headerName: "Объект",
            field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.orderName) as string,
            width: 280,
        },
        showOutsourcer
            ? {
                  sortable: true,
                  headerName: "Вид работ",
                  field: nameof((a: FlowDatasetOrderPaymentSnapshotType) => a.workType) as string,
                  width: 100,
              }
            : null,
    ];

    return result.filter(isNotNull);
};

export class PaymentList extends React.PureComponent<PaymentListProps> {
    private options1 = DefaultAgGridOptions();
    private options2 = DefaultAgGridOptions();

    render() {
        const { palnPayments, actualPayments, onClose, onPrint, title, showOutsourcer } = this.props;
        const bottomPlan = buildPaymentsBottomLine(palnPayments);
        const bottomActual = buildPaymentsBottomLine(actualPayments);

        return (
            <Dialog
                isCloseButtonShown={true}
                isOpen={true}
                className={`${styles.list} ${showOutsourcer ? "" : styles.short}`}
                onClose={onClose}
                title={title}
                backdropClassName="standard"
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    <GridTheme bordered={true}>
                        <h3>{PLAN}</h3>
                        <AgGridReact
                            rowSelection="single"
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={columns(showOutsourcer, true, true)}
                            rowData={palnPayments}
                            rowHeight={ROW_HEIGHT}
                            headerHeight={HEADER_HEIGHT}
                            getRowNodeId={getRowNodeId}
                            localeText={agGridLocale}
                            pinnedBottomRowData={bottomPlan}
                            gridOptions={this.options1}
                            context={this.props}
                            immutableData={true}
                            modules={defaultModules}
                        />
                    </GridTheme>
                    <GridTheme bordered={true}>
                        <h3>{ACTUAL}</h3>
                        <AgGridReact
                            rowSelection="single"
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={columns(showOutsourcer, false, false)}
                            rowData={actualPayments}
                            rowHeight={ROW_HEIGHT}
                            headerHeight={HEADER_HEIGHT}
                            getRowNodeId={getRowNodeId}
                            localeText={agGridLocale}
                            pinnedBottomRowData={bottomActual}
                            gridOptions={this.options2}
                            context={this.props}
                            immutableData={true}
                            modules={defaultModules}
                        />
                    </GridTheme>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button icon="print" onClick={onPrint} text={texts.print} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export function buildPaymentsBottomLine(payments: FlowDatasetOrderPaymentSnapshotType[]) {
    const sum = payments.reduce((acc, p) => acc + p.sum, 0);
    const bottom: FlowDatasetOrderPaymentSnapshotType[] = [
        {
            created: "",
            date: texts.total,
            day: "",
            guid: "",
            name: "",
            sum: sum,
            sortableDate: "",
            money: formatMoney(sum, { noSymbol: true }),
            hasActualPayments: false,
            actualPaymentsSign: "",
            orderName: "",
            outsourser: "",
            workType: "",
            comment: "",
            type: "",
        },
    ];
    return bottom;
}

interface PaymentListProps extends DateCellContext {
    palnPayments: FlowDatasetOrderPaymentSnapshotType[];
    actualPayments: FlowDatasetOrderPaymentSnapshotType[];
    title: string;
    showOutsourcer: boolean;
    onClose: () => void;
    onPrint: () => void;
}
