import React from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import styles from "./EmployeePositionDetails.module.scss";
import EmployeePositionSchema from "./validationPosition";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes, Switch, Tooltip, Position } from "@blueprintjs/core";
import { EmployeePositionType, fields, ACCEPT } from "../../models/employee-position";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { ReadOnlyFormElement, StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { Prompt } from "react-router";
import { texts } from "modules/common/texts";
import { onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { toString } from "modules/common/services/strings";
import { DefaultSelectedOption, SimpleSelect, renderSingleOption } from "modules/common/services/form/select";
import { DepartmentDictionaryType } from "../../models/department-dictionary";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { FileLink } from "modules/common/components/files/FileLink";
import { EMPTY_OBJECT_ID } from "modules/common/constants";

const digits = onlyDigitsInputKeyPress();

class EmployeePositionDetailsBase extends React.PureComponent<EmployeePositionDetailsProps, EmployeePositionState> {
    private schema: Schema<any>;
    private commentArea: HTMLTextAreaElement | null = null;
    private didLoaded: boolean = false;
    constructor(props: EmployeePositionDetailsProps) {
        super(props);

        this.schema = EmployeePositionSchema();
        this.state = { fileState: { fileId: "", fileName: "" } };
    }

    private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.commentArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.commentArea);
    };

    public componentDidMount() {
        const { departments } = this.props;
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
        departments.load();
    }

    public componentDidUpdate() {
        this.resize();
        const { manualFile } = this.props;

        if (manualFile) {
            if (!this.didLoaded) {
                this.setState({ fileState: manualFile });
                this.didLoaded = true;
            }
        }
    }

    render() {
        const { schema, props } = this;
        const { employeePosition, onSaved, onRemoved, canChange, departments, department } = props;
        const positionDepartments = buildDepartmwentsOptions(departments);

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(employeePosition, department)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await employeePosition.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            formProps.setSubmitting(true);
                            const success = await employeePosition.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    {!employeePosition.isNewlyCreated && (
                                        <ReadOnlyFormElement
                                            value={employeePosition.id}
                                            label={
                                                <span className="planr-input-label-help">
                                                    Идентификатор&nbsp;&nbsp;
                                                    <Tooltip
                                                        content="Используйте в настройках приложения для должности системного администратора"
                                                        position={Position.RIGHT}
                                                    >
                                                        <GeneralIcon type="general-question" />
                                                    </Tooltip>
                                                </span>
                                            }
                                            inline={true}
                                            style={{ position: "relative" }}
                                        >
                                            <GeneralIcon
                                                type="general-copy"
                                                title="Скопировать"
                                                className={styles.copyId}
                                                onClick={employeePosition.copyId}
                                            />
                                        </ReadOnlyFormElement>
                                    )}

                                    <StandardFormInput
                                        name={fields.order}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                type="text"
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                onKeyPress={digits}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.departmentId}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            let option = positionDepartments[field.value];
                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <SimpleSelect
                                                        className={`${Classes.FILL} planr-default-input`}
                                                        filterable={false}
                                                        activeItem={option}
                                                        inputProps={field}
                                                        items={Object.values(positionDepartments)}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(item) => {
                                                            form.setFieldValue(field.name, item.id);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        disabled={!canChange || departments.isEmpty}
                                                    >
                                                        <DefaultSelectedOption
                                                            option={option}
                                                            empty={departments.isEmpty}
                                                        />
                                                    </SimpleSelect>
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                    <StandardFormInput
                                        name={fields.nonProduction}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <Switch
                                                checked={field.value}
                                                {...field}
                                                large={true}
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.comment}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.commentAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>

                                    <div
                                        style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}
                                    >
                                        <span
                                            className="planr-form-input"
                                            style={{ fontSize: "12px", color: "#00273D" }}
                                        >
                                            Долж. инструкция
                                        </span>
                                        <Field name="manualFile">
                                            {(fieldProps: FieldProps) => (
                                                <>
                                                    {this.state.fileState.fileId === "" && canChange && (
                                                        <PlanRDndUploader
                                                            style={{
                                                                width: "380px",
                                                            }}
                                                            onFileSelected={async (file: File) => {
                                                                const { field, form } = fieldProps;

                                                                const success = await employeePosition.uploadFile(file);
                                                                success && form.setFieldValue(field.name, success);
                                                                success &&
                                                                    form.setFieldValue(fields.fileId, success.fileId);
                                                                success && form.setFieldTouched(field.name, true);
                                                                if (success) {
                                                                    const newFile = {
                                                                        fileId: success.fileId,
                                                                        fileName: success.fileName,
                                                                    };
                                                                    success && this.setState({ fileState: newFile });
                                                                }
                                                            }}
                                                            accept={ACCEPT}
                                                        />
                                                    )}
                                                    {this.state.fileState.fileId !== "" && (
                                                        <FileLink
                                                            style={{
                                                                width: "380px",
                                                                height: "58px",
                                                            }}
                                                            baseUrl={this.props.baseUrl}
                                                            file={this.state.fileState}
                                                            readOnly={!canChange}
                                                            onRemove={() => {
                                                                const { form } = fieldProps;
                                                                form.setFieldValue(fields.fileId, EMPTY_OBJECT_ID);
                                                                this.setState({
                                                                    fileState: { fileId: "", fileName: "" },
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`должность ${employeePosition.name}`}
                                                isNewlyCreated={employeePosition.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const EmployeePositionDetails = observer(EmployeePositionDetailsBase);

interface EmployeePositionDetailsProps {
    employeePosition: EmployeePositionType;
    onSaved: () => void;
    onRemoved: () => void;
    canChange: boolean;
    departments: DepartmentDictionaryType;
    department: string;
    manualFile: { fileId: string; fileName: string } | null;
    baseUrl: string;
}

interface EmployeePositionState {
    fileState: { fileId: string; fileName: string };
}

function buildDepartmwentsOptions(departmetns: DepartmentDictionaryType) {
    const result: TStringMap<SelectItem> = {};

    departmetns.departments.forEach(({ id, label }) => {
        result[id] = { id, label };
    });

    return result;
}

function getFormValues(employeePosition: EmployeePositionType, dep: string) {
    return {
        ...getSnapshot(employeePosition),
        order: toString(employeePosition.order),

        [fields.departmentId]: employeePosition.departmentId || dep,
    };
}
