import { Button, Classes, FormGroup } from "@blueprintjs/core";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import { Constants } from "modules/root/models/constants";
import React, { useCallback } from "react";
import { CommonBusinessEventProps, Error, star } from "./common";
import { EventTypes, fields } from "modules/orders-manage/models/order-events";
import { observer } from "mobx-react";

const Base = ({ event, readonly }: CommonBusinessEventProps) => {
    const status = Constants.changeStatusBusinessEventValuesOptions.find((o) => o.id === event.model.value);

    const onChangeHandler = useCallback(
        (item: SelectItem) => {
            event.model.setValue(fields.status, item.id);
        },
        [event]
    );

    return (
        <FormGroup
            labelInfo={star}
            helperText={event.model.validate[fields.status]}
            intent={event.model.validate[fields.status] ? Error : "none"}
            className={Classes.SMALL}
        >
            <div className={`${Classes.INPUT_GROUP}`}>
                <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    filterable={false}
                    activeItem={status}
                    items={Constants.changeStatusBusinessEventValuesOptions}
                    itemRenderer={renderSingleOption}
                    onItemSelect={onChangeHandler}
                    disabled={readonly}
                >
                    {readonly && <Button fill={true} className="selected-option" text={status?.label} />}
                    {!readonly && (
                        <DefaultSelectedOption
                            option={status}
                            empty={!Constants.changeStatusBusinessEventValuesOptions.length}
                        />
                    )}
                </SimpleSelect>
            </div>
        </FormGroup>
    );
};

Base.TYPE = EventTypes.ChangeStatusBusinessEvent;

export const ChangeStatusBusinessEvent = observer(Base);
