import React from "react";
import { observer } from "mobx-react";
import { OrderTimesheetSpendingType } from "modules/orders-manage/models/order-spending";
import { Collapse } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import styles from "./TimesheetSpendings.module.scss";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { Caret } from "modules/common/components/collapse/Caret";
import { formatMoney } from "../../../../common/components/money/Money";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";

export const TimesheetSpendings = observer(({ spendings, collapser }: TimesheetSpendingsProps) => {
    return (
        <div className={`${styles.spendings}`}>
            {spendings.spendingsMap.map(({ label, employee, hours, money, comment }) => {
                const index = label.lastIndexOf(" ");
                const newLabel = label.slice(0, index);

                return (
                    <div className={`${styles.period}`} key={label}>
                        <div className={styles.label} onClick={() => collapser.toggle(label)}>
                            <Caret collapsed={!collapser.plain[label]} style={{ top: "3px", right: "5px" }} />

                            <span className={styles.departmentHeader} title={comment}>
                                {newLabel}
                            </span>

                            <div className={styles.labelNumbers}>
                                <div className="order-total " style={{ top: "-18px", left: "222px", maxWidth: "90px" }}>
                                    Часы: {formatHours(spendings.showMinutes ? hours : Math.round(hours))}
                                </div>
                                <div className="actual-total " style={{ top: "-18px", left: "322px" }}>
                                    Всего затрат:&nbsp;
                                    <Money className="spendings-money" amount={money} />
                                </div>
                            </div>
                        </div>
                        <Collapse isOpen={!!collapser.plain[label]}>
                            <div className={styles.row}>
                                <div className={` ${styles.department} ${styles.info}`}>Сотрудник</div>
                                <div className={` ${styles.hours} ${styles.info}`}>Часы</div>
                                <div className={` ${styles.money} ${styles.info}`}>
                                    {
                                        <>
                                            Всего затрат{" "}
                                            {employee.find(
                                                (row) => row.hoursForExpertise && row.hoursForExpertise > 0
                                            ) && <span className={styles.shortMoney}>(Ответы на замечания)</span>}
                                        </>
                                    }
                                </div>
                            </div>

                            {employee.map((row) => (
                                <div className={styles.row} key={row.employer.id}>
                                    <div className={styles.department}>{row.employer.name}</div>
                                    <div className={styles.hours}>
                                        {formatHours(spendings.showMinutes ? row.hours : Math.round(row.hours))}
                                    </div>
                                    <div className={styles.money}>
                                        {formatMoney(row.spending)}{" "}
                                        {row.hoursForExpertise && row.hoursForExpertise > 0 ? (
                                            <span className={styles.shortMoney}>
                                                ({formatMoney(row.spendingExpertise)} (
                                                {formatHours(
                                                    spendings.showMinutes
                                                        ? row.hoursForExpertise
                                                        : Math.round(row.hoursForExpertise)
                                                )}
                                                ))
                                            </span>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </Collapse>
                    </div>
                );
            })}
        </div>
    );
});

interface TimesheetSpendingsProps {
    spendings: OrderTimesheetSpendingType;
    collapser: SectionCollapserType;
}
