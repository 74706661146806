import React, { useCallback } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import styles from "./IndicatorsDetails.module.scss";
import IndicatorSchema from "./indicator-validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, Classes } from "@blueprintjs/core";
import { IndicatorType, fields } from "modules/dictionaries/order-indicators/models/indicator";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";

const schema = IndicatorSchema();

export const IndicatorDetails: React.FC<IndicatorDetailsProps> = observer((props) => {
    const { indicator, canChange, onSaved, onRemoved } = props;

    const onSubmit = useCallback(
        async (values: FormValues, form: FormikHelpers<FormValues>) => {
            if (!canChange) {
                return;
            }

            const succsessfull = await indicator.save(values);
            form.setSubmitting(false);

            if (succsessfull) {
                form.resetForm();
                onSaved();
            }
        },
        [canChange, onSaved, indicator]
    );

    return (
        <div className={styles.details}>
            <Formik<FormValues>
                initialValues={getFormValues(indicator)}
                enableReinitialize={true}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                {(formProps) => {
                    const onRemove = async () => {
                        formProps.setSubmitting(true);
                        const success = await indicator.delete();
                        formProps.setSubmitting(false);
                        success && onRemoved();
                    };

                    return (
                        <Form autoComplete="off">
                            <Prompt when={formProps.dirty && canChange} message={texts.messages.leaveConfiramtion} />
                            <div className={Classes.DIALOG_BODY}>
                                <StandardFormInput
                                    name={fields.label}
                                    schema={schema}
                                    small={true}
                                    className="planr-form-input"
                                >
                                    {({ field }) => (
                                        <InputGroup
                                            id={field.name}
                                            className="planr-default-input"
                                            {...field}
                                            type="text"
                                            autoComplete="off"
                                            data-lpignore="true"
                                            disabled={!canChange}
                                        />
                                    )}
                                </StandardFormInput>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                {canChange && (
                                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                        <StandardFormButtons
                                            {...formProps}
                                            isRemoved={false}
                                            what={`ед. измерения ${indicator.label}`}
                                            isNewlyCreated={indicator.isNewlyCreated}
                                            onRemove={onRemove}
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

interface IndicatorDetailsProps {
    indicator: IndicatorType;
    canChange: boolean;
    onSaved: () => void;
    onRemoved: () => void;
}

function getFormValues(indicator: IndicatorType) {
    return {
        ...getSnapshot(indicator),
    };
}

type FormValues = ReturnType<typeof getFormValues>;
