import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { ProjectPortfoliosStoreType } from "../models/project-portfolio-store";
import { ProjectPortfolioDetails } from "./details/ProjectPortfolioDetails";
import { ProjectPortfoliosList } from "./list/ProjectPortfoliosList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./ProjectPortfoliosPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class ProjectPortfoliosPage extends React.Component<
    ProjectPortfoliosPageProps & RouteComponentProps,
    ProjectPortfoliosPageState
> {
    private unregister: UnregisterCallback | null = null;

    constructor(props: ProjectPortfoliosPageProps & RouteComponentProps) {
        super(props);

        this.state = { portfolioId: getSelectedProjectPortfolioId(props.location.pathname) };
    }

    componentDidMount() {
        document.title = menu.dictionaries.nested.projectPortfolio.label;

        const { history } = this.props;
        const { portfolioId } = this.state;

        this.unregister = history.listen((location) => {
            const portfolioId = getSelectedProjectPortfolioId(location.pathname);

            if (this.state.portfolioId !== portfolioId) {
                this.setState({ portfolioId });
                this.loadDetails(portfolioId);
            }
        });

        this.loadList();
        this.loadDetails(portfolioId);
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { store, access } = this.props;
        const canChange = can(func.PROJECTPORTFOLIO_EDIT, access);

        return (
            <Block columned={true} className={styles.page}>
                <Route path={routes.dictionaries.projectPortfolio.details()}>
                    <DetailsPopup title="Портфель проектов" onCancel={this.onProjectPortfolioSelected}>
                        <ProjectPortfolioDetails
                            canChange={canChange}
                            projectPortfolio={store.details}
                            onSaved={this.onProjectPortfolioSaved}
                            onRemoved={this.onProjectPortfolioRemoved}
                        />
                    </DetailsPopup>
                </Route>
                {canChange && (
                    <PlanrButton
                        type="secondary"
                        size="small"
                        icon="general-plus-big"
                        onClick={this.onProjectPortfolioAdd}
                        style={{
                            width: "290px",
                            marginBottom: "32px",
                            borderRadius: "120px",
                        }}
                    >{`${texts.add} портфель проектов`}</PlanrButton>
                    // <DictioantyAddButton
                    //     onClick={this.onProjectPortfolioAdd}
                    //     label={`${texts.add} портфель проектов`}
                    // />
                )}
                <div className={styles.right}>
                    <Search query={store.list.query} onSearch={store.list.setQuery} />
                </div>
                <ProjectPortfoliosList
                    store={store.list}
                    onSelected={this.onProjectPortfolioSelected}
                    onRemoved={this.onProjectPortfolioRemoved}
                />
            </Block>
        );
    }

    onProjectPortfolioAdd = () => {
        this.onProjectPortfolioSelected(EMPTY_OBJECT_ID);
    };

    onProjectPortfolioSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.dictionaries.projectPortfolio.details(id) : routes.dictionaries.projectPortfolio.path;

        history.push(url);
    };

    onProjectPortfolioSaved = () => {
        this.onProjectPortfolioSelected("");
        this.loadList();
    };

    onProjectPortfolioRemoved = () => {
        const { history } = this.props;

        this.loadList();

        const url = routes.dictionaries.projectPortfolio.path;
        history.push(url);
    };

    loadList = () => this.props.store.list.load();

    loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(ProjectPortfoliosPage));

function getSelectedProjectPortfolioId(pathname: string) {
    const match = matchPath<ProjectPortfoliosPageState>(pathname, routes.dictionaries.projectPortfolio.details());

    return match && match.params ? match.params.portfolioId : "";
}

interface ProjectPortfoliosPageProps {
    store: ProjectPortfoliosStoreType;
    access: string[];
}

interface ProjectPortfoliosPageState {
    portfolioId: string;
}
