import React from "react";
import { Formik, Form } from "formik";
import styles from "./OrderStatusDetails.module.scss";
import OrderStatusSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { OrderStatusType, fields } from "../../models/order-status";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { ColorPicker } from "modules/common/components/form/ColorPicker";
import { Prompt } from "react-router";
import { texts } from "modules/common/texts";
import { Constants } from "modules/root/models/constants";
import { SimpleSelect, renderSingleOption, DefaultSelectedOption } from "modules/common/services/form/select";

const schema = OrderStatusSchema();

class OrderStatusDetailsBase extends React.PureComponent<OrderStatusDetailsProps> {
    private descriptionArea: HTMLTextAreaElement | null = null;

    private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.descriptionArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.descriptionArea);
    };

    public componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    render() {
        const { orderStatus, onSaved, onRemoved, canChange } = this.props;
        const typeOptions = Constants.serviceOrderStatuses.map((type) => ({
            id: type,
            label: type,
        }));

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(orderStatus)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await orderStatus.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            if (!canChange) {
                                return;
                            }

                            formProps.setSubmitting(true);
                            const success = await orderStatus.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.name}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.description}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.descriptionAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.color}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => (
                                            <div className={` planr-default-input`}>
                                                <ColorPicker
                                                    color={field.value}
                                                    onSelected={(color) => {
                                                        form.setFieldValue(field.name, color);
                                                        form.setFieldTouched(field.name, true);
                                                    }}
                                                    readOnly={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.type}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const option = typeOptions.find((o) => o.id === field.value);
                                            const clear = () => {
                                                form.setFieldValue(field.name, "");
                                                form.setFieldTouched(field.name, true);
                                            };

                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <SimpleSelect
                                                        className={`full-width-select ${Classes.FILL}`}
                                                        filterable={false}
                                                        activeItem={option}
                                                        inputProps={field}
                                                        items={typeOptions}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(item) => {
                                                            form.setFieldValue(field.name, item.id);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        disabled={!canChange || !typeOptions.length}
                                                    >
                                                        <DefaultSelectedOption
                                                            option={option}
                                                            empty={!typeOptions.length}
                                                            onClear={clear}
                                                            disabled={!canChange}
                                                        />
                                                    </SimpleSelect>
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`статус договора ${orderStatus.name}`}
                                                isNewlyCreated={orderStatus.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const OrderStatusDetails = observer(OrderStatusDetailsBase);

interface OrderStatusDetailsProps {
    canChange: boolean;
    orderStatus: OrderStatusType;
    onSaved: () => void;
    onRemoved: () => void;
}

function getFormValues(status: OrderStatusType) {
    return {
        ...getSnapshot(status),
    };
}
