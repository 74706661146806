const crud = (controller: string) => ({
    list: () => `/${controller}/list`,
    dictionary: () => `/${controller}/dictionary`,
    details: (id: string) => `/${controller}/${id}`,
    update: (id: string) => `/${controller}/${id}`,
    delete: (id: string) => `/${controller}/${id}`,
    create: () => `/${controller}`,
});

export const apiUrls = {
    dashboard: {
        orders: {
            money: () => "/dashboard/orders",
            status: () => "/dashboard/orders/status",
        },
        accounts: (id = "") => `/dashboard/accounts/${id}`,
        refresh: () => `/dashboard/refresh`,
        orderAgents: () => `/dashboard/order-agents`,
        employee: () => "/dashboard/employee",
        salary: () => "/dashboard/salary",
        companyAge: () => "/dashboard/company-age",
        income: () => "/dashboard/income",
        engineers: () => "/dashboard/engineers",
        dividends: () => "/dashboard/dividends",
        production: () => "/dashboard/production",
        profitability: () => "/dashboard/profitability",
        workingOut: () => "/dashboard/workingout",
        incomeToEmployee: () => "/dashboard/income-to-employee",
        overhead: {
            absolute: () => "/dashboard/overhead/absolute",
            relative: () => "/dashboard/overhead/relative",
        },
        print: () => `/dashboard/print`,
    },
    orders: {
        detailedList: "/orders/detailed-list",
        reportOrderYear: "orders/order-year-report",
        workResult: (id: string) => `/orders/${id}/content-tasks/result`,
        workResultComment: (id: string) => `/orders/${id}/content-tasks/result`,
        workResultCommentFile: (id: string) => `/orders/${id}/content-tasks/result/file`,
        workResultFile: (id: string, fileId: string) => `/orders/${id}/content-tasks/result/${fileId}`,
        dictionary: () => "/orders/dictionary",
        summary: {
            details: (id: string) => `/orders/summary/${id}`,
            premiums: (id: string) => `/orders/summary/${id}/premiums`,
            short: (id: string) => `/orders/summary/short/${id}`,
            update: (id: string) => `/orders/summary/${id}`,
            create: () => "/orders/summary",
            delete: (id: string) => `/orders/summary/${id}`,
            remove: (id: string) => `/orders/summary-remove/${id}`,
            cancel: (id: string) => `/orders/summary-remove-cancel/${id}`,
            links: (id: string) => `/orders/summary/${id}/links`,
            contentReplacement: (id: string, fromId: string) =>
                `/orders/summary/${id}/content-replacement?fromId=${fromId}`,
            tasks: {
                versions: (id: string) => `/order/wopi/files/${id}/versions`,
                diff: (id: string) => `/order/wopi/files/${id}/diff`,
                fileInfo: (id: string, version = "") =>
                    version
                        ? `/order/wopi/files/${id}/version/${encodeURIComponent(version)}`
                        : `/order/wopi/files/${id}`,
                contents: (id: string) => `/order/wopi/files/${id}/contents`,
                copy: (id: string) => `/order/wopi/files/${id}/copy`,
                anchors: "/orders/anchors/technical-task",
            },
        },
        ipds: {
            update: (id: string) => `/orders/ipd/${id}`,
        },
        techIndicators: {
            update: (id: string) => `/orders/tech-indicators/${id}`,
        },
        orderCommunication: {
            update: (id: string) => `/orders/communication/${id}`,
        },
        paymentDate: (id: string) => `/orders/payment/${id}/date`,
        mails: {
            list: "/orders/mails",
            update: (id: string) => `/orders/mails/${id}`,
            create: "/orders/mails",
            assign: (id: string) => `/orders/${id}/assign-mails`,
            remove: "/orders/mails",
        },
        print: {
            outsourcerOrder: (id: string) => `/orders/${id}/print/outsourcer-order`,
            technicalTask: (id: string) => `/orders/${id}/print/technical-task`,
            ipdOrder: (id: string) => `/orders/${id}/print/ipd-order`,
            communication: (id: string) => `/orders/${id}/print/communication`,
            contentOrder: (id: string) => `/orders/${id}/print/content-order`,
            contentInvoice: (id: string) => `/orders/${id}/print/invoice-order`,
            printStages: (id: string) => `/orders/${id}/print/stages`,
        },

        contentTasks: {
            list: (id: string) => `/orders/${id}/content-tasks`,
            contentList: "/orders/content-tasks-list",
            addTask: (id: string, contentGuid: string) => `/orders/${id}/content-tasks/${contentGuid}`,
            addTasks: (id: string) => `/orders/${id}/content-tasks`,
            downloadStructure: (id: string) => `/orders/${id}/content-tasks/structure`,
            downloadSheets: (id: string) => `/orders/${id}/content-tasks/sheet`,
            removeTasks: (id: string) => `/orders/${id}/content-tasks`,
            removeVersion: (id: string, contentGuid: string) => `/orders/${id}/content-tasks/${contentGuid}/files`,
            saveFlag: (id: string, contentGuid: string) => `/orders/${id}/content-tasks/${contentGuid}/state`,
            saveWorkFiles: (id: string, contentGuid: string) => `/orders/${id}/content-tasks/${contentGuid}/files`,
            downloadFilesVersion: (id: string, contentGuid: string, version: number) =>
                `/orders/${id}/content-tasks/${contentGuid}/files?version=${version}`,
            saveSheet: (id: string, contentGuid: string) => `/orders/${id}/content-tasks/${contentGuid}/sheet`,
            anchors: "/orders/anchors/content-tasks",
        },
        orderAgents: {
            list: (id: string) => `/orders/${id}/order-agents`,
            saveOrderAgent: (id: string) => `/orders/${id}/order-agents`,
            removeTasks: (id: string) => `/orders/${id}/order-agents`,
        },
        logs: `/orders/logs`,
        stages: {
            list: (id: string) => `/orders/${id}/stages`,
            listIpd: (id: string) => `/orders/${id}/ipdStages`,
            update: (id: string, guid: string) => `/orders/${id}/stages/${guid}`,
            delete: (id: string, guid: string) => `/orders/${id}/stages/${guid}`,
            savelog: (id: string, guid: string) => `/orders/${id}/stageslog/${guid}`,
            create: (id: string) => `/orders/${id}/stages/`,
            comlpetions: {
                list: (id: string) => `/orders/${id}/stages/completions`,
            },
            expertises: {
                list: (id: string) => `/orders/${id}/stages/expertises`,
            },
            sentExpertises: {
                list: (id: string) => `/orders/${id}/stages/sentExpertises`,
            },
            consignments: {
                list: (id: string) => `/orders/${id}/stages/consignments`,
            },
            payments: {
                list: (id: string) => `/orders/${id}/stages/payments`,
                save: (id: string, guid: string) => `/orders/${id}/stages/${guid}/payments`,
                match: (id: string) => `/orders/${id}/stages/payments/match`,
                link: (id: string, stage: string) => `/orders/${id}/stages/${stage}/payments/link`,
            },
            timeline: (id: string) => `/orders/${id}/timeline`,
        },
    },
    outsourcers: {
        ...crud("outsourcers"),
        credentials: (id: string) => `/outsourcers/${id}/credentials`,
        self: () => `/outsourcers/self`,
        singleAccess: (id: string) => `/outsourcers/${id}/access/single`,
        confirmRequest: (id: string) => `/outsourcers/${id}/confirm`,
    },
    suppliers: {
        ...crud("suppliers"),
        listNoRules: () => `/suppliers/listNoRules`,
        listNoRulesAgents: () => `/suppliers/listNoRulesAgents`,
    },
    clients: {
        ...crud("clients"),
        credentials: (id: string) => `/clients/${id}/credentials`,
        print: (id: string) => `/clients/${id}/print`,
    },
    bankDetails: crud("bank-details"),
    workTypes: crud("work-types"),
    projectPortfolios: crud("project-portfolios"),
    orderTypes: crud("order-types"),
    orderStatuses: crud("order-statuses"),
    productionStages: crud("production-stages"),
    ipdTypes: crud("ipd-types"),
    agentsCategories: { ...crud("agents-categories"), listNoRules: () => `/agents-categories/listNoRules` },
    orderIndicators: crud("order-indicators"),
    indicators: crud("indicators"),
    overheadTypes: crud("overhead-types"),
    employeePositions: {
        ...crud("employee-positions"),
        singleAccess: (id: string) => `/employee-positions/${id}/access/single`,
        wholeAccess: (id: string) => `/employee-positions/${id}/access/whole`,
    },
    materialValues: {
        ...crud("material-values"),
        stop: (id: string) => `/material-values/stop/${id}`,
        restore: (id: string) => `/material-values/restore/${id}`,
        employer: (id: string) => `/material-values/employer/${id}`,
    },
    employee: {
        ...crud("employee"),
        restore: (id: string) => `/employee/${id}/restore`,
        premiums: (id: string) => `/employee/${id}/premiums`,
        removed: "/employee/list/removed",
        access: "/employee/access",
        singleAccess: (id: string) => `/employee/${id}/access/single`,
        credentials: (id: string) => `/employee/${id}/credentials`,
        ownSpendings: (id: string) => `/employee/${id}/own-spendings`,
        engineer: (id: string) => `/employee/${id}/engineer`,
        printUserDocuments: (id: string) => `/employee/${id}/documents`,
        printAllDocuments: `/employee/documents`,
        bot: {
            register: "/employee/bot/register",
        },
    },
    departments: crud("departments"),
    categories: crud("material-values/category"),
    deprGroups: crud("material-values/group"),
    tokens: {
        list: "/tokens/list",
        toggle: (id: string) => `/tokens/toggle/${id}`,
    },
    auth: {
        login: "/auth/login",
        logout: "/auth/logout",
    },
    application: {
        metadata: "/application/metadata",
        changelog: (version = "", limit = 5) => `/application/changelog?version=${version}&limit=${limit}`,
        subscribe: "/application/subscribe",
        startSettings: "/application/start-settings",
        files: {
            download: `/application/files/download`,
            print: `/application/files/print`,
            content: (id: string, name: string, type: "content" | "preview" = "content") =>
                `/application/files/${id}/content/${encodeURIComponent(name)}?type=${type}`,
            image: (id: string, name: string, type = "image") =>
                `/application/files/${id}/image/${encodeURIComponent(name)}?type=${type}`,
            upload: "/application/files",
            copy: "/application/files/copy",
        },
        generate: {
            guid: "/application/generate/guid",
            id: "/application/generate/id",
        },
        print: "/application/print",
        print2: "/application/print/v2",
        batchPrint: "/application/print/batch",
        batchPrint2: "/application/print/v2/batch",
        dictionary: {
            agents: "/application/dictionary/agents",
        },
    },
    timesheet: {
        list: "/timesheet",
        update: "/timesheet",
        comment: "/timesheet/comment",
        batch: "/timesheet/batch",
        export: "/timesheet/export",
        unit: {
            list: "/timesheet/unit",
            print: {
                departmetns: "/timesheet/unit/print/departmetns",
                direct: "/timesheet/unit/print/direct",
                workingout: "/timesheet/unit/print/workingout",
            },
            report: "timesheet/employer-spending-report",
        },
    },
    workload: {
        list: "/workload",
        update: "/workload",
        tasks: "/workload/tasks",
        batch: "/workload/batch",
        comment: "/workload/comment",
    },
    schedule: {
        list: "/schedule",
        save: (id: string) => `/schedule/${id}`,
        delete: "/schedule",
        fill: "/schedule/fill",
        recalculate: "/schedule/recalculate",
        overtimeScale: "/schedule/overtime-scale",
        calendar: "/schedule/calendar",
        print: "/schedule/print",
        vacation: {
            transfer: (userId: string) => `/schedule/vacation/${userId}/transfer`,
        },
    },
    financeRequests: {
        list: "/orders/finance-requests",
        dictionary: "/orders/finance-requests/dictionary",
        toggle: (id: string) => `/orders/finance-requests/toggle/${id}`,
        cancel: (id: string) => `/orders/finance-requests/cancel/${id}`,
        export: "/orders/finance-requests/export",
    },
    spendings: {
        ...crud("spendings"),
        orderSpendings: (id: string) => `/spendings/order-spendings/${id}`,
        import: {
            todo: "/spendings/import/todo",
            upload: "/spendings/import",
            commit: "/spendings/import/commit",
        },
        export: "/spendings/export",
        overhead: {
            copy: "/spendings/overhead/copy",
            summary: "/spendings/overhead/summary",
            detailed: (year: string) => `/spendings/overhead/detailed/${year}`,
            plan: "/spendings/overhead/plan",
            cancel: (id: string) => `/spendings/overhead/request/cancel/${id}`,
            toggle: (id: string) => `/spendings/overhead/request/toggle/${id}`,
            requests: () => "/spendings/overhead/request",
        },
    },
    messages: {
        unread: {
            list: "/messages/unread/list",
            count: "/messages/unread/count",
            open: (id: string) => `/messages/open/${id}`,
            openList: "/messages/open",
        },
        list: "/messages/list",
        delete: "/messages",
    },
    knowledgeBase: {
        list: "/knowledge-base/list",
        file: {
            delete: (id: string) => `/knowledge-base/file/${id}`,
            upload: "/knowledge-base/upload",
            rename: (id: string) => `/knowledge-base/file/${id}/rename`,
        },
        folder: {
            delete: (id: string) => `/knowledge-base/folder/${id}`,
            create: "/knowledge-base/folder",
            rename: (id: string) => `/knowledge-base/folder/${id}/rename`,
        },
    },
    news: {
        list: () => "/news/list",
        open: (id: string) => `/news/${id}/read`,
        update: (id: string) => `/news/${id}`,
        create: () => "/news",
        delete: (id: string) => `/news/${id}`,
    },
    surveys: {
        list: () => "/survey/list",
        create: () => "/survey",
        delete: (id: string, status = "Завершен") => `/survey/${id}/${status}`,
        vote: (id: string) => `/survey/${id}/vote`,
    },
    events: {
        list: () => "/calendar-events/list",
        create: () => "/calendar-events",
        update: (id: string) => `/calendar-events/${id}`,
        delete: (id: string) => `/calendar-events/${id}`,
    },
    settings: {
        update: `/application/settings`,
        get: `/application/settings`,
        shortSettings: `/application/shortSettings`,
    },
    templates: {
        technicalTask: {
            fileInfo: (id: string) => `/templates/wopi/files/${encodeURIComponent(id)}`,
            contents: (id: string) => `/templates/wopi/files/${encodeURIComponent(id)}/contents`,
        },
    },
};
