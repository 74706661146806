import { types, applySnapshot, getSnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { UserTimesheet, UserTimesheetSnapshotType } from "./user-timesheet";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { groupBy } from "lodash";
import {
    OrderDictionary,
    initialState as emptyOrders,
    OrderDictionaryItemSnapshotType,
} from "modules/orders-manage/models/order-dictionary";
import { texts } from "modules/common/texts";
import { TimesheetCellMode } from "../components/TimesheetCell";
import { buildCacheStorage } from "modules/common/services/cache";
import { nameof } from "modules/common/services/typescript";
import { printPdf, base64ToBlob } from "modules/common/services/files";
import { Constants } from "modules/root/models/constants";
import { saveAs } from "file-saver";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { Queryable } from "modules/common/models/queryable";
import { makePeriodName } from "modules/common/models/period";
import {
    TasksDictionary,
    WorkloadTasksSnapshotType,
    initialState as emptyTasks,
    WorkloadDayType,
    WorkloadDayStore,
} from "modules/spending/workload/models/workload-day";
import { makeWorkloadInputSnapshot } from "modules/spending/workload/models/workload-store";

const NAME = "TimesheetStore";
const cache = buildCacheStorage(NAME);
const zoomKey = () => nameof((s: TimesheetStoreSnapshotType) => s.zoom) as string;

export const TimesheetStore = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            orders: OrderDictionary,
            tasks: TasksDictionary,
            users: types.array(UserTimesheet),
            month: types.number,
            year: types.number,
            onlyMine: types.boolean,
            workDaysCount: types.number,
            zoom: types.number,
            loader: types.number,
            collapser: SectionCollapser,
            cellMode: types.union(
                types.literal<TimesheetCellMode>("hours"),
                types.literal<TimesheetCellMode>("projects")
            ),
            showMinutes: types.boolean,
        })
    )
    .views((self) => ({
        get workHoursCount() {
            return self.workDaysCount * Constants.workDayHours;
        },

        get filteredRows() {
            return self.users.filter((r) => {
                const user = r.user
                    ? `${r.user.label.toLowerCase()} ${r.user.department} ${r.user.position}`.toLowerCase()
                    : "";
                return user.includes(self.pureQuery);
            });
        },
    }))
    .views((self) => ({
        get exportModel() {
            return {
                year: self.year,
                month: self.month,
                colorByProjects: self.cellMode === "projects",
                employerIds: self.filteredRows.map((e) => e.user.id),
            };
        },
    }))
    .views((self) => ({
        get departmentMap() {
            return groupBy(self.filteredRows, (user) => user.user.department);
        },

        get transform() {
            return +(self.zoom / 100).toFixed(2);
        },

        get isEmpty() {
            return self.users.length === 0;
        },

        get isLoading() {
            return self.loader > 0;
        },
    }))
    .actions((self) => {
        const updateMe = (data: TimesheetSummary) => {
            applySnapshot(self.users, treat(data.users));
            self.workDaysCount = data.monthWorkDayCount;
            self.month = data.month;
            self.year = data.year;
            self.showMinutes = data.showMinutes;
        };

        const exportByMonth = async (print: boolean) => {
            const blocks = Object.keys(self.departmentMap).map((departmentName) => ({
                name: departmentName,
                users: self.departmentMap[departmentName].map((user) => user.truncated(self.showMinutes)),
                peoples: self.departmentMap[departmentName].length,
            }));

            const convertTo = print ? "pdf" : "xlsx";

            var file: FileDescription = await self.transport.post<any>(apiUrls.application.print, {
                variables: JSON.stringify({
                    blocks,
                    employeeCount: self.filteredRows.length,
                    daysCount: self.workDaysCount,
                    hoursCount: self.workHoursCount,
                }),
                templateId: "ExportTimesheetTab",
                convertTo,
            });
            file.name = `Табель ${makePeriodName(self.month, self.year)}.${convertTo}`;

            return file;
        };

        return {
            setPeriod: (year: number, month: number) => {
                self.year = year;
                self.month = month;
            },

            load: flow(function* (year: number | null = null, month: number | null = null) {
                self.orders.isEmpty && self.orders.load();
                self.tasks.isEmpty && self.tasks.load();
                self.loader++;
                try {
                    const data = yield self.transport.get<TimesheetSummary>(apiUrls.timesheet.list, {
                        params: {
                            year: year || self.year,
                            month: month || self.month,
                            onlyMine: self.onlyMine,
                        },
                    });

                    updateMe(data);

                    groupBy(data.users, (user) => self.collapser.set(user.user.department, true));

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                } finally {
                    self.loader--;
                }
            }),

            save: flow(function* (model: any) {
                try {
                    const data: UserTimesheetSnapshotType[] = treat(
                        yield self.transport.post<any>(apiUrls.timesheet.update, model)
                    );

                    const map = groupBy(data, (d) => d.user.id);
                    const replacement = self.users.map((u) => {
                        if (map[u.user?.id]) {
                            return map[u.user?.id][0];
                        }

                        return getSnapshot(u);
                    });

                    applySnapshot(self.users, replacement);

                    self.notify.success(texts.messages.saved);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            saveArrayOfCells: flow(function* (model: []) {
                try {
                    const days = { days: model };
                    const data: any = yield self.transport.post<any>(apiUrls.timesheet.batch, days);

                    data.forEach((item: any) => {
                        const user = item.users;
                        const map = groupBy(treat(user), (d) => d.user.id);
                        const replacement = self.users.map((u) => {
                            if (map[u.user?.id]) {
                                return map[u.user?.id][0];
                            }

                            return getSnapshot(u);
                        });

                        applySnapshot(self.users, replacement);
                    });
                    self.notify.success(texts.messages.saved);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            setComment: flow(function* (model: any) {
                try {
                    yield self.transport.post<any>(apiUrls.timesheet.comment, model);

                    const replacement = self.users.map((u) => {
                        if (u.user?.id === model.employerId) {
                            u.days.forEach((day: any) => {
                                if (day.comment) {
                                    day.isCommented = true;
                                } else {
                                    day.isCommented = false;
                                }
                            });
                        }

                        return getSnapshot(u);
                    });

                    applySnapshot(self.users, replacement);

                    self.notify.success(texts.messages.saved);

                    return true;
                } catch (er) {
                    self.notify.error(er);
                    return false;
                }
            }),
            print: flow(function* (mode: DepartmentBlockMode) {
                self.loader++;

                try {
                    let file: FileDescription;

                    if (mode === "month") {
                        file = yield exportByMonth(true);
                    } else {
                        file = yield self.transport.post(apiUrls.timesheet.export, {
                            ...self.exportModel,
                            asPdf: true,
                        });
                    }
                    const blob = yield base64ToBlob(file.content || "", file.mimeType);
                    const fileURL = URL.createObjectURL(blob);
                    const printer = printPdf(fileURL, true);
                    if (printer) {
                        printer.onclose = () => URL.revokeObjectURL(fileURL);
                    }
                } catch (er) {
                    self.notify.error(er);
                    return false;
                } finally {
                    self.loader--;
                }
            }),

            export: flow(function* (mode: DepartmentBlockMode) {
                self.loader++;

                try {
                    let file: FileDescription;

                    if (mode === "month") {
                        file = yield exportByMonth(false);
                    } else {
                        file = yield self.transport.post(apiUrls.timesheet.export, self.exportModel);
                    }

                    const blob: any = yield base64ToBlob(file.content || "", file.mimeType);
                    saveAs(blob, file.name);
                } catch (er) {
                    self.notify.error(er);
                    return false;
                } finally {
                    self.loader--;
                }
            }),

            setCellMode: (mode: TimesheetCellMode) => {
                self.cellMode = mode;
            },

            setZoom: (value: number) => {
                cache.set(zoomKey(), value);
                self.zoom = value;
            },
        };
    })
    .named(NAME);

function treat(data: UserTimesheetSnapshotType[]) {
    const newData = data.map((item) => {
        item.days.forEach((day) => {
            day.isCommented = !!day.comment;
        });
        return item;
    });
    return newData;
}

export type DepartmentBlockMode = "month" | "day";

export type TimesheetStoreType = typeof TimesheetStore.Type;
export type TimesheetStoreSnapshotType = typeof TimesheetStore.SnapshotType;

export const initialState = (onlyMine = false): TimesheetStoreSnapshotType => {
    const now = new Date();
    const zoom = cache.get(zoomKey(), 100);

    return {
        orders: emptyOrders(),
        tasks: emptyTasks(),
        users: [],
        month: now.getMonth() + 1,
        year: now.getFullYear(),
        onlyMine,
        loader: 0,
        workDaysCount: 0,
        cellMode: "hours",
        collapser: {
            opened: {},
        },
        zoom,
        pureQuery: "",
        query: "",
        showMinutes: false,
    };
};

export function makeTimesheetInputStore(
    day: WorkloadDayType,
    orders: OrderDictionaryItemSnapshotType[],
    tasks: WorkloadTasksSnapshotType[]
) {
    return WorkloadDayStore.create(makeWorkloadInputSnapshot(getSnapshot(day), orders, tasks, true), {
        http: day.transport,
        notificator: day.notify,
    });
}

interface TimesheetSummary {
    users: UserTimesheetSnapshotType[];
    monthWorkDayCount: number;
    month: number;
    year: number;
    showMinutes: boolean;
}
