import React from "react";
import { ApplicationStoreType } from "modules/root/models/store";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { DictionariesTabsPage } from "modules/dictionaries/components/DictionariesTabsPage";
import WorkTypesPage from "./work-types/components/WorkTypesPage";
import OrderTypesPage from "modules/dictionaries/order-types/components/OrderTypesPage";
import OrderStatusesPage from "modules/dictionaries/order-statuses/components/OrderStatusPage";
import ProductionStagesPage from "modules/dictionaries/production-stages/components/ProductionStagesPage";
import IpdTypesPage from "modules/dictionaries/ipd-types/components/IpdTypesPage";
import ProjectPortfoliosPage from "modules/dictionaries/project-portfolios/components/ProjectPortfoliosPage";
import BankDetailsPage from "modules/dictionaries/bank-details/components/BankDetailsPage";
import { observer } from "mobx-react";
import functionalities from "./functionalities";
import { canAny, can } from "modules/session/auth/access";
import { DeprGroupsPage } from "./inventory/components/DeprGroupPage";
import { CategoriesPage } from "./inventory/components/CategoriesPage";
import OrderIndicatorsPage from "./order-indicators/components/OrderIndicatorsPage";
import { IndicatorsPage } from "./order-indicators/components/IndicatorsPage";
import AgentsCategoriesPage from "./agents-categories/components/AgentsCategoriesPage";

const READ = Object.values(functionalities).filter((func) => func.endsWith("_READ"));

export const Routes = observer(({ store, baseUrl }: RoutesProps) => {
    const { loggedIn, access } = store.session;
    const visible = canAny(READ, access);

    return (
        <>
            <ProtectedRoute
                path={routes.dictionaries.path}
                loggedIn={loggedIn}
                render={(props) =>
                    visible ? (
                        <DictionariesTabsPage
                            {...props}
                            orderTypesPanel={
                                can(functionalities.ORDERTYPE_READ, access) ? (
                                    <OrderTypesPage store={store.orderTypes} access={access} />
                                ) : null
                            }
                            workTypesPanel={
                                can(functionalities.WORKTYPE_READ, access) ? (
                                    <WorkTypesPage store={store.workTypes} access={access} />
                                ) : null
                            }
                            orderStatusesPanel={
                                can(functionalities.ORDERSTATUS_READ, access) ? (
                                    <OrderStatusesPage store={store.orderStatuses} access={access} />
                                ) : null
                            }
                            projectPortfoliosPanel={
                                can(functionalities.PROJECTPORTFOLIO_READ, access) ? (
                                    <ProjectPortfoliosPage store={store.projectPortfolios} access={access} />
                                ) : null
                            }
                            productionStagesPanel={
                                can(functionalities.PRODUCTIONSTAGE_READ, access) ? (
                                    <ProductionStagesPage store={store.productionStages} access={access} />
                                ) : null
                            }
                            ipdTypesPanel={
                                can(functionalities.IPDTYPE_READ, access) ? (
                                    <IpdTypesPage store={store.ipdTypes} access={access} />
                                ) : null
                            }
                            agentsCategoriesPanel={
                                can(functionalities.AGENTSCATEGORIES_READ, access) ? (
                                    <AgentsCategoriesPage store={store.agentsCategories} access={access} />
                                ) : null
                            }
                            orderIndicatorsPanel={
                                can(functionalities.ORDERINDICATOR_READ, access) ? (
                                    <OrderIndicatorsPage
                                        store={store.orderIndicators}
                                        indicators={store.indicators}
                                        access={access}
                                    />
                                ) : null
                            }
                            inventoryPanel={
                                can(functionalities.INVENTORY_READ, access) ? (
                                    <CategoriesPage
                                        store={store.inventoryItems}
                                        categories={store.categories}
                                        access={access}
                                    />
                                ) : null
                            }
                            bankDetailsPanel={
                                can(functionalities.BANKDETAILS_READ, access) ? (
                                    <BankDetailsPage store={store.bankDetails} access={access} />
                                ) : null
                            }
                        />
                    ) : null
                }
            />
            <ProtectedRoute
                path={routes.dictionaries.inventory.pathGroups}
                loggedIn={loggedIn}
                render={(props) =>
                    can(functionalities.INVENTORY_READ, access) ? <DeprGroupsPage {...props} access={access} /> : null
                }
            />
            <ProtectedRoute
                path={routes.dictionaries.orderIndicator.pathIndicators}
                loggedIn={loggedIn}
                render={(props) =>
                    can(functionalities.ORDERINDICATOR_READ, access) ? (
                        <IndicatorsPage {...props} access={access} />
                    ) : null
                }
            />
            {/* <ProtectedRoute
                path={routes.orders.workResult()}
                loggedIn={loggedIn}
                render={(props) =>
                    can(func.ORDERS_CONTENT_TASK_READ, access) ||
                    can(ClientFunctionalities.CLIENTS_USER_ACCESS, access) ? (
                        <WorkResultPage
                            {...props}
                            store={store.workResult}
                            readOnly={!can(ClientFunctionalities.CLIENTS_USER_ACCESS, access)}
                            baseUrl={baseUrl}
                        />
                    ) : null
                }
            /> */}
        </>
    );
});

interface RoutesProps {
    baseUrl: string;
    store: ApplicationStoreType;
}
