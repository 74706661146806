import { types, applySnapshot, isAlive } from "mobx-state-tree";
import {
    BankDetails,
    fields as bankDetailsFields,
    initialState as bankDetailsInitialState,
    formatBankDetails,
    ShortBankDetails,
} from "modules/common/models/bank-details";
import { BaseEntity, DictionaryItem, isNewlyCreated } from "modules/common/models/entity";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { EMPTY_OBJECT_ID, DATE_TIME_FORMAT } from "modules/common/constants";
import moment from "moment";
import { texts } from "modules/common/texts";
import { nameof } from "modules/common/services/typescript";
import Schema, { LegalNameLabel } from "../components/details/validation";
import { getFieldLabel } from "modules/common/services/form/fields";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { MimeTypes } from "modules/common/services/files";
import { FileMetadata } from "modules/common/models/file-metadata";

export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png, MimeTypes.pdf].join(",");

export const SupplierDocument = FileMetadata;

const SupplierBase = types.compose(
    Transport,
    Notificator,
    BaseEntity,
    types.model({
        phone: types.string,
        phone2: types.string,
        phone3: types.string,
        email: types.string,
        comment: types.string,
        name: types.string,
        fullName: types.string,
        hasAgentOrder: types.boolean,
        bankDetails: types.maybeNull(BankDetails),
        documents: types.array(SupplierDocument),
        isAgentReward: types.boolean,
        agentReward: types.number,
        memberName: types.string,
        categories: types.array(types.string),
        materials: types.array(types.string),
    })
);

export const SupplierDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            bankDetails: types.maybeNull(ShortBankDetails),
        })
    )
    .named("OutsourcerDictionaryItem");

export const Supplier = SupplierBase.actions((self) => ({
    load: flow(function* (id: string) {
        try {
            const snapshot: any = isNewlyCreated(id)
                ? initialState()
                : yield self.transport.get<SupplierSnapshotType>(apiUrls.suppliers.details(id));

            isAlive(self) && applySnapshot(self, snapshot);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    save: flow(function* (model: SupplierSnapshotType & { documents: FileBase[] }) {
        try {
            const body: any = { ...model };

            body.documentIds = model.documents.map((d: FileBase) => d.fileId);
            delete body[fields.documents];

            self.isNewlyCreated
                ? yield self.transport.put<SupplierSnapshotType>(apiUrls.suppliers.create(), body)
                : yield self.transport.post<SupplierSnapshotType>(apiUrls.suppliers.update(self.id), body);

            self.notify.success(texts.messages.saved);
            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    delete: flow(function* () {
        if (self.isNewlyCreated) {
            return;
        }

        try {
            yield self.transport.delete<boolean>(apiUrls.suppliers.delete(self.id));
            self.notify.success(texts.messages.removed);

            isAlive(self) && applySnapshot(self, initialState());

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    uploadFile: flow(function* (file: File) {
        try {
            const model = new FormData();

            model.append("file", file);
            model.append("accept", ACCEPT);

            const result: UploadFileResult = yield self.transport.post<any>(apiUrls.application.files.upload, model);
            const { id, previewMimeType, mimeType } = result;

            const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
            return fileBase;
        } catch (er) {
            self.notify.error(er);
            return null;
        }
    }),

    format() {
        return formatSupplier(self);
    },
})).named("Supplier");

export type SupplierSnapshotType = typeof SupplierBase.SnapshotType;
export type SupplierType = typeof Supplier.Type;

export const fields = {
    name: nameof((a: SupplierType) => a.name) as string,
    fullName: nameof((a: SupplierType) => a.fullName) as string,
    phone: nameof((a: SupplierType) => a.phone) as string,
    email: nameof((a: SupplierType) => a.email) as string,
    comment: nameof((a: SupplierType) => a.comment) as string,
    bankDetails: nameof((a: SupplierType) => a.bankDetails) as string,
    hasAgentOrder: nameof((a: SupplierType) => a.hasAgentOrder) as string,
    documents: nameof((a: SupplierType) => a.documents) as string,
    ...bankDetailsFields(`${nameof((a: SupplierType) => a.bankDetails) as string}.`),
};

export const initialState = (): SupplierSnapshotType => ({
    id: EMPTY_OBJECT_ID,
    comment: "",
    created: moment().format(DATE_TIME_FORMAT),
    email: "",
    name: "",
    fullName: "",
    phone: "",
    phone2: "",
    phone3: "",
    hasAgentOrder: false,
    bankDetails: bankDetailsInitialState(),
    documents: [],
    isAgentReward: false,
    agentReward: 0,
    memberName: "",
    categories: [],
    materials: [],
});

export function formatSupplier(supplier: SupplierSnapshotType | null) {
    let result = "";

    if (supplier) {
        const schema = Schema();

        result += `${LegalNameLabel}: ${supplier.name}\n`;
        result += `${getFieldLabel(fields.fullName, schema, null)}: ${supplier.fullName}\n`;
        result += `${getFieldLabel(fields.phone, schema, null)}: ${formatPhoneNumber(supplier.phone)}\n`;
        result += `${getFieldLabel(fields.email, schema, null)}: ${supplier.email}\n`;
        result += `${getFieldLabel(fields.comment, schema, null)}: ${supplier.comment}\n`;
        result += formatBankDetails(supplier.bankDetails);
    }

    return result;
}
