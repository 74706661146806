import { Button, Classes, Dialog, Divider, Icon, InputGroup, MenuItem, Popover } from "@blueprintjs/core";
import { Switch } from "@blueprintjs/core/lib/esnext/components";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import { Field, FieldProps, Form, Formik, FormikHelpers, getIn } from "formik";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { EmployerName } from "modules/common/components/employer/EmployerName";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { isNewlyCreated } from "modules/common/models/entity";
import { MimeTypes } from "modules/common/services/files";
import { DefaultSelectedOption, RendererProps, SelectFactory } from "modules/common/services/form/select";
import { texts } from "modules/common/texts";
import { OwnSpendingsWidget } from "modules/main/components/own-spendings/OwnSpendingsWidget";
import { OwnSpendingsWidgetStoreType } from "modules/main/models/own-spendings-store";
import { Constants } from "modules/root/models/constants";
import { EmployeePositionDictionaryType } from "modules/spending/departments/models/employee-position-dictionary";
import React from "react";
import { Prompt } from "react-router";
import { buildPatchEmployer } from "../../../../common/services/form/values";
import { EmployerType, fields } from "../../models/employer";
import { EmployeeListType } from "../../models/employer-list";
import ChildDetails from "./ChildDetails";
import styles from "./EmployerDetails.module.scss";
import { EmployerDocuments } from "./EmployerDocuments";
import EmployerSchema, { employerLabels } from "./validation";

const PositionSelect = SelectFactory<PositionSelectItem>();
export const ACCEPT = [MimeTypes.jpeg, MimeTypes.png].join(",");

const old = new Date(1920, 1, 1);
const labels = employerLabels();
const schema = EmployerSchema();

class Employer extends React.Component<EmployerDetailsProps, EmployerDetailsState> {
    private formProps: FormikHelpers<any> | null = null;
    constructor(props: EmployerDetailsProps) {
        super(props);
        this.state = {
            showChildrenDialog: false,
            childId: "",
            avatar: "",
            showChild: false,
            showAvatarLoad: false,
        };
    }
    async componentDidMount() {
        const { employerList, employerId, employer } = this.props;

        if (employerId !== EMPTY_OBJECT_ID) {
            employerList.loadProjects(employerId);
            if (employer) {
                this.setState({ avatar: employer.avatar ? employer.avatar : "" });
            }
        }
    }
    render() {
        const { employer, onSaved, positions, canChange, employerId } = this.props;
        const { onPrintCredentials, baseUrl, spendings, orderAccess, employerList } = this.props;
        const isNewEmployer = isNewlyCreated(employerId);
        const employerPositions = buildPositionsOptions(positions);
        const initial = getFormValues(employer);
        const hasLogin = !!initial.login;
        const gipDepartmentId = Constants.gipDepartmentId;

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={initial}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }
                        const succsessfull = await employer.save(buildPatchEmployer(initial, values, ["documents"]));
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved(""); // employer.id
                        }
                    }}
                >
                    {(formProps) => {
                        this.formProps = formProps;
                        const { dirty, values } = formProps;
                        const position = positions.asMap[(formProps.values as any)[fields.positionId]];
                        const selectedDepartmentId = position ? position.departmentId : "";

                        return (
                            <Form autoComplete="off">
                                <Prompt when={dirty && canChange} message={texts.messages.leaveConfiramtion} />
                                <Dialog
                                    icon="info-sign"
                                    title="Дети"
                                    canEscapeKeyClose={true}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.showChildrenDialog}
                                    onClose={this.toggleChildrenDialog}
                                    style={{ width: `620px` }}
                                    backdropClassName="standard"
                                >
                                    <div className={Classes.DIALOG_BODY}>
                                        <div>
                                            {employer.children && employer.children.length > 0 && (
                                                <div className={styles.childrenRow} style={{ fontWeight: "bold" }}>
                                                    <div className={styles.childrenRowName}>Имя</div>
                                                    <div className={styles.childrenRowBDay}>Дата рождения</div>
                                                    <div className={styles.childrenRowDel}></div>
                                                </div>
                                            )}

                                            {employer.children &&
                                                employer.children.map((child) => (
                                                    <div
                                                        key={child.guid}
                                                        className={styles.childrenRow}
                                                        onDoubleClick={() => {
                                                            this.setState({ childId: child.guid });
                                                            this.toggleChild();
                                                        }}
                                                    >
                                                        <div className={styles.childrenRowName}>{child.name}</div>
                                                        <div className={styles.childrenRowBDay}>{child.birthday}</div>
                                                        <div className={styles.childrenRowDel}>
                                                            <GeneralIcon
                                                                type="general-trash"
                                                                style={{ color: "red", cursor: "pointer" }}
                                                                onClick={() => employer.removeChild(child.guid)}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            <div className={styles.checkers} style={{ marginTop: "20px" }}>
                                                <PlanrButton type="secondary" onClick={this.toggleChild}>
                                                    Добавить
                                                </PlanrButton>
                                                <PlanrButton
                                                    style={{ marginLeft: "10px" }}
                                                    type="graybtn"
                                                    onClick={this.toggleChildrenDialog}
                                                >
                                                    Закрыть
                                                </PlanrButton>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>
                                <Dialog
                                    icon="info-sign"
                                    title=""
                                    canEscapeKeyClose={true}
                                    canOutsideClickClose={false}
                                    isOpen={this.state.showChild}
                                    onClose={this.toggleChild}
                                    style={{ width: `600px` }}
                                    backdropClassName="standard"
                                >
                                    {
                                        <ChildDetails
                                            canChange={canChange}
                                            childId={this.state.childId}
                                            employer={employer}
                                            hideDialog={this.toggleChild}
                                        />
                                    }
                                </Dialog>

                                <div className={Classes.DIALOG_BODY}>
                                    <div>
                                        <div className={styles.avatar}>
                                            <Field name={fields.avatar}>
                                                {({ form, field }: FieldProps) => {
                                                    const onFileSelected = async (file: File) => {
                                                        var reader = new FileReader();
                                                        // const updateAvatar = (str: any) => {
                                                        //     this.setState({ avatar: str });
                                                        // };
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => {
                                                            form.setFieldValue(field.name, reader.result);
                                                            form.setFieldTouched(field.name, true);
                                                            this.toggleAvatarLoad();
                                                        };
                                                        reader.onerror = function (error) {};
                                                    };

                                                    const onRemove = () => {
                                                        form.setFieldValue(field.name, "");
                                                        form.setFieldTouched(field.name, true);
                                                    };

                                                    return (
                                                        <div className={styles.avatar}>
                                                            <Dialog
                                                                icon="info-sign"
                                                                title="Загрузка фото сотрудника"
                                                                canEscapeKeyClose={true}
                                                                canOutsideClickClose={true}
                                                                isOpen={this.state.showAvatarLoad}
                                                                onClose={this.toggleAvatarLoad}
                                                                style={{ width: `500px` }}
                                                                backdropClassName="standard"
                                                            >
                                                                <div className={Classes.DIALOG_BODY}>
                                                                    <div className={styles.avatarLoad}>
                                                                        <div>Примечания: </div>

                                                                        <ul>
                                                                            <li>Цветная фотография (ТОЛЬКО ЛИЦО);</li>
                                                                            <li>
                                                                                Ваше лицо не должно быть скрыто очками,
                                                                                шляпой или иными предметами, должно быть
                                                                                этичным;
                                                                            </li>
                                                                            <li>Размер изображения не более 5 мб;</li>
                                                                            <li>Формат – JPEG, PNG;</li>
                                                                            <li>Разрешение: 120х120px.</li>
                                                                        </ul>
                                                                        <div>
                                                                            <PlanRDndUploader
                                                                                accept={ACCEPT}
                                                                                onFileSelected={onFileSelected}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Dialog>
                                                            <span className={styles.avatarText}>Фото</span>

                                                            <div style={{ marginRight: "18px" }}>
                                                                {field.value ? (
                                                                    <Popover
                                                                        interactionKind="hover"
                                                                        className="planr-column-selector"
                                                                        content={
                                                                            <div className={styles.bigAvatarContainer}>
                                                                                {field.value ? (
                                                                                    <img
                                                                                        src={field.value}
                                                                                        alt={"avatar"}
                                                                                        className={styles.bigAvatar}
                                                                                    />
                                                                                ) : (
                                                                                    <Icon icon="user" iconSize={120} />
                                                                                )}
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={field.value}
                                                                            alt={"avatar"}
                                                                            className={styles.avatarImage}
                                                                        />
                                                                    </Popover>
                                                                ) : (
                                                                    <Icon icon="user" iconSize={55} />
                                                                )}
                                                            </div>
                                                            <PlanrButton
                                                                type="lightgreenish"
                                                                size="small"
                                                                icon="general-plus-big"
                                                                onClick={this.toggleAvatarLoad}
                                                            />
                                                            <PlanrButton
                                                                type="secondary"
                                                                size="small"
                                                                icon="general-trash"
                                                                onClick={onRemove}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            </Field>
                                        </div>
                                        <StandardFormInput
                                            name={fields.name}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    small
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.positionId}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field, form }) => {
                                                const option = employerPositions[field.value];

                                                return (
                                                    <div className={`${Classes.INPUT_GROUP}`}>
                                                        <PositionSelect
                                                            className={`planr-default-input ${Classes.FILL}`}
                                                            filterable={true}
                                                            itemPredicate={filterPositionPredicate}
                                                            activeItem={option}
                                                            inputProps={{
                                                                ...field,
                                                                placeholder: labels[field.name],
                                                            }}
                                                            items={Object.values(employerPositions)}
                                                            itemRenderer={PositionOptionRenderer}
                                                            onItemSelect={(item) => {
                                                                form.setFieldValue(field.name, item.id, true);
                                                            }}
                                                            disabled={!canChange || positions.isEmpty}
                                                        >
                                                            <DefaultSelectedOption
                                                                option={option}
                                                                empty={positions.isEmpty}
                                                                render={SelectedPositionRenderer}
                                                            />
                                                        </PositionSelect>
                                                    </div>
                                                );
                                            }}
                                        </StandardFormInput>
                                        {selectedDepartmentId !== "" && selectedDepartmentId !== gipDepartmentId && (
                                            <StandardFormInput
                                                name={fields.canBeEngineer}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        {...field}
                                                        large={true}
                                                        disabled={!canChange}
                                                    />
                                                )}
                                            </StandardFormInput>
                                        )}
                                        <StandardFormInput
                                            name={fields.hireDate}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field, form }) => {
                                                return (
                                                    <div className={`${Classes.INPUT_GROUP} `}>
                                                        <DatePicker
                                                            clasName="planr-default-input"
                                                            value={field.value}
                                                            onChange={(date) => {
                                                                form.setFieldValue(field.name, date);
                                                                form.setFieldTouched(field.name, true);
                                                            }}
                                                            disabled={!canChange}
                                                            small
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.contractNumber}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                    small
                                                />
                                            )}
                                        </StandardFormInput>
                                        <div className={styles.checkers}>
                                            <StandardFormInput
                                                name={fields.poohBah}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        {...field}
                                                        large={true}
                                                        disabled={!canChange}
                                                    />
                                                )}
                                            </StandardFormInput>

                                            <StandardFormInput
                                                name={fields.isBim}
                                                schema={schema}
                                                small={true}
                                                style={{ marginLeft: "90px" }}
                                                className="planr-form-input"
                                            >
                                                {({ field }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        {...field}
                                                        large={true}
                                                        disabled={!canChange}
                                                        style={{ marginLeft: "-100px" }}
                                                    />
                                                )}
                                            </StandardFormInput>
                                        </div>

                                        <StandardFormInput
                                            name={fields.workSince}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            label="Стаж в организации"
                                        >
                                            {() => {
                                                return (
                                                    <InputGroup
                                                        value={employer.workingPeriod}
                                                        className="planr-default-input"
                                                        autoComplete="off"
                                                        small
                                                        readOnly={true}
                                                    />
                                                );
                                            }}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.phone}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    small
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.email}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    small
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.address}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    className="planr-default-input"
                                                    small
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.birthday}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field, form }) => {
                                                return (
                                                    <div className={`${Classes.INPUT_GROUP} `}>
                                                        <DatePicker
                                                            clasName="planr-default-input"
                                                            value={field.value}
                                                            onChange={(date) => {
                                                                form.setFieldValue(field.name, date);
                                                                form.setFieldTouched(field.name, true);
                                                            }}
                                                            small
                                                            minDate={old}
                                                            disabled={!canChange}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.birthday}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            label="Возраст"
                                        >
                                            {() => {
                                                return (
                                                    <InputGroup
                                                        value={employer.agePeriod}
                                                        className="planr-default-input"
                                                        small
                                                        autoComplete="off"
                                                        readOnly={true}
                                                    />
                                                );
                                            }}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.birthday}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                            label="Дети"
                                        >
                                            {() => (
                                                <div style={{ display: "flex" }}>
                                                    <InputGroup
                                                        value={employer.childCount}
                                                        small
                                                        className="planr-default-input"
                                                        autoComplete="off"
                                                        readOnly={true}
                                                    />
                                                    <PlanrButton
                                                        size="small"
                                                        type="secondary"
                                                        onClick={this.toggleChildrenDialog}
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        Список
                                                    </PlanrButton>
                                                </div>
                                            )}
                                        </StandardFormInput>
                                        <StandardFormInput
                                            name={fields.hobby}
                                            schema={schema}
                                            small={true}
                                            className="planr-form-input"
                                        >
                                            {({ field }) => (
                                                <InputGroup
                                                    id={field.name}
                                                    {...field}
                                                    small
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    disabled={!canChange}
                                                />
                                            )}
                                        </StandardFormInput>
                                        <div className={styles.checkers}>
                                            <StandardFormInput
                                                name={fields.fired}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field, form }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        {...field}
                                                        large={true}
                                                        onChange={(e) => {
                                                            const newValue = !field.value;
                                                            const unchecked = !newValue;

                                                            field.onChange(e);
                                                            unchecked && form.setFieldValue(fields.fireDate, null);
                                                            unchecked && form.setFieldTouched(fields.fireDate, true);
                                                        }}
                                                        disabled={!canChange}
                                                    />
                                                )}
                                            </StandardFormInput>

                                            <StandardFormInput
                                                name={fields.canBeReturn}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                                label={<GeneralIcon type="general-boomerang" size={26}></GeneralIcon>}
                                                style={{ marginLeft: "70px" }}
                                            >
                                                {({ field }) => (
                                                    <Switch
                                                        checked={field.value}
                                                        {...field}
                                                        large={true}
                                                        disabled={!canChange}
                                                        style={{ marginLeft: "-100px" }}
                                                    />
                                                )}
                                            </StandardFormInput>
                                        </div>
                                        {getIn(values, fields.fired) && (
                                            <StandardFormInput
                                                name={fields.fireDate}
                                                schema={schema}
                                                label="Дата увольнения"
                                                required={true}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field, form }) => {
                                                    return (
                                                        <div className={`${Classes.INPUT_GROUP} `}>
                                                            <DatePicker
                                                                clasName="planr-default-input"
                                                                value={field.value}
                                                                onChange={(date) => {
                                                                    form.setFieldValue(field.name, date);
                                                                    form.setFieldTouched(field.name, true);
                                                                }}
                                                                small
                                                                disabled={!canChange}
                                                            />
                                                        </div>
                                                    );
                                                }}
                                            </StandardFormInput>
                                        )}
                                        <Divider style={{ marginTop: "-5px" }} />
                                        <br />
                                        {canChange && (
                                            <div className={styles.credentials}>
                                                <div>
                                                    <StandardFormInput
                                                        name={fields.login}
                                                        schema={schema}
                                                        small={true}
                                                        className="planr-form-input"
                                                    >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                id={field.name}
                                                                {...field}
                                                                className="planr-default-input"
                                                                autoComplete="off"
                                                                disabled={!canChange}
                                                                small
                                                            />
                                                        )}
                                                    </StandardFormInput>

                                                    <StandardFormInput
                                                        name={fields.newPassword}
                                                        schema={schema}
                                                        small={true}
                                                        label={hasLogin ? "Новый пароль" : "Пароль"}
                                                        className="planr-form-input"
                                                    >
                                                        {({ field }) => (
                                                            <InputGroup
                                                                id={field.name}
                                                                {...field}
                                                                className="planr-default-input"
                                                                autoComplete="off"
                                                                small
                                                                disabled={!canChange}
                                                            />
                                                        )}
                                                    </StandardFormInput>
                                                </div>
                                                {!!employer.login && (
                                                    <div className={styles.printCredentials}>
                                                        <PlanrButton
                                                            type="blueish"
                                                            size="small"
                                                            onClick={() => onPrintCredentials(employer)}
                                                            icon="general-print"
                                                        ></PlanrButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className={styles.files}>
                                        <Field name="documents">
                                            {(fieldProps: FieldProps) => (
                                                <EmployerDocuments
                                                    canChange={canChange}
                                                    employer={employer}
                                                    fieldProps={fieldProps}
                                                    baseUrl={baseUrl}
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    {!isNewEmployer && (
                                        <div className={styles.projects}>
                                            <OwnSpendingsWidget
                                                store={spendings}
                                                withSpendings={true}
                                                collapsable={false}
                                                orderAccess={orderAccess}
                                                employeeList={employerList}
                                                employerId={employerId}
                                            />
                                        </div>
                                    )}
                                    {isNewEmployer && (
                                        <div className={styles.newEmployee}>
                                            <StandardFormInput
                                                name={fields.salary}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field, form }) => (
                                                    <MoneyInput
                                                        className="planr-default-input"
                                                        id={field.name}
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                        value={field.value}
                                                        onMoneyChange={(money) => {
                                                            form.setFieldValue(field.name, money.value, true);
                                                        }}
                                                    />
                                                )}
                                            </StandardFormInput>

                                            <StandardFormInput
                                                name={fields.bonus}
                                                schema={schema}
                                                small={true}
                                                className="planr-form-input"
                                            >
                                                {({ field, form }) => (
                                                    <MoneyInput
                                                        className="planr-default-input"
                                                        id={field.name}
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                        value={field.value}
                                                        onMoneyChange={(money) => {
                                                            form.setFieldValue(field.name, money.value, true);
                                                        }}
                                                    />
                                                )}
                                            </StandardFormInput>
                                        </div>
                                    )}
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS} style={{ marginTop: "-60px" }}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`сотрудника ${employer.name}`}
                                                isNewlyCreated={employer.isNewlyCreated}
                                                onRemove={this.onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }

    toggleChildrenDialog = () => {
        this.setState({ showChildrenDialog: !this.state.showChildrenDialog });
    };

    toggleChild = () => {
        this.setState({ showChild: !this.state.showChild });
    };

    toggleAvatarLoad = () => {
        this.setState({ showAvatarLoad: !this.state.showAvatarLoad });
    };

    onRemove = async () => {
        if (!this.formProps) {
            return;
        }

        this.formProps.setSubmitting(true);
        const success = await this.props.employer.delete();
        this.formProps.setSubmitting(false);
        success && this.props.onRemoved();
    };
}

export const EmployerDetails = observer(Employer);

interface EmployerDetailsProps {
    baseUrl: string;
    canChange: boolean;
    positions: EmployeePositionDictionaryType;
    employer: EmployerType;
    spendings: OwnSpendingsWidgetStoreType;
    onSaved: (id: string) => void;
    onRemoved: () => void;
    onPrintCredentials: (employer: EmployerType) => void;
    orderAccess: boolean;
    employerList: EmployeeListType;
    employerId: string;
}

interface EmployerDetailsState {
    showChildrenDialog: boolean;
    showChild: boolean;
    showAvatarLoad: boolean;
    childId: string;
    avatar: string;
}

function buildPositionsOptions(positions: EmployeePositionDictionaryType) {
    const result: TStringMap<PositionSelectItem> = {};

    positions.positions.forEach(({ id, label, departmentName }) => {
        result[id] = { id, label, department: departmentName };
    });

    return result;
}

function getFormValues(employer: EmployerType) {
    return {
        ...getSnapshot(employer),
        [fields.phone]: employer.formattedPhone,
        [fields.workSince]: employer.workSinceAsDate,
        [fields.birthday]: employer.birthdayAsDate,
        [fields.fireDate]: employer.fireDateAsDate,
        [fields.hireDate]: employer.hireDateAsDate,
        [fields.fired]: employer.fired,
        [fields.positionId]: employer.employePositionId,
        [fields.documents]: employer.documents.map((doc) => doc.asFileBase),
        [fields.salary]: 0,
        [fields.bonus]: 0,
        [fields.children]: employer.children,
    };
}

const SelectedPositionRenderer = ({ disabled, icon, text, option }: RendererProps) => {
    const item: PositionSelectItem | null | undefined = option as any;

    return (
        <Button
            disabled={disabled}
            style={{ height: "50px" }}
            fill={true}
            rightIcon={icon}
            className="selected-option"
            text={item ? <EmployerName name={text} position={item.department} /> : text}
        />
    );
};

const PositionOptionRenderer: ItemRenderer<PositionSelectItem> = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    const text = <EmployerName name={item.label} position={item.department} />;

    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item.id}
            onClick={handleClick}
            text={text}
            className={item.removed ? "removed" : ""}
        />
    );
};

const filterPositionPredicate: ItemPredicate<PositionSelectItem> = (query, item, _index, exactMatch) => {
    const normalizedQuery = query.toLowerCase().trim();

    if (exactMatch) {
        return item.label.toLowerCase() === normalizedQuery;
    } else {
        const normalizedText = `${item.label} ${item.department}`.toLowerCase();
        return normalizedText.indexOf(normalizedQuery) >= 0;
    }
};

interface PositionSelectItem extends SelectItem {
    department: string;
}
