import React from "react";
import ReactDOM from "react-dom";
import { outsourcedSpendingFields, getDebit } from "modules/orders-manage/models/order-spending";
import { OutsourcerDictionaryType } from "modules/agents/outsourcers/models/outsourcer-dictionary";
import { Icon, Classes, InputGroup, Spinner, Tooltip } from "@blueprintjs/core";
import { Money } from "modules/common/components/money/Money";
import { eat } from "modules/common/services/typescript";
import styles from "./OutsourcedSpendings.module.scss";
import { OutsourcerSelect } from "./OutsourcerSelect";
import { DraggableProvided } from "react-beautiful-dnd";
import { getDragItemStyle } from "modules/common/services/drag";
import { MoneyInput } from "modules/common/components/money/MoneyInput";
import { findIndex } from "lodash";
import { NumberFormatValues } from "react-number-format";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { preventSubmitKeyDown, onlyDigitsInputKeyPress } from "modules/common/services/form/values";
import { Constants } from "modules/root/models/constants";
import { DatePicker } from "modules/common/components/form/DatePicker";
import { formatDate } from "modules/common/services/formatting/date";
import { LARGE_ICON_AS_BUTTON_SIZE } from "modules/common/constants";
import {
    OrderFileSnapshotType,
    InvoiceFileSnapshotType,
    OrderFileLabel,
    OrderFileSorter,
} from "modules/orders-manage/models/order-file";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import { otherSpendingLabels, OutsourcedOrderSpendingSchema } from "../../validation";
import { OrderBase } from "../../summary/OrderBase";
import { OrderFiles } from "../../summary/OrderFiles";
import portal from "../../portal";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcons } from "modules/common/components/planr/icon/Generalcon";
import { FileUploaderHOC } from "modules/common/components/files/Uploader";
import { texts } from "modules/common/texts";
import { ACTUAL_PAYMENTS_BLOCK_NAME, MAIN_DATA_BLOCK_NAME, PLAN_PAYMENTS_BLOCK_NAME } from "./print-view-model";
import {
    outsourcerPaymentFields,
    OutsourcerPaymentSnapshotType,
} from "modules/agents/outsourcers/models/outsourcer-payment";
import { ActualPaymentSnapshotType } from "../../../../agents/outsourcers/models/outsourcer-payment";

const digits = onlyDigitsInputKeyPress();
const labels = otherSpendingLabels();
const fields = outsourcedSpendingFields;
const schema = OutsourcedOrderSpendingSchema(false);

const OutsourcerOrderLabel = OrderFileLabel("Договор");

export interface PaymentStatusToggler {
    toggleStatus?: (guid: string) => void;
    formDirty: boolean;
}

export class OutsourcedSpendingRow extends React.PureComponent<OutsourcedSpendingRowProps> {
    renderInternal() {
        const { spending, onRemove, onChange, paymentFactory, formDirty, printOrderFile } = this.props;
        const { toggleStatus, readOnly, agents } = this.props;
        const { draggable, highlight, disabled } = this.props;
        const outsourcerId = spending[fields.outsourcerId];

        const agent = agents.asMap[outsourcerId];
        const canPrintPhys = printOrderFile && agent && !agent.isLegalEntity && !agent.selfEmployed;
        const canPrintSelf = printOrderFile && agent && agent.selfEmployed;
        return (
            <div
                ref={draggable.innerRef}
                {...draggable.draggableProps}
                {...draggable.dragHandleProps}
                className={styles.row}
                style={this.getItemStyle()}
            >
                <div className={styles.left}>
                    <div className={styles.one}>
                        <div className={styles.wrapper}>
                            <div className={styles.one}>
                                {/** Выдано \ сдано \ принято */}
                                <div className={styles.taskState}>
                                    <TaskState spending={spending} readOnly={readOnly} onChange={onChange} />

                                    <div className="status-doc-del">
                                        {!(disabled || readOnly) && (
                                            <RemoveConfirmation
                                                onConfirmed={onRemove}
                                                what={() => "исполнителя (включая оплаты, договоры и другие данные)"}
                                                render={({ confirmRemoving }) => {
                                                    return (
                                                        <PlanrButton
                                                            icon="general-trash"
                                                            type={"graybtn"}
                                                            round={true}
                                                            onClick={confirmRemoving}
                                                            title={labels[fields.taskAccepted]}
                                                        />
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>

                                {/** Сумма и остаток */}
                                <div>
                                    {MAIN_DATA_BLOCK_NAME}
                                    <div>
                                        <OrderSum
                                            spending={spending}
                                            readOnly={disabled || readOnly}
                                            onChange={onChange}
                                        >
                                            <div style={{ minWidth: "155px" }}>
                                                <label>Срок выполнения (дни):</label>
                                                <InputGroup
                                                    value={spending[fields.days] ?? ""}
                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                        const val = parseInt(e.currentTarget.value, 10);
                                                        onChange(fields.days, isNaN(val) ? null : val);
                                                    }}
                                                    className="planr-default-input"
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    onKeyDown={preventSubmitKeyDown}
                                                    onKeyPress={digits}
                                                    disabled={disabled || readOnly}
                                                />
                                            </div>
                                        </OrderSum>

                                        <div className={styles.outsourcer}>
                                            <label>Исполнитель:</label>
                                            <OutsourcerSelect
                                                agents={agents}
                                                field={fields.outsourcerId}
                                                label={labels[fields.outsourcerId]}
                                                onChange={onChange}
                                                readOnly={disabled || readOnly}
                                                value={outsourcerId}
                                            />
                                        </div>
                                        <div style={{ marginTop: "33px", display: "flex" }}>
                                            {/* Номер и дата договора */}
                                            <OrderBase
                                                inline={true}
                                                what="Договор"
                                                schema={schema}
                                                number={spending[fields.orderNumber]}
                                                date={spending[fields.startDate]}
                                                onChange={onChange}
                                                addOrderFile={this.addOrderFile}
                                                readOnly={disabled || readOnly}
                                                upload={this.props.upload}
                                                newId={this.props.newId}
                                            >
                                                {(canPrintPhys || canPrintSelf) && (
                                                    <PlanrButton
                                                        icon="general-import"
                                                        type={"secondary"}
                                                        size="small"
                                                        onClick={this.printOrderFile}
                                                        title="Составить договор"
                                                        style={{
                                                            width: "60px",
                                                            marginTop: "11px",
                                                            height: "38px",
                                                            marginLeft: "12px",
                                                        }}
                                                    />
                                                )}
                                            </OrderBase>
                                        </div>
                                    </div>
                                    <div>
                                        {/* Список файлов договоров с подрядчиком */}
                                        <div className="order-files-list">
                                            <OrderFiles<OrderFileSnapshotType>
                                                readOnly={disabled || readOnly}
                                                removeLabel={OutsourcerOrderLabel}
                                                fileLabel={OutsourcerOrderLabel}
                                                sorter={OrderFileSorter}
                                                files={spending[fields.orders]}
                                                baseUrl={this.props.baseUrl}
                                                onRemove={(id) => {
                                                    const value = spending[fields.orders].filter(
                                                        (f: OrderFileSnapshotType) => f.guid !== id
                                                    );
                                                    onChange(fields.orders, value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <PlanPayments
                            outsourcer={spending[fields.outsourcerId]}
                            value={spending[fields.planPayments]}
                            factory={paymentFactory}
                            onChange={(paymnets) => {
                                onChange(fields.planPayments, paymnets);
                            }}
                            readOnly={readOnly}
                            formDirty={formDirty}
                            toggleStatus={toggleStatus}
                            baseUrl={this.props.baseUrl}
                            upload={this.props.upload}
                        />
                    </div>
                </div>

                <ActualPayments data={spending[fields.actualPayments]} highlighted={highlight || ""} />
            </div>
        );
    }

    printOrderFile = () => {
        const { spending, printOrderFile } = this.props;
        const outsourcerId = spending[fields.outsourcerId];
        let contentGuid = spending[fields.contentGuid] ?? "";
        let comment = spending[fields.comment] ?? "";

        printOrderFile && printOrderFile(outsourcerId, contentGuid, comment);
    };

    render() {
        const { isDragging } = this.props;
        const result = this.renderInternal();

        return isDragging ? ReactDOM.createPortal(result, portal) : result;
    }

    getItemStyle = (): React.CSSProperties => {
        const { isDragging, draggable } = this.props;
        return getDragItemStyle(isDragging, draggable.draggableProps.style);
    };

    addOrderFile = (file: OrderFileSnapshotType) => {
        const { spending, onChange } = this.props;
        const value = spending[fields.orders];
        file.sortOrder = value.length + 1;

        onChange(fields.orders, [file, ...value]);
    };
}

interface OutsourcedSpendingRowProps extends PaymentStatusToggler, IdFactory, UploaderFatory {
    disabled: boolean | undefined;
    isDragging: boolean;
    draggable: DraggableProvided;
    spending: TStringMap<any>;
    agents: OutsourcerDictionaryType;
    onChange: (field: string, value: any) => void;
    printOrderFile?: (outsourcerId: string, contentGuid: string, comment: string) => void;
    onRemove: () => void;
    paymentFactory: OutsourcerPaymentFactory;
    readOnly?: boolean;
    highlight?: string;
    baseUrl: string;
}

interface ActualPaymentsProps {
    data: ActualPaymentSnapshotType[];
    highlighted: string;
}

class ActualPayments extends React.PureComponent<ActualPaymentsProps> {
    render() {
        const { data, highlighted } = this.props;

        return (
            <div className={styles.actuals}>
                <div className={styles.paymentsName}>{ACTUAL_PAYMENTS_BLOCK_NAME}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={styles.headerRow}>
                            <td className={styles.dateTop}>Дата:</td>
                            <td className={styles.sumTop}>Сумма:</td>
                            <td className={styles.sumTop}>Корректировка:</td>
                        </tr>
                        {data.map((payment) => {
                            const correctionSum = payment.correctionPayments.reduce((accumulator, object) => {
                                return accumulator + object.sum;
                            }, 0);

                            return (
                                <tr
                                    key={payment.guid}
                                    data-object-id={payment.guid}
                                    className={highlighted === payment.guid ? "payment-row-highlighted" : ""}
                                >
                                    <td>{payment.day}</td>
                                    <td>
                                        <Money amount={payment.sum} />
                                    </td>
                                    <td>
                                        {payment.correctionPayments.length > 0 && (
                                            <Tooltip
                                                className={Classes.TOOLTIP_INDICATOR}
                                                content={
                                                    <div className={styles.actuals}>
                                                        <table className={styles.table}>
                                                            <tbody>
                                                                <tr className={styles.headerRow}>
                                                                    <td className={styles.dateTop}>Дата:</td>
                                                                    <td className={styles.sumTop}>Сумма:</td>
                                                                </tr>
                                                                {payment.correctionPayments.map((p) => (
                                                                    <tr
                                                                        key={p.guid}
                                                                        data-object-id={p.guid}
                                                                        className={
                                                                            highlighted === p.guid
                                                                                ? "payment-row-highlighted"
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <td>{p.day}</td>
                                                                        <td>
                                                                            <Money amount={p.sum} />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            >
                                                <Money amount={payment.sum - correctionSum} />
                                            </Tooltip>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

interface TaskStateProps {
    spending: TStringMap<any>;
    onChange: (field: string, value: any) => void;
    readOnly?: boolean;
}

export class TaskState extends React.PureComponent<TaskStateProps> {
    render() {
        const { spending } = this.props;

        const issued = !!spending[fields.taskIssued];
        const completed = !!spending[fields.taskCompleted];
        const accepted = !!spending[fields.taskAccepted];

        return (
            <>
                <span>Статус</span>

                <div className="status-doc">
                    <PlanrButton
                        icon="general-doc-arrow-right"
                        type={issued ? "blueish" : "graybtn"}
                        round={true}
                        onClick={this.issuedChange}
                        title={labels[fields.taskIssued]}
                    />
                </div>
                <div className="status-doc">
                    <PlanrButton
                        icon="general-doc-check"
                        type={completed ? "blueish" : "graybtn"}
                        round={true}
                        onClick={this.completedChange}
                        title={labels[fields.taskCompleted]}
                    />
                </div>
                <div className="status-doc">
                    <PlanrButton
                        icon="general-check"
                        type={accepted ? "greenish" : "graybtn"}
                        round={true}
                        onClick={this.acceptedChange}
                        title={labels[fields.taskAccepted]}
                    />
                </div>
            </>
        );
    }

    onChange = (field: string) => () => {
        const { spending, onChange, readOnly } = this.props;

        const value = !!spending[field];
        !readOnly && onChange(field, !value);
    };

    issuedChange = this.onChange(fields.taskIssued);

    completedChange = this.onChange(fields.taskCompleted);

    acceptedChange = this.onChange(fields.taskAccepted);
}

interface OrderSumProps {
    spending: TStringMap<any>;
    onChange: (field: string, value: any) => void;
    readOnly?: boolean;
}

export class OrderSum extends React.PureComponent<OrderSumProps> {
    render() {
        const { spending, readOnly, children } = this.props;

        return (
            <div className={styles.orderSum}>
                <div>
                    <label className={styles.labelSum}>{labels[fields.actualSum]}:</label>
                    <MoneyInput
                        className="planr-default-input"
                        value={spending[fields.actualSum]}
                        onMoneyChange={this.onChange}
                        autoComplete="off"
                        data-lpignore="true"
                        onKeyDown={preventSubmitKeyDown}
                        disabled={readOnly}
                    />
                </div>
                <div>
                    <label className={styles.labelSum}>Остаток:</label>
                    <MoneyInput
                        className="planr-default-input"
                        value={`${getDebit(spending as any, "actual")}`}
                        disabled={true}
                        onMoneyChange={eat}
                    />
                </div>
                {children}
            </div>
        );
    }

    onChange = (money: NumberFormatValues) => {
        if (!this.props.readOnly) {
            this.props.onChange(fields.actualSum, money.value);
        }
    };
}

interface PlanPaymentsProps extends PaymentStatusToggler, UploaderFatory {
    value: OutsourcerPaymentSnapshotType[];
    factory: OutsourcerPaymentFactory;
    title?: string;
    onChange: (value: OutsourcerPaymentSnapshotType[]) => void;
    readOnly?: boolean;
    baseUrl: string;
    outsourcer: string;
}

export class PlanPayments extends React.Component<PlanPaymentsProps> {
    render() {
        const { value, title, readOnly, formDirty, toggleStatus, upload, baseUrl, outsourcer } = this.props;
        return (
            <div className={styles.plan}>
                <div className={styles.paymentsName}>{title || PLAN_PAYMENTS_BLOCK_NAME}</div>
                <table className={styles.table}>
                    <tbody>
                        <tr className={styles.headerRow}>
                            <td className={styles.sumTop}>Сумма:</td>
                            <td className={styles.dateTop}>Дата:</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        {value.map((payment) => (
                            <PlanPaymentRow
                                key={payment.guid}
                                onChange={(field, text) => {
                                    this.onPaymentChange(payment, field, text);
                                }}
                                onRemove={this.removePayment}
                                payment={payment}
                                readOnly={readOnly}
                                toggleStatus={toggleStatus}
                                formDirty={formDirty}
                                upload={upload}
                                baseUrl={baseUrl}
                                outsourcer={outsourcer}
                            />
                        ))}
                    </tbody>
                </table>
                {!readOnly && (
                    <PlanrButton
                        icon="general-plus-big"
                        type="dashed"
                        onClick={this.addPayment}
                        style={{ width: "476px" }}
                    >
                        Добавить оплату
                    </PlanrButton>
                )}
            </div>
        );
    }

    onPaymentChange = (payment: OutsourcerPaymentSnapshotType, field: string, text: any) => {
        const { value, onChange } = this.props;
        const index = value.indexOf(payment);
        const newValue = [...value.slice(0, index), { ...payment, [field]: text }, ...value.slice(index + 1)];
        onChange(newValue);
    };

    addPayment = async () => {
        const { onChange, value } = this.props;
        const payment = await this.props.factory.emptyOutsourcerPayment(value.length + 1);
        const newValue = [...value, payment];

        onChange(newValue);
    };

    removePayment = (guid: string) => {
        const { onChange, value } = this.props;
        const index = findIndex(value, (o) => o.guid === guid);

        if (index >= 0) {
            const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
            onChange(newValue);
        }
    };
}

export interface OutsourcerPaymentFactory {
    emptyOutsourcerPayment: (index: number) => Promise<ActualPaymentSnapshotType>;
}

interface PlanPaymentRowProps extends PaymentStatusToggler, UploaderFatory {
    payment: TStringMap<any>;
    readOnly?: boolean;
    onChange: (field: string, value: any) => void;
    onRemove: (guid: string) => void;
    baseUrl: string;
    outsourcer: string;
}

interface PlanPaymentRowState {
    loading: boolean;
}

export class PlanPaymentRow extends React.PureComponent<PlanPaymentRowProps, PlanPaymentRowState> {
    constructor(props: PlanPaymentRowProps) {
        super(props);

        this.state = {
            loading: false,
        };
    }
    render() {
        const { readOnly, payment, outsourcer } = this.props;

        return (
            <>
                <tr>
                    <td className={styles.sum}>
                        {!readOnly && (
                            <MoneyInput
                                className="planr-default-input"
                                value={payment[outsourcerPaymentFields.sum]}
                                onMoneyChange={this.onSumChanged}
                                autoComplete="off"
                                data-lpignore="true"
                                onKeyDown={preventSubmitKeyDown}
                            />
                        )}
                        {readOnly && (
                            <div className="centered">
                                <Money amount={payment[outsourcerPaymentFields.sum]} noFraction={true} />
                            </div>
                        )}
                    </td>
                    <td className={styles.date}>
                        {!readOnly && (
                            <div className={`${Classes.INPUT_GROUP} `}>
                                <DatePicker
                                    clasName="planr-default-input"
                                    value={payment[outsourcerPaymentFields.date]}
                                    onChange={this.onDateChanged}
                                />
                            </div>
                        )}
                        {readOnly && (
                            <div className="centered">{formatDate(payment[outsourcerPaymentFields.date])}</div>
                        )}
                    </td>

                    <td className="centered">{outsourcer && this.statusButton()}</td>
                    <td className="centered">
                        {!readOnly && !payment[outsourcerPaymentFields.invoice] && !this.state.loading && (
                            <FileUploaderHOC
                                accept={"*"}
                                onFileSelected={this.uploadInvoiceFile}
                                render={({ onClick }) => (
                                    <PlanrButton
                                        type="whiteish"
                                        icon="general-attach"
                                        size="small"
                                        onClick={onClick}
                                        title="Прикрепить счет"
                                    />
                                )}
                            />
                        )}
                        {!readOnly && !payment[outsourcerPaymentFields.invoice] && this.state.loading && (
                            <div className="uploader-icon">{<Spinner intent="primary" size={16} />}</div>
                        )}
                        {payment[outsourcerPaymentFields.invoice] && (
                            <PlanrButton
                                type="whiteish"
                                icon="general-un-attach"
                                size="small"
                                style={{ color: "#1DD278" }}
                                title="Открепить счет"
                                onClick={this.onInvoiceRemove}
                            />
                        )}
                    </td>
                    <td className="centered">
                        {!readOnly && (
                            <RemoveConfirmation
                                onConfirmed={this.onRemove}
                                what={() => "оплату"}
                                render={({ confirmRemoving }) => {
                                    return (
                                        <PlanrButton
                                            type="neutral"
                                            icon="general-trash"
                                            size="small"
                                            onClick={confirmRemoving}
                                            style={{ color: "#E31818" }}
                                            title={texts.remove}
                                        />
                                    );
                                }}
                            />
                        )}
                        {payment[outsourcerPaymentFields.automatic] && (
                            <Icon icon="lock" htmlTitle="Создано автоматически" iconSize={LARGE_ICON_AS_BUTTON_SIZE} />
                        )}
                    </td>
                </tr>
            </>
        );
    }

    uploadInvoiceFile = async (file: File) => {
        this.setState({ loading: true });
        const meta = await this.props.upload(file);

        if (meta) {
            const data: InvoiceFileSnapshotType = {
                ...meta,
            };

            this.props.onChange(outsourcerPaymentFields.invoice, data);
        }
        this.setState({ loading: false });
    };

    onInvoiceRemove = () => {
        this.props.onChange(outsourcerPaymentFields.invoice, null);
    };

    onSumChanged = (money: NumberFormatValues) => this.props.onChange(outsourcerPaymentFields.sum, money.value);

    onDateChanged = (date: Date | null) => this.props.onChange(outsourcerPaymentFields.date, date);

    statusIcon = (status: string): GeneralIcons => {
        switch (status) {
            case Constants.orderPaymentStatusNew:
                return "general-question";
            case Constants.orderPaymentStatusAccepted:
                return "general-like";
            case Constants.orderPaymentStatusPaid:
                return "general-check";
            default:
                return "general-credit-card";
        }
    };

    statusButton = () => {
        const { payment, formDirty, readOnly } = this.props;
        const status: string = payment[outsourcerPaymentFields.status];
        const icon = this.statusIcon(status);

        const canBeToggled = ["", Constants.orderPaymentStatusCancelled];

        if (readOnly || formDirty || !canBeToggled.includes(status)) {
            return (
                <PlanrButton
                    type="neutral"
                    icon={icon}
                    title={status}
                    size="small"
                    style={
                        status === Constants.orderPaymentStatusPaid
                            ? { color: "#1DD278" }
                            : { color: "rgba(0, 39, 61, 0.3)" }
                    }
                />
            );
        }

        if (canBeToggled.includes(status)) {
            return (
                <PlanrButton
                    type="neutral"
                    size="small"
                    icon={icon}
                    onClick={this.toggleStatus}
                    title={status || "Отправить запрос на оплату"}
                />
            );
        }

        return null;
    };

    toggleStatus = () => {
        const { payment, toggleStatus } = this.props;
        const id = payment[outsourcerPaymentFields.guid];

        toggleStatus && toggleStatus(id);
    };

    onRemove = () => this.props.onRemove(this.props.payment[outsourcerPaymentFields.guid]);
}
