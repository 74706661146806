import React from "react";
import common from "modules/spending/timesheet/components/WeekDays.module.scss";
import styles from "./ScheduleTableHeader.module.scss";
import rowStyles from "./ScheduleUserRow.module.scss";
import ScheduleSchema from "./validation";
import { fields } from "../models/schedule-unit";
import { getFieldLabel } from "modules/common/services/form/fields";
import { ScheduleStoreType } from "../models/schedule-store";
import { observer } from "mobx-react";

const schema = ScheduleSchema();

export const ScheduleTableHeader: React.FC<DepartmentTableHeaderProps> = observer(({ store, children }) => (
    <div className={`${styles.header} ${common.row} ${rowStyles.row}`}>
        {children}

        <div className={`schedule-user-name ${common.cell} ${common.part}`}>Сотрудник</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>Ставка</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            {getFieldLabel(fields.salary, schema, null)}
        </div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>{getFieldLabel(fields.bonus, schema, null)}</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            {getFieldLabel(fields.increase, schema, null)}
        </div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>Переработка</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            {getFieldLabel(fields.vacationSalary, schema, null)}
        </div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            {getFieldLabel(fields.vacationBonus, schema, null)}
        </div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            {getFieldLabel(fields.decrease, schema, null)}
        </div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>НДФЛ</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>Взносы</div>
        {!store.bonusTaxeHidden && (
            <div className={`schedule-cell ${common.cell} ${common.part}`}>{store.bonusTaxePercent} %</div>
        )}
        <div className={`schedule-cell ${common.cell} ${common.part}`}>Доход сотр.</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>Расход орг.</div>
        <div className={`schedule-cell ${common.cell} ${common.part}`}>
            Ставка <br />в час
        </div>
    </div>
));

interface DepartmentTableHeaderProps {
    store: ScheduleStoreType;
}
