import React from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { MailForm, FormProps } from "./Form";
import { OrderDictionaryType, OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import {
    ProjectSelect,
    renderProjectOption,
    ProjectSelectedOption,
    filterProjectItemPredicate,
} from "modules/common/components/form/ProjectSelect";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { fields as mailFields } from "modules/orders-manage/models/order-mails";
import { texts } from "modules/common/texts";
import styles from "./Details.module.scss";
import { OrderMailsSchema } from "./validation";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { observer } from "mobx-react";

const schema = OrderMailsSchema();

class Detals extends React.PureComponent<DetailsProps> {
    render() {
        const { title, orders, onClose, canClientEdit, hideProject, ...rest } = this.props;

        return (
            <Dialog
                isCloseButtonShown={true}
                isOpen={true}
                onClose={onClose}
                title={title}
                backdropClassName="standard"
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
                className={`figma-dialog ${styles.dialog}`}
            >
                <MailForm {...rest} submitLabel={texts.save} canClientEdit={canClientEdit}>
                    {orders && !hideProject && (
                        <StandardFormInput
                            inline={true}
                            name={mailFields.orderId}
                            schema={schema}
                            small={true}
                            className="planr-form-input"
                        >
                            {({ field, form }) => {
                                const projectSelected = (project: OrderDictionaryItemType | null) => {
                                    form.setFieldValue(field.name, project ? project.id : "");
                                    form.setFieldTouched(field.name, true);
                                };

                                const item = orders.asMap[field.value] || null;

                                return (
                                    <div className={`${Classes.INPUT_GROUP} ${Classes.SMALL}`}>
                                        <ProjectSelect
                                            className={"full-width-select"}
                                            filterable={true}
                                            itemPredicate={filterProjectItemPredicate}
                                            activeItem={item}
                                            items={orders.orders}
                                            itemRenderer={renderProjectOption}
                                            onItemSelect={projectSelected}
                                            resetOnClose={true}
                                            inputProps={{
                                                placeholder: "Проект",
                                            }}
                                        >
                                            <ProjectSelectedOption
                                                option={item}
                                                onClear={() => projectSelected(null)}
                                            />
                                        </ProjectSelect>
                                    </div>
                                );
                            }}
                        </StandardFormInput>
                    )}
                </MailForm>
            </Dialog>
        );
    }
}

export const MailDetals = observer(Detals);

interface DetailsProps extends FormProps {
    title: string;
    onClose: () => void;
    orders?: OrderDictionaryType;
    employee: EmployerDictionaryType;
    hideProject: boolean;
    canClientEdit?: boolean;
}
