import React, { useCallback, useEffect, useState } from "react";
import { Search } from "modules/common/components/form/Search";

import styles from "./Filters.module.scss";
import { Constants } from "modules/root/models/constants";

import { FiltersContract } from "./types";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import { Classes } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { DictionaryItemSnapshotType } from "modules/common/models/entity";
import { ALL_MAILS } from "modules/common/constants";

export const Filters: React.FC<FiltersProps> = (props) => {
    let options: { [k: string]: any } = {};
    const [filterItem, setfilterItem] = useState({ id: "", label: "" });
    const filterStatuses = [ALL_MAILS, "Принято", "Исполнено", "Просрочено"];
    let i = 0;
    filterStatuses.forEach((label) => {
        let res: DictionaryItemSnapshotType = { id: i.toString(), label };
        options[i] = res;
        i++;
    });
    const item = options[0];
    useEffect(() => {
        setfilterItem(item);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { children, query, setQuery, hideProject } = props;
    const { filtersType, setFiltersType, toggleFiltersStatus } = props;

    const selectType = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            let type = e.currentTarget.title;
            if (type === ALL_MAILS) {
                type = "";
            }
            setFiltersType(type || "");
        },
        [setFiltersType]
    );

    const selectStatuses = useCallback(
        (selection: string) => {
            let status = selection;
            if (selection === ALL_MAILS) {
                status = "";
            }

            toggleFiltersStatus(status || "");
        },
        [toggleFiltersStatus]
    );

    return (
        <div className={styles.filters}>
            <div className={"planr-mail-group"}>
                <PlanrButton
                    type="neutral"
                    icon={"general-mail"}
                    round={true}
                    onClick={selectType}
                    title={ALL_MAILS}
                    style={!filtersType ? {} : { color: "rgba(0, 39, 61, 0.4)" }}
                    size="small"
                />

                <PlanrButton
                    type="neutral"
                    icon={"general-mail-in"}
                    round={true}
                    onClick={selectType}
                    title={Constants.incomingOrderMailType}
                    className="mail-group-btn"
                    style={filtersType === Constants.incomingOrderMailType ? {} : { color: "rgba(0, 39, 61, 0.4)" }}
                    size="small"
                />

                <PlanrButton
                    type="neutral"
                    icon={"general-mail-out"}
                    round={true}
                    onClick={selectType}
                    title={Constants.outcomingOrderMailType}
                    className="mail-group-btn"
                    style={filtersType === Constants.outcomingOrderMailType ? {} : { color: "rgba(0, 39, 61, 0.4)" }}
                    size="small"
                />
            </div>

            <div className={styles.statusFilter} style={!hideProject ? { width: "190px" } : {}}>
                <StatusFilterHeader
                    width={300}
                    currentOptions={options}
                    value={filterItem.id}
                    onChange={async (status: any) => {
                        setfilterItem(status);
                        selectStatuses(status.label);
                    }}
                />
            </div>

            <div className={styles.tools}>
                {children}
                <Search query={query} onSearch={setQuery} />
            </div>
        </div>
    );
};

interface FiltersProps extends FiltersContract {
    hideProject?: boolean;
}

interface StatusFilterHeaderProps {
    value: string;
    onChange: (value: any) => void;
    currentOptions: { [k: string]: any };
    width: number;
    filter?: string;
}

const StatusFilterHeader = observer(
    class extends React.Component<StatusFilterHeaderProps> {
        render() {
            const { width, value, currentOptions } = this.props;

            const option = currentOptions[value];

            return (
                <SimpleSelect
                    className={`${Classes.FILL} planr-default-input`}
                    filterable={false}
                    items={Object.values(currentOptions)}
                    itemRenderer={renderSingleOption}
                    onItemSelect={this.onItemSelect}
                >
                    <DefaultSelectedOption
                        option={option}
                        empty={!!Constants.employerDocumentType.values.length}
                        style={{ overflow: "hidden", maxWidth: `${width - 3}px` }}
                    />
                </SimpleSelect>
            );
        }

        onItemSelect = (item: SelectItem) => this.props.onChange(item);
    }
);
