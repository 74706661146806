import React from "react";
import { Spinner, Overlay } from "@blueprintjs/core";
import styles from "./Loader.module.scss";

export const Loader = ({ active, usePortal }: LoaderProps) => {
    if (typeof usePortal === undefined) {
        usePortal = true;
    }

    return (
        <Overlay usePortal={usePortal} isOpen={active} className={styles.align} backdropClassName="standard">
            <Spinner intent="primary" className={styles.spinner} />
        </Overlay>
    );
};

export const LocalLoader = ({ active, text, zIndex }: { active: boolean; text?: string; zIndex?: number }) => {
    return active ? (
        <div className={styles.localLoader} style={{ zIndex }}>
            {text && <div className={styles.loaderText}>{text}</div>}
            <Spinner intent="primary" className={styles.spinner} />
        </div>
    ) : null;
};

interface LoaderProps {
    active: boolean;
    usePortal?: boolean;
}
