import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { base64ToBlob, printPdf } from "modules/common/services/files";
import { OrderPayment } from "modules/orders-manage/models/payment";
import { formatDate } from "modules/common/services/formatting/date";
import { flatten, max } from "lodash";
import { toJsonHard } from "modules/common/services/mobx/serialize";
import { OverheadSpending } from "modules/expenses/overhead/models/overhead-spendings";
import { texts } from "modules/common/texts";

export const OverheadSpendingRow = types
    .compose(
        OverheadSpending,
        types.model({
            actual: types.boolean,
            money: types.string,
        })
    )
    .named("OverheadSpendingRow");

export type OverheadSpendingRowSnapshotType = typeof OverheadSpendingRow.SnapshotType;

const FlowDatasetOrderPayment = types
    .compose(
        OrderPayment,
        types.model({
            outsourser: types.string,
            orderName: types.string,
            workType: types.string,
            hasActualPayments: types.boolean,
            actualPaymentsSign: types.string,
        })
    )
    .named("FlowDatasetOrderPayment");

export const OrdersChartDataset = types
    .compose(
        Transport,
        Notificator,
        types.model({
            labels: types.array(types.string),
            planOwn: types.array(types.number),
            actualOwn: types.array(types.number),
            planOutsourced: types.array(types.number),
            actualOutsourced: types.array(types.number),
            planPayments: types.array(types.number),
            actualPayments: types.array(types.number),
            actualOverhead: types.array(types.number),
            planOverhead: types.array(types.number),
            planOutsourcedPayments: types.array(types.array(FlowDatasetOrderPayment)),
            actualOutsourcedPayments: types.array(types.array(FlowDatasetOrderPayment)),
            planPaymentsPayments: types.array(types.array(FlowDatasetOrderPayment)),
            actualPaymentsPayments: types.array(types.array(FlowDatasetOrderPayment)),
            planOverheadSpendings: types.array(types.array(OverheadSpendingRow)),
            actualOverheadSpendings: types.array(types.array(OverheadSpendingRow)),
            loaded: types.boolean,
            loading: types.boolean,
        })
    )
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.loaded = value;
        },

        loadFromCache() {
            return self.transport.get<any>(apiUrls.dashboard.orders.money(), {
                headers: {
                    "X-Cached": "yes",
                },
            });
        },
    }))
    .actions((self) => ({
        load: flow(function* () {
            if (self.loading) {
                return;
            }

            self.loading = true;

            try {
                const cached = yield self.loadFromCache();
                if (cached) {
                    applySnapshot(self, {
                        ...cached,
                        loaded: true,
                        loading: false,
                    });
                }
            } catch {
                // nothing
            }

            try {
                const data: any = yield self.transport.get<any>(apiUrls.dashboard.orders.money());

                applySnapshot(self, {
                    ...data,
                    loaded: true,
                    loading: false,
                });

                return true;
            } catch (er) {
                self.loading = false;
                self.setLoaded(true);
                self.notify.error(er);
                return false;
            }
        }),

        batchPrint: flow(function* (commands: PrintCommand[], title: string) {
            try {
                const parts = commands.map(({ json, title }) => ({
                    variables: json,
                    templateId: getTemplateId(title),
                }));

                const data: FileDescription = yield self.transport.post<any>(apiUrls.application.batchPrint, {
                    parts,
                    title,
                });
                const blob: any = yield base64ToBlob(data.content || "", data.mimeType);

                const fileURL = URL.createObjectURL(blob);
                const printer = printPdf(fileURL, true);
                if (printer) {
                    printer.onclose = () => URL.revokeObjectURL(fileURL);
                }

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        setPaymentDate: flow(function* (guid: string, value: Date) {
            try {
                const date = formatDate(value);
                yield self.transport.post<any>(apiUrls.orders.paymentDate(guid), { date });

                const iteratee = [
                    ...flatten(self.planOutsourcedPayments),
                    ...flatten(self.actualOutsourcedPayments),
                    ...flatten(self.planPaymentsPayments),
                    ...flatten(self.actualPaymentsPayments),
                ];

                const target = iteratee.find((p) => p.guid === guid);
                target && (target.date = date);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get amChartData() {
            const periods = self.labels;

            return periods.map((period, index) => ({
                period,
                planOwn: self.planOwn[index],
                actualOwn: self.actualOwn[index],
                planOutsourced: self.planOutsourced[index],
                actualOutsourced: self.actualOutsourced[index],
                actualPayments: self.actualPayments[index],
                planPayments: self.planPayments[index],
                actualOverhead: self.actualOverhead[index],
                planOverhead: self.planOverhead[index],
            }));
        },

        get paymentsMap(): TStringMap<FlowDatasetOrderPaymentSnapshotType[][]> {
            return {
                [PLAN_PAYMENTS_LABEL]: toJsonHard(self.planPaymentsPayments),
                [ACTUAL_PAYMENTS_LABEL]: toJsonHard(self.actualPaymentsPayments),
                [PLAN_OUTSOURCED_LABEL]: toJsonHard(self.planOutsourcedPayments),
                [ACTUAL_OUTSOURCED_LABEL]: toJsonHard(self.actualOutsourcedPayments),
            };
        },

        get overheadsMap(): TStringMap<OverheadSpendingRowSnapshotType[][]> {
            return {
                [PLAN_OVERHERAD_LABEL]: toJsonHard(self.planOverheadSpendings),
                [ACTUAL_OVERHERAD_LABEL]: toJsonHard(self.actualOverheadSpendings),
            };
        },

        get maxValue() {
            const all = [
                ...self.planOwn,
                ...self.actualOwn,
                ...self.planOutsourced,
                ...self.actualOutsourced,
                ...self.actualPayments,
                ...self.planPayments,
                ...self.actualOverhead,
                ...self.planOverhead,
            ];

            return max(all) ?? 0;
        },
    }));

export interface PrintCommand {
    json: string;
    title: string;
}

export function withOutsourcer(title: string) {
    return title.includes(OUTSOURCE);
}

function getTemplateId(title: string) {
    if (withOutsourcer(title)) {
        return "GetOrderFlowDatasetPayments";
    }

    if (title.includes(PAYMENTS)) {
        return "GetOrderFlowDatasetPayments_NoOutsourcer";
    }

    return "GetOrderFlowDatasetSpendings";
}

export const PLAN = texts.plan;
export const ACTUAL = texts.actual;
export const PAYMENTS = "Платежи";
export const OUTSOURCE = "Аутсорс";
export const OVERHERAD = "Накладные";

export const PLAN_PAYMENTS_LABEL = `${PLAN} ${PAYMENTS}`;
export const ACTUAL_PAYMENTS_LABEL = `${ACTUAL} ${PAYMENTS}`;
export const PLAN_OUTSOURCED_LABEL = `${PLAN} ${OUTSOURCE}`;
export const ACTUAL_OUTSOURCED_LABEL = `${ACTUAL} ${OUTSOURCE}`;
export const PLAN_OVERHERAD_LABEL = `${PLAN} ${OVERHERAD}`;
export const ACTUAL_OVERHERAD_LABEL = `${ACTUAL} ${OVERHERAD}`;

export type FlowDatasetOrderPaymentSnapshotType = typeof FlowDatasetOrderPayment.SnapshotType;
export type OrdersChartDatasetSnapshotType = typeof OrdersChartDataset.SnapshotType;
export type OrdersChartDatasetType = typeof OrdersChartDataset.Type;

export const initialState = (): OrdersChartDatasetSnapshotType => ({
    labels: [],
    planOwn: [],
    actualOwn: [],
    actualOutsourced: [],
    planOutsourced: [],
    planPayments: [],
    actualPayments: [],
    actualOutsourcedPayments: [],
    actualPaymentsPayments: [],
    planOutsourcedPayments: [],
    planPaymentsPayments: [],
    actualOverheadSpendings: [],
    planOverheadSpendings: [],
    actualOverhead: [],
    planOverhead: [],
    loaded: false,
    loading: false,
});
