import React, { useState, useCallback } from "react";
import { Dialog, Classes } from "@blueprintjs/core";
import { texts } from "modules/common/texts";
import { capitalize } from "modules/common/services/strings";
import { Buttons } from "./Buttons";
import { DictionaryItemSnapshotType } from "modules/common/models/entity";
import { PlanrButton } from "../planr/button/Button";

export function RemoveConfirmation<T>(props: React.PropsWithChildren<RemoveConfiramtionProps<T>>) {
    const { what, onConfirmed, children, render, actionName, removeOptions } = props;
    const [inConfiramtion, setConfirmation] = useState(false as any);
    const additionalRemoveOptions = removeOptions || [];

    const yes = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setConfirmation(false);

            const optionId = e.currentTarget.dataset.option;
            const option = additionalRemoveOptions.find((o) => o.id === optionId);

            onConfirmed(inConfiramtion, option ? option.id : undefined);
        },
        [onConfirmed, inConfiramtion, additionalRemoveOptions]
    );

    const no = useCallback((e?: React.SyntheticEvent) => {
        e && e.stopPropagation();
        setConfirmation(false);
    }, []);

    const drawer = render || children;

    return (
        <>
            {drawer && drawer({ confirmRemoving: (data: T) => setConfirmation(data || true) })}
            <Dialog
                icon="help"
                canOutsideClickClose={false}
                onClose={no}
                title={texts.confirmation}
                isOpen={!!inConfiramtion}
                className="figma-dialog"
                backdropClassName="standard"
            >
                <div
                    className={Classes.DIALOG_BODY}
                    style={{
                        fontFamily: "GothamPro, Arial, sans-serif",
                        color: "#00273D",
                    }}
                >
                    Вы действительно хотите {actionName || "удалить"} <span>{what(inConfiramtion)}</span>?
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <Buttons
                        left={
                            <div style={{ display: "flex" }}>
                                <PlanrButton onClick={yes} type="greenish" size="small" title="option.id">
                                    {actionName ? capitalize(actionName) : texts.remove}
                                </PlanrButton>
                                {/* <Button key="main" onClick={yes} intent="success">
                                    {actionName ? capitalize(actionName) : texts.remove}
                                </Button> */}

                                {additionalRemoveOptions.map((option) => (
                                    // <Button key={option.id} data-option={option.id} onClick={yes} intent="primary">
                                    //     {option.label}
                                    // </Button>
                                    <PlanrButton
                                        key={option.id}
                                        onClick={yes}
                                        type="blueish"
                                        size="small"
                                        data-option={option.id}
                                    >
                                        {option.label}
                                    </PlanrButton>
                                ))}

                                {/* <Button
                                    onClick={no}
                                    style={additionalRemoveOptions.length ? { marginRight: "auto" } : undefined}
                                >
                                    {texts.cancel}
                                </Button> */}
                                <PlanrButton onClick={no} type="graybtn" size="small">
                                    {texts.cancel}
                                </PlanrButton>
                            </div>
                        }
                    />
                </div>
            </Dialog>
        </>
    );
}

interface RemoveConfiramtionProps<T> {
    /** Entity name to be removed */
    what: (data: T) => string;

    /** Custom action name */
    actionName?: string;
    /** Event handler for confirmed remove action */
    onConfirmed: (data: T, option?: string) => void;
    /** (DEPRECATED) UI elements initiating remove action */
    render?: (props: RemoveConfirmationRenderProps<T>) => React.ReactNode;
    /** UI elements initiating remove action */
    children?: (props: RemoveConfirmationRenderProps<T>) => React.ReactNode;
    /** Re-render observation property */
    observation?: any;
    /** Additional remove buttons */
    removeOptions?: DictionaryItemSnapshotType[];
}

export interface RemoveConfirmationRenderProps<T> {
    confirmRemoving: (context: T) => void;
}
