import cookies from 'js-cookie';
import { toString } from '../strings';
import { ColumnResizedEvent } from '@ag-grid-community/core';

const prefix = (tableName: string) => `size_${tableName}_`;

const keyBuilder = (tableName: string) => {
    const table = prefix(tableName);
    return (columnName: string) => `${table}${columnName}`;
}

export type AgGridSizeSaver = ({ finished, column }: ColumnResizedEvent) => void;
export const saveAgGridColumnSize = (tableName: string): AgGridSizeSaver => {
    const builder = keyBuilder(tableName);

    return ({ finished, column }) => {
        if (finished && column) {
            cookies.set(builder(column.getColId()), toString(column.getActualWidth() || 0));
        }
    }
}

export const getColumnSize = (tableName: string) => {
    const builder = keyBuilder(tableName);

    return (column: string, defaultSize: number | undefined) => {
        const value = cookies.get(builder(column)) || '';
        const size = parseInt(value, 10);

        return isNaN(size) ? defaultSize : size;
    };
}
