import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { MainMenuWithRouter } from "modules/root/components/menu/MainMenu";
import { AppLogo } from "modules/root/components/logo/AppLogo";
import { Routes } from "modules/root/routes";
import { ApplicationStoreType } from "modules/root/models/store";
import { Notifications } from "./Notifications";
import { AuthPopup } from "modules/session/auth/components/AuthPopup";
import styles from "./App.module.scss";
import { observer } from "mobx-react";
import { User } from "./User";
import { OrderListWrapper } from "modules/orders-manage/list/OrderListWrapper";
import { RootStoreProvider } from "modules/root/models/hooks";
import { InvalidVersion } from "./InvalidVersion";
import { ReactComponent as GeneralIcons } from "modules/root/styles/planr/general.svg";
import { AppVersion } from "../version/AppVersion";
import StartPopup from "modules/root/components/StartPage/StartPopup";

class App extends React.Component<AppProps> {
    render() {
        const { store, onLogout } = this.props;
        const { loggedIn, authPopupVisible } = store.session;
        const versionMismatch = store.apiVersion && store.apiVersion !== store.uiVerson;
        const startInfoFilled = store.startInfoFilled;
        const resetState = (successfull: boolean) => {
            const { store } = this.props;
            store.setStartupInfoState(successfull);
        };
        const invalidVersionVisible = !authPopupVisible && versionMismatch;

        const startSettingsVisible = !authPopupVisible && !invalidVersionVisible && loggedIn && !startInfoFilled;

        const mainContentVisible = !authPopupVisible && !invalidVersionVisible && !startSettingsVisible;
        return (
            <RootStoreProvider value={store}>
                <GeneralIcons className="hidden" />
                <div className={styles.mainlayout}>
                    {authPopupVisible && <AuthPopup store={store.session} />}
                    {startSettingsVisible && (
                        <>
                            <StartPopup
                                store={store.startSettings}
                                onLogout={onLogout}
                                access={store.session.access}
                                onSaved={resetState}
                                session={store.session}
                            />
                            <Notifications />
                        </>
                    )}
                    {invalidVersionVisible && <InvalidVersion />}
                    {mainContentVisible && (
                        <BrowserRouter>
                            {loggedIn && <User store={store.session} onLogout={onLogout} />}
                            {loggedIn && (
                                <div className={styles.leftColumn}>
                                    <div className={styles.mainLogo}>
                                        <AppLogo />
                                        <AppVersion
                                            version={store.apiVersion}
                                            changelog={store.changelog}
                                            prefix="1."
                                        />

                                        {/** preload font-aweseome icons */}
                                        <i className={`fa fa-pencil-square-o ${styles.preload}`} aria-hidden="true" />
                                    </div>
                                    <MainMenuWithRouter store={store} />
                                    <OrderListWrapper store={store.orders.list} access={store.session.access} />
                                </div>
                            )}
                            <div className={styles.mainContainer}>
                                <Notifications />
                                <Switch>
                                    <Routes store={store} />
                                </Switch>
                            </div>
                        </BrowserRouter>
                    )}
                </div>
            </RootStoreProvider>
        );
    }
}

export default observer(App);

export const ArrowEvents = {
    arrow: "rost.event.arrow",
};

interface AppProps {
    store: ApplicationStoreType;
    onLogout: () => void;
}
