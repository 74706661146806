import React, { useCallback, useEffect, useRef } from "react";
import { SaveOrderMailValue, fields } from "modules/orders-manage/models/order-mails";
import { Formik, Form, Field, FieldProps } from "formik";
import { OrderMailsSchema } from "./validation";
import { Classes, InputGroup } from "@blueprintjs/core";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { DatePicker } from "modules/common/components/form/DatePicker";
import styles from "./Form.module.scss";
import { findIndex } from "lodash";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { isNewlyCreated } from "modules/common/models/entity";
import { texts } from "modules/common/texts";
import { UploaderFatory } from "modules/orders-manage/types";
import { Constants } from "modules/root/models/constants";
import { ReactComponent as IncomingIcon } from "modules/common/components/icons/incoming.icon.svg";
import { ReactComponent as OutcomingIcon } from "modules/common/components/icons/outcoming.icon.svg";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import { getFieldLabel } from "modules/common/services/form/fields";
import { FileLink } from "modules/common/components/files/FileLink";

import { EmployerSelect } from "modules/common/components/form/EmployerSelect";
import { PlanRDndUploader } from "modules/common/components/planr/dndUploader/FileDndUploader";
import { PlanrButton } from "modules/common/components/planr/button/Button";

const counterpartLabel = (type: string) =>
    type === Constants.incomingOrderMailType
        ? "От кого"
        : type === Constants.outcomingOrderMailType
        ? "Кому"
        : "Кому/От кого";

const schema = OrderMailsSchema();
export const removeLabel = (node: SaveOrderMailValue) => `сообщение ${node.number}`;

export const MailForm: React.FC<FormProps> = (props) => {
    const { mail, onSubmit, onRemove, children, submitLabel, employee, baseUrl } = props;

    const mounted = useRef(true);
    const remove = useCallback(() => onRemove(mail), [onRemove, mail]);
    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <Formik
            initialValues={mail}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                await onSubmit(values);

                if (mounted.current) {
                    setSubmitting(false);
                }
            }}
        >
            {(formProps) => {
                const type = formProps.values.type;

                const savedAssignee = formProps.values.assigneeId
                    ? {
                          id: formProps.values.assigneeId,
                          label: formProps.values.assigneeName,
                      }
                    : null;

                const savedMentor = formProps.values.mentorId
                    ? {
                          id: formProps.values.mentorId,
                          label: formProps.values.mentorName,
                      }
                    : null;

                return (
                    <Form autoComplete="off" className={styles.form}>
                        <div className={Classes.DIALOG_BODY}>
                            {/* Входящее \ исходящее */}
                            <Field name={fields.type}>
                                {({ field, form }: FieldProps) => {
                                    const selectType = (e: React.MouseEvent<SVGSVGElement>) => {
                                        const value = e.currentTarget.dataset.type || "";
                                        form.setFieldValue(field.name, value);
                                        form.setFieldTouched(field.name);
                                    };

                                    return (
                                        <div className={`bp3-form-group bp3-inline ${styles.typeRadio}`}>
                                            {/* <GeneralIcon type="general-mail-in" onClick={selectType} size={24}/>
                                            <GeneralIcon type="general-mail-out" onClick={selectType} /> */}
                                            <IncomingIcon
                                                data-type={Constants.incomingOrderMailType}
                                                className={
                                                    type === Constants.incomingOrderMailType ? styles.active : ""
                                                }
                                                onClick={selectType}
                                            />
                                            <OutcomingIcon
                                                data-type={Constants.outcomingOrderMailType}
                                                className={
                                                    type === Constants.outcomingOrderMailType ? styles.active : ""
                                                }
                                                onClick={selectType}
                                            />
                                        </div>
                                    );
                                }}
                            </Field>

                            {children}

                            {/* Дата письма */}
                            <StandardFormInput
                                name={fields.date}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {({ field, form }) => {
                                    return (
                                        <div className={`${Classes.INPUT_GROUP}`}>
                                            <DatePicker
                                                value={field.value}
                                                clasName="planr-default-input"
                                                onChange={(date) => {
                                                    form.setFieldValue(field.name, date);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            />
                                        </div>
                                    );
                                }}
                            </StandardFormInput>

                            {/* Номер письма */}
                            <StandardFormInput
                                name={fields.number}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {({ field }) => (
                                    <InputGroup
                                        id={field.name}
                                        {...field}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                    />
                                )}
                            </StandardFormInput>

                            {/* Кому / от кого */}
                            <StandardFormInput
                                name={fields.counterpart}
                                schema={schema}
                                small={true}
                                label={counterpartLabel(type)}
                                className="planr-form-input"
                            >
                                {({ field }) => (
                                    <InputGroup
                                        id={field.name}
                                        {...field}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                    />
                                )}
                            </StandardFormInput>

                            {/* Тема */}
                            <StandardFormInput
                                name={fields.name}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {({ field }) => (
                                    <InputGroup
                                        id={field.name}
                                        {...field}
                                        className="planr-default-input"
                                        autoComplete="off"
                                        data-lpignore="true"
                                    />
                                )}
                            </StandardFormInput>

                            {/* Исполнитель (сотрудник) */}
                            <StandardFormInput
                                name={fields.assigneeId}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {(fieldProps) => {
                                    const clear = () => {
                                        fieldProps.form.setFieldValue(fieldProps.field.name, "");
                                        fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                                    };

                                    return (
                                        <div className={`${Classes.INPUT_GROUP}`}>
                                            <EmployerSelect
                                                employee={employee.employee}
                                                field={fieldProps}
                                                saved={savedAssignee}
                                                onClear={clear}
                                            />
                                        </div>
                                    );
                                }}
                            </StandardFormInput>

                            {/* Ответственный (сотрудник) */}
                            <StandardFormInput
                                name={fields.mentorId}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {(fieldProps) => {
                                    const clear = () => {
                                        fieldProps.form.setFieldValue(fieldProps.field.name, "");
                                        fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                                    };

                                    return (
                                        <div className={`${Classes.INPUT_GROUP}`}>
                                            <EmployerSelect
                                                employee={employee.employee}
                                                field={fieldProps}
                                                saved={savedMentor}
                                                onClear={clear}
                                            />
                                        </div>
                                    );
                                }}
                            </StandardFormInput>

                            {/* Крайний срок */}
                            <StandardFormInput
                                name={fields.deadline}
                                schema={schema}
                                small={true}
                                className="planr-form-input"
                            >
                                {({ field, form }) => {
                                    return (
                                        <div className={`${Classes.INPUT_GROUP} `}>
                                            <DatePicker
                                                clasName="planr-default-input"
                                                value={field.value}
                                                onChange={(date) => {
                                                    form.setFieldValue(field.name, date);
                                                    form.setFieldTouched(field.name, true);
                                                }}
                                            />
                                        </div>
                                    );
                                }}
                            </StandardFormInput>

                            {/* Статусы */}
                            <div className={styles.statusInput}>
                                {getFieldLabel(fields.accepted, schema, null)}
                                <PlanrButton
                                    type="neutral"
                                    icon={!!formProps.values.accepted ? "general-checkbox" : "general-zero-checkbox"}
                                    title={!!formProps.values.accepted ? "Принято" : ""}
                                    onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                                        formProps.setFieldValue(
                                            fields.accepted,
                                            e.currentTarget.title !== "Принято" ? true : false
                                        );
                                        formProps.setFieldTouched(fields.accepted, true);
                                    }}
                                />
                                {/* <Checkbox
                                    large={true}
                                    checked={!!formProps.values.accepted}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        formProps.setFieldValue(fields.accepted, e.currentTarget.checked);
                                        formProps.setFieldTouched(fields.accepted, true);
                                    }}
                                    label={getFieldLabel(fields.accepted, schema, null)}
                                /> */}
                                {getFieldLabel(fields.completed, schema, null)}
                                <PlanrButton
                                    type="neutral"
                                    icon={!!formProps.values.completed ? "general-checkbox" : "general-zero-checkbox"}
                                    title={!!formProps.values.completed ? "Исполнено" : ""}
                                    onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                                        formProps.setFieldValue(
                                            fields.completed,
                                            e.currentTarget.title !== "Исполнено" ? true : false
                                        );
                                        formProps.setFieldTouched(fields.completed, true);
                                    }}
                                />
                                {/* <Checkbox
                                    large={true}
                                    checked={!!formProps.values.completed}
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                        formProps.setFieldValue(fields.completed, e.currentTarget.checked);
                                        formProps.setFieldTouched(fields.completed, true);
                                    }}
                                    label={getFieldLabel(fields.completed, schema, null)}
                                /> */}
                            </div>

                            <Field name={fields.documents}>
                                {({ field, form }: FieldProps) => {
                                    const onRemoveFile = (file: FileBase) => {
                                        const value = field.value;
                                        const index = findIndex(value, (f: FileBase) => f.fileId === file.fileId);

                                        if (index >= 0) {
                                            const newValue = [...value.slice(0, index), ...value.slice(index + 1)];
                                            form.setFieldValue(field.name, newValue);
                                            form.setFieldTouched(field.name, true);
                                        }
                                    };

                                    const onFileSelected = async (file: File) => {
                                        const { upload } = props;
                                        const success = await upload(file);

                                        if (success) {
                                            const value = [...field.value, success];
                                            form.setFieldValue(field.name, value);
                                            form.setFieldTouched(field.name);
                                        }
                                    };

                                    return (
                                        <div className={styles.fileList}>
                                            <label className="bp3-label">Загружено:</label>

                                            <PlanRDndUploader
                                                accept={"*"}
                                                onFileSelected={onFileSelected}
                                                withLoader={true}
                                                multiple={true}
                                            />

                                            {field.value.map((doc: FileBase, index: number) => (
                                                <FileLink
                                                    baseUrl={baseUrl}
                                                    file={doc}
                                                    key={index}
                                                    onRemove={onRemoveFile}
                                                />
                                            ))}
                                        </div>
                                    );
                                }}
                            </Field>
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <StandardFormButtons
                                {...formProps}
                                isRemoved={false}
                                what={removeLabel(mail)}
                                isNewlyCreated={isNewlyCreated(mail.id)}
                                onRemove={remove}
                                submitLabel={submitLabel || texts.ready}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export interface FormProps extends UploaderFatory {
    baseUrl: string;
    mail: SaveOrderMailValue;
    employee: EmployerDictionaryType;
    onSubmit: (mail: SaveOrderMailValue) => Promise<any>;
    onRemove: (mail: SaveOrderMailValue) => void;
    submitLabel?: string;
    canClientEdit?: boolean;
}
