import { observer } from "mobx-react";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { texts } from "modules/common/texts";
import { EventDateSnapshot, EventDateType } from "modules/orders-manage/models/order-events";
import { OrderStageRowItem, PlanOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import { IdFactory } from "modules/orders-manage/types";
import { Constants } from "modules/root/models/constants";
import React, { useCallback } from "react";
import { BusinessEvent } from "./BusinessEvent";
import styles from "./Event.module.scss";

export const EventsFormItem = observer(
    ({ events, payments, stages, newId, onAddEvent, onRemoveEvent, additions, stageGuid }: EventsFromItemProps) => {
        const onAddEventHandler = useCallback(async () => {
            onAddEvent({
                daysCount: 0,
                workDays: false,
                model: {
                    id: "",
                    newId: await newId(),
                    paymentGuid: "",
                    stageGuid: "",
                    status: "",
                    type: Constants.businessEventTypes[0],
                    belonging: [],
                    additionalText: "",
                    additionNumber: "",
                },
            });
        }, [onAddEvent, newId]);

        return (
            <div className={styles.eventsFormItem}>
                <div className={styles.eventList}>
                    {events.map((e, index) => (
                        <BusinessEvent
                            key={index}
                            event={e}
                            payments={payments}
                            stages={stages}
                            onRemove={onRemoveEvent}
                            additions={additions}
                            stageGuid={stageGuid}
                        />
                    ))}
                </div>

                <PlanrButton
                    type="secondary"
                    onClick={onAddEventHandler}
                    icon="general-plus-small"
                    size="small"
                    className={styles.addButton}
                >
                    {texts.add} событие
                </PlanrButton>
            </div>
        );
    }
);

interface EventsFromItemProps extends IdFactory {
    events: EventDateType[];
    payments: PlanOrderPaymentDetailsType[];
    additions: any;
    stages: OrderStageRowItem[];
    excludeBelonging?: string;
    onAddEvent: (e: EventDateSnapshot) => void;
    onRemoveEvent: (e: EventDateType) => void;
    stageGuid?: string;
}
