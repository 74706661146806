import React from "react";
import styles from "./OrderAgentsRow.module.scss";

export const OrderAgentsHeader = () => {
    return (
        <div className={`${styles.row} ${styles.headerRow} flat-mode`}>
            <div className={styles.iconCell}>&nbsp;</div>
            <div className={styles.category}>Категория</div>
            <div className={`${styles.name}`}>Наименование организации</div>

            <div className={`${styles.materials} `}>Поставляемое оборудование</div>

            <div className={styles.reward}>Агентское вознаграждение %</div>

            <div className={styles.price}>Стоимость по проекту</div>

            <div className={styles.fio}>ФИО представителя</div>

            <div className={styles.phone}>Телефон</div>

            <div className={styles.comment}>Комментарии</div>

            <div className={styles.deal}>Сделка</div>
            <div className={styles.iconCell}>&nbsp;</div>
        </div>
    );
};
