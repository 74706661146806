import { types, getParent, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { EmployerPremiums, initialState as emptyPremiums } from "modules/spending/employee/models/employer-premiums";
import {
    Outsourcer,
    initialState as emptyOutsourcer,
    OutsourcerSnapshotType,
} from "modules/agents/outsourcers/models/outsourcer";
import {
    WorkTypeDictionary,
    initialState as emptyWorkTypes,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import {
    AgentsCategoriesDictionary,
    initialState as emptyCategories,
} from "modules/dictionaries/agents-categories/models/agents-categories-dictionary";

const SelfOutsourcer = Outsourcer.named("SelfOutsourcer").actions((self) => ({
    load: flow(function* () {
        try {
            const snapshot = yield self.transport.get<OutsourcerSnapshotType>(apiUrls.outsourcers.self());

            applySnapshot(self, snapshot);

            return true;
        } catch (er) {
            self.notify.error(er);

            return false;
        }
    }),

    createUrl() {
        return apiUrls.outsourcers.self();
    },

    updateUrl() {
        return apiUrls.outsourcers.self();
    },
}));

const ExternalUser = types
    .compose(
        Transport,
        Notificator,
        types.model({
            self: SelfOutsourcer,
            workTypes: WorkTypeDictionary,
            categories: AgentsCategoriesDictionary,
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                yield self.self.load();
                yield self.workTypes.load();

                return true;
            } catch (er) {
                self.notify.error((er as Error).message);
                return false;
            }
        }),
    }));

export const Session = types
    .compose(
        Transport,
        Notificator,
        types.model({
            userName: types.string,
            userLogin: types.string,
            userAvatar: types.maybeNull(types.string),
            userId: types.string,
            access: types.array(types.string),
            loggedIn: types.boolean,
            manualFile: types.maybeNull(
                types.model({
                    fileId: types.string,
                    fileName: types.string,
                })
            ),
            tgBotLink: types.string,
            authPopupVisible: types.boolean,
            premiums: EmployerPremiums,
            outsourcer: types.maybeNull(ExternalUser),
            credentials: types.model({
                login: types.string,
                password: types.string,
            }),
        })
    )
    .views((self) => ({
        get fileLink() {
            if (self.manualFile) {
                return (
                    self.baseUrl + apiUrls.application.files.content(self.manualFile.fileId, self.manualFile.fileName)
                );
            } else {
                return "";
            }
        },
    }))
    .actions((self) => ({
        showConfirmationWarning() {
            self.notify.success("Ожидается подтверждение устройства администратором.");
        },

        setAccess(acces: string[]) {
            applySnapshot(self.access, acces);
        },

        loadPremiums() {
            return self.premiums.load(self.userId);
        },

        setTgBotLink(link: string) {
            self.tgBotLink = link;
        },
    }))
    .actions((self) => ({
        logIn: flow(function* (model: any) {
            try {
                const meta: AppMetadata = yield self.transport.post<AppMetadata>(apiUrls.auth.login, model);

                const parent = getParent(self);
                if (parent && typeof parent.userLoggedIn === "function") {
                    parent.userLoggedIn(meta);
                }

                if (self.authPopupVisible) {
                    self.authPopupVisible = false;
                }

                if (!meta.waitConfirmation) {
                    self.loggedIn = true;
                } else {
                    self.showConfirmationWarning();
                }

                self.userName = meta.userName;
                self.userLogin = meta.userLogin;
                self.userId = meta.userId;
                self.userAvatar = meta.userAvatar;
                self.manualFile = meta.manualFile;
                self.setAccess(meta.access);
                self.setTgBotLink(meta.tgBotLink);

                if (meta.isLegalEntity !== null && !self.outsourcer) {
                    self.outsourcer = ExternalUser.create(
                        {
                            self: emptyOutsourcer(meta.isLegalEntity, meta.userId),
                            workTypes: emptyWorkTypes(),
                            categories: emptyCategories(),
                        },
                        {
                            http: self.transport,
                            notificator: self.notify,
                        }
                    );
                }

                return true;
            } catch (er) {
                if (self.loggedIn) {
                    self.authPopupVisible = true;
                }

                self.notify.error((er as Error).message);
                return false;
            }
        }),

        logOut: flow(function* () {
            try {
                yield self.transport.post<boolean>(apiUrls.auth.logout);
                self.loggedIn = false;
                self.outsourcer = null;
                return true;
            } catch (er) {
                self.notify.error((er as Error).message);
                return false;
            }
        }),
        updateFile: flow(function* () {
            try {
                const meta: AppMetadata = yield self.transport.get<AppMetadata>(apiUrls.application.metadata);
                self.manualFile = meta.manualFile;
                self.setAccess(meta.access);
                return true;
            } catch (er) {
                self.notify.error((er as Error).message);
                return false;
            }
        }),
        updateUser(data: string[]) {
            if (data.length > 1) {
                self.userAvatar = data[0];
                self.userName = data[1];
                self.userLogin = data[2];
            }
        },
        setPopupStaste(visible: boolean) {
            self.authPopupVisible = visible;
        },

        setLoggedInState(value: boolean) {
            self.loggedIn = value;
        },

        tgRegister: flow(function* (token: string) {
            try {
                const [chatId, userId] = token ? token.split(":") : ["", ""];
                if (!chatId) {
                    return "Неверно переданы параметры регистрации";
                }

                const chat = parseInt(chatId, 10);

                yield self.transport.post(apiUrls.employee.bot.register, {
                    chatId: chat,
                    userId,
                });

                self.setTgBotLink("");

                return "";
            } catch (er) {
                const text = typeof er === "string" ? er : (er as any).message;
                self.notify.error(er);
                return text;
            }
        }),
    }))
    .named("Token");

export type SessionSnapshotType = typeof Session.SnapshotType;
export type SessionType = typeof Session.Type;

export const initialState = (meta: AppMetadata): SessionSnapshotType => {
    var loggedIn = !!meta.userName && !meta.waitConfirmation;

    return {
        userId: meta.userId,
        userName: meta.userName,
        userLogin: meta.userLogin,
        userAvatar: meta.userAvatar,
        access: meta.access,
        loggedIn,
        authPopupVisible: false,
        premiums: emptyPremiums(),
        manualFile: meta.manualFile,
        tgBotLink: meta.tgBotLink,
        outsourcer:
            loggedIn && meta.isLegalEntity !== null
                ? {
                      self: emptyOutsourcer(meta.isLegalEntity, meta.userId),
                      workTypes: emptyWorkTypes(),
                      categories: emptyCategories(),
                  }
                : null,
        credentials: meta.credentials,
    };
};
