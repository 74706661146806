import { nameof } from "./services/typescript";
import { KnowledgeBaseTypes } from "modules/technical/models/store";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
type KnowledgeBase = typeof KnowledgeBaseTypes;

export const routes = {
    dictionaries: {
        path: "/dictionaries",
        workType: {
            path: "/dictionaries/work-types",
            details: (id = ":typeId") => `/dictionaries/work-types/${id}`,
        },
        orderType: {
            path: "/dictionaries/order-types",
            details: (id = ":typeId") => `/dictionaries/order-types/${id}`,
        },
        orderIndicator: {
            path: "/dictionaries/order-indicators",
            pathIndicators: "/dictionaries/indicators",
            details: (id = ":typeId") => `/dictionaries/order-indicators/${id}`,
            indicatorsDetails: (id = ":id") => `/dictionaries/indicators/${id}`,
        },
        orderStatus: {
            path: "/dictionaries/order-statuses",
            details: (id = ":statusId") => `/dictionaries/order-statuses/${id}`,
        },
        productionStage: {
            path: "/dictionaries/production-stages",
            details: (id = ":stageId") => `/dictionaries/production-stages/${id}`,
        },
        ipdType: {
            path: "/dictionaries/ipd-types",
            details: (id = ":typeId") => `/dictionaries/ipd-types/${id}`,
        },
        agentsCategories: {
            path: "/dictionaries/agents-categories",
            details: (id = ":typeId") => `/dictionaries/agents-categories/${id}`,
        },
        overheadType: {
            path: "/dictionaries/overhead-types",
            details: (id = ":typeId") => `/dictionaries/overhead-types/${id}`,
        },
        inventory: {
            path: "/dictionaries/material-values",
            pathGroups: "/dictionaries/group",
            categoriesDetails: (id = ":id") => `/dictionaries/material-values/category/${id}`,
            deprGroupsDetails: (id = ":id") => `/dictionaries/group/material-values/group/${id}`,
            itemsDetils: (id = ":id") => `/dictionaries/material-values/material-values/${id}`,
        },

        projectPortfolio: {
            path: "/dictionaries/project-portfolios",
            details: (id = ":portfolioId") => `/dictionaries/project-portfolios/${id}`,
        },
        bankDetails: {
            path: "/dictionaries/bank-details",
            details: (id = ":detailsId") => `/dictionaries/bank-details/${id}`,
        },
    },
    orders: {
        path: "/orders",
        manage: "/orders-manage",
        base: (id = ":orderId") => `/orders/${id}`,
        ipdTab: (id = ":orderId") => `/orders/${id}#tab=${OrderTabs.ipd}`,
        techIndicatorTab: (id = ":orderId") => `/orders/${id}#tab=${OrderTabs.orderTechIndicators}`,
        communicationTab: (id = ":orderId") => `/orders/${id}#tab=${OrderTabs.orderCommunication}`,
        details: (id = ":orderId", tab = "summary") => `/orders/${id}` + (tab ? `#tab=${tab}` : ""),
        workResult: (id = ":orderId", tab = "work-preview") => `/orders/${id}` + (tab ? `#tab=${tab}` : ""),
        mails: "/order-mails",
    },
    spending: {
        path: "/spending",
        timesheet: "/spending/timesheet",
        workload: "/spending/workload",
        schedule: "/spending/schedule",
        unit: "/spending/unit",
        reports: "/spending/reports",
        department: {
            path: "/spending/departments",
            details: (id = ":departmentId") => `/spending/departments/departments/${id}`,
            positionDetails: (id = ":positionId") => `/spending/departments/empl-positions/${id}`,
        },
        employee: {
            path: "/spending/employee",
            details: (id = ":employerId") => `/spending/employee/${id}`,
        },
    },
    reports: {
        path: "/reports",
        employerSpending: "/reports/employer-spending",
        orderYear: "/reports/order-year",
    },
    expenses: {
        path: "/expenses",
        import: "/expenses/import",
        overhead: {
            path: "/expenses/overhead",
            details: (id = ":typeId") => `/expenses/overhead/${id}`,
        },
    },
    agents: {
        clients: {
            path: "/agents/clients",
            details: (id = ":clientId") => `/agents/clients/${id}`,
        },
        outsourcers: {
            outsourcers: "/agents/outsourcers",
            legal: "/agents/outsourcers/legal",
            physical: "/agents/outsourcers/physical",
            details: {
                outsourcers: (id = ":outsourcerId") => `/agents/outsourcers/${id}`,
                legal: (id = ":outsourcerId") => `/agents/outsourcers/legal/${id}`,
                physical: (id = ":outsourcerId") => `/agents/outsourcers/physical/${id}`,
            },
        },
        suppliers: {
            path: "/agents/suppliers",
            details: (id = ":supplierId") => `/agents/suppliers/${id}`,
        },
        path: "/agents",
    },
    tokens: {
        automatic: "/tokens/automatic",
        outer: "/tokens/outer",
        path: "/tokens",
    },
    access: "/access",
    settings: "/settings",
    login: "/login",

    main: {
        path: "/",
        ui: "/ui-kit",
        open: (id = ":newsId") => `/${id}`,
        bot: (token = ":token") => `/bot/${token}`,
    },
    financeRequests: {
        path: "/finance-requests",
        tab: (tab = "new") => "/finance-requests" + (tab ? `#tab=${tab}` : ""),
    },
    technical: {
        path: "/technical",
        manuals: `/technical/${nameof((a: KnowledgeBase) => a.manuals)}`,
        standards: `/technical/${nameof((a: KnowledgeBase) => a.standards)}`,
        templates: `/technical/${nameof((a: KnowledgeBase) => a.templates)}`,
        organisations: `/technical/${nameof((a: KnowledgeBase) => a.organisations)}`,
        orders: `/technical/${nameof((a: KnowledgeBase) => a.orders)}`,
    },
};
