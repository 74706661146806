import React from "react";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { fields, workTypeNames, OutsourcerSnapshotType } from "modules/agents/outsourcers/models/outsourcer";
import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import Schema from "modules/agents/outsourcers/components/details/validation";
import { getFieldLabel } from "modules/common/services/form/fields";
import { Rating } from "modules/common/components/rating/Rating";

import { Money } from "modules/common/components/money/Money";
import { isNotNull } from "modules/common/services/typescript";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";

const schema = Schema(false);

export const columns = (tableName: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        // RowNumberColumn(),
        {
            headerName: "",
            field: fields.isConfirmed,
            width: 40,
            sortable: true,
            resizable: false,
            cellClass: "centered",
            cellRendererFramework: ({ data }: ICellRendererParams) => {
                if (data.type === "physOutsourcer" || data.type === "legalOutsourcer") {
                    if (data.isConfirmed) {
                        return <GeneralIcon type={"general-like"} size={14} style={{ color: "#1DD278" }} />;
                    } else {
                        return (
                            <GeneralIcon
                                type={"general-question"}
                                size={14}
                                style={{ color: "rgba(0, 39, 61, 0.3)" }}
                            />
                        );
                    }
                }
                return null;
                // return value ? (
                //     <GeneralIcon type={"general-like"} size={14} style={{ color: "#1DD278" }} />
                // ) : (
                //     <GeneralIcon type={"general-question"} size={14} style={{ color: "rgba(0, 39, 61, 0.3)" }} />
                // );
            },
        },
        {
            headerName: "",
            field: fields.isLegalEntity,
            width: 145,
            sortable: true,
            resizable: false,
            cellRendererFramework: ({ data }: ICellRendererParams) => {
                let text =
                    data.type === "client"
                        ? "Заказчик"
                        : data.type === "supplier"
                        ? "Поставщик"
                        : data.type === "physOutsourcer" || data.type === "legalOutsourcer"
                        ? "Аутсорсер"
                        : "Агент";

                if (data.type !== "agent" && data.isAgent) {
                    text += " (Агент)";
                }
                return <span>{text}</span>;
            },
        },
        {
            headerName: "",
            field: fields.selfEmployed,
            width: 40,
            sortable: true,
            resizable: false,
            cellClass: "centered",
            cellRendererFramework: ({ data }: ICellRendererParams) => {
                return data.type === "physOutsourcer" ? (
                    <GeneralIcon type={"general-ppl"} size={14} />
                ) : data.type === "legalOutsourcer" ? (
                    <GeneralIcon type={"general-folder"} size={14} />
                ) : null;
            },
        },
        {
            headerName: "",
            field: fields.selfEmployed,
            width: 40,
            sortable: true,
            resizable: false,
            cellClass: "centered",
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return value === true ? <GeneralIcon type={"general-case"} size={14} /> : null;
            },
        },
        {
            headerName: "Наименование",
            field: fields.name,
            width: columnSize(fields.name, 150),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.phone, schema, null),
            field: fields.phone,
            width: columnSize(fields.phone, 140),
            sortable: true,
            resizable: true,
            valueGetter: ({ data }: ValueGetterParams) => {
                const row: OutsourcerSnapshotType = data;
                return row.phone || row.phone2 || row.phone3;
            },
            valueFormatter: ({ value }: ValueFormatterParams) => formatPhoneNumber(value),
        },

        {
            headerName: getFieldLabel(fields.email, schema, null),
            field: fields.email,
            width: columnSize(fields.email, 130),
            sortable: true,
            resizable: true,
        },
        {
            headerName: getFieldLabel(fields.comment, schema, null),
            field: fields.comment,
            width: columnSize(fields.comment, 115),
            sortable: false,
            resizable: true,
        },
        {
            headerName: "Вид работ",
            field: fields.workTypeIds,
            width: columnSize(fields.workTypeIds, 155),
            sortable: true,
            resizable: true,
            valueGetter: ({ data }: ValueGetterParams) => {
                return workTypeNames(data);
            },
        },
        {
            headerName: "Цена",
            field: fields.priceRating,
            width: columnSize(fields.priceRating, 90),
            sortable: true,
            resizable: true,
            cellClass: "flat centered",
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return value === "" ? null : <Rating rating={value} />;
            },
        },
        {
            headerName: "Качество",
            field: fields.qualityRating,
            width: columnSize(fields.qualityRating, 90),
            sortable: true,
            resizable: true,
            cellClass: "flat centered",
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return value === "" ? null : <Rating rating={value} />;
            },
        },
        {
            headerName: "Сроки",
            field: fields.timeRating,
            width: columnSize(fields.timeRating, 90),
            sortable: true,
            resizable: true,
            cellClass: "flat centered",
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                return value === "" ? null : <Rating rating={value} />;
            },
        },
        {
            headerName: "Стоимость",
            field: fields.actualSum,
            width: columnSize(fields.actualSum, 120),
            sortable: true,
            resizable: true,
            cellRendererFramework: (data: any) => {
                let actSum = 0;
                if (data.data.type !== "client") {
                    if (data.data.spendings && data.data.spendings.length > 0) {
                        actSum = data.data.spendings.reduce((acc: any, sp: any) => acc + sp.actualSum, 0);
                        return <Money amount={actSum} />;
                    }
                } else {
                    if (data.data.orders && data.data.orders.length > 0) {
                        actSum = data.data.orders.reduce((acc: any, sp: any) => acc + sp.planSum, 0);
                        return <Money amount={actSum} />;
                    }
                }
                return null;
            },
        },
        {
            headerName: "Оплачено",
            field: fields.debit,
            width: columnSize(fields.debit, 110),
            sortable: true,
            resizable: true,
            cellRendererFramework: (data: any) => {
                let actSum = 0;
                if (data.data.type !== "client") {
                    if (data.data.spendings && data.data.spendings.length > 0) {
                        actSum = data.data.spendings.reduce((acc: any, sp: any) => acc + sp.debit, 0);
                        return <Money amount={actSum} />;
                    }
                } else {
                    if (data.data.orders && data.data.orders.length > 0) {
                        actSum = data.data.orders.reduce((acc: any, sp: any) => acc + sp.actSum, 0);
                        return <Money amount={actSum} />;
                    }
                }
                return null;
            },
        },
        {
            headerName: "Долг",
            field: fields.credit,
            width: columnSize(fields.credit, 115),
            sortable: true,
            resizable: true,
            cellRendererFramework: (data: any) => {
                let actSum = 0;
                if (data.data.type !== "client") {
                    if (data.data.spendings && data.data.spendings.length > 0) {
                        actSum = data.data.spendings.reduce((acc: any, sp: any) => acc + sp.credit, 0);
                        return <Money amount={actSum} />;
                    }
                } else {
                    if (data.data.orders && data.data.orders.length > 0) {
                        actSum = data.data.orders.reduce((acc: any, sp: any) => acc + sp.remainSum, 0);
                        return <Money amount={actSum} />;
                    }
                }
                return null;
            },
        },
    ].filter(isNotNull);

    return result;
};
