import React from "react";
import {
    am4core,
    am4charts,
    SCROLL_DELAY,
    customizeScrollbar,
    createScrollSeries,
    am4lang_ru_RU,
    customizeBulletTooltip,
    customizeSeriesTooltip,
} from "../am";
import {
    OrdersChartDatasetType,
    ACTUAL_PAYMENTS_LABEL,
    PLAN_PAYMENTS_LABEL,
    PAYMENTS,
    OUTSOURCE,
    FlowDatasetOrderPaymentSnapshotType,
    PLAN_OUTSOURCED_LABEL,
    ACTUAL_OUTSOURCED_LABEL,
    withOutsourcer,
    OVERHERAD,
    PLAN_OVERHERAD_LABEL,
    ACTUAL_OVERHERAD_LABEL,
    OverheadSpendingRowSnapshotType,
    PrintCommand,
} from "modules/main/models/orders-chart";
import { observer } from "mobx-react";
import { IDisposer } from "mobx-state-tree";
import { reaction } from "mobx";
import { STANDARD_GREY } from "modules/common/constants";
import { capitalize } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { PaymentList, buildPaymentsBottomLine } from "./PaymentList";
import styles from "../Dashboard.module.scss";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { sortBy } from "lodash";
import { OverheadSpendingsList, buildOverheadSpendingsBottomLine } from "./OverheadSpendingsList";
import { Series, ValueAxis } from "@amcharts/amcharts4/charts";

const CORNER_RADIUS = 4;

export const MainChart = observer(
    class extends React.PureComponent<MainChartProps, MainChartState> {
        private rangechanged = false;
        private container = React.createRef<HTMLDivElement>();
        private chart: any;
        private stop: IDisposer | null = null;

        constructor(props: any) {
            super(props);
            this.state = { period: "", type: "" };
        }

        componentDidMount() {
            const { store } = this.props;
            this.setupChart(store.amChartData);
            store.load();

            this.stop = reaction(
                () => store.loaded,
                (loaded) => {
                    if (loaded) {
                        this.setupChart(store.amChartData);
                        store.setLoaded(false);
                    }
                }
            );
        }

        setupChart = (data: any[]) => {
            if (!this.container.current) {
                return;
            }

            if (!this.chart && data.length) {
                const chart = am4core.create(this.container.current, am4charts.XYChart);
                chart.language.locale = am4lang_ru_RU;
                chart.zoomOutButton.disabled = true;

                const periodAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                periodAxis.dataFields.category = "period";
                periodAxis.title.text = "";
                periodAxis.renderer.minGridDistance = 20;
                periodAxis.renderer.cellStartLocation = 0.1;
                periodAxis.renderer.cellEndLocation = 0.9;
                periodAxis.renderer.grid.template.location = 0;
                periodAxis.renderer.grid.template.stroke = am4core.color("#FFF", 0);
                periodAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
                periodAxis.renderer.labels.template.horizontalCenter = "middle";
                periodAxis.renderer.labels.template.verticalCenter = "middle";
                periodAxis.renderer.labels.template.rotation = 315;
                periodAxis.renderer.labels.template.fontSize = "11px";
                periodAxis.renderer.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                periodAxis.renderer.line.strokeOpacity = 1;
                periodAxis.renderer.line.strokeWidth = 1;
                periodAxis.renderer.line.stroke = am4core.color("#000");
                periodAxis.cursorTooltipEnabled = false;
                periodAxis.renderer.labels.template.events.on("hit", (ev) => {
                    this.printWholePeriod((ev.target.dataItem as any).category);
                });

                const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.min = 0;
                valueAxis.title.text = "";
                valueAxis.renderer.labels.template.fill = am4core.color(STANDARD_GREY);
                valueAxis.renderer.labels.template.fontSize = "11px";
                valueAxis.renderer.line.strokeOpacity = 1;
                valueAxis.renderer.line.strokeWidth = 1;
                valueAxis.renderer.line.stroke = am4core.color("#000");
                valueAxis.cursorTooltipEnabled = false;

                const legenddata: any[] = [];

                const createBarSeries = (
                    legend: number,
                    field: string,
                    name: string,
                    color: string,
                    stacked: boolean
                ) => {
                    const series = chart.series.push(new am4charts.ColumnSeries());
                    series.dataFields.valueY = field;
                    series.dataFields.categoryX = "period";
                    series.name = name;
                    //series.columns.template.tooltipText = "{name}\n{categoryX}: [bold]{valueY}[/]";
                    series.stacked = stacked;
                    series.columns.template.width = am4core.percent(85);
                    series.columns.template.fill = am4core.color(color);
                    series.columns.template.stroke = am4core.color(color);
                    series.xAxis = periodAxis;

                    series.columns.template.column.adapter.add("cornerRadiusTopLeft", cornerRadius(chart) as any);
                    series.columns.template.column.adapter.add("cornerRadiusTopRight", cornerRadius(chart) as any);

                    customizeSeriesTooltip(series.columns, color, name, "{categoryX}: {valueY}", 150);

                    if (this.clickable(name)) {
                        series.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                        series.columns.template.events.on("hit", (ev) => {
                            this.onDataClick(name, ev.target.dataItem?.categories["categoryX"] || "");
                        });
                    }

                    if (legend >= 0) {
                        legenddata.push({
                            name,
                            fill: am4core.color(color),
                            _series: series,
                            _index: legend,
                        });
                    }

                    return series;
                };

                const createLineSeries = (
                    legend: number,
                    field: string,
                    name: string,
                    color: string,
                    dashed?: boolean
                ) => {
                    const paretoSeries = chart.series.push(new am4charts.StepLineSeries());
                    paretoSeries.dataFields.valueY = field;
                    paretoSeries.dataFields.categoryX = "period";
                    paretoSeries.name = name;
                    paretoSeries.strokeWidth = 2;
                    paretoSeries.fill = am4core.color(color);
                    paretoSeries.stroke = am4core.color(color);
                    paretoSeries.xAxis = periodAxis;

                    if (dashed) {
                        paretoSeries.strokeDasharray = "5,3";
                    }

                    const circleBullet = paretoSeries.bullets.push(new am4charts.CircleBullet());
                    //circleBullet.tooltipText = "{name}\n{categoryX}: [bold]{valueY}[/]";
                    circleBullet.circle.fill = am4core.color(color);
                    circleBullet.circle.stroke = am4core.color(color, 0.15);
                    circleBullet.circle.strokeWidth = 8;
                    circleBullet.circle.radius = 3;
                    circleBullet.clickable = true;

                    if (this.clickable(name)) {
                        circleBullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
                        circleBullet.events.on("hit", (e) => {
                            this.onDataClick(name, e.target.dataItem?.categories["categoryX"] || "");
                        });
                    }

                    customizeBulletTooltip(circleBullet, color, name, "{categoryX}: {valueY}", 150);

                    if (legend >= 0) {
                        legenddata.push({
                            name,
                            fill: am4core.color(color),
                            _series: paretoSeries,
                            _index: legend,
                        });
                    }

                    return paretoSeries;
                };

                createLineSeries(5, "planPayments", PLAN_PAYMENTS_LABEL, "#1DD278", true);
                createLineSeries(6, "actualPayments", ACTUAL_PAYMENTS_LABEL, "#E31818", true);

                createBarSeries(1, "planOwn", "План ЗП", "#36ACD7", false);
                createBarSeries(3, "planOutsourced", PLAN_OUTSOURCED_LABEL, "#D76C36", true);
                createBarSeries(7, "planOverhead", "План Накладные", "#FBB916", true);

                createBarSeries(2, "actualOwn", "Факт ЗП", "#9CD9F0", false);
                createBarSeries(4, "actualOutsourced", ACTUAL_OUTSOURCED_LABEL, "#F0B89C", true);
                createBarSeries(7, "actualOverhead", "Факт Накладные", "#FBE2A3", true);

                const scrollbar = new am4charts.XYChartScrollbar();
                chart.scrollbarX = scrollbar;
                scrollbar.series.push(
                    createScrollSeries(chart, "actualPayments", "period", ACTUAL_PAYMENTS_LABEL, "#5E7784")
                );
                scrollbar.parent = chart.chartAndLegendContainer;
                scrollbar.events.on("rangechanged", () => {
                    this.rangechanged = true;
                });
                customizeScrollbar(scrollbar);

                chart.numberFormatter.numberFormat = "#,###. ₽";
                chart.dateFormatter.dateFormat = "MM.yyyy";

                chart.legend = new am4charts.Legend();
                chart.legend.useDefaultMarker = true;
                chart.legend.data = sortBy(legenddata, (l) => l._index);
                chart.legend.itemContainers.template.events.on("hit", function (ev) {
                    const item = ev.target.dataItem;
                    if (item && item.dataContext) {
                        const series: Series = (item.dataContext as any)._series;

                        if (!ev.target.isActive) {
                            series.hide();
                        } else {
                            series.show();
                        }
                    }
                });
                const markerTemplate = chart.legend.markers.template;
                markerTemplate.width = 16;
                markerTemplate.height = 16;

                const marker: any = markerTemplate.children.getIndex(0);
                if (marker) {
                    marker.cornerRadius(8, 8, 8, 8);
                }

                chart.events.on("datavalidated", () => {
                    valueAxis.min = valueAxis.minZoomed;
                    valueAxis.max = valueAxis.maxZoomed;

                    const values = chart.data;
                    if (values.length > 8 && !this.rangechanged) {
                        const start = values[values.length - 8].period;
                        const end = values[values.length - 1].period;

                        setTimeout(() => {
                            if (!periodAxis.isDisposed()) {
                                periodAxis.zoomToCategories(start, end);
                            }
                        }, SCROLL_DELAY);
                    }
                });

                this.chart = chart;
            }

            if (this.chart) {
                if (this.props.graduator) {
                    const valueAxis = this.chart.yAxes.getIndex(0);
                    valueAxis.renderer.grid.template.disabled = true;
                    valueAxis.renderer.labels.template.disabled = true;
                    this.props.graduator(valueAxis, this.props.store.maxValue);
                }

                const keys = [
                    "planOwn",
                    "planOutsourced",
                    "planOverhead",
                    "actualOwn",
                    "actualOutsourced",
                    "actualOverhead",
                ];

                // скрываем колонки с 0
                this.chart.data = data.map((step) => {
                    const clone: any = { ...step };
                    keys.forEach((key) => {
                        if (clone[key] === 0) {
                            delete clone[key];
                        }
                    });
                    return clone;
                });
            }
        };

        componentWillUnmount() {
            this.stop && this.stop();
            this.chart && this.chart.dispose();
        }

        render() {
            const { store, height } = this.props;
            const { type } = this.state;
            const showLoader = !store.loaded && store.loading;
            const popupData = this.getPopupData();

            return (
                <div className={styles.widget} style={{ width: `100%`, minWidth: `100%`, height: `${height}px` }}>
                    {popupData && (popupData.label.includes(OUTSOURCE) || popupData.label.includes(PAYMENTS)) && (
                        <PaymentList
                            palnPayments={popupData.plan}
                            actualPayments={popupData.actual}
                            onClose={this.cleanPayments}
                            title={popupData.label}
                            showOutsourcer={withOutsourcer(type)}
                            onPrint={this.printFromPopup}
                            setPaymentDate={store.setPaymentDate}
                        />
                    )}

                    {popupData && popupData.label.includes(OVERHERAD) && (
                        <OverheadSpendingsList
                            palnSpendings={popupData.plan}
                            actualSpendings={popupData.actual}
                            onClose={this.cleanPayments}
                            title={popupData.label}
                            onPrint={this.printFromPopup}
                        />
                    )}

                    <LocalLoader active={showLoader} />

                    <div ref={this.container} style={{ height: "100%" }}></div>
                </div>
            );
        }

        cleanPayments = () => this.setState({ period: "", type: "" });

        getPopupData = (): PopupData | null => {
            const { period, type } = this.state;
            const { paymentsMap, overheadsMap, labels } = this.props.store;
            const index = labels.indexOf(period);

            if (index >= 0) {
                if (type.includes(PAYMENTS)) {
                    const label = maleTitle(period, PAYMENTS);
                    return {
                        plan: paymentsMap[PLAN_PAYMENTS_LABEL][index],
                        actual: paymentsMap[ACTUAL_PAYMENTS_LABEL][index],
                        label,
                    };
                }

                if (type.includes(OUTSOURCE)) {
                    const label = maleTitle(period, OUTSOURCE);
                    return {
                        plan: paymentsMap[PLAN_OUTSOURCED_LABEL][index],
                        actual: paymentsMap[ACTUAL_OUTSOURCED_LABEL][index],
                        label,
                    };
                }

                if (type.includes(OVERHERAD)) {
                    const label = maleTitle(period, OVERHERAD);
                    return {
                        plan: overheadsMap[PLAN_OVERHERAD_LABEL][index],
                        actual: overheadsMap[ACTUAL_OVERHERAD_LABEL][index],
                        label,
                    };
                }
            }

            return null;
        };

        clickable = (label: string) => {
            const { store } = this.props;

            return !!store.paymentsMap[label] || !!store.overheadsMap[label];
        };

        onDataClick = (label: string, period: string) => {
            if (label && period && this.clickable(label)) {
                this.setState({ period, type: label });
            }
        };

        printFromPopup = () => {
            const { period, type } = this.state;

            const filter = type.includes(OUTSOURCE)
                ? (a: string) => a.includes(OUTSOURCE)
                : type.includes(PAYMENTS)
                ? (a: string) => a.includes(PAYMENTS)
                : (a: string) => a.includes(OVERHERAD);

            this.printWholePeriod(period, filter);
        };

        printWholePeriod = (period: string, filter = (_: string) => true) => {
            const { store } = this.props;
            const index = store.labels.indexOf(period);

            if (index >= 0) {
                const commands: PrintCommand[] = [];

                Object.keys(store.paymentsMap)
                    .filter(filter)
                    .forEach((label) => {
                        const title = maleTitle(period, label);
                        const json = buildPaymentsPrintJson(store.paymentsMap[label][index], title);

                        commands.push({ json, title });
                    });

                Object.keys(store.overheadsMap)
                    .filter(filter)
                    .forEach((label) => {
                        const title = maleTitle(period, label);
                        const json = buildOverheadSpendingsPrintJson(store.overheadsMap[label][index], title);

                        commands.push({ json, title });
                    });

                commands.length && store.batchPrint(commands, period);
            }
        };
    }
);

export interface MainChartProps {
    store: OrdersChartDatasetType;

    height: number;
    graduator?: (axis: ValueAxis, maxValue: number) => void;
}

interface MainChartState {
    /** Period index */
    period: string;
    /** Chart type */
    type: string;
}

interface PopupData {
    plan: any[];
    actual: any[];
    label: string;
}

function maleTitle(period: string, label: string) {
    return `${capitalize(period)}: ${label}`;
}

function buildPaymentsPrintJson(payments: FlowDatasetOrderPaymentSnapshotType[], title: string) {
    const bottom = buildPaymentsBottomLine(payments);
    const value = [...payments, ...bottom];
    return JSON.stringify({ value, title });
}

function buildOverheadSpendingsPrintJson(spendings: OverheadSpendingRowSnapshotType[], title: string) {
    const bottom = buildOverheadSpendingsBottomLine(spendings);
    const value = [...spendings, ...bottom];
    return JSON.stringify({ value, title });
}

const cornerRadius = (chart: am4charts.XYChart) => (radius: number, item: any) => {
    let dataItem = item.dataItem;

    const series: am4charts.XYSeries = dataItem.component;
    const className = series.className;
    const checker = series.dataFields.valueY?.includes("plan") ? "plan" : "actual";

    // Find the last series in this stack
    let lastSeries;
    chart.series.each((s) => {
        if (s.className !== className || !s.dataFields.valueY || !s.dataFields.valueY.includes(checker)) {
            return;
        }

        if (dataItem.dataContext[s.dataFields.valueY] && !s.isHidden && !s.isHiding) {
            lastSeries = s;
        }
    });

    // If current series is the one, use rounded corner
    return series === lastSeries ? CORNER_RADIUS : radius;
};
