import { types, applySnapshot } from "mobx-state-tree";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { apiUrls } from "modules/common/services/communication/urls";
import { flow } from "modules/common/models/flow";
import { AgentsCategoriesDictionaryItem, AgentsCategoriesDictionaryItemType } from "./agents-categories";
import { Queryable } from "modules/common/models/queryable";

export const AgentsCategoriesDictionary = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            types: types.array(AgentsCategoriesDictionaryItem),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: AgentsCategoriesDictionaryItemType[] = yield self.transport.get<any>(
                    apiUrls.agentsCategories.dictionary()
                );
                applySnapshot(self.types, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.types.length;
        },

        get filtered() {
            return self.types.filter((idp) => {
                const text = `${idp.label}${idp.description}`.toLowerCase();
                return text.includes(self.pureQuery);
            });
        },
    }))
    .named("AgentsCategoriesDictionary");

export const initialState = (): AgentsCategoriesDictionarySnapshotType => ({
    types: [],
    pureQuery: "",
    query: "",
});

export type AgentsCategoriesDictionaryType = typeof AgentsCategoriesDictionary.Type;
export type AgentsCategoriesDictionarySnapshotType = typeof AgentsCategoriesDictionary.SnapshotType;
