import React from "react";
import { Menu } from "@blueprintjs/core";
import { MainMenuItem } from "./MainMenuItem";
import styles from "./MainMenu.module.scss";
import { withRouter, RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { menuItems as itemsBuilder } from "./types";
import { ApplicationStoreType } from "modules/root/models/store";

export const menuItems = itemsBuilder;

export const MainMenu = observer((props: MainMenuProps) => {
    const { currentItem, store } = props;
    const session = store.session;
    const items = menuItems(store.session.access, store.orders.list);
    const access = getSnapshot(session.access);
    const menuStore = store.mainMenu;

    return (
        <div className={`${styles.menu} planr-main-menu`}>
            <div className={styles.scroller}>
                <Menu className="root">
                    {Object.keys(items).map((id) => {
                        const isExpanded = !!menuStore.items.get(id);
                        const itemConfig = items[id];

                        return (
                            <MainMenuItem
                                key={id}
                                item={itemConfig}
                                currentItem={currentItem}
                                expanded={isExpanded}
                                onClick={() => {
                                    menuStore.toggle(id, !isExpanded);
                                    itemConfig.handler && itemConfig.handler(!isExpanded);
                                }}
                                access={access}
                            />
                        );
                    })}
                </Menu>
            </div>
        </div>
    );
});

export const MainMenuWithRouter = withRouter(
    observer((props: RouteComponentProps & MainMenuWithRouterProps) => {
        const { location, ...rest } = props;
        const currentItem = location.pathname + location.hash;
        return <MainMenu currentItem={currentItem} {...rest} />;
    })
);

interface MainMenuWithRouterProps {
    store: ApplicationStoreType;
}

interface MainMenuProps extends MainMenuWithRouterProps {
    currentItem: string;
}
