import React from "react";
import { WorkTypeLinkSnapshotType } from "modules/orders-manage/models/order";
import { Dialog, Classes } from "@blueprintjs/core";
import {
    WorkTypeDictionaryType,
    WorkTypeDictionaryItemSnapshotType as W,
} from "modules/dictionaries/work-types/models/work-type-dictionary";
import { observer } from "mobx-react";
import styles from "./Block.module.scss";
import { Buttons } from "modules/common/components/form";
import { texts } from "modules/common/texts";

import { Search } from "modules/common/components/form/Search";
import { PlanrButton } from "modules/common/components/planr/button/Button";

export const ICON_SIZE = 17;

type TUnit = WorkTypeLinkSnapshotType;

export const ContentDetais = observer(
    class extends React.Component<ContentDetaisProps, ContentDetaisState> {
        constructor(props: ContentDetaisProps) {
            super(props);
            this.state = { units: [...props.units], message: "" };
        }

        render() {
            const { workTypes, objectName, workTypeCategory, factory, orderNumber, objectNumber } = this.props;
            const { units, message } = this.state;

            return (
                <Dialog
                    isCloseButtonShown={true}
                    isOpen={true}
                    className={`${styles.details} figma-dialog`}
                    onClose={this.onCancel}
                    title={workTypeCategory}
                    backdropClassName="standard"
                    canOutsideClickClose={false}
                >
                    <div className={Classes.DIALOG_BODY}>
                        <Search query={workTypes.query} onSearch={workTypes.setQuery} />

                        {workTypes.filteredAsMap[workTypeCategory].map((type) => {
                            const checked = !!units.find((work) => work.workTypeId === type.id);

                            return (
                                <div key={type.id} className={styles.row}>
                                    {" "}
                                    <div className={styles.rowFields}>
                                        <div className={styles.iconCell}>
                                            <PlanrButton
                                                type="neutral"
                                                icon={checked ? "general-checkbox" : "general-zero-checkbox"}
                                                size="small"
                                                onClick={async () => {
                                                    let newValue: TUnit[];

                                                    // add new type
                                                    if (!checked) {
                                                        this.setState({ message: "" });

                                                        const content = await factory.emptyContentFromWorkType(
                                                            orderNumber,
                                                            objectNumber,
                                                            objectName,
                                                            type,
                                                            units.length + 1
                                                        );

                                                        newValue = [...units, content];
                                                    } else {
                                                        newValue = units.filter((work) => work.workTypeId !== type.id);
                                                    }

                                                    this.setState({ units: newValue });
                                                }}
                                            />
                                        </div>

                                        <div className={styles.workAndInventory}>{type.label}</div>

                                        <div className={styles.description}>{type.description}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div style={{ display: "flex" }}>
                                    <PlanrButton type="greenish" size="small" onClick={this.onClose}>
                                        {texts.save}
                                    </PlanrButton>

                                    <PlanrButton
                                        type="graybtn"
                                        onClick={this.onCancel}
                                        size="small"
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {texts.cancel}
                                    </PlanrButton>
                                </div>
                                // <>
                                //     <Button intent="success" onClick={this.onClose} text={texts.save} />

                                //     <Button onClick={this.onCancel} text={texts.cancel} />
                                // </>
                            }
                        >
                            {message && <div className={styles.error}>{message}</div>}
                        </Buttons>
                    </div>
                </Dialog>
            );
        }

        onClose = () => {
            const { units } = this.state;
            const { onClose } = this.props;

            if (!units.length) {
                this.setState({ message: "Необходимо выбрать список работ" });
            } else {
                onClose(units);
            }
        };

        onCancel = () => this.props.onClose([]);
    }
);

export interface ContentFactory {
    emptyContent: (orderNumber: string, sortOrder: number) => Promise<TUnit>;
    emptyContentFromWorkType: (
        orderNumber: string,
        objectNumber: number,
        objectName: string,
        type: W,
        sortOrder: number
    ) => Promise<TUnit>;
}

interface ContentDetaisProps {
    workTypes: WorkTypeDictionaryType;
    units: TUnit[];
    onClose: (units: TUnit[]) => void;
    objectNumber: number;
    objectName: string;
    orderNumber: string;
    workTypeCategory: string;
    factory: ContentFactory;
}

interface ContentDetaisState {
    units: TUnit[];
    message: string;
}
