import React from "react";

import { OrderMailSnapshotType } from "modules/orders-manage/models/order-mails";
import styles from "./SidePanel.module.scss";

import { Previewer as FileViewer } from "modules/common/components/files/Previewer";
import { RenderCacheType } from "modules/orders-manage/models/orders-store";
import { FileMetadataSnapshotType } from "modules/common/models/file-metadata";
import { observer } from "mobx-react";
import { Documents } from "./Documents";

import { texts } from "modules/common/texts";
import { LocalLoader } from "modules/common/components/loader/Loader";

import { PlanrButton } from "modules/common/components/planr/button/Button";

interface SidePanelProps {
    selection: OrderMailSnapshotType | null;
    renderer: RenderCacheType;
    onPreview: (document: FileMetadataSnapshotType | null) => void;
    goBack: (from: OrderMailSnapshotType) => void;
    goForward: (from: OrderMailSnapshotType) => void;
    onPrint: (fileId: string) => void;
    onDownload: (fileId: string) => void;
    loading: boolean;
}

class Panel extends React.Component<SidePanelProps> {
    render() {
        const { selection, renderer, onPreview, loading } = this.props;

        return (
            <div className={`side-panel ${styles.details}`}>
                <LocalLoader active={loading} />

                {selection && (
                    <>
                        <div className={styles.detailsTools}>
                            <div className={styles.detailsBtns}>
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-big-arrow-left"
                                    onClick={this.goBack}
                                    title="Предыдущее письмо"
                                    round={true}
                                    size="small"
                                />
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-big-arrow-right"
                                    onClick={this.goForward}
                                    title="Следующее письмо"
                                    round={true}
                                    size="small"
                                />
                            </div>
                            <div className={styles.detailsBtns}>
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-download"
                                    onClick={this.download}
                                    title={`${texts.download} файл`}
                                    round={true}
                                    size="small"
                                />
                                <PlanrButton
                                    type="graybtn"
                                    icon="general-print"
                                    onClick={this.print}
                                    title={`${texts.print} файл`}
                                    round={true}
                                    size="small"
                                />
                            </div>
                        </div>

                        <div className={styles.viewer}>
                            <div className={styles.detailsFiles}>
                                {selection.documents.length > 0 && (
                                    <Documents columns={4} documents={selection.documents} onSelect={onPreview} />
                                )}
                            </div>

                            <FileViewer document={renderer.viewer} />
                        </div>
                    </>
                )}
            </div>
        );
    }

    goBack = () => this.props.selection && this.props.goBack(this.props.selection);

    goForward = () => this.props.selection && this.props.goForward(this.props.selection);

    print = () => {
        if (this.props.renderer.id) {
            this.props.onPrint(this.props.renderer.id);
        }
    };

    download = () => {
        if (this.props.renderer.id) {
            this.props.onDownload(this.props.renderer.id);
        }
    };
}

export const SidePanel = observer(Panel);
