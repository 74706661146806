import * as yup from 'yup';
import { fields } from 'modules/dictionaries/work-types/models/work-type';
const { object, string } = yup;

export const workTypeLabels = ({
    [fields.name]: 'Сокращенно',
    [fields.description]: 'Описание',
    [fields.category]: 'Категория',
});

const WorkTypeSchema = object().shape({

    [fields.name]: string()
        .label(workTypeLabels[fields.name])
        .required(),

    [fields.description]: string()
        .label(workTypeLabels[fields.description])
        .notRequired(),

    [fields.category]: string()
        .label(workTypeLabels[fields.category])
        .required(),
});

export default WorkTypeSchema;
