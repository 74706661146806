import { getSnapshot, types } from "mobx-state-tree";
import { DEFAULT_SORTING_ASCENDING_VALUE } from "modules/common/constants";
import { flow } from "modules/common/models/flow";
import { Notificator } from "modules/common/models/notificator";
import { Queryable } from "modules/common/models/queryable";
import { TableSorter } from "modules/common/models/table-sorter";
import { Transport } from "modules/common/models/transport";
import { getSortOption } from "modules/common/services/table/sorting-storage";
import { Outsourcer, OutsourcerSnapshotType, OutsourcerType, fields } from "./outsourcer";

export const OutsourcerList = types
    .compose(
        Transport,
        Notificator,
        Queryable,
        types.model({
            outsourcers: types.array(Outsourcer),
            sorter: TableSorter,
            tabIndex: types.string,
            outsourceIndex: types.string,
        })
    )
    .views((self) => ({
        get asMap(): TStringMap<OutsourcerType> {
            return self.outsourcers.reduce((result, outsourcer) => {
                result[outsourcer.id] = outsourcer;
                return result;
            }, {} as TStringMap<OutsourcerType>);
        },

        get data() {
            const predicate = (o: OutsourcerType) => {
                let result = true;
                result =
                    result &&
                    `${
                        o.type === "supplier"
                            ? "supplier"
                            : o.type === "client"
                            ? "client"
                            : o.type === "legalOutsourcer" || o.type === "physOutsourcer"
                            ? "outsourcer"
                            : ""
                    } ${o.isAgent === true ? "agent" : ""}`.indexOf(self.tabIndex) >= 0;

                result = result && o.type.indexOf(self.outsourceIndex) >= 0;
                if (self.pureQuery) {
                    result =
                        result &&
                        `${o.name} ${o.phone} ${o.email} ${o.workTypeNames} ${o.actualSum}`
                            .toLowerCase()
                            .indexOf(self.pureQuery) >= 0;
                }

                return result;
            };

            return self.outsourcers.filter((o) => predicate(o)).map((o) => getSnapshot(o));
        },

        get isEmpty() {
            return !self.outsourcers.length;
        },
    }))
    .actions((self) => ({
        tabSelect(index: string) {
            self.tabIndex = index;
        },
        tabOutsourceSelect(index: string) {
            self.outsourceIndex = index;
        },
        delete: flow(function* (outsourcer: OutsourcerSnapshotType) {
            const model = Outsourcer.create(outsourcer, {
                http: self.transport,
                notificator: self.notify,
            });
            return yield model.delete();
        }),
    }))
    .named("OutsourcerList");

export type OutsourcerListType = typeof OutsourcerList.Type;
export type OutsourcerListSnapshotType = typeof OutsourcerList.SnapshotType;
export { Outsourcer };

export const initialState = (tableName: string): OutsourcerListSnapshotType => {
    const sortStorage = getSortOption(tableName);
    const options = sortStorage({ column: fields.name, asc: DEFAULT_SORTING_ASCENDING_VALUE });

    return {
        outsourcers: [],
        pureQuery: "",
        query: "",
        sorter: {
            id: tableName,
            tableName,
            column: options.column,
            asc: options.asc,
        },
        tabIndex: "",
        outsourceIndex: "",
    };
};
