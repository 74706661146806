import { GridOptions, RowClickedEvent } from "@ag-grid-community/core";
import { AgGridReact } from "@ag-grid-community/react";
import { Classes, Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Buttons } from "modules/common/components/form";
import { Search } from "modules/common/components/form/Search";
import { LocalLoader } from "modules/common/components/loader/Loader";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { MAX_INT } from "modules/common/constants";
import { routes } from "modules/common/routes";
import { DefaultAgGridOptions, defaultModules } from "modules/common/services/table/helpers";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import {
    OrderContentTaskListItemType,
    OrderContentTaskStore,
    OrderContentTaskStoreType,
} from "modules/main/models/order-content-task-list";
import { OrderTabs } from "modules/orders-manage/details/OrderDetailsTabsPage";
import { PARAM_RECORD } from "modules/orders-manage/models/order";
import { NotificationStoreType } from "modules/root/models/alerts";
import { agGridLocale } from "modules/root/services/locale";
import React from "react";
import styles from "./TaskList.module.scss";
import { columns } from "./columns";

const modules = [...defaultModules];
const TABLE_NAME = OrderContentTaskStore.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);

class TaskItems extends React.Component<TaskListProps, TaskListState> {
    constructor(props: TaskListProps) {
        super(props);

        this.state = {
            showRemoveOrder: false,
            order: null,
        };
    }
    async componentDidMount() {
        await this.props.store.load();
    }
    setCopied = () => {
        this.props.notification.addNotification("success", "Текст скопирован!");
    };

    private options: GridOptions = DefaultAgGridOptions();
    private config = columns(TABLE_NAME, this.props.baseUrl, this.setCopied);

    render() {
        const { mainData, loading } = this.props.store;
        return (
            <div className={`${styles.wrapper} `}>
                <Dialog
                    title={`Удаление проекта`}
                    isOpen={this.state.showRemoveOrder}
                    onClose={this.toggleShowRemoveOrder}
                    backdropClassName="standard"
                    className={`${styles.smallDialog} ${styles.workDialog} figma-dialog`}
                >
                    <div className={`${Classes.DIALOG_BODY} `}>
                        <div className={styles.workItemContainer}>
                            {`Вы точно хотите удалить договор ${this.state.order?.inventoryNumber} - ${this.state.order?.orderName}?`}
                        </div>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <Buttons
                            left={
                                <div
                                    style={{
                                        display: "flex",
                                    }}
                                >
                                    <PlanrButton
                                        size="small"
                                        type="danger"
                                        onClick={() => {
                                            this.setRemoved(this.state.order);
                                        }}
                                    >
                                        Удалить
                                    </PlanrButton>
                                    <PlanrButton size="small" type="graybtn" onClick={this.toggleShowRemoveOrder}>
                                        Отмена
                                    </PlanrButton>
                                </div>
                            }
                        />
                    </div>
                </Dialog>
                {loading ? (
                    <div>
                        <LocalLoader active={loading} />
                    </div>
                ) : (
                    <div>
                        <div style={{ marginLeft: "-16px", width: "100%" }}>
                            <Search
                                query={this.props.store.query}
                                onSearch={this.props.store.setQuery}
                                style={{ background: "#fff" }}
                            />
                        </div>
                        {mainData.length > 0 ? (
                            <div className={styles.tableContainer}>
                                <GridTheme bordered={true} className={`designed ${styles.table}`}>
                                    <AgGridReact
                                        rowStyle={{
                                            fontFamily: "GothamPro, Arial, sans-serif",
                                            color: "#00273D",
                                        }}
                                        rowHeight={32}
                                        rowSelection="single"
                                        suppressCellSelection={true}
                                        enableCellTextSelection={true}
                                        columnDefs={this.config}
                                        rowData={mainData}
                                        localeText={agGridLocale}
                                        onRowDoubleClicked={this.onRowSelected}
                                        // getRowNodeId={getRowNodeId}
                                        // getContextMenuItems={getContextMenuItems}
                                        //  onGridReady={this.onGridReady}
                                        // onCellContextMenu={this.onCellContextMenu}
                                        // onSortChanged={store.sorter.resorted}
                                        onColumnResized={sizeSaver}
                                        gridOptions={this.options}
                                        modules={modules}
                                        rowBuffer={MAX_INT}
                                        context={this}
                                    />
                                </GridTheme>
                            </div>
                        ) : (
                            <div className={styles.noData}>Нет заданий</div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    setShowRemove = (order: OrderContentTaskListItemType) => {
        this.setState({ order });
        this.toggleShowRemoveOrder();
    };

    setRemoved = async (order: OrderContentTaskListItemType | null) => {
        if (order !== null) {
            await this.props.store.delete(order.orderId, order.guid);
            this.setState({ order: null });
            this.toggleShowRemoveOrder();
        }
    };

    setCancel = async (order: OrderContentTaskListItemType) => {
        await this.props.store.cancel(order.orderId, order.guid);
    };

    onRowSelected = ({ data }: RowClickedEvent) => {
        let path = routes.orders.details(data.orderId, OrderTabs.project);
        //  path += `&${PARAM_SECTION}=${section}`;
        if (data.type === "task") {
            path += `&${PARAM_RECORD}=${data.task.id}`;
            window.open(path, "_blank");
        } else if (data.type === "confirm") {
            path = routes.orders.details(data.orderId, OrderTabs.summary);
            window.open(path, "_blank");
        } else if (data.type === "agent") {
            path = routes.agents.outsourcers.details.outsourcers(data.orderId);
            window.open(path, "_blank");
        }
    };

    toggleShowRemoveOrder = () => {
        this.setState({ showRemoveOrder: !this.state.showRemoveOrder });
    };
}

export const TaskList = observer(TaskItems);

interface TaskListProps {
    store: OrderContentTaskStoreType;
    notification: NotificationStoreType;
    baseUrl: string;
}
interface TaskListState {
    showRemoveOrder: boolean;
    order: OrderContentTaskListItemType | null;
}
