import React from "react";
import { LoggedInProps } from "modules/common/components/routing/AnonymousRoute";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import OrderDetailsTabsPage from "./details/OrderDetailsTabsPage";
import { MainTabsPage } from "modules/main/components/MainTabsPage";
import { ApplicationStoreType } from "modules/root/models/store";

export const Routes = ({ store, loggedIn, baseUrl }: RoutesProps) => {
    return (
        <>
            <ProtectedRoute
                path={routes.orders.manage}
                loggedIn={loggedIn}
                render={(props) => (
                    <MainTabsPage
                        {...props}
                        access={store.session.access}
                        table={store.detailedOrders}
                        session={store.session}
                        dashboard={store.dashboard}
                        accounts={store.moneyAccounts}
                    />
                )}
            />
            <ProtectedRoute
                path={routes.orders.path}
                loggedIn={loggedIn}
                render={(props) => (
                    <OrderDetailsTabsPage
                        {...props}
                        baseUrl={baseUrl}
                        store={store.orders}
                        settings={store.settings}
                        session={store.session}
                        clientStore={store.workResult}
                    />
                )}
            />
        </>
    );
};

interface RoutesProps extends LoggedInProps {
    baseUrl: string;
    store: ApplicationStoreType;
}
