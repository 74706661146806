import React from "react";
import { Formik, Form } from "formik";
import styles from "./WorkTypeDetails.module.scss";
import WorkTypeSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { InputGroup, TextArea, Classes } from "@blueprintjs/core";
import { WorkTypeType, fields } from "modules/dictionaries/work-types/models/work-type";
import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { renderSingleOption, DefaultSelectedOption } from "modules/common/services/form/select";
import { CategorySelect, buildWorkCategoryOptions } from "modules/common/components/form/WorkCategorySelect";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { Constants } from "modules/root/models/constants";

class WorkTypeDetailsBase extends React.PureComponent<WorkTypeDetailsProps> {
    private descriptionArea: HTMLTextAreaElement | null = null;

    private descriptionAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.descriptionArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.descriptionArea);
    };

    public componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    render() {
        const { props } = this;
        const { workType, onSaved, onRemoved, canChange } = props;
        const categories = buildWorkCategoryOptions(Constants);

        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(workType)}
                    enableReinitialize={true}
                    validationSchema={WorkTypeSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await workType.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            if (!canChange) {
                                return;
                            }

                            formProps.setSubmitting(true);
                            const success = await workType.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.name}
                                        schema={WorkTypeSchema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <InputGroup
                                                id={field.name}
                                                {...field}
                                                className="planr-default-input"
                                                autoComplete="off"
                                                data-lpignore="true"
                                                disabled={!canChange}
                                            />
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.description}
                                        schema={WorkTypeSchema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.descriptionAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.category}
                                        schema={WorkTypeSchema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const option = categories[field.value];

                                            return (
                                                <div className={`${Classes.INPUT_GROUP}`}>
                                                    <CategorySelect
                                                        className={`planr-default-input ${Classes.FILL}`}
                                                        filterable={false}
                                                        activeItem={option}
                                                        inputProps={field}
                                                        items={Object.values(categories)}
                                                        itemRenderer={renderSingleOption}
                                                        onItemSelect={(item) => {
                                                            form.setFieldValue(field.name, item.id);
                                                            form.setFieldTouched(field.name, true);
                                                        }}
                                                        disabled={!canChange}
                                                    >
                                                        <DefaultSelectedOption option={option} disabled={!canChange} />
                                                    </CategorySelect>
                                                </div>
                                            );
                                        }}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`тип работ ${workType.name}`}
                                                isNewlyCreated={workType.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const WorkTypeDetails = observer(WorkTypeDetailsBase);

interface WorkTypeDetailsProps {
    workType: WorkTypeType;
    onSaved: () => void;
    onRemoved: () => void;
    canChange: boolean;
}

function getFormValues(workType: WorkTypeType) {
    return {
        ...getSnapshot(workType),
    };
}
