import { formatMoney } from "modules/common/components/money/Money";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { PlanrTag } from "modules/common/components/planr/tag/PlanrTag";
import { ActualOrderPaymentDetailsType } from "modules/orders-manage/models/order-stages-store";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styles from "./ActualPayment.module.scss";

export const ActualPayment: React.FC<ActualPaymentProps> = ({ payment, draggable, invalid, paidSum }) => {
    const draggablePrps = draggable
        ? {
              ref: draggable.innerRef,
              ...draggable.draggableProps,
              ...draggable.dragHandleProps,
          }
        : {};

    let title = "";
    if (invalid) {
        title = "Этот платеж используется слишком много раз";
    }

    return (
        <div className={`${styles.actualPayment}`} {...draggablePrps}>
            <div style={{ maxWidth: "100%", minWidth: "100%" }}>
                <div className={styles.name}>
                    <div className={styles.paymentDate}>{payment.date}</div>
                    <div className={styles.paymentSum} style={{ display: "flex", alignItems: "center" }}>
                        {invalid && (
                            <GeneralIcon
                                type="general-exclamation"
                                style={{ color: "#EA561E", marginRight: "5px" }}
                                title={title}
                            />
                        )}
                        <div>
                            <PlanrTag type={invalid ? "danger" : "neutral"}>{formatMoney(payment.sum)}</PlanrTag>
                            {paidSum && (
                                <PlanrTag type={invalid ? "danger" : "neutral"}>{formatMoney(paidSum)}</PlanrTag>
                            )}
                        </div>
                    </div>
                </div>

                <div title={payment.comment} className={`${styles.paymentComment} payment-comment`}>
                    {payment.comment}
                </div>
            </div>
        </div>
    );
};

interface ActualPaymentProps {
    draggable?: DraggableProvided;
    payment: ActualOrderPaymentDetailsType;
    invalid?: boolean;
    paidSum?: number;
}
