import { types } from "mobx-state-tree";
import { formatMoney } from "modules/common/components/money/Money";
import { formatDate } from "modules/common/services/formatting/date";
import { nameof } from "modules/common/services/typescript";
import { InvoiceFile } from "modules/orders-manage/models/order-file";
import { BasePayment } from "modules/orders-manage/models/payment";

export const OutsourcerPayment = types
    .compose(
        BasePayment,
        types.model({
            name: types.string,
            guid: types.string,
            isCash: types.boolean,
            employerId: types.string,
            sortableDate: types.string,
            money: types.string,
            planPaymentGuid: types.string,
            automatic: types.boolean,
            comment: types.string,
            status: types.string,
            invoice: types.maybeNull(InvoiceFile),
        })
    )
    .named("OutsourcerPayment");

export type OutsourcerPaymentType = typeof OutsourcerPayment.Type;
export type OutsourcerPaymentSnapshotType = typeof OutsourcerPayment.SnapshotType;

export const ActualPayment = types
    .compose(
        OutsourcerPayment,
        types.model({
            correctionPayments: types.array(OutsourcerPayment),
        })
    )
    .named("ActualPayment");

export type ActualPaymentType = typeof ActualPayment.Type;
export type ActualPaymentSnapshotType = typeof ActualPayment.SnapshotType;

export const outsourcerPaymentFields = {
    guid: nameof((a: OutsourcerPaymentType) => a.guid) as string,
    name: nameof((a: OutsourcerPaymentType) => a.name) as string,
    date: nameof((a: OutsourcerPaymentType) => a.date) as string,
    sum: nameof((a: OutsourcerPaymentType) => a.sum) as string,
    isCash: nameof((a: OutsourcerPaymentType) => a.isCash) as string,
    automatic: nameof((a: OutsourcerPaymentType) => a.automatic) as string,
    invoice: nameof((a: OutsourcerPaymentType) => a.invoice) as string,
    status: nameof((a: OutsourcerPaymentType) => a.status) as string,
};

export const emptyOutsourcerPayment = (guid: string, index: number): ActualPaymentSnapshotType => ({
    created: formatDate(new Date()),
    date: "",
    day: "",
    guid,
    name: index.toString().padStart(5, "0"),
    sum: 0,
    isCash: false,
    employerId: "",
    sortableDate: "",
    money: formatMoney(0),
    automatic: false,
    comment: "",
    status: "",
    invoice: null,
    planPaymentGuid: "",
    correctionPayments: [],
});
