import { InputGroup } from "@blueprintjs/core";
import classnames from "classnames";
import { SupplierType } from "modules/agents/suppliers/models/supplier";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { formatMoney } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { GeneralIcon } from "modules/common/components/planr/icon/Generalcon";
import { routes } from "modules/common/routes";
import { getDragItemStyle } from "modules/common/services/drag";
import { preventSubmitKeyDown } from "modules/common/services/form/values";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { texts } from "modules/common/texts";
import { AgentsCategoriesType } from "modules/dictionaries/agents-categories/models/agents-categories";
import { SaveAgent, agentFields } from "modules/orders-manage/models/order-agents";
import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styles from "./OrderAgentsRow.module.scss";

export class OrderAgentsRow extends React.Component<OrderAgentsRowProps> {
    render() {
        const {
            agent,
            readOnly,
            active,
            onChange,
            onRemove,
            isDragging,
            draggable,
            agents,
            agentsCategories,
            responses,
            setResponse,
            removeResponse,
            addResponse,
            onShowMaterials,
        } = this.props;

        const rowOnEdit = active && active.guid === agent.guid;

        const agentInfo = agents.find((i) => i.id === agent.agentId);
        const val = (+agent.price * +agent.newReward) / 100;
        const getNameWithPath = () => {
            let path = "";

            path = agentInfo ? routes.agents.outsourcers.details.outsourcers(agentInfo.id) : "";

            return path ? (
                <a href={path} target="_blank" rel="noopener noreferrer">
                    {agentInfo && agentInfo.name}
                </a>
            ) : (
                ""
            );
        };
        return (
            <div
                ref={draggable.innerRef}
                {...draggable.draggableProps}
                {...draggable.dragHandleProps}
                style={getDragItemStyle(isDragging, draggable.draggableProps.style)}
                className={classnames({
                    [styles.row]: true,
                    [styles.rowEdit]: rowOnEdit && active,
                    // [styles.highlighted]: !!ipd.documentFileId,
                    // [styles.inquiried]: !!ipd.requestFileId && !ipd.documentFileId,
                })}
            >
                {!rowOnEdit && !readOnly && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-edit"
                            onClick={this.activate}
                            size="small"
                            style={{ color: "#EA561E" }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton
                            type="neutral"
                            icon="general-redo"
                            onClick={this.onSave}
                            size="small"
                            style={{ color: "#1DD278" }}
                        />
                        {/* <SaveIcon className="action-icon" onClick={this.onSave} /> */}
                    </div>
                )}
                <div className={styles.category}>
                    {agentInfo && agentInfo.categories && (
                        <div>
                            {agentInfo.categories.map((c) => {
                                const category = agentsCategories.find((cat: any) => cat.id === c);
                                if (category) {
                                    return <div>{category.name};</div>;
                                }
                                return null;
                            })}
                        </div>
                    )}
                </div>
                <div className={styles.name}>{agentInfo && getNameWithPath()}</div>
                <div className={styles.materials}>
                    {rowOnEdit && active && (
                        <div className={styles.responses}>
                            {responses &&
                                responses
                                    .filter((r) => r.activeGuid === active.guid)
                                    .map((res) => {
                                        return (
                                            <div key={res.guid} className={styles.responsesRow}>
                                                <InputGroup
                                                    type="text"
                                                    autoComplete="off"
                                                    className="planr-default-input"
                                                    data-lpignore="true"
                                                    value={res.value}
                                                    required={true}
                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                        setResponse(e.currentTarget.value, res.guid);
                                                    }}
                                                />
                                                <GeneralIcon
                                                    type="general-trash"
                                                    onClick={() => {
                                                        removeResponse(res.guid);
                                                    }}
                                                    title={texts.remove}
                                                    style={{
                                                        width: "20px",
                                                        maxWidth: "20px",
                                                        minWidth: "20px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}

                            <div className={styles.addRows}>
                                {agentInfo && agentInfo.materials.length > 0 && (
                                    <PlanrButton
                                        size="small"
                                        type="secondary"
                                        onClick={() => {
                                            onShowMaterials(agentInfo);
                                        }}
                                        style={{ height: "25px", width: "133px", marginBottom: "5px" }}
                                    >
                                        Из карточки
                                    </PlanrButton>
                                )}
                                <PlanrButton
                                    size="small"
                                    type="secondary"
                                    onClick={() => {
                                        addResponse("", active.guid);
                                    }}
                                    style={{ height: "25px", width: "133px", marginLeft: "0px" }}
                                >
                                    Добавить
                                </PlanrButton>
                            </div>
                        </div>
                    )}
                    <div>
                        {!rowOnEdit &&
                            agent &&
                            agent.materials &&
                            agent.materials.map((res, index) => <div key={index}>{res};</div>)}
                    </div>
                </div>
                <div className={styles.reward}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="number"
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.newReward}
                            // className={Classes.FILL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange([
                                    {
                                        field: agentFields.newReward,
                                        value: e.currentTarget.value,
                                    },
                                ]);
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && agent && agent.newReward && (
                        <div>
                            <div>{agent.newReward}%</div>
                            <div>{formatMoney(val)}</div>
                        </div>
                    )}
                </div>
                <div className={styles.price}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="number"
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.price}
                            // className={Classes.FILL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange([
                                    {
                                        field: agentFields.price,
                                        value: e.currentTarget.value,
                                    },
                                ]);
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && agent && formatMoney(+agent.price)}
                </div>
                <div className={styles.fio}>{agentInfo && agentInfo.memberName}</div>
                <div className={styles.phone}>
                    <div>{agentInfo && formatPhoneNumber(agentInfo.phone)}</div>
                    <div>{agentInfo && formatPhoneNumber(agentInfo.phone2)}</div>
                    <div>{agentInfo && formatPhoneNumber(agentInfo.phone3)}</div>
                </div>
                <div className={styles.comment}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="text"
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.description}
                            // className={Classes.FILL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange([
                                    {
                                        field: agentFields.description,
                                        value: e.currentTarget.value,
                                    },
                                ]);
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && agent && agent.description}
                </div>
                <div className={styles.deal}>
                    {rowOnEdit && active && (
                        <InputGroup
                            type="number"
                            className="planr-default-input"
                            autoComplete="off"
                            data-lpignore="true"
                            value={active.deal}
                            // className={Classes.FILL}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onChange([
                                    {
                                        field: agentFields.deal,
                                        value: e.currentTarget.value,
                                    },
                                ]);
                            }}
                            onKeyDown={preventSubmitKeyDown}
                        />
                    )}
                    {!rowOnEdit && agent && formatMoney(+agent.deal)}
                </div>
                {!readOnly && !active && (
                    <div className={styles.iconCell}>
                        <RemoveConfirmation
                            onConfirmed={onRemove}
                            what={(w) => {
                                return "агента";
                            }}
                            render={({ confirmRemoving }) => {
                                return (
                                    <PlanrButton
                                        type="neutral"
                                        icon="general-trash"
                                        onClick={() => confirmRemoving(agent)}
                                        size="small"
                                        title={texts.remove}
                                    />
                                );
                            }}
                        />
                    </div>
                )}
                {rowOnEdit && active && (
                    <div className={styles.iconCell}>
                        <PlanrButton type="neutral" icon="general-undo" onClick={this.deactivate} size="small" />
                    </div>
                )}
            </div>
        );
    }

    activate = () => this.props.onActivate(this.props.agent);

    onSave = () => {
        const { active } = this.props;

        if (active) {
            this.props.onSave(active);
        }
    };

    deactivate = () => this.props.onActivate(null);
}

interface OrderAgentsRowProps {
    agent: SaveAgent;
    onChange: (changes: Array<{ field: string; value: any }>) => void;
    onSave: (ipd: SaveAgent) => void;
    onRemove: (ipd: SaveAgent) => void;
    readOnly: boolean;
    active: SaveAgent | null;
    onActivate: (ipd: SaveAgent | null) => void;
    isDragging: boolean;
    draggable: DraggableProvided;
    agents: SupplierType[];
    agentsCategories: AgentsCategoriesType[];
    responses: { guid: string; value: string; activeGuid: string }[];
    setResponse: (v: string, g: string) => void;
    removeResponse: (g: string) => void;
    addResponse: (v: string, activeGuid: string) => void;
    onShowMaterials: (agentInfo: SupplierType | null) => void;
}
