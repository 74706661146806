import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { DATE_FORMAT } from "modules/common/constants";
import { DictionaryItem } from "modules/common/models/entity";
import { nameof } from "modules/common/services/typescript";
import moment from "moment";

export const EventTypes = {
    ChangeStatusBusinessEvent: "Смена статуса проекта",
    OrderPaymentBusinessEvent: "Оплата планового платежа",
    OrderStageStartedBusinessEvent: "Начало работ по этапу",
    OrderStageCompletedBusinessEvent: "Окончание работ по этапу",
    ConsignmentBusinessEvent: "Передача документации Заказчику",
    ExpertiseBusinessEvent: "Получение заключения экспертизы",
    SentExpertiseBusinessEvent: "Передача в экспертизу",
    IpdFileBusinessEvent: "Загрузка ИРД от заказчика",
    AdditionBusinessEvent: "Подписание доп. соглашения",
    OrderSignedBusinessEvent: "Подписание договора",
    OrderWorkStartedBusinessEvent: "Начало работы над проектом",
};

export const Duration = types.model({
    daysCount: types.number,
    workDays: types.boolean,
});

export const BusinessEventModel = types
    .model({
        id: types.string,
        newId: types.string,
        type: types.string,
        status: types.optional(types.string, ""),
        paymentGuid: types.optional(types.string, ""),
        stageGuid: types.optional(types.string, ""),
        belonging: types.array(types.string),
        additionalText: types.optional(types.string, ""),
        additionNumber: types.optional(types.string, ""),
    })
    .actions((self) => ({
        setValue(field: string, value: any) {
            switch (field) {
                case fields.status: {
                    self.status = value;
                    break;
                }
                case fields.type: {
                    self.type = value;
                    break;
                }
                case fields.paymentGuid: {
                    self.paymentGuid = value;
                    break;
                }
                case fields.stageGuid: {
                    self.stageGuid = value;
                    break;
                }
                case fields.additionNumber: {
                    self.additionNumber = value;
                    break;
                }
            }
        },
    }))
    .views((self) => ({
        get value() {
            switch (self.type) {
                case EventTypes.ChangeStatusBusinessEvent:
                    return self.status;
                case EventTypes.OrderPaymentBusinessEvent:
                    return self.paymentGuid;
                case EventTypes.OrderStageStartedBusinessEvent:
                case EventTypes.OrderStageCompletedBusinessEvent:
                case EventTypes.ExpertiseBusinessEvent:
                    return self.stageGuid;
                case EventTypes.SentExpertiseBusinessEvent:
                    return self.stageGuid;
                case EventTypes.ConsignmentBusinessEvent:
                    return self.stageGuid;
                case EventTypes.IpdFileBusinessEvent:
                    return self.stageGuid;
                case EventTypes.AdditionBusinessEvent:
                    return self.additionNumber;
                default:
                    return "";
            }
        },

        get validate() {
            const result: TStringMap<string> = {};
            switch (self.type) {
                case EventTypes.ChangeStatusBusinessEvent: {
                    if (!self.status) {
                        result[fields.status] = "Необходимо указать статус";
                    }

                    break;
                }
                case EventTypes.OrderPaymentBusinessEvent: {
                    if (!self.paymentGuid) {
                        result[fields.paymentGuid] = "Необходимо выбрать платеж";
                    }

                    break;
                }
                case EventTypes.OrderStageStartedBusinessEvent:
                case EventTypes.OrderStageCompletedBusinessEvent: {
                    if (!self.stageGuid) {
                        result[fields.stageGuid] = "Необходимо выбрать этап";
                    }

                    break;
                }
                case EventTypes.AdditionBusinessEvent: {
                    if (!self.additionNumber) {
                        result[fields.additionNumber] = "Выберите доп. соглашение";
                    }

                    break;
                }
            }
            return result;
        },
    }));

export const EventDate = types
    .compose(
        Duration,
        types.model({
            model: BusinessEventModel,
        })
    )
    .views((self) => ({
        get eventId() {
            return self.model.id;
        },
    }))
    .actions((self) => ({
        setId(id: string) {
            self.model.id = id;
            self.model.newId = id;
        },

        setWorkDays(val: boolean) {
            self.workDays = val;
        },

        setDaysCount(val: number) {
            self.daysCount = val;
        },
    }))
    .named("EventDate");

export const BusinessEventDictionaryItem = types
    .compose(
        DictionaryItem,
        types.model({
            triggerDate: types.string,
            isPlanEvent: types.boolean,
            type: types.string,
        })
    )
    .views((self) => ({
        get isTodayPoint() {
            return self.type === "Сегодня";
        },
    }))
    .views((self) => ({
        get triggerDateAsDate() {
            return moment(self.triggerDate, DATE_FORMAT);
        },

        get triggerDateText() {
            return self.isTodayPoint ? "" : self.triggerDate;
        },
    }))
    .named("BusinessEventDictioanryItem");

export type BusinessEventModelType = Instance<typeof BusinessEventModel>;
export type EventDateType = Instance<typeof EventDate>;
export type EventDateSnapshot = SnapshotOut<typeof EventDate>;
export type BusinessEventDictionaryItemType = Instance<typeof BusinessEventDictionaryItem>;

export const fields = {
    type: nameof((a: BusinessEventModelType) => a.type) as string,
    status: nameof((a: BusinessEventModelType) => a.status) as string,
    paymentGuid: nameof((a: BusinessEventModelType) => a.paymentGuid) as string,
    stageGuid: nameof((a: BusinessEventModelType) => a.stageGuid) as string,
    additionNumber: nameof((a: BusinessEventModelType) => a.additionNumber) as string,
};

export const treat = (ev: any[]) => {
    ev.forEach((e) => {
        e.newId = e.id;
    });

    return ev;
};
