import React from "react";
import { ScrollPdfViewer } from "./ScrollPdfViewer";
import { FileMetadataType } from "modules/common/models/file-metadata";
import { isPdf, isImage } from "modules/common/services/files";
import { ImageViewer } from "./ImageViewer";
import styles from "./Previewer.module.scss";
import { observer } from "mobx-react";

export const Previewer = observer(({ document, toolbox }: PreviewerProps) => {
    return (
        <div className={`${styles.previewer} previewer`}>
            {/* {document && (
                <div className="previewer-btns">
                    {" "}
                    <PlanrButton
                        type="graybtn"
                        icon={"general-print"}
                        // onClick={() => ask(folder.label)}
                        round={true}
                        title="Печать"
                        style={{ marginLeft: "8px", backgroundColor: "#E4E8EB" }}
                    />
                </div>
            )} */}
            {document && getViewer(document, toolbox)}
        </div>
    );
});

const getViewer = (document: FileMetadataType, toolbox?: boolean) => {
    if (isPdf(document.mimeType) || isPdf(document.previewMimeType)) {
        return <ScrollPdfViewer document={document} toolbox={toolbox} />;
    }

    if (isImage(document.mimeType)) {
        return <ImageViewer document={document} toolbox={toolbox} />;
    }

    return (
        <div style={{ margin: "auto", textAlign: "center" }}>
            <div>Просмотр файла не поддерживается</div>
        </div>
    );
};

interface PreviewerProps {
    document: FileMetadataType | null;
    toolbox?: boolean;
}
