import React from 'react';
import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { nameof, isNotNull } from 'modules/common/services/typescript';
import { formatMoney } from 'modules/common/components/money/Money';
import { Dialog, Classes, Button } from '@blueprintjs/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ROW_HEIGHT, HEADER_HEIGHT, DefaultAgGridOptions, defaultModules } from 'modules/common/services/table/helpers';
import { agGridLocale } from 'modules/root/services/locale';
import styles from './OverheadSpendingsList.module.scss';
import { texts } from 'modules/common/texts';
import { PLAN, ACTUAL, OverheadSpendingRowSnapshotType } from 'modules/main/models/orders-chart';
import { GridTheme } from 'modules/common/components/table/GridTheme';

const columns = (actual: boolean) => {
    const result: ColDef[] = [
        {
            sortable: true,
            headerName: 'Дата',
            field: nameof((a: OverheadSpendingRowSnapshotType) => a.date) as string,
            width: 100,
            sort: 'asc',
            cellClass: 'centered',
            valueGetter: ({ data }) => {
                const row = data as OverheadSpendingRowSnapshotType;
                return actual ? row.date : row.month;
            },
            comparator: (valueA, valueB, nodeA, nodeB) => {
                const dateA = (nodeA.data as OverheadSpendingRowSnapshotType).sortableDate;
                const dateB = (nodeB.data as OverheadSpendingRowSnapshotType).sortableDate;
                return dateA.localeCompare(dateB);
            }
        },
        {
            sortable: true,
            headerName: 'Сумма',
            field: nameof((a: OverheadSpendingRowSnapshotType) => a.sum) as string,
            width: 100,
            cellClass: 'righted',
            valueFormatter: (params) => formatMoney(params.value, { noSymbol: true })
        },
        {
            sortable: true,
            headerName: 'Тип',
            valueGetter: ({ data }: ValueGetterParams) => (data as OverheadSpendingRowSnapshotType).overheadType.name,
            field: nameof((a: OverheadSpendingRowSnapshotType) => a.overheadType)  as string,
            width: 200
        }
    ];

    return result.filter(isNotNull);
};

export class OverheadSpendingsList extends React.PureComponent<OverheadSpendingsListProps> {
    private options1 = DefaultAgGridOptions();
    private options2 = DefaultAgGridOptions();

    render () {
        const { actualSpendings, palnSpendings, onClose, onPrint, title } = this.props;
        const bottomPlan = buildOverheadSpendingsBottomLine(palnSpendings);
        const bottomActual = buildOverheadSpendingsBottomLine(actualSpendings);

        return (
            <Dialog
                isCloseButtonShown={true}
                isOpen={true}
                className={`${styles.list}`}
                onClose={onClose}
                title={title}
                backdropClassName='standard'
                canOutsideClickClose={false}
                canEscapeKeyClose={false}
            >
                <div className={Classes.DIALOG_BODY}>
                    <GridTheme bordered={true}>
                        <h3>{PLAN}</h3>
                        <AgGridReact
                            rowSelection='single'
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={columns(false)}
                            rowData={palnSpendings}
                            rowHeight={ROW_HEIGHT}
                            headerHeight={HEADER_HEIGHT}
                            getRowNodeId={getRowNodeId}
                            localeText={agGridLocale}
                            pinnedBottomRowData={bottomPlan}
                            gridOptions={this.options1}
                            context={this.props}
                            immutableData={true}
                            modules={defaultModules}
                        />
                    </GridTheme>
                    <GridTheme bordered={true}>
                        <h3>{ACTUAL}</h3>
                        <AgGridReact
                            rowSelection='single'
                            suppressCellSelection={true}
                            enableCellTextSelection={true}
                            columnDefs={columns(true)}
                            rowData={actualSpendings}
                            rowHeight={ROW_HEIGHT}
                            headerHeight={HEADER_HEIGHT}
                            getRowNodeId={getRowNodeId}
                            localeText={agGridLocale}
                            pinnedBottomRowData={bottomActual}
                            gridOptions={this.options2}
                            context={this.props}
                            immutableData={true}
                            modules={defaultModules}
                        />
                    </GridTheme>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button icon="print" onClick={onPrint} text={texts.print} />
                    </div>
                </div>
            </Dialog>
        );
    }
}

export const getRowNodeId = (node: OverheadSpendingRowSnapshotType) => node.id;

export function buildOverheadSpendingsBottomLine (payments: OverheadSpendingRowSnapshotType[]) {
    const sum = payments.reduce((acc, p) => acc + p.sum, 0);
    const bottom: OverheadSpendingRowSnapshotType[] = [{
        actual: false,
        created: '',
        date: '',
        sortableDate: '',
        day: '',
        id: '',
        month: '',
        overheadType: {
            id: '',
            name: texts.total,
        },
        sum,
        money: formatMoney(sum, { noSymbol: true }),
        year: 0
    }];
    return bottom;
}

interface OverheadSpendingsListProps {
    palnSpendings: OverheadSpendingRowSnapshotType[];
    actualSpendings: OverheadSpendingRowSnapshotType[];
    title: string;
    onClose: () => void;
    onPrint: () => void;
}
