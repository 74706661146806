import React from "react";
import { DetailsPopup } from "modules/common/components/form/DetailsPopup";
import { Block } from "modules/common/components/page/Block";
import { Route, withRouter, RouteComponentProps, matchPath } from "react-router";
import { routes } from "modules/common/routes";
import { UnregisterCallback } from "history";
import { observer } from "mobx-react";
import { texts } from "modules/common/texts";
import { EMPTY_OBJECT_ID } from "modules/common/constants";
import { AgentsCategoriesStoreType } from "../models/agents-categories-store";
import { AgentsCategoriesDetails } from "./details/AgentsCategoriesDetails";
import { AgentsCategoriesList } from "./list/AgentsCategoriesList";
import { menuItems } from "modules/root/components/menu/MainMenu";
import func from "modules/dictionaries/functionalities";
import { can } from "modules/session/auth/access";

import styles from "./AgentsCategoriesPage.module.scss";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { Search } from "modules/common/components/form/Search";

const menu = menuItems();

class AgentsCategoriesPage extends React.Component<
    AgentsCategoriesPageProps & RouteComponentProps,
    AgentsCategoriesPageState
> {
    private unregister: UnregisterCallback | null = null;

    constructor(props: AgentsCategoriesPageProps & RouteComponentProps) {
        super(props);

        this.state = { typeId: getSelectedAgentsCategoriesId(props.location.pathname) };
    }

    componentDidMount() {
        document.title = menu.dictionaries.nested.agentsCategories.label;

        const { history } = this.props;
        const { typeId } = this.state;

        this.loadList();
        this.loadDetails(typeId);

        this.unregister = history.listen((location) => {
            const typeId = getSelectedAgentsCategoriesId(location.pathname);

            if (this.state.typeId !== typeId) {
                this.setState({ typeId });
                this.loadDetails(typeId);
            }
        });
    }

    componentWillUnmount() {
        this.unregister && this.unregister();
    }

    render() {
        const { store, access } = this.props;
        const canChange = can(func.AGENTSCATEGORIES_EDIT, access);

        return (
            <Block columned={true} className={styles.page}>
                <Route path={routes.dictionaries.agentsCategories.details()}>
                    <DetailsPopup title="Категория" onCancel={this.onAgentsCategoriesSelected}>
                        <AgentsCategoriesDetails
                            canChange={canChange}
                            agentsCategories={store.details}
                            onSaved={this.onAgentsCategoriesSaved}
                            onRemoved={this.onAgentsCategoriesRemoved}
                        />
                    </DetailsPopup>
                </Route>
                {
                    canChange && (
                        <PlanrButton
                            type="secondary"
                            size="small"
                            icon="general-plus-big"
                            onClick={this.onAgentsCategoriesAdd}
                            style={{
                                width: "200px",
                                marginBottom: "32px",
                                borderRadius: "120px",
                            }}
                        >{`${texts.add} категорию`}</PlanrButton>
                    )

                    // <DictioantyAddButton onClick={this.onAgentsCategoriesAdd} label={`${texts.add} ИРД`} />
                }
                <div className={styles.right}>
                    <Search query={store.list.query} onSearch={store.list.setQuery} />
                </div>
                <AgentsCategoriesList
                    store={store.list}
                    onSelected={this.onAgentsCategoriesSelected}
                    onRemoved={this.onAgentsCategoriesRemoved}
                />
            </Block>
        );
    }

    onAgentsCategoriesAdd = () => {
        this.onAgentsCategoriesSelected(EMPTY_OBJECT_ID);
    };

    onAgentsCategoriesSelected = (id: string = "") => {
        const { history } = this.props;

        const url = id ? routes.dictionaries.agentsCategories.details(id) : routes.dictionaries.agentsCategories.path;

        history.push(url);
    };

    onAgentsCategoriesSaved = () => {
        this.onAgentsCategoriesSelected("");
        this.loadList();
    };

    onAgentsCategoriesRemoved = () => {
        const { history } = this.props;

        this.loadList();

        const url = routes.dictionaries.agentsCategories.path;
        history.push(url);
    };

    loadList = () => this.props.store.list.load();

    loadDetails = (id: string) => this.props.store.details.load(id);
}

export default withRouter(observer(AgentsCategoriesPage));

function getSelectedAgentsCategoriesId(pathname: string) {
    const match = matchPath<AgentsCategoriesPageState>(pathname, routes.dictionaries.agentsCategories.details());

    return match && match.params ? match.params.typeId : "";
}

interface AgentsCategoriesPageProps {
    store: AgentsCategoriesStoreType;
    access: string[];
}

interface AgentsCategoriesPageState {
    typeId: string;
}
