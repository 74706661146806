import React from "react";
import { ProtectedRoute } from "modules/common/components/routing/ProtectedRoute";
import { routes } from "modules/common/routes";
import { AnonymousRoute } from "modules/common/components/routing/AnonymousRoute";
import { TokensTabsPage } from "./tokens/components/TokensTabsPage";
import { TokensStoreType } from "./tokens/models/tokens-store";
import { SessionType } from "./auth/models/session";
import { AuthPage } from "./auth/components/AuthPage";
import AccessPage from "./access/components/AccessPage";
import SettingsPage from "./settings/components/SettingsPage";
import { observer } from "mobx-react";
import { AccessStoreType } from "./access/models/access-store";
import { SettingsStoreType } from "./settings/models/settings";

export const Routes = observer(({ tokens, session, access, settings }: RoutesProps) => {
    const loggedIn = session.loggedIn;

    return (
        <>
            <AnonymousRoute path={routes.login} loggedIn={loggedIn} render={() => <AuthPage store={session} />} />
            <ProtectedRoute
                path={routes.tokens.path}
                loggedIn={loggedIn}
                render={(props) => <TokensTabsPage {...props} store={tokens} />}
            />
            <ProtectedRoute
                path={routes.access}
                loggedIn={loggedIn}
                render={(props) => <AccessPage {...props} store={access} session={session} />}
            />
            <ProtectedRoute
                path={routes.settings}
                loggedIn={loggedIn}
                render={(props) => <SettingsPage {...props} store={settings} collapser={settings.collapser} />}
            />
        </>
    );
});

interface RoutesProps {
    tokens: TokensStoreType;
    session: SessionType;
    access: AccessStoreType;
    settings: SettingsStoreType;
}
