import React from "react";
import styles from "./TotalLine.module.scss";
import { Money } from "modules/common/components/money/Money";
import { ScheduleUnitSnapshotType } from "../models/schedule-unit";

export const TotalLine: React.FC<TotalLineProps> = ({ children, store, plan, bonusTaxeHidden }) => (
    <div className={`total-line ${styles.line} ${plan ? styles.plan : ""}`}>
        <div className={`${styles.cell} ${styles.title}`}>{children}</div>

        <div className={styles.cell}>
            <Money amount={store.finalSalary} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.totalSalary} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.bonus} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.increase} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.overtime} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.vacationSalary} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.vacationBonus} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.decrease} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.ndflTaxe} />
        </div>
        <div className={styles.cell}>
            <Money amount={store.socialTaxe} />
        </div>
        {/* {!bonusTaxeHidden && (
            <div className={`bonus-taxe ${styles.cell}`}>
                <Money amount={store.bonusTaxe} />
            </div>
        )} */}
        <div className={`profit ${styles.cell}`}>
            <Money amount={store.profit} />
        </div>
        <div className={`company-spending ${styles.cell}`}>
            <Money amount={store.companySpending} />
        </div>
        <div className={`${styles.cell} company-spending-per-hour`}>
            <Money amount={store.companySpendingPerHour} />
        </div>
        <div className={`green-cell`}></div>
    </div>
);

interface TotalLineProps {
    bonusTaxeHidden?: boolean;
    store: ScheduleUnitSnapshotType;
    plan?: boolean;
}
