import { Button, Classes, FormGroup } from "@blueprintjs/core";
import { DefaultSelectedOption, renderSingleOption, SimpleSelect } from "modules/common/services/form/select";
import React, { useCallback } from "react";
import { CommonBusinessEventProps, Error, star } from "./common";
import { EventTypes, fields } from "modules/orders-manage/models/order-events";
import { PlanOrderPaymentType } from "modules/orders-manage/models/payment";
import { formatMoney } from "modules/common/components/money/Money";
import { observer } from "mobx-react";

const Base = ({ event, readonly, payments }: CommonBusinessEventProps) => {
    const { value, validate } = event.model;
    const payment = payments.find((o) => o.newGuid === value);

    const item: SelectItem | null = payment ? { id: payment.newGuid, label: label(payment) } : null;
    const options = payments.map((o) => ({ id: o.newGuid, label: label(o) }));

    const onChangeHandler = useCallback(
        (i: SelectItem) => {
            event.model.setValue(fields.paymentGuid, i.id);
        },
        [event]
    );

    return (
        <FormGroup
            labelInfo={star}
            helperText={validate[fields.paymentGuid]}
            intent={validate[fields.paymentGuid] ? Error : "none"}
            className={Classes.SMALL}
        >
            <div className={`${Classes.INPUT_GROUP}`}>
                <SimpleSelect
                    className={`full-width-select ${Classes.FILL}`}
                    filterable={false}
                    activeItem={item}
                    items={options}
                    itemRenderer={renderSingleOption}
                    onItemSelect={onChangeHandler}
                    disabled={readonly}
                    popoverProps={{
                        usePortal: true,
                        boundary: "viewport",
                        popoverClassName: "business-event__typeselect-dropdown",
                    }}
                >
                    {readonly && (
                        <Button fill={true} className="selected-option">
                            {payment && label(payment)}
                        </Button>
                    )}
                    {!readonly && <DefaultSelectedOption option={item} empty={!options.length} />}
                </SimpleSelect>
            </div>
        </FormGroup>
    );
};

Base.TYPE = EventTypes.OrderPaymentBusinessEvent;

export const OrderPaymentBusinessEvent = observer(Base);

const label = (payment: PlanOrderPaymentType) => {
    let result = "";
    if (payment.type === "Авансовый") {
        result = "Аванс-платеж ";
    } else {
        result = "Платеж ";
    }

    result += "№" + payment.name + " на сумму " + formatMoney(payment.sum);

    return result;
};
