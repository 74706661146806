import Schema from "../details/validationPosition";
import { getColumnSize } from "modules/common/services/table/size-storage";
import { getFieldLabel } from "modules/common/services/form/fields";
import { EmployeePositionType, fields } from "../../models/employee-position";
import { EmployeeRenderer } from "modules/expenses/summary/components/columns";
import { fields as EmployeeFields } from "modules/spending/employee/models/employer";
import {
    ROW_HEIGHT,
    DefaultAgGridOptions,
    RowNumberColumn,
    defaultModules,
} from "modules/common/services/table/helpers";
import { ColDef, ICellRendererParams } from "@ag-grid-community/core";
import React from "react";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react";
import { prevent } from "modules/common/services/form/select";
import { Popover } from "@blueprintjs/core";
import styles from "./EmployeePositionsList.module.scss";
import { MAX_INT } from "modules/common/constants";
import { apiUrls } from "modules/common/services/communication/urls";

const schema = Schema();

export const columns = (tableName: string, baseUrl: string) => {
    const columnSize = getColumnSize(tableName);

    const result: ColDef[] = [
        RowNumberColumn(false, ""),
        {
            headerName: "№",
            field: fields.order,
            width: columnSize(fields.order, 60),
            sortable: true,
            resizable: false,
            comparator: (valueA, valueB) => {
                const numA = valueA === null ? MAX_INT : valueA;
                const numB = valueB === null ? MAX_INT : valueB;

                return numA - numB;
            },
        },
        {
            headerName: getFieldLabel(fields.name, schema, null),
            field: fields.name,
            width: columnSize(fields.name, 250),
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Долж. инструкция",
            field: fields.manualFile,
            width: columnSize(fields.name, 200),
            sortable: true,
            resizable: true,
            cellRendererFramework: ({ value }: ICellRendererParams) => {
                if (value) {
                    return (
                        <a
                            href={baseUrl + apiUrls.application.files.content(value.fileId, value.fileName)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {value.fileName}
                        </a>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            cellClass: "centered",
            headerName: "Сотрудники",
            field: fields.employeeCount,
            width: columnSize(fields.employeeCount, 120),
            sortable: true,
            resizable: true,
            cellRendererFramework: RenderEmployee,
        },
        {
            headerName: "Непр",

            field: fields.nonProduction,
            width: columnSize(fields.nonProduction, 80),
            valueFormatter: ({ value }) => {
                return value ? "Да" : "";
            },
            sortable: true,
            resizable: true,
        },
        {
            headerName: "Примечания",
            field: fields.comment,
            minWidth: 150,
            flex: 1,
            sortable: false,
            resizable: false,
        },
    ];

    return result;
};

class RenderEmployee extends React.PureComponent<ICellRendererParams, { value: any; active: boolean }> {
    render() {
        const positions: EmployeePositionType = this.props.data;

        const data = positions;
        const row =
            data.employee.length > 0 ? (
                <Popover position="auto" enforceFocus={false} isOpen={undefined}>
                    <span className="bp3-tooltip-indicator">{data.employeeCount}</span>

                    <CellDetails store={data} />
                </Popover>
            ) : (
                <span>{data.employeeCount}</span>
            );

        return <div className="actual">{row}</div>;
    }
}

export const detailsColumns = (): ColDef[] => {
    return [
        {
            suppressMovable: true,
            headerName: "Сотрудник",
            field: EmployeeFields.name,
            width: 300,
            sortable: true,
            headerClass: "centered",
            cellRendererFramework: EmployeeRenderer,
        },
    ];
};
const options = DefaultAgGridOptions();
const columnsEmployee = detailsColumns();
const CellDetails = ({ store }: CellDetailsProps) => {
    return (
        <GridTheme
            className={`no-last-column-break vacation-details ${styles.cellDetails}`}
            onClick={prevent}
            onDoubleClick={prevent}
        >
            <AgGridReact
                rowStyle={{
                    borderBottom: "1 px solid #EDF0F2",
                    fontFamily: "GothamPro, Arial, sans-serif",
                    color: "#00273D",
                    fontSize: "12px",
                }}
                enableCellTextSelection={true}
                columnDefs={columnsEmployee}
                rowData={store.employee}
                headerHeight={ROW_HEIGHT}
                domLayout="autoHeight"
                gridOptions={options}
                modules={defaultModules}
                immutableData={true}
            />
        </GridTheme>
    );
};

interface CellDetailsProps {
    store: EmployeePositionType;
}
