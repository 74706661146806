import { types, applySnapshot } from "mobx-state-tree";
import { nameof } from "modules/common/services/typescript";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { texts } from "modules/common/texts";
import { SectionCollapser } from "modules/common/models/section-collapser";
import { formatPhoneNumber } from "modules/common/services/formatting/phone";
import { PrintAnchorInfoList, initialState as emptyAnchors } from "modules/common/models/print-anchor";
import { MimeTypes } from "modules/common/services/files";

export const ACCEPT = MimeTypes.odt;

const ApplicationSettings = types
    .model({
        productionBudget: types.number,
        productionBudgetDelta: types.number,
        productionQuality: types.number,
        engineerBudget: types.number,
        engineerBudgetDelta: types.number,
        engineerQuality: types.number,
        engineerQualityDelta: types.number,
        engineerTime: types.number,
        engineerTimeDelta: types.number,
        address: types.string,
        directorName: types.string,
        companyName: types.string,
        shortCompanyName: types.string,
        okved: types.string,
        phoneNumber: types.string,
        email: types.string,
        directorNameGenitive: types.string,
        administratorPositions: types.string,
        showMinutes: types.boolean,
        prevDaysCount: types.number,
        futureDaysCount: types.number,
    })
    .views((self) => ({
        get formattedPhone() {
            return formatPhoneNumber(self.phoneNumber);
        },
    }))
    .named("ApplicationSettings");

export const SettingsStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            model: ApplicationSettings,
            collapser: SectionCollapser,
            techTaskAnchors: PrintAnchorInfoList,
            contentTaskAnchors: PrintAnchorInfoList,
            wopiToken: types.string,
            editorUrl: types.string,
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                yield self.contentTaskAnchors.load();
                yield self.techTaskAnchors.load();

                const snapshot = yield self.transport.get<any>(apiUrls.settings.get);

                applySnapshot(self.model, snapshot);
                self.wopiToken = snapshot.wopiToken;
                self.editorUrl = snapshot.editorUrl;

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        loadShortSettings: flow(function* () {
            try {
                const snapshot = yield self.transport.get<any>(apiUrls.settings.shortSettings);

                applySnapshot(self.model, snapshot);
                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        save: flow(function* (model: ApplicationSettingsSnapshotType) {
            try {
                const snapshot = yield self.transport.post<any>(apiUrls.settings.update, model);

                applySnapshot(self.model, snapshot);
                self.wopiToken = snapshot.wopiToken;
                self.editorUrl = snapshot.editorUrl;

                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),

        replaceTemplate: flow(function* (id: string, fileId: string) {
            try {
                yield self.transport.put<any>(apiUrls.templates.technicalTask.contents(id), {
                    fileId,
                });

                self.notify.success(texts.messages.saved);

                return true;
            } catch (er) {
                self.notify.error(er);

                return false;
            }
        }),

        upload: flow(function* (file: File) {
            try {
                const model = new FormData();

                model.append("file", file);
                model.append("accept", ACCEPT);

                const result: UploadFileResult = yield self.transport.post<any>(
                    apiUrls.application.files.upload,
                    model
                );
                const { id, previewMimeType, mimeType } = result;

                const fileBase: FileBase = { fileId: id, fileName: file.name, previewMimeType, mimeType };
                return fileBase;
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        }),
    }));

export const initialState = (): SettingsStoreSnapshotType => ({
    model: {
        engineerBudget: 0,
        engineerBudgetDelta: 0,
        engineerQuality: 0,
        engineerQualityDelta: 0,
        engineerTime: 0,
        engineerTimeDelta: 0,
        productionBudget: 0,
        productionBudgetDelta: 0,
        productionQuality: 0,
        address: "",
        directorName: "",
        companyName: "",
        shortCompanyName: "",
        okved: "",
        phoneNumber: "",
        email: "",
        directorNameGenitive: "",
        administratorPositions: "",
        showMinutes: false,
        prevDaysCount: 3,
        futureDaysCount: 0,
    },
    collapser: {
        opened: {
            kpi: false,
            general: false,
        },
    },
    contentTaskAnchors: emptyAnchors(apiUrls.orders.contentTasks.anchors),
    techTaskAnchors: emptyAnchors(apiUrls.orders.summary.tasks.anchors),
    editorUrl: "",
    wopiToken: "",
});

export const fields = {
    productionBudget: nameof((a: ApplicationSettingsSnapshotType) => a.productionBudget) as string,
    productionBudgetDelta: nameof((a: ApplicationSettingsSnapshotType) => a.productionBudgetDelta) as string,
    productionQuality: nameof((a: ApplicationSettingsSnapshotType) => a.productionQuality) as string,
    engineerBudget: nameof((a: ApplicationSettingsSnapshotType) => a.engineerBudget) as string,
    engineerBudgetDelta: nameof((a: ApplicationSettingsSnapshotType) => a.engineerBudgetDelta) as string,
    engineerQuality: nameof((a: ApplicationSettingsSnapshotType) => a.engineerQuality) as string,
    engineerQualityDelta: nameof((a: ApplicationSettingsSnapshotType) => a.engineerQualityDelta) as string,
    engineerTime: nameof((a: ApplicationSettingsSnapshotType) => a.engineerTime) as string,
    engineerTimeDelta: nameof((a: ApplicationSettingsSnapshotType) => a.engineerTimeDelta) as string,
    address: nameof((a: ApplicationSettingsSnapshotType) => a.address) as string,
    directorName: nameof((a: ApplicationSettingsSnapshotType) => a.directorName) as string,
    directorNameGenitive: nameof((a: ApplicationSettingsSnapshotType) => a.directorNameGenitive) as string,
    companyName: nameof((a: ApplicationSettingsSnapshotType) => a.companyName) as string,
    shortCompanyName: nameof((a: ApplicationSettingsSnapshotType) => a.shortCompanyName) as string,
    okved: nameof((a: ApplicationSettingsSnapshotType) => a.okved) as string,
    phoneNumber: nameof((a: ApplicationSettingsSnapshotType) => a.phoneNumber) as string,
    email: nameof((a: ApplicationSettingsSnapshotType) => a.email) as string,
    administratorPositions: nameof((a: ApplicationSettingsSnapshotType) => a.administratorPositions) as string,
    showMinutes: nameof((a: ApplicationSettingsSnapshotType) => a.showMinutes) as string,
    prevDaysCount: nameof((a: ApplicationSettingsSnapshotType) => a.prevDaysCount) as string,
    futureDaysCount: nameof((a: ApplicationSettingsSnapshotType) => a.futureDaysCount) as string,
};

export type SettingsStoreType = typeof SettingsStore.Type;
export type SettingsStoreSnapshotType = typeof SettingsStore.SnapshotType;
export type ApplicationSettingsSnapshotType = typeof ApplicationSettings.SnapshotType;
export type ApplicationSettingsType = typeof ApplicationSettings.Type;
