import { Collapse } from "@blueprintjs/core";
import { Field, FieldProps } from "formik";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import { Caret } from "modules/common/components/collapse/Caret";
import { Money } from "modules/common/components/money/Money";
import { move } from "modules/common/services/array";
import {
    fields,
    getContentUnits,
    OrderObjectSnapshotType,
    WorkTypeLinkSnapshotType,
} from "modules/orders-manage/models/order";
import {
    OrderTimesheetSpendingType,
    OwnOrderSpendingSnapshotType,
    TripOrderSpendingSnapshotType,
    TripOrderSpendingType,
} from "modules/orders-manage/models/order-spending";
import { SectionCollapserType } from "modules/orders-manage/models/orders-store";
import { EmployerDictionaryItemType } from "modules/spending/employee/models/employee-dictionary";
import React from "react";
import { DropResult } from "react-beautiful-dnd";
import { OwnSpendingObject } from "./OwnTripSpendingObject";
import styles from "./OwnTripSpendings.module.scss";

import { OutsourcerPaymentSnapshotType } from "modules/agents/outsourcers/models/outsourcer-payment";
import { formatHours } from "modules/spending/timesheet/models/user-timesheet";
import { OrderTimesheetSpendingRowSnapshotType } from "../../../models/order-spending";
import { OutsourcerPaymentFactory } from "../outsourced/OutsourcedSpendingRow";
import OwnTripSpendingEmployer from "./OwnTripSpendingEmployer";
import { TRIP_BLOCK_NAME } from "./print-view-model";

type TObject = OrderObjectSnapshotType;
type TUnit = WorkTypeLinkSnapshotType;
type TSpending = TripOrderSpendingType;

const OBJECTS_DRAG = "trip-objects";

export const OwnTripSpendings = observer(
    class extends React.PureComponent<OwnSpendingsProps> {
        private fieldProps: FieldProps | null = null;

        render() {
            const {
                name,
                employee,
                collapsed,
                onToggleCollapse,
                spendings,
                readOnly,
                actualCollapser,
                disabled,
                baseUrl,
                toggleStatus,
                tripSpendingFactory,
                upload,
                paymentFactory,
            } = this.props;
            const { factHours, factMoney } = spendings.spendingsTripTotal;

            return (
                <Field name={name}>
                    {(fieldProps: FieldProps) => {
                        this.fieldProps = fieldProps;

                        const value: TSpending[] = fieldProps.field.value;
                        let factSum = 0;
                        let planSum = 0;
                        value.forEach((trip) => {
                            trip.actualPayments.forEach((actP) => {
                                factSum += actP.sum;
                            });
                            trip.planPayments.forEach((planP) => {
                                if (planP.status !== "Оплачен") {
                                    planSum += planP.sum;
                                } else {
                                    planSum += 0;
                                }
                            });
                        });
                        return (
                            <div className={`own-spendings ${styles.spendings}`}>
                                <h1 className="planr-block-header collapser" onClick={onToggleCollapse}>
                                    {TRIP_BLOCK_NAME}
                                    <Caret collapsed={collapsed} />
                                </h1>

                                <div className={`actual-total`} onClick={onToggleCollapse} style={{ left: "200px" }}>
                                    Всего:&nbsp;&nbsp;
                                    <Money className="spendings-money" amount={factMoney} />
                                    {factHours
                                        ? ` (${formatHours(spendings.showMinutes ? factHours : Math.round(factHours))})`
                                        : ` (0ч)`}
                                </div>

                                <div onClick={onToggleCollapse} className={styles.tripSpendingsHeader}>
                                    Командировочные
                                </div>
                                <div
                                    className={`actual-total ${planSum > 0 ? "red-total" : "green-total"}`}
                                    onClick={onToggleCollapse}
                                >
                                    Всего по оплатам:&nbsp;
                                    <Money className="spendings-money" amount={factSum} />
                                </div>

                                <div
                                    className={`spendings-total ${planSum > 0 ? "red-total" : "green-total"}`}
                                    onClick={onToggleCollapse}
                                    style={{ left: "920px" }}
                                >
                                    Остаток по оплатам:&nbsp;
                                    <Money className="spendings-money" amount={planSum} />
                                </div>
                                <Collapse isOpen={!collapsed} keepChildrenMounted={true}>
                                    <div className="collapse">
                                        {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                                <Droppable
                                                    droppableId={OBJECTS_DRAG}
                                                    type={OBJECTS_DRAG}
                                                    isDropDisabled={readOnly}
                                                >
                                                    {(provided) => ( */}
                                        <DraggableInternal
                                            disabled={disabled}
                                            employee={employee}
                                            factory={this.props.factory}
                                            tripSpendingFactory={tripSpendingFactory}
                                            paymentFactory={paymentFactory}
                                            fieldProps={fieldProps}
                                            readOnly={readOnly}
                                            value={value}
                                            spendings={spendings}
                                            collapser={actualCollapser}
                                            baseUrl={baseUrl}
                                            toggleStatus={toggleStatus}
                                            name={name}
                                            upload={upload}
                                        />
                                        {/* )}
                                                </Droppable>
                                            </DragDropContext> */}
                                    </div>
                                    {/* <div className={"collapse-right-header"}>
                                            <span className={styles.spendingsSpan}>Фактические затраты</span>
                                            <TimesheetTripSpendings spendings={spendings} collapser={actualCollapser} />
                                        </div> */}
                                </Collapse>
                            </div>
                        );
                    }}
                </Field>
            );
        }

        getContentUnits = () => {
            if (!this.fieldProps) {
                return [];
            }

            return getContentUnits(this.fieldProps.form.values, false);
        };

        onDragEnd = (result: DropResult) => {
            // dropped outside the list
            if (!result.destination || !this.fieldProps) {
                return;
            }

            const { form, field } = this.fieldProps;

            // initial index
            const from = result.source.index;
            // new index
            const to = result.destination.index;

            // detect what exactly dragged was

            if (result.type === OBJECTS_DRAG) {
                // top level - object content

                const orderObjects = form.values[fields.objects] as TObject[];
                const content = this.getContentUnits();
                const indexes: TStringMap<number> = {};
                move(content, from, to).forEach((obj, index) => {
                    indexes[obj.guid] = index + 1;
                });

                const newValue = orderObjects.map((obj) => {
                    let changed = false;
                    const replacement = obj.content.map((cnt) => {
                        if (indexes[cnt.guid]) {
                            changed = true;
                            return { ...cnt, sortOrder: indexes[cnt.guid] };
                        }

                        return cnt;
                    });
                    if (changed) {
                        return { ...obj, content: replacement };
                    }
                    return obj;
                });

                form.setFieldValue(fields.objects, newValue);
                form.setFieldTouched(fields.objects, true);
            } else {
                // second level - spending inside object

                const value = field.value as TSpending[];
                const contentGuid = result.type;
                const spendings = sortBy(
                    value.filter((v) => v.contentGuid === contentGuid),
                    (s: TSpending) => s.sortOrder
                );

                const indexes: TStringMap<number> = {};
                move(spendings, from, to).forEach((s, index) => {
                    indexes[s.id] = index + 1;
                });

                const newValue = value.map((sp) => (indexes[sp.id] ? { ...sp, sortOrder: indexes[sp.id] } : sp));

                form.setFieldValue(field.name, newValue);
                form.setFieldTouched(field.name, true);
            }
        };
    }
);

export interface OwnSpendingFactory {
    emptyOwnSpending: (unit: TUnit, sortOrder: number) => Promise<OwnOrderSpendingSnapshotType>;
}
export interface TripSpendingFactory {
    emptyTripSpending: (unit: string) => Promise<TripOrderSpendingSnapshotType>;
}
interface OwnSpendingsProps {
    factory: OwnSpendingFactory;
    name: string;
    tripSpendingFactory: TripSpendingFactory;
    paymentFactory: OutsourcerPaymentFactory;
    ownSpendingName: string;
    employee: TStringMap<EmployerDictionaryItemType>;
    onToggleCollapse: () => void;
    collapsed: boolean;
    spendings: OrderTimesheetSpendingType;
    readOnly: boolean;
    actualCollapser: SectionCollapserType;
    disabled?: boolean | undefined;
    baseUrl: string;
    toggleStatus: (guid: string) => void;
    upload: (file: File) => Promise<FileBase | null>;
}

interface DraggableInternalProps {
    factory: OwnSpendingFactory;
    value: TSpending[];
    readOnly: boolean;
    fieldProps: FieldProps;
    employee: TStringMap<EmployerDictionaryItemType>;
    tripSpendingFactory: TripSpendingFactory;
    paymentFactory: OutsourcerPaymentFactory;
    collapser: SectionCollapserType;
    disabled?: boolean | undefined;
    spendings: OrderTimesheetSpendingType;
    baseUrl: string;
    toggleStatus?: (guid: string) => void;
    name: string;
    upload: (file: File) => Promise<FileBase | null>;
}

const DraggableInternal = observer(
    class extends React.Component<DraggableInternalProps> {
        addSpending = async (guid: string, employerId: string) => {
            const { fieldProps } = this.props;

            // if (fieldProps) {
            const value: TSpending[] = fieldProps.field.value;
            const spendingIndex = value.findIndex((v) => v.contentGuid === guid && v.employerId === employerId);
            if (spendingIndex > -1) {
                const payment = await this.props.paymentFactory.emptyOutsourcerPayment(
                    value[spendingIndex].planPayments.length + 1
                );
                const planPayments = [...value[spendingIndex].planPayments, payment];
                let newSpending = { ...value[spendingIndex] };
                newSpending.employerId = employerId;
                newSpending.planPayments = [...planPayments] as any;
                const newSpendings = [...value];

                newSpendings.splice(spendingIndex, 1);
                newSpendings.push(newSpending);
                fieldProps.form.setFieldValue(fieldProps.field.name, newSpendings);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
                //  debugger;
            } else {
                const estimate = await this.props.tripSpendingFactory.emptyTripSpending(guid);
                const payment = await this.props.paymentFactory.emptyOutsourcerPayment(value.length + 1);
                estimate.planPayments.push(payment);
                estimate.employerId = employerId;
                estimate.contentGuid = guid;
                const newValue = [...value, estimate];
                fieldProps.form.setFieldValue(fieldProps.field.name, newValue);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            }
        };

        changePayment = (id: string, payment: OutsourcerPaymentSnapshotType, field: string, text: any) => {
            const { fieldProps } = this.props;
            const value: TSpending[] = fieldProps.field.value;
            const spendingIndex = value.findIndex((v) => v.id === id);
            if (spendingIndex > -1) {
                const planPayments = [...value[spendingIndex].planPayments];
                const index = planPayments.findIndex((v: any) => v.guid === payment.guid);
                const newPayments = [
                    ...planPayments.slice(0, index),
                    { ...payment, [field]: text },
                    ...planPayments.slice(index + 1),
                ];

                const newValue = { ...value[spendingIndex] };
                newValue.planPayments = [...newPayments] as any;
                const newSpendings = [...value];
                newSpendings.splice(spendingIndex, 1, newValue);

                fieldProps.form.setFieldValue(fieldProps.field.name, newSpendings);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            }
        };
        removePayment = (id: string, payment: OutsourcerPaymentSnapshotType) => {
            const { fieldProps } = this.props;
            const value: TSpending[] = fieldProps.field.value;
            const spendingIndex = value.findIndex((v) => v.id === id);
            if (spendingIndex > -1) {
                const planPayments = [...value[spendingIndex].planPayments];
                const actPayments = [...value[spendingIndex].actualPayments];

                const index = planPayments.findIndex((v: any) => v.guid === payment.guid);
                const actIndex = actPayments.findIndex((v: any) => v.planPaymentGuid === payment.guid);
                const newPlanPayments = [...planPayments.slice(0, index), ...planPayments.slice(index + 1)];
                const newActPayments = [...actPayments.slice(0, actIndex), ...actPayments.slice(actIndex + 1)];
                const newValue = { ...value[spendingIndex] };
                newValue.planPayments = [...newPlanPayments] as any;
                newValue.actualPayments = [...newActPayments] as any;
                const newSpendings = [...value];
                newSpendings.splice(spendingIndex, 1, newValue);

                fieldProps.form.setFieldValue(fieldProps.field.name, newSpendings);
                fieldProps.form.setFieldTouched(fieldProps.field.name, true);
            }
        };

        render() {
            const { value, readOnly, fieldProps, collapser, spendings, baseUrl, toggleStatus, upload } = this.props;
            const form = fieldProps.form;
            return (
                <div
                    // {...provided.droppableProps} ref={provided.innerRef}
                    className={styles.objects}
                >
                    {spendings.spendingsMap.map((spending, index) => {
                        if (spending.employee.find((emp) => emp.hoursForTrip > 0)) {
                            const index = spending.label.lastIndexOf(" ");
                            const label = spending.label.slice(0, index);
                            const guid = spending.label.slice(index + 1);

                            return (
                                <OwnSpendingObject
                                    key={spending.label}
                                    index={index}
                                    item={spending}
                                    collapsed={!collapser.plain[spending.label]}
                                    onToggleCollapse={() => {
                                        collapser.toggle(spending.label);
                                    }}
                                    tripSpendings={value}
                                    label={label}
                                    guid={guid}
                                >
                                    <div className={`${styles.spendingsRows}`}>
                                        {spending.employee.map((row: OrderTimesheetSpendingRowSnapshotType) => {
                                            const onAdd = () => this.addSpending(guid, row.employer.id);
                                            if (row.hoursForTrip > 0) {
                                                return (
                                                    <OwnTripSpendingEmployer
                                                        spending={row}
                                                        spendings={spendings}
                                                        formDirty={form.dirty}
                                                        baseUrl={baseUrl}
                                                        toggleStatus={toggleStatus}
                                                        addSpending={onAdd}
                                                        upload={upload}
                                                        readOnly={readOnly}
                                                        tripSpendings={[...value]}
                                                        fieldProps={fieldProps}
                                                        guid={guid}
                                                        onPaymentChange={this.changePayment}
                                                        onRemovePayment={this.removePayment}
                                                    />
                                                );
                                            }

                                            return null;
                                        })}
                                    </div>
                                </OwnSpendingObject>
                            );
                        }
                        return null;
                    })}
                </div>
            );
        }
    }
);
