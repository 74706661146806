import React from "react";
import { Formik, Form } from "formik";
import styles from "./OrderIndicatorDetails.module.scss";
import OrderIndicatorSchema from "./validation";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import { TextArea, Classes } from "@blueprintjs/core";

import { fixTextAreaHeight, MODAL_AWAIT_DELAY } from "modules/common/services/form/textarea";
import { StandardFormInput } from "modules/common/components/form/StandardFormInput";
import { Schema } from "yup";
import { StandardFormButtons } from "modules/common/components/form/StandardFormButtons";
import { texts } from "modules/common/texts";
import { Prompt } from "react-router";
import { fields, OrderIndicatorType } from "../../models/order-indicator";

import {
    DefaultSelectedOption,
    filterItemPredicate,
    renderSingleOption,
    SimpleSelect,
} from "modules/common/services/form/select";
import { IndicatorsListType } from "../../models/indicator-list";

class OrderIndicatorDetailsBase extends React.PureComponent<OrderIndicatorDetailsProps> {
    private schema: Schema<any>;
    private commentArea: HTMLTextAreaElement | null = null;
    private nameArea: HTMLTextAreaElement | null = null;

    constructor(props: OrderIndicatorDetailsProps) {
        super(props);

        this.schema = OrderIndicatorSchema();
    }

    private commentAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.commentArea = ref;
    };

    private nameAreaRef = (ref: HTMLTextAreaElement | null) => {
        this.nameArea = ref;
    };

    private resize = () => {
        fixTextAreaHeight(this.commentArea);
        fixTextAreaHeight(this.nameArea);
    };

    public componentDidMount() {
        setTimeout(this.resize, MODAL_AWAIT_DELAY);
    }

    public componentDidUpdate() {
        this.resize();
    }

    render() {
        const { schema, props } = this;
        const { orderIndicator, onSaved, onRemoved, canChange, indicators } = props;
        return (
            <div className={styles.details}>
                <Formik
                    initialValues={getFormValues(orderIndicator)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        if (!canChange) {
                            return;
                        }

                        const succsessfull = await orderIndicator.save(values);
                        setSubmitting(false);

                        if (succsessfull) {
                            resetForm();
                            onSaved();
                        }
                    }}
                >
                    {(formProps) => {
                        const onRemove = async () => {
                            if (!canChange) {
                                return;
                            }

                            formProps.setSubmitting(true);
                            const success = await orderIndicator.delete();
                            formProps.setSubmitting(false);
                            success && onRemoved();
                        };

                        return (
                            <Form autoComplete="off">
                                <Prompt
                                    when={formProps.dirty && canChange}
                                    message={texts.messages.leaveConfiramtion}
                                />
                                <div className={Classes.DIALOG_BODY}>
                                    <StandardFormInput
                                        name={fields.label}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field }) => (
                                            <div className={`${Classes.INPUT_GROUP} planr-default-input`}>
                                                <TextArea
                                                    id={field.name}
                                                    {...field}
                                                    growVertically={true}
                                                    className={Classes.FILL}
                                                    inputRef={this.nameAreaRef}
                                                    disabled={!canChange}
                                                />
                                            </div>
                                        )}
                                    </StandardFormInput>

                                    <StandardFormInput
                                        name={fields.idIndicator}
                                        schema={schema}
                                        small={true}
                                        className="planr-form-input"
                                    >
                                        {({ field, form }) => {
                                            const typeOption = indicators.asMap[field.value];
                                            return (
                                                <SimpleSelect
                                                    className={`full-width-select ${Classes.FILL}`}
                                                    activeItem={typeOption}
                                                    items={indicators.indicators}
                                                    itemRenderer={renderSingleOption}
                                                    onItemSelect={(i) => {
                                                        form.setFieldValue(field.name, i.id, true);
                                                    }}
                                                    filterable={true}
                                                    itemPredicate={filterItemPredicate}
                                                >
                                                    <DefaultSelectedOption option={typeOption} />
                                                </SimpleSelect>
                                            );
                                        }}
                                    </StandardFormInput>
                                </div>
                                <div className={Classes.DIALOG_FOOTER}>
                                    {canChange && (
                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                            <StandardFormButtons
                                                {...formProps}
                                                isRemoved={false}
                                                what={`ТЭП ${orderIndicator.label}`}
                                                isNewlyCreated={orderIndicator.isNewlyCreated}
                                                onRemove={onRemove}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
}

export const OrderIndicatorDetails = observer(OrderIndicatorDetailsBase);

interface OrderIndicatorDetailsProps {
    orderIndicator: OrderIndicatorType;
    onSaved: () => void;
    onRemoved: () => void;
    canChange: boolean;
    indicators: IndicatorsListType;
}

function getFormValues(orderIndicator: OrderIndicatorType) {
    return {
        ...getSnapshot(orderIndicator),
    };
}
