import React from "react";
import { observer } from "mobx-react";
import styles from "./OrderStatusList.module.scss";
import { columns } from "./columns";
import { saveAgGridColumnSize } from "modules/common/services/table/size-storage";
import { defaultModules, DefaultAgGridOptions } from "modules/common/services/table/helpers";
import { RemoveConfirmation } from "modules/common/components/form/RemoveConfirmation";
import { agGridEntityContextMenu } from "modules/common/components/table/EntityContextMenu";
import { OrderStatusListType, OrderStatusList } from "../../models/order-status-list";
import { OrderStatusSnapshotType, formatOrderStatus } from "../../models/order-status";
import copyToClipboard from "copy-to-clipboard";
import { MenuModule } from "@ag-grid-enterprise/menu";
import {
    GridOptions,
    GetContextMenuItemsParams,
    CellContextMenuEvent,
    RowClickedEvent,
    GridReadyEvent,
} from "@ag-grid-community/core";
import { GridTheme } from "modules/common/components/table/GridTheme";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { agGridLocale } from "modules/root/services/locale";
import { MAX_INT } from "modules/common/constants";

const modules = [...defaultModules, MenuModule];
const TABLE_NAME = OrderStatusList.name;
const sizeSaver = saveAgGridColumnSize(TABLE_NAME);
const config = columns(TABLE_NAME);

export const OrderStatusesList = observer(
    class extends React.Component<OrderStatusesListProps> {
        private options: GridOptions = DefaultAgGridOptions(true);

        render() {
            const { store, onSelected } = this.props;

            return (
                <RemoveConfirmation<OrderStatusSnapshotType>
                    observation={store.data}
                    what={this.removeLabel}
                    onConfirmed={this.removeStatus}
                    render={({ confirmRemoving }) => {
                        const getContextMenuItems = ({ node }: GetContextMenuItemsParams) =>
                            agGridEntityContextMenu({
                                onCopy: () => copyToClipboard(formatOrderStatus(node.data), { format: "text/plain" }),
                                onChange: () => onSelected(getRowNodeId(node.data)),
                                onRemove: () => confirmRemoving(node.data),
                            });

                        return (
                            <GridTheme bordered={true} className={`designed ${styles.grid}`}>
                                <AgGridReact
                                    rowStyle={{
                                        borderBottom: "1 px solid #EDF0F2",
                                        fontFamily: "GothamPro, Arial, sans-serif",
                                        color: "#00273D",
                                        height: "32px",
                                        fontSize: "12px",
                                    }}
                                    rowSelection="single"
                                    suppressCellSelection={true}
                                    enableCellTextSelection={true}
                                    columnDefs={config}
                                    rowData={store.data}
                                    localeText={agGridLocale}
                                    onRowDoubleClicked={this.onRowSelected}
                                    getRowNodeId={getRowNodeId}
                                    getContextMenuItems={getContextMenuItems}
                                    onGridReady={this.onGridReady}
                                    onCellContextMenu={this.onCellContextMenu}
                                    onSortChanged={store.sorter.resorted}
                                    onColumnResized={sizeSaver}
                                    gridOptions={this.options}
                                    modules={modules}
                                    rowBuffer={MAX_INT}
                                />
                            </GridTheme>
                        );
                    }}
                />
            );
        }

        removeLabel = (status: OrderStatusSnapshotType) => `статус договора ${status.name}`;

        removeStatus = async (status: OrderStatusSnapshotType) => {
            const { store, onRemoved } = this.props;
            const success = await store.delete(status);
            success && onRemoved();
        };

        onCellContextMenu = ({ node }: CellContextMenuEvent) => {
            node && node.setSelected(true, true);
        };

        onRowSelected = ({ data }: RowClickedEvent) => {
            const { onSelected } = this.props;
            onSelected(getRowNodeId(data));
        };

        onGridReady = async ({ api }: GridReadyEvent) => {
            const { sorter } = this.props.store;
            setTimeout(() => api.setSortModel(sorter.agGridSortModel));
        };
    }
);

const getRowNodeId = (e: OrderStatusSnapshotType) => e.id;

interface OrderStatusesListProps {
    onRemoved: () => void;
    onSelected: (id: string) => void;
    store: OrderStatusListType;
}
