import { types, applySnapshot, getSnapshot, getParent } from "mobx-state-tree";
import { WorkloadUnit, WorkloadUnitTypeType, WorkloadUnitTypeDetails, WorkloadUnitOrderLink } from "./workload-unit";
import { uniq, sortBy } from "lodash";
import { SORTABLE_DATE_FORMAT } from "modules/common/constants";
import moment from "moment";
import { Transport } from "modules/common/models/transport";
import { Notificator } from "modules/common/models/notificator";
import { OrderDictionaryItem, OrderDictionaryItemType } from "modules/orders-manage/models/order-dictionary";
import { Constants } from "modules/root/models/constants";
import { EmployerDictionaryItem } from "modules/spending/employee/models/employee-dictionary";
import { flow } from "modules/common/models/flow";
import { apiUrls } from "modules/common/services/communication/urls";
import { IdEntity } from "modules/common/models/entity";

export const WorkloadDay = types
    .compose(
        Transport,
        Notificator,
        types.model({
            isPast: types.boolean,
            isDayOff: types.boolean,
            day: types.string,
            dayOfMonth: types.string,
            units: types.array(WorkloadUnit),
            user: EmployerDictionaryItem,
            totalHours: types.number,
            workedOutHours: types.number,
            minimalHours: types.number,
            isDayWorkable: types.boolean,
            comment: types.string,
            isCommented: types.boolean,
            plan: types.array(
                types.model({
                    workId: types.string,
                    workName: types.string,
                    workDescription: types.string,
                    hours: types.number,
                    type: types.string,
                    order: WorkloadUnitOrderLink,
                })
            ),
        })
    )
    .views((self) => ({
        get missingUnit() {
            return self.units.find((unit) => !unit.isWorkedOut);
        },
        get isFuture() {
            const day = moment(self.day, SORTABLE_DATE_FORMAT);
            const now = moment();

            return day.isAfter(now);
        },
        get isComment() {
            return self.comment;
        },
    }))
    .views((self) => ({
        get missingType() {
            return self.missingUnit ? self.missingUnit.type : "";
        },
        get projects() {
            const result = uniq(
                self.units.filter((unit) => !!unit.fullInventoryNumber).map((unit) => unit.fullInventoryNumber)
            );
            return result;
        },
        get displayHours() {
            const separation = [[Constants.workedVacationHoursType.name], [Constants.workedIllnesHoursType.name]];

            for (let separators of separation) {
                const units = self.units.filter((unit) => separators.includes(unit.type));
                if (units.length) {
                    return units.reduce((acc, unit) => acc + unit.hours, 0);
                }
            }

            return self.workedOutHours || self.totalHours;
        },
        get displayType() {
            if (!self.units.length) {
                return null;
            }

            const priotities = [
                Constants.workedOutFromHomeHoursType.name,
                Constants.workedOutHoursType.name,
                Constants.workedOutForExpertiseHoursType.name,
                Constants.workedOutForTripHoursType.name,
                Constants.workedVacationHoursType.name,
                Constants.workedIllnesHoursType.name,
            ];

            for (let priority of priotities) {
                const unit = self.units.find((u) => u.type === priority);
                if (unit) {
                    return unit.typeDetails;
                }
            }

            return self.units[0].typeDetails;
        },
    }))
    .actions((self) => ({
        isToday() {
            const now = moment().format(SORTABLE_DATE_FORMAT);
            return self.day === now;
        },
        isExpired() {
            const day = moment(self.day, SORTABLE_DATE_FORMAT);
            const now = moment();

            return now.diff(day, "day") > Constants.timesheetPastDayLimit;
        },
        setComment: (str: string) => {
            self.comment = str;
        },
    }))
    .actions((self) => ({
        isDayEditable: (canManage: boolean, userLogin: string) => {
            if (self.isFuture) {
                return canManage;
            }

            if (self.isExpired()) {
                return canManage;
            }

            return self.user.login === userLogin || canManage;
        },
    }))
    .named("WorkloadDay");

export type WorkloadDayType = typeof WorkloadDay.Type;
export type WorkloadDaySnapshotType = typeof WorkloadDay.SnapshotType;

export const WorkloadTasks = types
    .compose(
        IdEntity,
        types.model({
            order: types.model({
                id: types.string,
                name: types.string,
                inventoryNumber: types.number,
                fullInventoryNumber: types.string,
            }),
            parent: types.maybeNull(types.string),
            description: types.string,
            key: types.string,
            name: types.string,
            progress: types.number,
            type: types.string,
            guid: types.string,
        })
    )
    .named("WorkloadTasks");

export const TasksDictionary = types
    .compose(
        Transport,
        Notificator,
        types.model({
            tasks: types.array(WorkloadTasks),
        })
    )
    .actions((self) => ({
        load: flow(function* () {
            try {
                const data: any[] = yield self.transport.get<any>(apiUrls.workload.tasks);
                applySnapshot(self.tasks, data);

                return true;
            } catch (er) {
                self.notify.error(er);
                return false;
            }
        }),
    }))
    .views((self) => ({
        get isEmpty() {
            return !self.tasks.length;
        },
    }))
    .named("TasksDictionary");

export const WorkedOutTypes = [
    Constants.workedOutHoursType.name,
    Constants.workedOutFromHomeHoursType.name,
    Constants.workedOutForExpertiseHoursType.name,
    Constants.workedOutForTripHoursType.name,
];

const DayWorkRow = types
    .model({
        project: types.maybeNull(types.number),
        hours: types.number,
        minutes: types.number,
        comment: types.string,
        type: WorkloadUnitTypeDetails,
        fromHome: types.boolean,
        forExpertise: types.boolean,
        forTrip: types.boolean,
        guid: types.string,
        workId: types.string,
        workName: types.string,
        workDescription: types.string,
    })
    .actions((self) => ({
        setProject: (newInventory: number) => {
            self.project = newInventory;
        },
        setTask: (workId: string) => {
            self.workId = workId;
            self.guid = workId;
        },
        setHours: (hours: number) => {
            self.hours = hours;
            const parent: WorkloadDayStoreType | null = getParent(self);
            parent && (parent.dirty = true);
        },
        setMinutes: (hours: number) => {
            self.minutes = hours;
            const parent: WorkloadDayStoreType | null = getParent(self);
            parent && (parent.dirty = true);
        },
        setComment: (str: string) => {
            self.comment = str;
            const parent: WorkloadDayStoreType | null = getParent(self);
            parent && (parent.dirty = true);
        },
        toggleFromHome: () => {
            if (self.type.name === Constants.workedOutHoursType.name) {
                self.fromHome = !self.fromHome;
                self.forExpertise = false;
                self.forTrip = false;
            }
        },
        toggleForExpertise: () => {
            if (self.type.name === Constants.workedOutHoursType.name) {
                self.forExpertise = !self.forExpertise;
                self.fromHome = false;
                self.forTrip = false;
            }
        },
        toggleForTrip: () => {
            if (self.type.name === Constants.workedOutHoursType.name) {
                self.forTrip = !self.forTrip;
                self.fromHome = false;
                self.forExpertise = false;
            }
        },
    }))
    .views((self) => ({
        get isWorkedOut() {
            return (
                self.type.name === Constants.workedOutHoursType.name ||
                self.type.name === Constants.workedOutFromHomeHoursType.name ||
                self.type.name === Constants.idleHoursType.name ||
                self.type.name === Constants.freeFromWorkHoursType.name ||
                self.type.name === Constants.workedOutForExpertiseHoursType.name ||
                self.type.name === Constants.workedOutForTripHoursType.name
            );
        },
        get isDisabled() {
            return self.type.fullDay || self.type.name === Constants.withoutPayHoursType.name;
        },
    }));

export type DayWorkRowType = typeof DayWorkRow.Type;
export type DayWorkRowSnapshotType = typeof DayWorkRow.SnapshotType;

export const MAX_RECORD_COUNT = 10;

export const WorkloadDayStore = types
    .compose(
        Transport,
        Notificator,
        types.model({
            units: types.array(DayWorkRow),
            orders: types.array(OrderDictionaryItem),
            tasks: types.array(WorkloadTasks),
            user: EmployerDictionaryItem,
            minimalHours: types.number,
            isDayOff: types.boolean,
            nonProduction: types.boolean,
            day: WorkloadDay,
            dirty: types.boolean,

            forActualTimesheet: types.boolean,
        })
    )
    .views((self) => ({
        get ordersMap() {
            const map = new Map<number, OrderDictionaryItemType>();

            return self.orders.reduce((result, order) => {
                result.set(order.inventoryNumber, order);
                return result;
            }, map);
        },

        get totalHours() {
            return self.units.reduce((sum, unit) => {
                const hours = unit.hours + unit.minutes / 60;
                return sum + hours;
            }, 0);
        },

        get missingUnit() {
            return self.units.find((unit) => !unit.isWorkedOut);
        },

        get workedOutHours() {
            const units = self.units.filter((unit) => unit.isWorkedOut);
            return units.reduce((sum, unit) => sum + unit.hours, 0);
        },

        get workButtonActive() {
            const condition = [Constants.workedOutHoursType.name];

            return self.units.some((u) => condition.includes(u.type.name));
        },

        get vacationButtonActive() {
            const condition = [Constants.vacationHoursType.name];

            return self.units.some((u) => condition.includes(u.type.name));
        },

        get illnessButtonActive() {
            const condition = [Constants.illnesHoursType.name];

            return self.units.some((u) => condition.includes(u.type.name));
        },
    }))
    .views((self) => ({
        get usedProjects() {
            return self.units.filter((unit) => unit.project !== null).map((unit) => unit.project as number);
        },

        get missingType() {
            return self.missingUnit ? self.missingUnit.type.name : "";
        },
    }))
    .actions((self) => ({
        validate() {
            if (self.units.some((unit) => !unit.hours && unit.minutes < 1)) {
                throw new Error("Необходимо заполнить все строки");
            }

            if (self.forActualTimesheet && self.units.some((unit) => unit.project != null && !unit.workId)) {
                throw new Error("Необходимо выбрать вид работ");
            }

            const keys = sortBy(uniq(self.units.map((unit) => unit.type.name)));
            const validator = Constants.maxHoursPerDays.find((max) => max.unitTypes.every((t) => keys.includes(t)));

            const maxHours = validator ? validator.hours : Constants.workDayHours;
            const totalHours = !!validator
                ? self.units
                      .filter((unit) => validator.unitTypes.includes(unit.type.name))
                      .reduce((acc, unit) => acc + unit.hours, 0)
                : self.totalHours;

            if (totalHours > maxHours) {
                const what = validator ? ` для ${validator.unitTypes.join(", ")}` : "";
                throw new Error(`Общее кол-во часов${what} не может превышать ${maxHours}`);
            }

            const minHours = Constants.workDayHours;
            if (self.forActualTimesheet && !self.isDayOff && self.totalHours < minHours) {
                throw new Error(`Необходимо заполнить минимум ${minHours} час(ов)`);
            }
        },

        projectTasks(inventoryNumber: number | null) {
            let tasks: { [k: string]: any } = {};
            let taks: any[] = [];
            self.tasks.forEach((task) => {
                if (task.order.inventoryNumber === inventoryNumber) {
                    let res: OptionItem = { id: task.guid, label: `${task.name} ${task.description}` };
                    tasks[task.guid] = res;
                    taks.push(res);
                }
            });

            return tasks;
        },

        recalculateWithoutPayHours() {
            const unit = self.units.find((u) => u.type.name === Constants.withoutPayHoursType.name);
            if (unit) {
                const value = getTypeAllowedHours(self.units, Constants.withoutPayHoursType);
                unit.setHours(value);
            }
        },

        revertChanges(initialData: DayWorkRowSnapshotType[]) {
            applySnapshot(self.units, initialData);
            self.dirty = false;
        },
    }))
    .actions((self) => ({
        getSubmitModel() {
            try {
                self.validate();

                const units: SaveWorkloadUnit[] = self.units.map(
                    ({ hours, project, minutes, type, fromHome, forExpertise, forTrip, comment, workId }) => ({
                        hours,
                        minutes,
                        inventoryNumber: project,
                        type: type.name,
                        fromHome,
                        forExpertise,
                        forTrip,
                        comment,
                        workId,
                    })
                );

                return {
                    day: self.day.day,
                    employerId: self.user.id,
                    units,
                    comment: self.day.comment,
                };
            } catch (er) {
                self.notify.error(er);
                return null;
            }
        },

        addUnit(unit: DayWorkRowSnapshotType) {
            const notAllowed: string[] = [];

            self.units.forEach((u) => {
                if (!unit.type.stacksWith.includes(u.type.name)) {
                    notAllowed.push(u.type.name);
                }
            });

            const units = [
                ...self.units
                    // remove incompatible items
                    .filter((u) => !notAllowed.includes(u.type.name))
                    .map((u) => getSnapshot(u)),
                unit,
            ];

            applySnapshot(self.units, units);
            self.recalculateWithoutPayHours();
        },
    }))
    .actions((self) => ({
        addProject: (inventory: number | null = null) => {
            const candidate = self.units.find((unit) => unit.type.name === Constants.workedOutHoursType.name);
            const max = self.nonProduction ? 1 : MAX_RECORD_COUNT;

            if (self.units.filter((u) => u.isWorkedOut).length < max) {
                self.addUnit({
                    hours: self.forActualTimesheet ? 0 : Constants.workDayHours,
                    minutes: 0,
                    project: inventory,
                    type: getSnapshot(Constants.workedOutHoursType),
                    fromHome: candidate ? candidate.fromHome : false,
                    forExpertise: candidate ? candidate.forExpertise : false,
                    forTrip: candidate ? candidate.forTrip : false,
                    comment: "",
                    guid: "",
                    workId: "",
                    workName: "",
                    workDescription: "",
                });

                self.dirty = true;
            }
        },

        removeUnit: (index: number | string) => {
            if (typeof index === "string") {
                const elements = self.units.filter((u) => u.type.name !== index);
                self.units.replace(elements);
                self.dirty = true;
            } else {
                if (self.units.length > index) {
                    const element = self.units[index];

                    self.units.remove(element);
                    self.dirty = true;
                }
            }

            self.recalculateWithoutPayHours();
        },
    }))
    .actions((self) => ({
        setMissingType: (type: WorkloadUnitTypeType) => {
            const current = self.missingUnit;

            if (current && type.name === current.type.name) {
                self.removeUnit(type.name);
                return;
            }

            if (!current || current.type.name !== type.name) {
                self.addUnit({
                    hours: getTypeAllowedHours(self.units, type),
                    minutes: 0,
                    project: null,
                    type: getSnapshot(type),
                    fromHome: false,
                    forExpertise: false,
                    forTrip: false,
                    comment: "",
                    guid: "",
                    workId: "",
                    workName: "",
                    workDescription: "",
                });

                self.dirty = true;
            }
        },
    }));

interface SaveWorkloadUnit {
    hours: number;
    inventoryNumber: number | null;
    type: string;
    fromHome: boolean;
    forExpertise: boolean;
    forTrip: boolean;
    workId: string | null;
}

export type WorkloadDayStoreType = typeof WorkloadDayStore.Type;
export type WorkloadDayStoreSnapshotType = typeof WorkloadDayStore.SnapshotType;
export type WorkloadTasksSnapshotType = typeof WorkloadTasks.SnapshotType;
export type TasksDictionarySnapshotType = typeof TasksDictionary.SnapshotType;
export type TasksDictionaryType = typeof TasksDictionary.Type;
export const initialState = (): TasksDictionarySnapshotType => ({
    tasks: [],
});

export function getTypeAllowedHours(units: DayWorkRowType[], type: WorkloadUnitTypeType) {
    if (type.name === Constants.withoutPayHoursType.name) {
        const other = units.reduce((acc, unit) => acc + (unit.isWorkedOut ? unit.hours : 0), 0);
        return Math.max(0, Constants.workDayHours - other);
    }

    return type.fullDay ? Constants.workDayHours : type.defaultHours || 0;
}
