import React from "react";
import { Toast, Toaster, Intent, IconName } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { NotificationType } from "modules/common/services/notifications/types";
import { ToasterOptions } from "./toaster";
import { useMst } from "modules/root/models/hooks";

export const Notifications: React.FC = observer(() => {
    const { notifications } = useMst();

    return (
        <Toaster {...ToasterOptions}>
            {notifications.alerts.map((alert) => {
                const { id, type, message } = alert;
                return (
                    <Toast
                        icon={icon(type)}
                        key={id}
                        onDismiss={() => notifications.closeNotification(id)}
                        message={message}
                        intent={intent(type)}
                        timeout={timeout(type)}
                    />
                );
            })}
        </Toaster>
    );
});

export const icon = (type: NotificationType): IconName => {
    switch (type) {
        case "error":
            return "disable";
        case "success":
            return "small-tick";
        case "warning":
            return "notifications";
        default:
            return "error";
    }
};

export const timeout = (type: NotificationType) => (type === "error" || type === "warning" ? 0 : 5000);

export const intent = (type: NotificationType): Intent => {
    switch (type) {
        case "error":
            return "danger";
        case "success":
            return "success";
        case "warning":
            return "primary";
        default:
            return "none";
    }
};
