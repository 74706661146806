import { FormikProps } from "formik";
import { observer } from "mobx-react";
import { Money } from "modules/common/components/money/Money";
import { PlanrButton } from "modules/common/components/planr/button/Button";
import { CollapseExpandAll } from "modules/common/components/table/CollapseExpandAll";
import { texts } from "modules/common/texts";
import { fields, OrderExternalParams, OrderSummarySnapshotType } from "modules/orders-manage/models/order";
import { OrdersStoreType } from "modules/orders-manage/models/orders-store";
import { IdFactory, UploaderFatory } from "modules/orders-manage/types";
import { EmployerDictionaryType } from "modules/spending/employee/models/employee-dictionary";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Schema } from "yup";
import { OtherSpendingFactory, OtherSpendings } from "./other/OtherSpendings";
import { OutsourcerPaymentFactory } from "./outsourced/OutsourcedSpendingRow";
import { OutsourcedSpendingFactory, OutsourcedSpendings } from "./outsourced/OutsourcedSpendings";
import { OwnSpendingFactory, OwnSpendings } from "./own/OwnSpendings";
import { OwnTripSpendings, TripSpendingFactory } from "./ownTripSpendings/OwnTripSpendings";
import { OrderPremium } from "./premium/Premium";
import styles from "./Spendings.module.scss";

class Base extends React.Component<SpendingsProps & RouteComponentProps> {
    static whyDidYouRender = false;

    recordPointer = "";
    printable = React.createRef<HTMLDivElement>();

    constructor(props: any) {
        super(props);

        // try expand section from query
        const external = OrderExternalParams(this.props.location.hash);
        this.recordPointer = external.rowId;
    }

    printOrderFile = (outsourcerId: string, contentGuid: string, comment?: string) => {
        const { store } = this.props;
        store.printOutsourcerOrder(outsourcerId, contentGuid, comment);
    };

    render() {
        const {
            ownSpendingFactory,
            paymentFactory,
            otherSpendingFactory,
            outsourcedSpendingFactory,
            tripSpendingFactory,
        } = this.props;
        const { formProps, readOnly, baseUrl, newId, upload, employee, store, schema, isActiveTab } = this.props;
        const { factMoney } = store.details.timesheetSpendings.spendingsTotal;
        if (!isActiveTab) {
            return <div className={styles.spendings}></div>;
        }

        const outsourcerOrderTotal = formProps.values.outsourcedOrderSpendins.reduce(
            (acc, unit) => +unit.actualSum + acc,
            0
        );

        const sumPremiums = () => {
            const { store } = this.props;
            let assignedSum = 0;
            Object.values(store.premiums.premiumProductionAsMap).forEach((premiums) => {
                premiums.forEach((p) => {
                    assignedSum += p.sum;
                }, 0);
            });
            assignedSum += store.premiums.engineerPremium.sum;
            return assignedSum;
        };

        const outsourcerActualTotal = formProps.values.outsourcedOrderSpendins.reduce((acc, unit) => {
            return (
                acc +
                unit.actualPayments.reduce((t, p) => t + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0), 0)
            );
        }, 0);
        const outsourcerLeftTotal = outsourcerOrderTotal - outsourcerActualTotal;

        const otherOrderTotal = formProps.values.otherOrderSpendings.reduce((acc, unit) => +unit.actualSum + acc, 0);
        const otherActualTotal = formProps.values.otherOrderSpendings.reduce((acc, unit) => {
            return (
                acc +
                unit.actualPayments.reduce((t, p) => t + p.sum - p.correctionPayments.reduce((s, c) => s + c.sum, 0), 0)
            );
        }, 0);
        const otherLeftTotal = otherOrderTotal - otherActualTotal;
        let ownOrderTotal = 0;
        const premiums = sumPremiums();
        store.details.ownOrderSpendings.forEach((item) => {
            const user = employee.employee.filter((employee) => employee.id === item.employerId);
            ownOrderTotal += user[0].companySpendingPerHour * item.hours;
        });
        const ownActualTotal = factMoney;

        const premiumsActualTotal = store.premiums.paidSum;

        const orderTotal = outsourcerOrderTotal + otherOrderTotal + ownOrderTotal;

        const actualTotal = outsourcerActualTotal + otherActualTotal + ownActualTotal;
        const actualTotalPremium = outsourcerActualTotal + otherActualTotal + ownActualTotal + premiumsActualTotal;
        const leftTotal = outsourcerLeftTotal + otherLeftTotal;

        const orderStatusId = (formProps.values as any)[fields.orderStatusId];
        const cantEditFinanceValues = !orderStatusId || store.statuses.canEditOrderFinanceFields(orderStatusId);
        const cantAssingPremium = store.statuses.canAssingPremium(store.details.orderStatusId);
        const canEditDistribution = !cantAssingPremium;

        return (
            <div className={styles.spendings}>
                <div className="planr-tools">
                    <PlanrButton
                        type="graybtn"
                        icon="general-contact"
                        round={true}
                        title="Сформировать лист контактов"
                    />

                    <PlanrButton
                        type="graybtn"
                        icon="general-print"
                        round={true}
                        title={texts.print}
                        onClick={() => {
                            store.printSpendingsTab(formProps.values);
                        }}
                    />

                    <CollapseExpandAll onChange={this.collapseAll} />
                </div>

                <div
                    ref={this.printable}
                    className={styles.inner}
                    //     style={premiumsOrderTotal > 0 || premiumsActualTotal > 0 ? { paddingTop: "45px" } : {}}
                >
                    <div className={styles.spendingsTotal}>
                        <div>
                            <div className="order-total" style={{ display: "flex", flexDirection: "column" }}>
                                {premiums > 0 ? (
                                    <div>
                                        Всего в т.ч. премии:&nbsp;
                                        <Money className="spendings-money" amount={orderTotal + premiums} />
                                    </div>
                                ) : (
                                    <div>
                                        Всего по договору:&nbsp;
                                        <Money className="spendings-money" amount={orderTotal} />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div
                            className={`actual-total ${leftTotal > 0 ? "red-total" : "green-total"}`}
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            {premiumsActualTotal > 0 ? (
                                <div>
                                    По оплатам (в т.ч. премии):&nbsp;
                                    <Money className="spendings-money" amount={actualTotalPremium} />
                                </div>
                            ) : (
                                <div>
                                    Всего по оплатам:&nbsp;
                                    <Money className="spendings-money " amount={actualTotal} />
                                </div>
                            )}
                        </div>
                        <div
                            className={`spendings-total ${
                                orderTotal - actualTotal !== 0 ? "red-total" : "green-total"
                            }`}
                        >
                            Остаток по оплатам:&nbsp;
                            <Money className="spendings-money" amount={leftTotal} />
                        </div>
                    </div>
                    <OutsourcedSpendings
                        disabled={!cantEditFinanceValues}
                        agents={store.agents}
                        name={fields.outsourcedOrderSpendins}
                        paymentFactory={paymentFactory}
                        spendingFactory={outsourcedSpendingFactory}
                        collapsed={store.spendingCollapse.outsource}
                        onToggleCollapse={() => store.toggleCollapse("outsource")}
                        innerCollapser={store.outsourceCollapser}
                        toggleStatus={store.togglePaymentStatus}
                        readOnly={readOnly}
                        highlightRow={this.recordPointer}
                        baseUrl={baseUrl}
                        newId={newId}
                        upload={upload}
                        printOrderFile={store.details.isNewlyCreated ? undefined : this.printOrderFile}
                    />

                    <OtherSpendings
                        disabled={!cantEditFinanceValues}
                        agents={store.agents}
                        name={fields.otherOrderSpendings}
                        paymentFactory={paymentFactory}
                        collapsed={store.spendingCollapse.other}
                        factory={otherSpendingFactory}
                        onToggleCollapse={(value?: boolean) => {
                            typeof value === "undefined"
                                ? store.toggleCollapse("other")
                                : store.setupCollapseState("other");
                        }}
                        toggleStatus={store.togglePaymentStatus}
                        readOnly={readOnly}
                        highlightRow={this.recordPointer}
                        innerCollapser={store.otherCollapser}
                        baseUrl={baseUrl}
                        newId={newId}
                        upload={upload}
                        printOrderFile={store.details.isNewlyCreated ? undefined : this.printOrderFile}
                    />

                    <OwnSpendings
                        disabled={!cantEditFinanceValues}
                        factory={ownSpendingFactory}
                        name={fields.ownOrderSpendings}
                        employee={employee.asMap}
                        collapsed={store.spendingCollapse.own}
                        onToggleCollapse={() => store.toggleCollapse("own")}
                        spendings={store.details.timesheetSpendings}
                        readOnly={readOnly}
                        planCollapser={store.ownPlanCollapser}
                        actualCollapser={store.ownActualCollapser}
                    />

                    <OwnTripSpendings
                        disabled={!cantEditFinanceValues}
                        tripSpendingFactory={tripSpendingFactory}
                        factory={ownSpendingFactory}
                        name={fields.tripOrderSpendings}
                        paymentFactory={paymentFactory}
                        ownSpendingName={fields.ownOrderSpendings}
                        employee={employee.asMap}
                        collapsed={store.spendingCollapse.trip}
                        onToggleCollapse={() => store.toggleCollapse("trip")}
                        spendings={store.details.timesheetSpendings}
                        readOnly={readOnly}
                        upload={upload}
                        actualCollapser={store.rowTripCollapser}
                        baseUrl={baseUrl}
                        toggleStatus={store.togglePaymentStatus}
                    />

                    <OrderPremium
                        collapsed={store.spendingCollapse.premium}
                        onToggleCollapse={() => store.toggleCollapse("premium")}
                        readOnly={readOnly}
                        bank={store.details.premiumBank}
                        store={store.premiums}
                        name={fields.employeeFeedback}
                        canAssing={cantAssingPremium}
                        cantEdit={canEditDistribution}
                        schema={schema}
                        formProps={formProps}
                        hide={false}
                        premiums={premiums}
                    />
                </div>
            </div>
        );
    }

    collapseAll = (state: boolean) => {
        const { store } = this.props;
        store.spendingCollapse.setAll(state);
        store.otherCollapser.setAll(state);
        store.outsourceCollapser.setAll(state);
        store.ownPlanCollapser.setAll(state);
    };
}

export const Spendings = withRouter(observer(Base));

interface SpendingsProps extends UploaderFatory, IdFactory {
    formProps: FormikProps<OrderSummarySnapshotType>;
    paymentFactory: OutsourcerPaymentFactory;
    ownSpendingFactory: OwnSpendingFactory;
    otherSpendingFactory: OtherSpendingFactory;
    outsourcedSpendingFactory: OutsourcedSpendingFactory;
    tripSpendingFactory: TripSpendingFactory;
    employee: EmployerDictionaryType;
    store: OrdersStoreType;
    readOnly: boolean;
    schema: Schema<any>;
    baseUrl: string;
    isActiveTab: boolean;
}
